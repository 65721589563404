import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

export default function BasicBreadcrumbs() {
  const navigate = useNavigate();

  function handleClick(event) {
    const target = event.target;

    if (target.tagName === "A") {
      event.preventDefault(); // Prevent default navigation for `A` tags

      const href = target.getAttribute("href");
      if (href === "back") {
        navigate(-1, { state: "communicationTracker" }); // Navigate back with state
      } else if (href) {
        navigate(href); // Navigate to the specified path
      }
    }
  }

  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Dashboard
        </Link>
        <Link underline="hover" color="inherit" href="back">
          CommunicationTrackerList
        </Link>
        <Typography color="text.primary" className="fw-bold">
          CommunicationDetail
        </Typography>
      </Breadcrumbs>
    </div>
  );
}
