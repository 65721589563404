import React,{useState,useEffect} from "react";
import DateRangePickerLatest from "../commonComponents/DateRangePicker";
import LogsTable from "./StoreLogsTable";
import BasicPagination from "../commonComponents/Pagination";
import { useLocation } from "react-router-dom";
import { getStoreItemLogsApi} from "../commonComponents/storeApi";
import { errorsMessage } from "../../constant";
import SimpleBackdrop from "../commonComponents/ShowBackDrop";
function RecentLogs() {
    const[loading,setLoading] = useState(false)
    const[itemLog,setItemLog] = useState([])
   const [pageCount, setPageCount] = useState(1);
   const[startDate,setStartDate]=useState(null)
   const[endDate,setEndDate]=useState(null)
     const [page, setPage] = useState(1);
    const location = useLocation();
    const { item_id, store_id,name } = location.state || {};
    const userProfile = JSON.parse(localStorage.getItem("profile"));
    const user_id = userProfile?.response?.userDetails?.id;
    // function handlePaginateChange(event, value) {
    //     setPage(value);
    //   }
       function itemLogs() {
          setLoading(true);
          getStoreItemLogsApi({ user_id, store_id:store_id,item_id:item_id,page:page,startDate:startDate,endDate:endDate })
            .then((res) => {
              setItemLog(res?.data?.response?.storeItemLog?.data);
            setPageCount(res?.data?.response?.storeItemLog?.last_page)
             
            })
            .catch((err) => {
              errorsMessage(err?.response?.status);
            })
            .finally(() => {
              setLoading(false);
            });
        }
        function handlePaginateChange(event, value) {
          setPage(value);
        }
        const handleFilterChange = (name, value) => {
          console.log("Change detected:", name, value); 
          setStartDate(value?.[0])// Logs the filter name and value
          setEndDate(value?.[1])
        };
        useEffect(()=>{
        itemLogs()
        },[store_id,item_id,page,startDate,endDate])
  return (
    <div className="page-wrapper mt-3">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <h3 className="fw-semibold" style={{ fontSize: "1.5rem" }}>
                RECENT LOGS
              </h3>
            </div>
            <div className="col-md-4 text-end">
              <div>
          <DateRangePickerLatest handleChange={handleFilterChange} />
        
              </div>
            </div>
          </div>
          <LogsTable itemLog={itemLog} name={name}/>
           <SimpleBackdrop open={loading} />
          <div className="text-end mt-2">
            <BasicPagination
              handlePaginateChange={handlePaginateChange}
              page={page}
              pageCount={pageCount}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecentLogs;
