import React from "react";
import CircularSize from "./Loader";

const ItemsSuggestion = ({ suggestions, suggestionLoading }) => {
  return (
    <div className="suggestions-container">
      <div className="suggestions-header">
        <h3 className="title">Suggestions</h3>
        <span className="item-count">No. Of Items : {suggestions.length}</span>
      </div>

      {suggestionLoading ? (
        <div className="p-4 d-flex justify-content-center">
          <CircularSize />
        </div>
      ) : suggestions?.length > 0 ? (
        <div className="suggestions-list">
          {suggestions.map((item, index) => (
            <div key={index} className="suggestion-item">
              {item}
            </div>
          ))}
        </div>
      ) : (
        <div className="p-4 text-center text-muted">
          There are no item suggestions.
        </div>
      )}
    </div>
  );
};

export default ItemsSuggestion;
