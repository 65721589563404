import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import { formatDate } from "../../../../constant";

export default function ConfirmCancelModal({
  open,
  setOpen,
  cancelModalLoader,
  handleCancelPo,
  deliveries,
  setCancelled_reason,
  cancelled_reason,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [showAll, setShowAll] = React.useState(false);
  const displayedDeliveries = showAll ? deliveries : deliveries.slice(0, 2);

  const handleClose = () => {
    setCancelled_reason("");
    setShowAll(false);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{
          borderRadius: "15px",
          "& .MuiPaper-root": {
            padding: ".9rem 1.2rem .9rem 1.2rem",
            borderRadius: "15px",
          },
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            fontWeight: "600",
            color: "black",
            fontSize: "2rem",
            textAlign: "left",
          }}
        >
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className="text-left"
            sx={{
              color: "black",
              fontSize: "1rem",
              textAlign: "left",
            }}
          >
            You want to Cancel the All Upcoming <br />
            Delivery Scheduled?
          </DialogContentText>

          <div
            className="cancel-modal mb-2"
            style={{ maxHeight: "11rem", overflow: "auto" }}
          >
            <div className="cancel-modal-header">
              <div className="cancel-modal-cell">Quantity</div>
              <div className="cancel-modal-cell">Upcoming Delivery Date</div>
            </div>
            {deliveries.length === 0 ? (
              <div className="cancel-modal-no-delivery">
                No delivery schedule for this purchase order.
              </div>
            ) : (
              <>
                {displayedDeliveries?.map((delivery, index) => (
                  <div className="cancel-modal-row" key={index}>
                    <div className="cancel-modal-cell">
                      Qty - {delivery.quantity}
                    </div>
                    <div className="cancel-modal-cell">
                      {formatDate(delivery.schedule_date)}
                    </div>
                  </div>
                ))}
                {!showAll && deliveries.length > 2 && (
                  <div
                    className="cancel-modal-show-more"
                    onClick={() => setShowAll(true)}
                    style={{ cursor: "pointer", color: "blue" }}
                  >
                    {deliveries.length - 2} more...
                  </div>
                )}
              </>
            )}
          </div>

          <textarea
            placeholder="Enter your remarks here..."
            rows="4"
            maxLength="250"
            style={{
              width: "30rem",
              background: "#F1F1F1",
              border: "1px solid #F1F1F1",
              borderRadius: "5px",
              padding: "10px",
            }}
            name="email_remarks"
            onChange={(e) => setCancelled_reason(e.target.value)}
            value={cancelled_reason}
          />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "left",
            padding: "0 2rem 0 1.6rem",
          }}
        >
          <button
            onClick={handleClose}
            className="btn"
            style={{
              border: "1.1px solid black",
              borderRadius: ".25rem",
              width: "7rem",
            }}
            disabled={cancelModalLoader}
          >
            <p
              style={{
                color: "black",
                fontSize: ".95rem",
                fontWeight: "600",
              }}
              className="mb-0"
            >
              No
            </p>
          </button>
          <button
            type="button"
            onClick={() => handleCancelPo()}
            className="btn btn-primary"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              padding: "10px 20px",
              width: "10rem",
            }}
            disabled={cancelModalLoader}
          >
            {!cancelModalLoader ? (
              "Yes Cancel"
            ) : (
              <CircularProgress
                size={20}
                sx={{ color: "white", position: "absolute" }}
              />
            )}
          </button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
