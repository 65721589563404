import React, { useEffect, useState } from "react";
import { signatureUpdateApi } from "../redux/api";
import { toast } from "react-toastify";

function DigitalSignatureUploader({ userData }) {
  const [file, setFile] = useState(null);
  const [existingImage, setExistingImage] = useState(null);
  const imageBaseUrl = process.env.REACT_APP_BASE_URL;
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileSizeInKB = selectedFile.size / 1024;
      const fileExtension = selectedFile.type.split("/")[1];
      if (
        (fileExtension === "png" || fileExtension === "jpeg") &&
        fileSizeInKB <= 500
      ) {
        setFile(selectedFile);
        signatureUpload(selectedFile);
      } else {
        alert("Please upload a PNG/JPEG file under 500KB.");
      }
    }
  };

  const signatureUpload = async (selectedFile) => {
    if (!selectedFile) {
      toast.error("No file selected. Please upload a valid signature.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("signature", selectedFile);

      formData.append("id", user_id);

      const response = await signatureUpdateApi(formData);

      if (response?.status === 200) {
        toast.success("Signature uploaded successfully!");
      } else {
        toast.error(
          response?.data?.message ||
            "Failed to upload signature. Please try again."
        );
      }
    } catch (error) {
      console.error("Error during signature upload:", error);
      toast.error("An unexpected error occurred. Please try again.");
    }
  };

  const handleFileRemove = () => {
    setFile(null);
    setExistingImage(null);
  };

  useEffect(() => setExistingImage(userData?.signature), [userData]);

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        margin: "20px",
      }}
    >
      <h3 style={{ marginBottom: "20px" }}>Digital Signature</h3>
      <div className="row">
        <div
          style={{
            border: "2px dashed #ccc",
            borderRadius: "5px",
            padding: "20px",
            textAlign: "center",
            cursor: "pointer",
          }}
          className="col-md-3"
        >
          <label
            htmlFor="file-upload"
            style={{ cursor: "pointer", color: "#5c6bc0" }}
          >
            <input
              type="file"
              id="file-upload"
              style={{ display: "none" }}
              accept="image/png, image/jpeg"
              onChange={handleFileChange}
            />
            Drop file to upload or{" "}
            <span style={{ textDecoration: "underline" }}>Browse</span>
          </label>
          <p style={{ margin: "10px 0", color: "#666" }}>
            Kindly upload your signature on a white background in PNG or JPEG
            format
          </p>
        </div>

        {(file || existingImage) && (
          <div style={{ marginTop: "20px" }} className="col-md-4">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #ddd",
                borderRadius: "5px",
                padding: "10px",
                background: "#f9f9f9",
              }}
            >
              <img
                src={
                  file
                    ? URL.createObjectURL(file) // Preview for the uploaded file
                    : imageBaseUrl + existingImage // Existing image URL
                }
                alt="Preview"
                style={{
                  width: "150px",
                  height: "130px",
                  objectFit: "contain",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  marginRight: "10px",
                }}
              />
              <div>
                <strong>{file ? file.name : "Existing Image"}</strong>
                {file && (
                  <p style={{ margin: 0, color: "#888", fontSize: "12px" }}>
                    {(file.size / 1024).toFixed(2)} KB
                  </p>
                )}
              </div>
              <button
                onClick={handleFileRemove}
                style={{
                  background: "transparent",
                  border: "none",
                  color: "red",
                  cursor: "pointer",
                  marginLeft: "auto",
                  fontSize: "16px",
                }}
              >
                &times;
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DigitalSignatureUploader;
