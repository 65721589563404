import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  addNewCommunicationApi,
  updateCommunicationApi,
} from "../../../../../redux/api";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import CommunicationTrackerForm from "./CommunicationTrackerForm";
import { errorsMessage } from "../../../../../constant";
import { updateCommunicationValidationSchema } from "./communicationValidationSchema";

function EditCommunicationTrackerModal(props) {
  const [loading, setLoading] = useState(false);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const initialValue = {
    to: "",
    written_by: "",
    subject: "",
    ref_po_loa_no: "",
    approved_by: "",
    letter_date: "",
    type: "",
    ref_no: "",
    status: "",
    attachment: "",
    revert_against_ref_no: "",
  };

  const updateCommunication = async (values) => {
    setLoading(true);
    try {
      const res = await updateCommunicationApi({
        ...values,
        user_id,
        communication_id: props?.data?.id,
      });
      toast.success("Communication Updated Successfully");
      props?.communicationTrackerListData();
      props.onHide();
      resetForm();
    } catch (error) {
      if (!error.response) {
        toast.error("Network error: Please check your internet connection.");
      } else {
        errorsMessage(error.response.status);
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (props?.data?.ref_no) {
      const { written_by_name, approved_by_name, ...restData } =
        props?.data || {};

      const removeAttachmentKey = {
        ...restData,
        attachment: "",
      };

      setValues(removeAttachmentKey);
    }
  }, [props?.data]);

  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: updateCommunicationValidationSchema,
    onSubmit: (values) => {
      updateCommunication(values);
    },
  });

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Communication
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <CommunicationTrackerForm
            values={values}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            formType="Edit"
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        {loading ? (
          <div className="text-center">
            <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
            <p>Loading...</p>
          </div>
        ) : (
          <div>
            <Button onClick={props.onHide} className="btn btn-secondary me-2">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              type="button"
              className="btn btn-primary"
            >
              Update
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
}
export default EditCommunicationTrackerModal;
