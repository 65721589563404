import React, { useEffect, useState } from "react";
import CommonTable from "../Common/CommonTable";
import { Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CommonTd from "../../Tender/Common/CommonTd";
import { getPurchaseOrderCsvFile, poOrderListApi } from "../purchaseApi";
import { errorsMessage, formatDate } from "../../constant";
import { FaFilePdf } from "react-icons/fa";
import BasicPagination from "../../StoreDepartment/commonComponents/Pagination";
import { getTenderUser } from "../../redux/api";
import { amountFormatJS } from "../../common/amountFormat";

function POListingPage() {
  const [loading, setLoading] = useState(false);
  const [poOrderList, setPoOrderList] = useState([]);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const department_id = userProfile?.response?.userDetails?.department_id;
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const { state } = useLocation();
  const [filters, setFilters] = useState({
    startDate: state?.startDate || "",
    endDate: state?.endDate || "",
    search_by: "railway_po_no",
    search: "",
    prepared_by: "",
  });
  const [timer, setTimer] = useState(null);
  const [purchaseUser, setPurchaseUser] = useState([]);
  const navigate = useNavigate();
  const railwayPOOptions = [
    { option: "Railway PO Number", value: "railway_po_no" },
    { option: "Purchase Order Number", value: "purchase_order_no" },
    { option: "Party Name", value: "party_name" },
  ];
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  async function downloadCsv() {
    try {
      const res = await getPurchaseOrderCsvFile({ ...filters, user_id });
      const fileUrl = res?.data?.response?.purchaseCSVList?.file_url;

      if (fileUrl) {
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = "PO_Listing.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("No file URL found in the response");
      }
    } catch (error) {
      errorsMessage(error?.response?.status);
    }
  }

  function getTenderUserList() {
    getTenderUser({ user_id, department_id })
      .then((res) => {
        setPurchaseUser(res?.data?.response?.TenderAuthorisedList);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    getTenderUserList();
  }, []);

  function poOrderData() {
    setLoading(true);
    poOrderListApi({ ...filters, user_id, page })
      .then((res) => {
        setPoOrderList(res?.data?.response?.PurchaseOrderList?.data);
        setPageCount(res?.data?.response?.PurchaseOrderList?.last_page);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handlePaginateChange(event, value) {
    setPage(value);
  }

  function editData(element) {
    navigate("/purchase-order/po-form", { state: { editData: element } });
  }

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    const delay = setTimeout(() => {
      poOrderData();
    }, 400);

    setTimer(delay);

    return () => clearTimeout(delay);
  }, [filters, page]);

  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="row mt-3 ">
            <div className="col-sm-2">
              <h4 className="page-title">Purchase Order</h4>
            </div>
            <div className="col-sm-7 text-end">
              <button
                className="btn btn-primary text-align-end"
                onClick={downloadCsv}
              >
                Download CSV
              </button>
            </div>
            <div className="col-sm-3 text-end pe-5">
              <select
                id="prepared_by"
                className="form-control"
                name="prepared_by"
                onChange={(e) =>
                  setFilters({ ...filters, prepared_by: e.target.value })
                }
              >
                <option value="" className="text-secondary" hidden disabled>
                  Prepared By
                </option>
                <option value="">All</option>
                {purchaseUser.map((user) => (
                  <option value={user.id} key={user.id}>
                    {user.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row mt-3 mb-2">
            <div className="col-sm-12 text-end">
              <div className="row">
                <div className="col-sm-5"></div>
                <div className="col-sm-2">
                  <select
                    className="form-control"
                    name="search_by"
                    style={{ backgroundColor: "#F5F8FA" }}
                    onChange={(e) =>
                      setFilters({ ...filters, search_by: e.target.value })
                    }
                  >
                    {railwayPOOptions.map((option, index) => (
                      <option key={index} value={option?.value}>
                        {option?.option}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-sm-2">
                  <input
                    type="text"
                    className="form-control"
                    name="purchase_order_no or party_name"
                    placeholder="Search"
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        search: e.target.value,
                      })
                    }
                  />
                </div>
                {/* <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control"
                    name="party_name"
                    placeholder="Search by Party Name"
                    onChange={(e) =>
                      setFilters({ ...filters, party_name: e.target.value })
                    }
                  />
                </div> */}
                <div className="col-sm-3 d-flex pe-5">
                  <input
                    type="date"
                    className="form-control"
                    name="startDate"
                    onChange={(e) =>
                      setFilters({ ...filters, startDate: e.target.value })
                    }
                  />
                  <input
                    type="date"
                    className="form-control"
                    name="endDate"
                    onChange={(e) =>
                      setFilters({ ...filters, endDate: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          {loading ? (
            <div
              className="d-flex align-items-center justify-content-center pb-5"
              style={{ height: "60vh" }}
            >
              <Spinner animation="grow" variant="danger" />
              <Spinner animation="grow" variant="danger" />
              <Spinner animation="grow" variant="danger" />
            </div>
          ) : (
            <div className="card">
              <div className="card-body">
                <div className="table-responsive" style={{ height: "70vh" }}>
                  <table className="table">
                    <thead
                      className="purchase-order-listing-heading "
                      style={{ position: "sticky", top: "-1px" }}
                    >
                      <tr>
                        <th>S.NO.</th>
                        <th>RAILWAY PO NO.</th>
                        <th>PURCHASE ORDER NO.</th>
                        <th>STATUS</th>
                        <th>PREPARED BY</th>
                        <th>ORDER DATE</th>
                        <th>PARTY NAME</th>
                        <th>PO AMOUNT (%)</th>
                        <th>VALUE OF PO</th>
                        <th>PO STATUS</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody className="purchase-order-listing-body">
                      {poOrderList.map((element, index) => (
                        <tr key={index}>
                          <td>{(page - 1) * 10 + index + 1}</td>
                          <td>{element.railway_po_no || "---"}</td>
                          <td>{element.purchase_order_no || "---"}</td>
                          <td>
                            {element?.cancelled_status
                              ? capitalizeFirstLetter(element?.cancelled_status)
                              : element?.status !== "approve"
                              ? capitalizeFirstLetter(element?.status)
                              : "Approved"}
                          </td>
                          <td>{element.prepared_by_name || "---"}</td>
                          <td>
                            {element.order_date
                              ? formatDate(element.order_date)
                              : "---"}
                          </td>
                          <td>{element.party_name || "---"}</td>
                          <td>
                            {element.po_amount_percentage
                              ? `${element.po_amount_percentage} %`
                              : "---"}
                          </td>
                          <td>
                            ₹
                            {element?.cancelled_status
                              ? amountFormatJS(element?.cancelled_amount)
                              : amountFormatJS(element.grand_total) || "---"}
                          </td>
                          <td
                            style={{
                              color:
                                element.po_status === "Closed"
                                  ? "red"
                                  : "green",
                            }}
                          >
                            {element.po_status || "---"}
                          </td>
                          <td>
                            <p
                              style={{
                                minWidth: "4rem",
                                maxWidth: "4rem",
                                margin: "auto",
                                padding: "auto",
                                cursor: "pointer",
                              }}
                              className="text-center"
                            >
                              {element.id ? (
                                <Link to={`/purchase-order-pdf/${element.id}`}>
                                  <FaFilePdf
                                    className="text-dark font-20 m-0 p-0"
                                    style={{ margin: "auto" }}
                                  />
                                </Link>
                              ) : (
                                "---"
                              )}
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          <div className="text-end mt-2">
            <BasicPagination
              handlePaginateChange={handlePaginateChange}
              page={page}
              pageCount={pageCount}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default POListingPage;
