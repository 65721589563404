import React from "react";

function GurantorCard({ children }) {
  return <div style={cardStyle}>{children}</div>;
}
const cardStyle = {
  borderRadius: "8px",
  padding: "1.2rem",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  background: "#FFFFFF",
};

export default GurantorCard;
