import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { addNewCommunicationApi } from "../../../../../redux/api";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import CommunicationTrackerForm from "./CommunicationTrackerForm";
import { errorsMessage } from "../../../../../constant";
import { communicationValidationSchema } from "./communicationValidationSchema";

function AddCommunicationTrackerModal(props) {
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;

  const initialValue = {
    to: "",
    written_by: "",
    subject: "",
    ref_po_loa_no: "",
    approved_by: "",
    letter_date: "",
    type: "",
    ref_no: "",
    status: "",
    attachment: "",
    revert_against_ref_no: "",
  };

  const addNewCommunication = async (values) => {
    setLoading(true);
    try {
      const res = await addNewCommunicationApi({
        ...values,
        user_id,
      });
      toast.success("Communication Added Successfully");
      props?.communicationTrackerListData();
      props.onHide();
      resetForm();
    } catch (error) {
      if (!error.response) {
        toast.error("Network error: Please check your internet connection.");
      } else {
        errorsMessage(error.response.status);
      }
      if (error?.response?.status == 422) {
        setServerError(error?.response?.data?.response);
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (props?.project) {
      setFieldValue("ref_po_loa_no", props?.project_id);
    }
  }, [props?.project_id, props?.show]);
  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: communicationValidationSchema,
    onSubmit: (values) => {
      addNewCommunication(values);
    },
  });

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add New Communication
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <CommunicationTrackerForm
            values={values}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            serverError={serverError}
            project={props?.project}
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        {loading ? (
          <div className="text-center">
            <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
            <p>Loading...</p>
          </div>
        ) : (
          <div>
            <Button onClick={props.onHide} className="btn btn-secondary me-2">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              type="button"
              className="btn btn-primary"
            >
              Add
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
}
export default AddCommunicationTrackerModal;
