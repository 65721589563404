import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

export default function TenderBreadCrumbs() {
  const navigate = useNavigate();
  function handleClick(event) {
    event.preventDefault();
    const target = event.target;
    if (target.tagName === "A") {
      navigate(target.getAttribute("href"));
    }
  }
  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/dashboard">
          Dashboard
        </Link>
        <Link underline="hover" color="inherit" href="/tenders">
          Tender List
        </Link>
        <Typography color="text.primary">Tender Logs</Typography>
      </Breadcrumbs>
    </div>
  );
}
