import React, { useEffect, useState } from "react";
import { paymentLogsApi } from "../../purchaseApi";
import Modal from "react-bootstrap/Modal";
import { errorsMessage } from "../../../constant";
import CommonTable from "../../Common/CommonTable";
import CommonTd from "../../../Tender/Common/CommonTd";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import Loader from "../../../common/Loader";

function PaymentLogsModal({ partyId, show, handleClose }) {
  const [loader, setLoader] = useState(false);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const attachmentBaseUrl = process.env.REACT_APP_BASE_URL;
  const [logsData, setLogsData] = useState([]);

  // Fetch payment logs
  const supplierPaymentList = () => {
    setLoader(true);
    paymentLogsApi({ party_id: partyId })
      .then((res) => {
        setLogsData(
          res?.data?.response?.getSupplierPaymentLog?.paymentLogs || []
        );
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const tableHeading = [
    { name: "Payable Amount", width: "10rem" },
    { name: "Paid Amount", width: "10rem" },
    { name: "Date", width: "10rem" },
    // { name: "Bill No", width: "10rem" },
    { name: "Paid By", width: "10rem" },
    { name: "Attachment", width: "10rem" },
  ];

  useEffect(() => {
    if (show && partyId) {
      supplierPaymentList();
    }
  }, [show, partyId]);

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <h4 className="text-white">Payment Logs</h4>
      </Modal.Header>
      <Modal.Body>
        {loader ? (
          <Loader />
        ) : logsData.length ? (
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table">
                  <CommonTable tableHeading={tableHeading} />
                  <tbody>
                    {logsData.map((element, index) => (
                      <tr key={index}>
                        <CommonTd
                          width="10rem"
                          value={element?.payable_amount}
                        />
                        <CommonTd width="10rem" value={element?.paid_amount} />
                        <CommonTd width="10rem" value={element?.paid_date} />
                        {/* <CommonTd width="10rem" value={element?.bill_no} /> */}
                        <CommonTd width="10rem" value={element?.paid_by} />
                        <td>
                          {element?.bill_attachment ? (
                            <Link
                              to={`${element.bill_attachment}`}
                              target="_blank"
                            >
                              <h6
                                style={{ minWidth: "10rem", maxWidth: "10rem" }}
                              >
                                <FaEye
                                  style={{
                                    width: "1.5rem",
                                    height: "1.5rem",
                                    cursor: "pointer",
                                  }}
                                />
                              </h6>
                            </Link>
                          ) : (
                            <h6
                              style={{ minWidth: "10rem", maxWidth: "10rem" }}
                            >
                              ---
                            </h6>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <>No Logs Available</>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default PaymentLogsModal;
