import React, { useCallback, useEffect, useState } from "react";
import { deliveryChallanMaterialOutwardApi } from "../commonComponents/storeApi";
import { useLocation } from "react-router-dom";
import { debounce } from "lodash";
import { errorsMessage } from "../../constant";
import RowsPagination from "../../common/RowsPagination";
import MaterialOutwardFilter from "./MaterialOutwardFilters";
import SimpleBackdrop from "../commonComponents/ShowBackDrop";
import { amountFormatJS } from "../../common/amountFormat";

function MaterialTable() {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);
  const [page, setPage] = React.useState(1); // 1-based page
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalRecords, setTotalRecords] = useState("");
  const { state } = useLocation();
  const [filters, setFilters] = useState({
    startDate: state ? state?.startDate : null,
    endDate: state ? state?.endDate : null,
    search: "",
    search_by: "",
  });

  function deliveryChallanMaterialOutwardList(term, page, rowsPerPage) {
    setLoading(true);
    deliveryChallanMaterialOutwardApi({
      ...term,
      user_id,
      page,
      per_page: rowsPerPage,
    })
      .then((res) => {
        setListData(res?.data?.response?.materialTrackerOutward?.data);
        setTotalRecords(res?.data?.response?.materialTrackerOutward?.total);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleFilterChange = (e) => {
    const { value, name } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleDateFilterChange = (name, value) => {
    if (name === "dateFilter") {
      setFilters({
        ...filters,
        startDate: value[0],
        endDate: value[1],
      });
    }
  };

  const debouncedFetchDeliveryChallanMaterailOutward = useCallback(
    debounce((term, page, rowsPerPage) => {
      deliveryChallanMaterialOutwardList(term, page, rowsPerPage);
    }, 600),
    [user_id]
  );

  useEffect(() => {
    debouncedFetchDeliveryChallanMaterailOutward(filters, page, rowsPerPage);
  }, [
    debouncedFetchDeliveryChallanMaterailOutward,
    page,
    filters,
    rowsPerPage,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  return (
    <div className="material-table-container">
      <div className="card">
        <div className="card-body">
          <div className="row my-3 ">
            <SimpleBackdrop open={loading} />
            <div className="col-md-3">
              <p className="fs-4">Material Outward</p>
            </div>
            <div className="col-md-9 text-end">
              <MaterialOutwardFilter
                handleFilterChange={handleFilterChange}
                filters={filters}
                handleDateFilterChange={handleDateFilterChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive" style={{ height: "66vh" }}>
                <table className="table">
                  <thead
                    className="material-outward-heading"
                    style={{ position: "sticky", top: "-1px" }}
                  >
                    <tr>
                      <th>S.NO.</th>
                      <th>Project ID/Railway PO.No.</th>
                      <th>Del. Challan No.</th>
                      <th>Date</th>
                      <th>Item Name</th>
                      <th>Item Qty</th>
                      <th>Prepared By</th>
                      <th>Deliver To</th>
                      <th>From Store</th>
                      <th>Total Amount</th>
                    </tr>
                  </thead>
                  <tbody className="material-outward-body">
                    {listData?.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>
                          {item.pd_projectTitle || "---"}
                          <p>{item.pd_poloaNo || "---"}</p>
                        </td>
                        <td>{item.challan_no || "---"}</td>
                        <td>{item.delivery_date || "---"}</td>
                        <td>{item.item_name || "---"}</td>
                        <td>{item.total_qty || "---"}</td>
                        <td>{item.prepared_by || "---"}</td>
                        <td>{item.shipping_store || "---"}</td>
                        <td>{item.store_name || "---"}</td>
                        <td className="material-outward-total-amount">
                          {item.total_amount !== undefined &&
                          item.total_amount !== null
                            ? amountFormatJS(item.total_amount)
                            : "---"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="mb-2">
                <RowsPagination
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  totalRecords={totalRecords}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MaterialTable;
