import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Components/Footer";
import ProjectKanbanBoard from "../Components/ProjectKanbanBoard";
import ProjectSetting from "../Components/ProjectSetting";
import ProjectMaterial from "../Components/ProjectMaterial";
import { getUserDropdown } from "../redux/api";
import { useParams } from "react-router-dom";
import axios from "axios";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Finance from "../Components/Finance/Finance";
import NewLetterIndex from "../Components/SettingSections/NewLetterIndex";
import CoachProductionList from "../Backoffice/BackofficeComponents/Trackers/CoachProductionList";
import CommunicationTrackerList from "../Backoffice/BackofficeComponents/Trackers/CommunicationTracker/OverViewComponents/CommunicationTrackerList";
import CommunicationTrackerListForProject from "../Backoffice/BackofficeComponents/Trackers/CommunicationTracker/OverViewComponents/CommunicationTrackerForProject";

function formatDate(value) {
  var date = new Date(value);
  var formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
  return formattedDate;
}

function ProjectsProjectDetail() {
  const [projectDetails, setprojectDetails] = useState();
  const [projectMembers, setProjectMembers] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [ticketTypeData, setTicketTypeData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [tabKey, setTabKey] = useState("kanban");
  const [lotData, setLotData] = useState([]);
  const [lotId, setLotId] = useState("");
  const [kanbanLotId, setKanbanLotId] = useState("");
  const [poStartDate, setPoStartDate] = useState("");
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const params = useParams();
  const location = useLocation();
  console.log("location", location);

  const fetchProjectDetails = () => {
    const url = apiurl + "untiprojectdetail";
    var body = {
      user_id: user_id,
      project_id: params.id,
    };
    axios
      .post(url, body, config)
      .then((res) => {
        const projectDetails = res?.data?.response?.projectDetails;
        setprojectDetails(projectDetails);
        setProjectMembers(projectDetails?.totalMembers);
        setLotData(projectDetails?.lotDetails);
        setLotId(projectDetails?.lotDetails[0]?.id);
        setKanbanLotId(projectDetails?.lotDetails[0]?.id);
        setPoStartDate(projectDetails?.startDate);
      })
      .catch((err) => {});
  };

  ////// FUNCTIONS FOR DROPDOWN LISTS START
  async function getScheduleList() {
    // const url = apiurl + "getschedulelist";
    // const body = { user_id: user_id, project_id: project_id };
    // await axios
    //   .post(url, body, config)
    //   .then((response) => {
    //     setScheduleData(response?.data?.response?.ScheduleList);
    //   })
    //   .catch((error) => {});
  }
  console.log("communication", tabKey);
  // useEffect(() => {
  //   console.log("communication", tabKey, state);
  //   if (state) {
  //     console.log(tabKey);
  //     setTabKey(state);
  //   }
  // }, [state]);

  useEffect(() => {
    fetchProjectDetails();
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"></li>
                      <li className="breadcrumb-item">
                        <Link to="/projects">Projects list /</Link>
                      </li>
                      <li className="breadcrumb-item active">Project detail</li>
                    </ol>
                  </div>
                  <h4 className="page-title font-48">PROJECTS DETAILS</h4>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body project__img">
                <div className="row">
                  <div className="col-lg-6 align-self-center">
                    <div className="">
                      <h5 className="font-30 m-0">
                        {projectDetails?.pd_projectTitle}{" "}
                      </h5>
                      <p className="text-muted m-0">
                        {projectDetails?.pd_projectItemDescription}
                      </p>
                      <span className="badge badge-soft-danger font-14 fw-semibold my-1 mt-2">
                        {projectDetails?.pd_railwayZone}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-4 align-self-center ps-md-5">
                    <div className="">
                      <ul className="list-unstyled border-0 mb-0">
                        <li className="mb-2">
                          <i className="las la-check-circle text-success me-1"></i>
                          <span className="fw-bold">Start date:</span>{" "}
                          {formatDate(projectDetails?.startDate)}
                        </li>
                        <li className="mb-2">
                          <i className="las la-check-circle text-success me-1"></i>
                          <span className="fw-bold">Completion date:</span>{" "}
                          {formatDate(projectDetails?.completionDate)}
                        </li>
                        <li className="mb-2">
                          <i className="las la-check-circle text-success me-1"></i>
                          <span className="fw-bold">Railway zone:</span>{" "}
                          {projectDetails?.pd_railwayZone}
                        </li>
                        <li className="mb-2">
                          <i className="las la-check-circle text-success me-1"></i>
                          <span className="fw-bold">Total member:</span>{" "}
                          {projectDetails?.totalMembersCount}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <Link
                      to={`/projects/projectpreview/${params.id}`}
                      className="btn btn-sm btn-de-primary border-danger"
                      target={"_blank"}
                    >
                      Project Preview
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={tabKey}
                  onSelect={(k) => setTabKey(k)}
                  className="mb-3"
                >
                  <Tab eventKey="kanban" title="KANBAN">
                    <ProjectKanbanBoard
                      scheduleData={scheduleData}
                      getScheduleList={getScheduleList}
                      ticketTypeData={ticketTypeData}
                      userData={userData}
                      tabKey={tabKey}
                      projectMembers={projectMembers}
                      projectDetails={projectDetails}
                      fetchProjectDetails={fetchProjectDetails}
                      lotData={lotData}
                      kanbanLotId={kanbanLotId}
                      setKanbanLotId={setKanbanLotId}
                      poStartDate={poStartDate}
                    />
                  </Tab>
                  {/* <Tab eventKey="progress" title="PROGRESS">
                    <ProjectProgress tabKey={tabKey} />
                  </Tab> */}
                  {/* 
                  <Tab eventKey="backlog" title="BACKLOG">
                    <ProjectBacklog
                      scheduleData={scheduleData}
                      getScheduleList={getScheduleList}
                      ticketTypeData={ticketTypeData}
                      userData={userData}
                      tabKey={tabKey}
                      projectMembers={projectMembers}
                      projectDetails={projectDetails}
                    />
                  </Tab> */}

                  <Tab eventKey="settings" title="TEAM MEMBER">
                    <ProjectSetting
                      tabKey={tabKey}
                      fetchProjectDetails={fetchProjectDetails}
                    />
                  </Tab>

                  <Tab eventKey="finance" title="FINANCE">
                    <Finance tabKey={tabKey}></Finance>
                  </Tab>
                  <Tab eventKey="letterIndex" title="LETTER INDEX">
                    <NewLetterIndex tabKey={tabKey}></NewLetterIndex>
                  </Tab>
                  <Tab
                    eventKey="material-costing"
                    title="MATERIAL PROCUREMENT TRACKER"
                  >
                    <ProjectMaterial
                      tabKey={tabKey}
                      lotData={lotData}
                      lotId={lotId}
                      setLotId={setLotId}
                    />
                  </Tab>
                  <Tab eventKey="coachProduction" title="COACH PRODUCTION">
                    <CoachProductionList
                      project_id={params.id}
                    ></CoachProductionList>
                  </Tab>
                  <Tab
                    eventKey="communicationTracker"
                    title="COMMUNICATION TRACKER"
                  >
                    <CommunicationTrackerListForProject
                      tabKey={tabKey}
                      project_id={params.id}
                    ></CommunicationTrackerListForProject>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ProjectsProjectDetail;
