import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { errorsMessage } from "../../constant";
import { getRequsitionListApi } from "../commonComponents/storeApi";
import { Spinner } from "react-bootstrap";
import { debounce } from "lodash";
import BasicPagination from "../commonComponents/Pagination";
import RequistionCollapsibleTable from "./RequistionCollapsableTable";
import RequisitionFilter from "./RequistionFilter";

function RequisitionList() {
  const [requistionData, setRequistionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const departmentName = userProfile?.response?.userDetails?.departmentName;
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const { state } = useLocation();

  const [filters, setFilters] = useState({
    search: "",
    startDate: state ? state?.startDate : null,
    endDate: state ? state?.endDate : null,
  });

  function requistionList(term, page = 1) {
    getRequsitionListApi({ ...term, user_id, page: page })
      .then((res) => {
        setRequistionData(res?.data?.response?.listRequisition?.data);
        setPageCount(res?.data?.response?.listRequisition?.last_page);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const debouncedFetchRequistionist = useCallback(
    debounce((term, page) => {
      requistionList(term, page);
    }, 600),
    [user_id]
  );

  useEffect(() => {
    debouncedFetchRequistionist(filters, page);
  }, [filters, debouncedFetchRequistionist, page]);

  function handlePaginateChange(event, value) {
    setPage(value);
  }

  const handleFilterChange = (name, value) => {
    if (name === "dateFilter") {
      setFilters({
        ...filters,
        startDate: value[0],
        endDate: value[1],
      });
    } else {
      setFilters({ ...filters, [name]: value });
    }
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab">
          <div className="container-fluid">
            <div className="row d-flex mx-3 my-3">
              <div className="col-md-4">
                <h4>REQUISITION FORM LIST</h4>
              </div>
              <div className="col-md-auto justify-content-end align-items-center ms-auto"> 
                <div className="row">
                  <RequisitionFilter handleFilterChange={handleFilterChange} />
                  <div className="col-md-auto d-flex justify-content-between">
                    {departmentName === "Store" ? (
                      <Link to={"/requisitionform/addrequisitionform"} className="d-inline-block ms-1">
                        <button className="btn btn-danger">Add New</button>{" "}
                      </Link>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <div
                className="d-flex align-items-center justify-content-center pb-5"
                style={{ height: "70vh" }}
              >
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
              </div>
            ) : (
              <RequistionCollapsibleTable
                requistionData={requistionData}
                departmentName={departmentName}
              />
            )}
            <div className="text-end mt-2">
              <BasicPagination
                handlePaginateChange={handlePaginateChange}
                page={page}
                pageCount={pageCount}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequisitionList;
