import React from "react";
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts";

const DashoardBarGraph = ({ monthOrderCount }) => {
  const monthName = [
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "Jan",
    "Feb",
    "Mar",
  ];

  let sortedData = [];
  let maxCount = 0;

  if (monthOrderCount?.length) {
    sortedData = [...monthOrderCount].sort((a, b) => a.month - b.month);
    maxCount = Math.max(...sortedData.map((item) => item.count), 0);
  }

  const getQuarter = (month) => {
    if (month >= 1 && month <= 3) return "Q1";
    if (month >= 4 && month <= 6) return "Q2";
    if (month >= 7 && month <= 9) return "Q3";
    if (month >= 10 && month <= 12) return "Q4";
    return "";
  };

  return (
    <div style={{ width: "100%" }}>
      <BarChart width={600} height={400} data={sortedData}>
        <Bar dataKey="count" fill="rgb(0, 149, 255)" />

        <XAxis
          dataKey="month"
          tickFormatter={(value) => monthName[value - 1]}
          height={50} // Increase height to provide space for quarters
        />
        <YAxis domain={[0, maxCount]} />

        {/* Custom rendering for quarters */}
        <g>
          {sortedData.map((entry, index) => {
            const quarter = getQuarter(entry.month);
            return (
              <text
                key={`quarter-${index}`}
                x={
                  index * (600 / sortedData.length) +
                  600 / (sortedData.length * 2)
                } // Center under the bar
                y={440} // Position below the XAxis
                textAnchor="middle"
                fill="#666"
                fontSize="12"
              >
                {quarter}
              </text>
            );
          })}
        </g>
      </BarChart>
    </div>
  );
};

export default DashoardBarGraph;
