import * as React from "react";
import TablePagination from "@mui/material/TablePagination";

export default function RowsPagination({
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  totalRecords,
}) {
  return (
    <TablePagination
      component="div"
      count={totalRecords}
      page={page - 1}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
}
