import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer";
import "../../PurchaseStyle/PurchaseStyle.css";
import Table from "react-bootstrap/Table";
import { errorsMessage, formatDate, formatNumber } from "../../../constant";
import {
  getProjectWiseCount,
  getPurchaseDashboard,
  getUpConmingDeliveryScheduleApi,
} from "../../purchaseApi";
import convertValue from "../../Common/AmountFormat";
import DashoardBarGraph from "../../Common/DashboardBarGraph";
import { Link, useNavigate } from "react-router-dom";
import QuaterFilter from "../../../common/QuaterFilter";
import { getCurrentQuarter } from "../../../services/constant-variable";
import { SlArrowRight } from "react-icons/sl";
import StatusText from "./utility/StatusText";

function PurchaseDashboard() {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [purchaseDashboardData, setPurchaseDashboardData] = useState([]);
  const [purchaseProjectCount, setPurchaseProjectCount] = useState([]);
  const [upcomingDeliverySechduleList, setUpcomingDeliveryScheduleList] =
    useState([]);
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const formatedDate = (date) => {
    if (typeof date !== "string" && date) {
      const pad = (num) => String(num).padStart(2, "0");
      return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
        date.getDate()
      )}`;
    }
  };
  const firstDate = new Date(year, month, 1);
  const lastDate = new Date(year, month + 1, 0);
  const [filters, setFilters] = useState({
    startDate: formatedDate(firstDate),
    endDate: formatedDate(lastDate),
    year: "",
    quarterData: "",
  });

  // Generate dynamic years array
  const currentYear = new Date().getFullYear();
  const startYear = 2013;
  const dynamicYears = Array.from(
    { length: currentYear - startYear + 1 },
    (_, i) => startYear + i
  );

  const getQuarterStartDate = (year, quarter) => {
    const quarterStartDates = {
      quarter_1: `${year}-04-01`,
      quarter_2: `${year}-07-01`,
      quarter_3: `${year}-10-01`,
      quarter_4: `${year}-01-01`,
    };
    return quarterStartDates[quarter] || "";
  };

  const getQuarterEndDate = (year, quarter) => {
    const quarterEndDates = {
      quarter_1: `${year}-06-30`,
      quarter_2: `${year}-09-30`,
      quarter_3: `${year}-12-31`,
      quarter_4: `${year}-03-31`,
    };
    return quarterEndDates[quarter] || "";
  };

  // Handle quarter search when form is submitted
  const handleQuarterSearch = () => {
    if (filters.year && filters.quarterData) {
      const updatedFilters = {
        ...filters,
        startDate: getQuarterStartDate(filters.year, filters.quarterData),
        endDate: getQuarterEndDate(filters.year, filters.quarterData),
      };
      setFilters(updatedFilters);
      purchaseDashboardDetail(); // Trigger data fetch after filter update
    }
  };

  // Handle form submission
  const handleSearch = (event) => {
    event.preventDefault();
    handleQuarterSearch();
  };

  const navigate = useNavigate();
  function purchaseDashboardDetail() {
    getPurchaseDashboard({ ...filters, user_id })
      .then((res) => {
        setPurchaseDashboardData(res?.data?.response?.purchaseDashboardDetails);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {});
  }
  function upComingDeliverySchedule() {
    getUpConmingDeliveryScheduleApi({ user_id })
      .then((res) => {
        console.log(
          "deliverySchedule",
          res?.data?.response?.upcomingScheduleDeliveryItems?.data
        );
        setUpcomingDeliveryScheduleList(
          res?.data?.response?.upcomingScheduleDeliveryItems?.data
        );
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {});
  }

  function purchaseRecentAssignProject() {
    getProjectWiseCount({ user_id })
      .then((res) => {
        setPurchaseProjectCount(res?.data?.response?.ProjectPOCount);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {});
  }
  const handleNavigate = (value) => {
    switch (value) {
      case "purchaseOrder":
        navigate("/purchase-order", {
          state: { startDate: filters?.startDate, endDate: filters?.endDate },
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentQuarter = getCurrentQuarter(currentDate);
    setFilters({
      ...filters,
      selectedYear: currentDate.getFullYear(),
      selectedQuarter: currentQuarter,
    });
  }, []);
  useEffect(() => {
    purchaseDashboardDetail();
    purchaseRecentAssignProject();
    upComingDeliverySchedule();
  }, [filters]);

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab">
          <div className="container-fluid">
            <div className="row mt-4 mb-4">
              <div className="col-4">
                {" "}
                <h4 className="page-title fw-bold me-auto ms-1"> DASHBOARD </h4>
              </div>
              <div className="col-8">
                {" "}
                <QuaterFilter filters={filters} setFilters={setFilters} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="purchase_card"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleNavigate("purchaseOrder")}
                    >
                      <div className="d-flex justify-content-between">
                        <div className="purchase_card_header">
                          <p>Purchase Order</p>
                          <h6>
                            {purchaseDashboardData?.total_purchase_orders}
                          </h6>
                        </div>
                        <div>
                          <svg
                            width="81"
                            height="81"
                            viewBox="0 0 81 81"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.21"
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0.360352 40.6523V60.8632C0.360352 71.9089 9.31464 80.8632 20.3603 80.8632H40.5713H60.7822C71.8279 80.8632 80.7822 71.9089 80.7822 60.8632V40.6523V20.4414C80.7822 9.39571 71.8279 0.441406 60.7822 0.441406H40.5713H20.3603C9.31466 0.441406 0.360352 9.3957 0.360352 20.4414V40.6523Z"
                              fill="#FEC53D"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M20.4656 33.0351L37.7569 43.0183C37.9431 43.1258 38.1384 43.2035 38.3371 43.2527V62.6146L21.6988 52.768C20.9344 52.3157 20.4656 51.4934 20.4656 50.6052V33.0351ZM60.6765 32.7695V50.605C60.6765 51.4932 60.2077 52.3154 59.4433 52.7678L42.805 62.6144V43.0829C42.8455 43.0626 42.8857 43.0411 42.9255 43.0181L60.6765 32.7695Z"
                              fill="#FEC53D"
                            />
                            <path
                              opacity="0.499209"
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M21.0088 28.1899C21.22 27.9232 21.4866 27.6978 21.7974 27.5322L39.3896 18.1622C40.1282 17.7688 41.014 17.7688 41.7525 18.1622L59.3448 27.5322C59.5844 27.6598 59.7977 27.823 59.9796 28.0133L40.6916 39.1492C40.5648 39.2225 40.4478 39.3062 40.3413 39.3988C40.2347 39.3062 40.1178 39.2225 39.9909 39.1492L21.0088 28.1899Z"
                              fill="#FEC53D"
                            />
                          </svg>
                        </div>
                      </div>
                      <p className="mb-0 pt-4">
                        <span>
                          <svg
                            width="33"
                            height="33"
                            viewBox="0 0 33 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.9438 8.22363L25.0132 11.2931L18.4723 17.834L13.1108 12.4726L3.17871 22.4181L5.06862 24.308L13.1108 16.2658L18.4723 21.6273L26.9165 13.1964L29.986 16.2658V8.22363H21.9438Z"
                              fill="#00B69B"
                            />
                          </svg>
                        </span>
                        <span>1.3%</span> Up from last month
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="purchase_card">
                      <div className="d-flex justify-content-between">
                        <div className="purchase_card_header">
                          <p>Purchase Order Value</p>
                          <h6>
                            {convertValue(
                              purchaseDashboardData?.total_purchase_order_values
                            )}
                          </h6>
                        </div>
                        <div>
                          <svg
                            width="82"
                            height="81"
                            viewBox="0 0 82 81"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.21"
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0.746094 40.6523V60.8632C0.746094 71.9089 9.70039 80.8632 20.7461 80.8632H40.957H61.1679C72.2136 80.8632 81.1679 71.9089 81.1679 60.8632V40.6523V20.4414C81.1679 9.39571 72.2136 0.441406 61.1679 0.441406H40.957H20.7461C9.7004 0.441406 0.746094 9.3957 0.746094 20.4414V40.6523Z"
                              fill="#4AD991"
                            />
                            <path
                              d="M26.3622 55.2488H57.6373C58.7888 55.2488 59.7223 56.1823 59.7223 57.3338C59.7223 58.4854 58.7888 59.4188 57.6373 59.4188H24.2771C23.1256 59.4188 22.1921 58.4854 22.1921 57.3338V23.9737C22.1921 22.8222 23.1256 21.8887 24.2771 21.8887C25.4287 21.8887 26.3622 22.8222 26.3622 23.9737V55.2488Z"
                              fill="#4AD991"
                            />
                            <path
                              opacity="0.5"
                              d="M34.1383 46.2492C33.3508 47.0893 32.0313 47.1319 31.1912 46.3443C30.3511 45.5567 30.3086 44.2373 31.0962 43.3972L38.9149 35.0571C39.6766 34.2447 40.9423 34.1742 41.7894 34.8971L47.9605 40.1631L56.0009 29.9787C56.7144 29.0749 58.0255 28.9206 58.9293 29.6342C59.8331 30.3477 59.9874 31.6588 59.2738 32.5626L49.8913 44.4472C49.1585 45.3754 47.8011 45.5089 46.9014 44.7412L40.5962 39.3608L34.1383 46.2492Z"
                              fill="#4AD991"
                            />
                          </svg>
                        </div>
                      </div>
                      <p className="mb-0 pt-4">
                        <span>
                          <svg
                            width="34"
                            height="33"
                            viewBox="0 0 34 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M22.3303 24.308L25.3997 21.2386L18.8587 14.6976L13.4973 20.059L3.56519 10.1135L5.4551 8.22363L13.4973 16.2658L18.8587 10.9044L27.303 19.3352L30.3725 16.2658V24.308H22.3303Z"
                              fill="#F93C65"
                            />
                          </svg>
                        </span>
                        <span className="text-danger">1.3%</span> Up from last
                        month
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="card card-radius overflow-hidden mt-5">
                      <div className="card-header purchase-card-header">
                        <div className="row align-items-center">
                          <div className="col">
                            <h4 className="card-title">Purchase Overview </h4>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <DashoardBarGraph
                          monthOrderCount={
                            purchaseDashboardData?.monthly_purchase_orders
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-12">
                      <div className="card card-radius ">
                        <div className="card-header purchase-card-header">
                          <div className="row align-items-center">
                            <div className="d-flex">
                              <div className="col-6">
                                <h4 className="card-title">Project Wise PO</h4>
                              </div>
                              <div className="col-6 text-end">
                                <Link to={"/purchase-project-review"}>
                                  <h4 className="card-title text-end text-primary purchase-dashboard-navigate-button">
                                    View all <SlArrowRight />
                                    <SlArrowRight />
                                  </h4>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            maxHeight: "20rem",
                            overflowY: "scroll",
                            overflowX: "hidden",
                          }}
                        >
                          <Table>
                            <thead>
                              <tr>
                                <th style={{ width: "6rem" }}>S.No.</th>
                                <th>PO/LOA No.</th>
                                <th>PO Generated</th>
                                <th>Value</th>
                                <th>% completion</th>
                              </tr>
                            </thead>
                            <tbody>
                              {purchaseProjectCount?.map(
                                (purchaseOrder, index) => (
                                  <tr key={purchaseOrder?.id}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {purchaseOrder?.PoLoaNo
                                        ? purchaseOrder?.PoLoaNo
                                        : " - - -"}
                                    </td>
                                    <td>
                                      {purchaseOrder?.po_count
                                        ? purchaseOrder?.po_count
                                        : "- - -"}
                                    </td>
                                    <td>
                                      {purchaseOrder?.op_value
                                        ? formatNumber(
                                            Math.floor(purchaseOrder?.op_value)
                                          )
                                        : "- - -"}
                                    </td>
                                    <td>
                                      {purchaseOrder?.po_completion
                                        ? purchaseOrder?.po_completion
                                        : "- - -"}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="card card-radius mt-5">
                      <div className="card-header purchase-card-header">
                        <div className="row align-items-center">
                          <div className="col">
                            <h4 className="card-title">
                              Upcoming Delivery Schedule{" "}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div
                        className="card-body"
                        style={{
                          maxHeight: "25rem",
                          overflowY: "scroll",
                          marginBottom: "1rem",
                          padding: "0",
                        }}
                      >
                        <Table className="purchase-dashboard-delivery-scheduled-table bordered">
                          <thead>
                            <tr>
                              <th>Scheduled Date</th>
                              <th>Supplier</th>
                              <th>PO No.</th>
                              <th>Store</th>
                              <th>GRN Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {upcomingDeliverySechduleList?.map(
                              (purchaseOrder) => (
                                <tr key={purchaseOrder?.id}>
                                  <td>
                                    {purchaseOrder?.delivery_date
                                      ? formatDate(purchaseOrder?.delivery_date)
                                      : "- - -"}
                                  </td>
                                  <td className="fw-bold">
                                    {purchaseOrder?.supplier_name
                                      ? purchaseOrder?.supplier_name
                                      : " - - -"}
                                  </td>
                                  <td>
                                    {purchaseOrder?.po_number
                                      ? purchaseOrder?.po_number
                                      : "- - -"}
                                  </td>
                                  <td>
                                    {purchaseOrder?.delivery_store_name
                                      ? purchaseOrder?.delivery_store_name
                                      : "- - -"}
                                  </td>
                                  <td>
                                    {purchaseOrder?.grn_date
                                      ? formatDate(purchaseOrder?.grn_date)
                                      : "- - -"}
                                  </td>
                                  <StatusText>
                                    {purchaseOrder?.status
                                      ? purchaseOrder?.status
                                      : "- - -"}
                                  </StatusText>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
export default PurchaseDashboard;
