import React from "react";

function BankDetailForm({
  values,
  handleChange,
  countryDropdown,
  stateBankDropdown,
  cityBankDropdown,
  errors,
  touched,
  serverSideValidation,
  handleBlur,
}) {
  return (
    <div>
      <div className="row g-3">
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Bank Name<span className="text-danger">*</span>
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Bank Name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.bank_name}
            name="bank_name"
          ></input>
          <p className="text-danger m-0">
            {touched?.bank_name && errors?.bank_name
              ? errors?.bank_name
              : serverSideValidation?.bank_name}
          </p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Account Holder<span className="text-danger">*</span>
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Account Holder Name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.account_holder}
            name="account_holder"
          ></input>
          <p className="text-danger m-0">
            {touched?.account_holder && errors?.account_holder
              ? errors?.account_holder
              : serverSideValidation?.account_holder}
          </p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Account No<span className="text-danger">*</span>
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Account Number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.account_no}
            name="account_no"
          ></input>
          <p className="text-danger m-0">
            {touched?.account_no && errors?.account_no
              ? errors?.account_no
              : serverSideValidation?.account_no}
          </p>
        </div>

        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            IFSC Code <span className="text-danger">*</span>
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter IFSC Code"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.ifsc_code}
            name="ifsc_code"
          ></input>
          <p className="text-danger m-0">
            {touched?.ifsc_code && errors?.ifsc_code
              ? errors?.ifsc_code
              : serverSideValidation?.ifsc_code}
          </p>
        </div>

        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Region/Country<span className="text-danger">*</span>
          </label>
          <select
            className="form-control border-secondary "
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.country}
            name="country"
          >
            <option value="">Select</option>
            {countryDropdown?.map((element) => (
              <option value={element.id}>{element.country_name}</option>
            ))}
          </select>
          <p className="text-danger m-0">
            {touched?.country && errors?.country
              ? errors?.country
              : serverSideValidation?.country}
          </p>
        </div>

        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            State<span className="text-danger">*</span>
          </label>
          <select
            className="form-control border-secondary "
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.state}
            name="state"
          >
            <option value="">Select</option>
            {stateBankDropdown.map((element) => (
              <option key={element.id} value={element.id}>
                {element.state_name}
              </option>
            ))}
          </select>
          <p className="text-danger m-0">
            {touched?.state && errors?.state
              ? errors?.state
              : serverSideValidation?.state}
          </p>
        </div>

        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            City<span className="text-danger">*</span>
          </label>
          <select
            className="form-control border-secondary "
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.city}
            name="city"
          >
            <option value="">Select</option>
            {cityBankDropdown?.map((element) => (
              <option value={element.id}>{element.city_name}</option>
            ))}
          </select>
          <p className="text-danger m-0">
            {touched?.city && errors?.city
              ? errors?.city
              : serverSideValidation?.city}
          </p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Swift Code{" "}
            {values?.country !== "1" ? (
              <span className="text-danger">*</span>
            ) : null}
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Swift Code"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.swift_code}
            name="swift_code"
            maxLength={11}
            disabled={values?.country === "1" ? true : false}
          ></input>
          <p className="text-danger m-0">
            {touched?.swift_code && errors?.swift_code
              ? errors?.swift_code
              : serverSideValidation?.swift_code}
          </p>
        </div>
        <div className="col-6">
          <label htmlFor="" className="form-label fw-bold">
            Address<span className="text-danger">*</span>
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Address"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.bank_address}
            name="bank_address"
          ></input>
          <p className="text-danger m-0">
            {touched?.bank_address && errors?.bank_address
              ? errors?.bank_address
              : serverSideValidation?.bank_address}
          </p>
        </div>

        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Pin Code<span className="text-danger">*</span>
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Pin Code"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.bank_pin_code}
            name="bank_pin_code"
            minLength={6}
            maxLength={6}
          ></input>
          <p className="text-danger m-0">
            {touched?.bank_pin_code && errors?.bank_pin_code
              ? errors?.bank_pin_code
              : serverSideValidation?.bank_pin_code}
          </p>
        </div>

        {/* <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Enter Email Address <span className="text-danger">*</span>
          </label>
          <input
            type="email"
            className="form-control border-secondary"
            placeholder="Enter Email Address"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.bank_email}
            name="bank_email"
          ></input>
          <p className="text-danger m-0">
            {touched?.bank_email && errors?.bank_email
              ? errors?.bank_email
              : serverSideValidation?.bank_email}
          </p>
        </div> */}

        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Telephone
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Telephone Number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.bank_telephone}
            name="bank_telephone"
          ></input>
          <p className="text-danger m-0">
            {touched?.bank_telephone && errors?.bank_telephone
              ? errors?.bank_telephone
              : serverSideValidation?.bank_telephone}
          </p>
        </div>

        {/* <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Mobile <span className="text-danger">*</span>
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Mobile Number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.bank_mobile}
            name="bank_mobile"
            minLength={10}
            maxLength={10}
          ></input>
          <p className="text-danger m-0">
            {touched?.bank_mobile && errors?.bank_mobile
              ? errors?.bank_mobile
              : serverSideValidation?.bank_mobile}
          </p>
        </div> */}

        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Fax No
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Fax Number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.bank_fax_no}
            name="bank_fax_no"
          ></input>
          <p className="text-danger m-0">
            {touched?.bank_fax_no && errors?.bank_fax_no
              ? errors?.bank_fax_no
              : serverSideValidation?.bank_fax_no}
          </p>
        </div>

        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Website
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Website"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.bank_website}
            name="bank_website"
          ></input>
          <p className="text-danger m-0">
            {touched?.bank_website && errors?.bank_website
              ? errors?.bank_website
              : serverSideValidation?.bank_website}
          </p>
        </div>
      </div>
    </div>
  );
}

export default BankDetailForm;
