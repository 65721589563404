import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useFormik } from "formik";
import * as Yup from "yup"; // Import Yup
import { makeSupplierPaymentApi } from "../../purchaseApi";
import { toast } from "react-toastify";
import { errorsMessage } from "../../../constant";
import { keyBy } from "lodash";

function MakePaymentModal({
  show,
  handleClose,
  dueAmount,
  partyId,
  supplierPaymentList,
}) {
  const [loader, setLoader] = useState(false);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;

  const initialValues = {
    party_id: "",
    payable_amount: "",
    paid_amount: "",
    paid_date: "",
    paid_by: "",
    bill_attachment: "",
    remarks: "",
  };
  // Define Yup validation schema
  const validationSchema = Yup.object({
    // paid_amount: Yup.number()
    //   .typeError("Amount paid must be a number")
    //   .required("Amount paid is required")
    //   .min(1, "Amount paid must be greater than 0")
    //   .max(dueAmount, `Amount paid cannot exceed ${dueAmount}`),
    paid_date: Yup.string().required("Date is required").nullable(),
    paid_by: Yup.string()
      .required("Paid by is required")
      .matches(/^[A-Za-z\s]+$/, "Paid by must contain only letters"),
    bill_attachment: Yup.mixed().required("Bill attachment is required"),
    remarks: Yup.string().max(500, "Remark cannot exceed 500 characters"),
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema, // Attach the validation schema here
    onSubmit: (values) => {
      makePayment();
    },
  });
  console.log("errors", errors);
  function addAttachment(e, name) {
    const data = new FileReader();
    data.addEventListener("load", () => {
      setFieldValue(name, data.result);
    });
    data.readAsDataURL(e.target.files[0]);
  }
  function makePayment() {
    setLoader(true);
    makeSupplierPaymentApi({
      user_id,
      ...values,
    })
      .then((res) => {
        toast.success("Payment Made Successfully");
        handleClose();
        supplierPaymentList();
        resetForm();
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  useEffect(() => {
    if (!show) {
      resetForm();
    }
  }, [show]);
  useEffect(() => {
    setFieldValue("payable_amount", dueAmount);
    setFieldValue("party_id", partyId);
  }, [dueAmount, partyId]);

  const hasError = (key) => {
    return touched[key] && errors[key];
  };
  return (
    <div>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <h4 className="text-white">Make Payment</h4>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="form-group ">
                  <label className="form-label">Payable Amount</label>
                  <input
                    type="text"
                    name="payable_amount"
                    className="form-control border-secondary"
                    placeholder="Payable Amount"
                    onBlur={handleBlur}
                    value={values.payable_amount}
                    onChange={handleChange}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="form-group ">
                  <label className="form-label">Amount Paid</label>
                  <input
                    type="text"
                    name="paid_amount"
                    className="form-control border-secondary"
                    placeholder="Amount Paid"
                    onBlur={handleBlur}
                    value={values.paid_amount}
                    onChange={handleChange}
                  />
                  {hasError("paid_amount") && (
                    <div className="text-danger">{errors.paid_amount}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="form-group ">
                  <label className="form-label">Date</label>
                  <input
                    type="date"
                    name="paid_date"
                    className="form-control border-secondary"
                    placeholder="date"
                    onBlur={handleBlur}
                    value={values.paid_date}
                    onChange={handleChange}
                  />
                  {hasError("paid_date") && (
                    <div className="text-danger">{errors.paid_date}</div>
                  )}
                </div>
              </div>

              <div className="col-md-6 mb-3">
                <div className="form-group ">
                  <label className="form-label">Paid By</label>
                  <input
                    type="text"
                    name="paid_by"
                    className="form-control border-secondary"
                    placeholder="Paid By"
                    onBlur={handleBlur}
                    value={values.paid_by}
                    onChange={handleChange}
                  />
                  {hasError("paid_by") && (
                    <div className="text-danger">{errors.paid_by}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="form-group">
                  <label htmlFor="" className="form-label">
                    Bill Attachment
                  </label>
                  <input
                    type="file"
                    className="form-control border-secondary"
                    name="bill_attachment"
                    onChange={(e) => addAttachment(e, "bill_attachment")}
                  ></input>
                  {hasError("bill_attachment") && (
                    <div className="text-danger">{errors.bill_attachment}</div>
                  )}
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div className="form-group ">
                  <label className="form-label">Remark</label>
                  <textarea
                    type="text"
                    name="remarks"
                    rows={2}
                    className="form-control border-secondary"
                    placeholder="Remark"
                    onBlur={handleBlur}
                    value={values.remarks}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <Modal.Footer>
              {loader ? (
                <div className="">
                  <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                  <p>Loading...</p>
                </div>
              ) : (
                <>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" type="submit">
                    Make Payment
                  </Button>
                </>
              )}
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default MakePaymentModal;
