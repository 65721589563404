import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import BankAccountCards from "./BankAccountCards";

function Cardscarousel({ gurantorData, modalShowFunc }) {
  const options = {
    item: 1,
    loop: false,
    margin: 20,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <div>
      <OwlCarousel
        className="owl-theme"
        {...options}
        key={gurantorData.map((data) => data.id).join("-")}
      >
        {gurantorData?.map((data, index) => (
          <BankAccountCards
            data={data}
            index={(index + 1) % 3}
            modalShowFunc={modalShowFunc}
          />
        ))}
      </OwlCarousel>
    </div>
  );
}

export default Cardscarousel;
