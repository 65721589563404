import { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useNavigate, useLocation } from "react-router-dom";
import Billing from "./Billing/Billing";
import Gurantor from "./Gurantor/Gurantor";

function FinancialTabs() {
  const navigate = useNavigate();
  const location = useLocation();
  const [key, setKey] = useState("BILLING");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const activeTab = queryParams.get("tab") || "BILLING";
    navigate(`?tab=${activeTab}`);
    setKey(activeTab);
  }, [location.search]);

  const handleSelect = (selectedKey) => {
    setKey(selectedKey);
    navigate(`?tab=${selectedKey}`);
  };

  return (
    <div className="page-wrapper">
      <div className="page-content-tab">
        <div className="container-fluid">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={handleSelect}
            className="mb-3"
          >
            <Tab eventKey="BILLING" title="BILLING">
              <Billing />
            </Tab>
            <Tab eventKey="GURRANTOR" title="GURANTOR">
              <Gurantor tabKey={key} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default FinancialTabs;
