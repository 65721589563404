import React, { useState } from "react";
import DateRangeCommonFilter from "../../../../../common/DateRangeCommonFilter";
import { sdPgTransactionCsvApi } from "../../../../../redux/api";

function SDPGFilters({
  filters,
  handleFilterChange,
  handleDateFilterChange,
  bankList,
}) {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [downloadLoading, setDownloadLoading] = useState(false);
  const railwayPOOptions = [
    { option: "Railway PO Number", value: "railway_po_number" },
    { option: "Project Name", value: "project_name" },
    { option: "Account Number", value: "account_number" },
  ];

  const handleDownload = () => {
    setDownloadLoading(true);
    sdPgTransactionCsvApi({ user_id })
      .then((res) => {
        const fileUrl = res?.data?.response?.untiSdPgTransactionCSV;

        if (fileUrl) {
          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = "Vendor_data.csv"; // Set the default file name
          document.body.appendChild(link);
          link.click(); // Simulate a click to start the download
          document.body.removeChild(link);
        } else {
          console.error("No file URL found in the response");
        }
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setDownloadLoading(false);
      });
  };

  return (
    <div className="row">
      <div className="col-md-4">
        <div className="row">
          <div className="col-md-5 pe-0">
            <select
              value={filters?.search_by}
              onChange={handleFilterChange}
              name="search_by"
              className="form-control "
              style={{ background: "#F0F4FF" }}
            >
              {railwayPOOptions.map((option, index) => (
                <option key={index} value={option?.value}>
                  {option?.option}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-7 ps-0">
            <input
              type="text"
              value={filters?.search}
              onChange={handleFilterChange}
              placeholder="Search"
              name="search"
              className="form-control"
            />
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <select
          value={filters?.bank_id}
          onChange={handleFilterChange}
          name="bank_id"
          className="form-control"
        >
          <option value={""} disabled>
            Search by bank
          </option>
          <option value="">All</option>
          {bankList?.map((bank) => (
            <option value={bank?.id} key={bank?.id}>
              {bank?.name}
            </option>
          ))}
        </select>
      </div>

      <div className="col-md-4">
        <div className="row">
          <div className="col-md-9">
            <DateRangeCommonFilter
              handleChange={handleDateFilterChange}
              className="w-65"
            />
          </div>
          <div className="col-md-3">
            <button
              onClick={handleDownload}
              className="btn btn-primary ms-2 w-30"
              disabled={downloadLoading}
              type="button"
            >
              <p className="m-0">
                {downloadLoading ? "Downloading" : "Download"}
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SDPGFilters;
