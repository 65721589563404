export const settingList = [
  {
    name: "Vendors",
    url: "/vendor-list",
  },
  {
    name: "Main Group Master",
    url: "/main-group-master",
  },
  {
    name: "Sub Group Master",
    url: "/sub-group-master",
  },
  {
    name: "Item Master",
    url: "/item-master",
  },
  {
    name: "Unit Master",
    url: "/unit-master",
  },
  // {
  //   name: "Printout Type Master",
  //   url: "/printout-type-master",
  // },
  {
    name: "Country Master",
    url: "/country-master",
  },
  {
    name: "State Master",
    url: "/state-master",
  },
  {
    name: "City Master",
    url: "/city-master",
  },
  // {
  //   name: "Add Parameter",
  //   url: "/add-parameter",
  // },
  {
    name: "Godown Master",
    url: "/godown-master",
  },
  {
    name: "Terms & Conditions",
    url: "/terms-and-conditions",
  },
  // {
  //   name: "Partywise Item Rate",
  //   url: "/partywise-item-rate",
  // },
  // {
  //   name: "Partywise Rate Excel Format",
  //   url: "/partywise-rate-excel-format",
  // },
  {
    name: "Vehicle Master",
    url: "/vechile-master",
  },
  // {
  //   name: "Upload Tender Documents",
  //   url: "/upload-tender-document",
  // },
  // {
  //   name: "Tender Details",
  //   url: "/tender-details",
  // },
  // {
  //   name: "Item Details",
  //   url: "/item-details",
  // },
  // {
  //   name: "Approval Master",
  //   url: "/approval-master",
  // },
  // {
  //   name: "Vendorwise Item",
  //   url: "/vendorwise-item",
  // },
  {
    name: "Brand Master",
    url: "/brand-master",
  },
  {
    name: "Material Master",
    url: "/material-master",
  },
  {
    name: "Currency Master",
    url: "/currency-master",
  },
];

export const managementList = [
  // {
  //   name: "Pending Requistion for PO",
  //   url: "pending-requistion-for-po",
  // },
  {
    name: "Purchase Order",
    url: "/purchase-order",
  },
  {
    name: "GRN List",
    url: "/GoodReceiptNote",
  },
  {
    name: "Supplier Payment",
    url: "/supplier-payment",
  },
  {
    name: "Material Tracker",
    url: "/Material-Tracker",
  },
  {
    name: "Delivery Schedule",
    url: "/Delivery-Schedule",
  },
  // {
  //   name: "Edit Purchase Order",
  //   url: "edit-purchase-order",
  // },
  // {
  //   name: "Pending PO Scheduling",
  //   url: "pending-po-scheduling",
  // },
  // {
  //   name: "Edit PO Schedule",
  //   url: "edit-po-schedule",
  // },
  // {
  //   name: "RFQ",
  //   url: "r-f-q",
  // },
  // {
  //   name: "Quotation Entry",
  //   url: "quotation-entry",
  // },
  // {
  //   name: "Document Details",
  //   url: "document-details",
  // },
  // {
  //   name: "Approve Invoice for Payment",
  //   url: "approve-invoice-for-payment",
  // },
];
