import { useState, useEffect, useCallback } from "react";
import { errorsMessage } from "../../constant";

const usePagination = (fetchDataFunction, user_id, key, search = "") => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(search ? null : 1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [timer, setTimer] = useState(null);

  const [termsAndCond, setTermsAndCond] = useState([]);
  const fetchData = useCallback(
    async (pageNumber = 1) => {
      setLoading(true);
      try {
        const res = await fetchDataFunction({
          user_id,
          page: !search ? pageNumber : null,
          key,
          search,
        });
        const newItems = res?.data?.response[key] || [];
        if (newItems.length === 0) {
          if (search) {
            setData([]);
            return newItems;
          }
          setHasMore(false);
        } else {
          setData((prevItems) => {
            if (pageNumber === 1 || search) {
              return newItems;
            }
            return [...prevItems, ...newItems];
          });
        }
        setTermsAndCond(newItems);
      } catch (err) {
        if (err) {
          console.log(err);
          errorsMessage(err?.response?.status);
        }
      } finally {
        setLoading(false);
      }
    },
    [fetchDataFunction, user_id, search]
  );

  useEffect(() => {
    fetchData(page);
  }, [page, fetchData]);

  const loadMore = () => {
    if (hasMore && !loading && !search) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const resetPagination = () => {
    setData([]);
    setPage(1);
    setHasMore(true);
  };

  return {
    data,
    loading,
    hasMore,
    loadMore,
    resetPagination,
    termsAndCond,
    fetchData,
  };
};

export default usePagination;
