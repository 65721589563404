import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { IoChevronForwardSharp } from "react-icons/io5";
import { Link } from "react-router-dom";

const InventoryTable = ({ inventory, setSearchTerm, searchTerm ,page}) => {
  return (
    <Box sx={{ background: "#FCFCFC", }}>
    {/* Search Bar */}
    <Box className=" mb-2 text-end">
      <TextField
        variant="outlined"
        placeholder="Search by title"
        size="small"
        sx={{
          width: "20rem",
          "& .MuiOutlinedInput-root": {
            paddingRight: "0", // Removes right padding from the input field
          },
        }}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                sx={{
                  width: "3rem",
                  backgroundColor: "#D03438",
                  borderRadius: "0",
                  "&:hover": {
                    backgroundColor: "#D03438", // Change to your desired hover color
                  },
                }}
              >
                <SearchIcon sx={{ color: "white" }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>

    <TableContainer
      component={Paper}
      sx={{ maxHeight: "83vh", boxShadow: "none", background: "#FCFCFC" }}
    >
      {/* <div className=" mt-3">
     
        <div className=" mb-2 text-end">
          <TextField
            variant="outlined"
            placeholder="Search by title"
            size="small"
            sx={{
              width: "20rem",
              "& .MuiOutlinedInput-root": {
                paddingRight: "0", // Removes right padding from the input field
              },
            }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      width: "3rem",
                      backgroundColor: "#D03438",
                      borderRadius: "0",
                      "&:hover": {
                        backgroundColor: "#D03438", // Change to your desired hover color
                      },
                    }}
                  >
                    <SearchIcon sx={{ color: "white" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div> */}

      <Table
        stickyHeader
        aria-label="inventory table"
        sx={{
          borderCollapse: "collapse",
          background: "#FCFCFC",
          border: "1px solid #F1F1F1",
          overflow: "auto",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "5rem", border: "none" }}>
              S.No.
            </TableCell>
            <TableCell style={{ width: "15rem", border: "none" }}>
              Category
            </TableCell>
            <TableCell style={{ width: "30rem", border: "none" }}>
              Item Name
            </TableCell>
            <TableCell style={{ width: "10rem", border: "none" }}>
              Unit
            </TableCell>
            <TableCell style={{ width: "10rem", border: "none" }}>
              Total In
            </TableCell>
            <TableCell style={{ width: "10rem", border: "none" }}>
              Total Out
            </TableCell>
            <TableCell style={{ width: "10rem", border: "none" }}>
              Balance
            </TableCell>
            <TableCell style={{ border: "none", width: "10rem" }}>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inventory?.map((row, index) => (
            <TableRow
              key={index}
              style={{
                border: "none",
                marginBottom: "3rem",
                background: "#FCFCFC",
              }}
            >
              <TableCell
                sx={{ borderRight: "none", borderLeft: "none", height: "2rem" }}
              >
                {(page - 1) * 20 + index + 1}
              </TableCell>
              <TableCell sx={{ borderRight: "none", borderLeft: "none" }}>
                {row.item_type ? row.item_type : "- - -"}
              </TableCell>
              <TableCell sx={{ borderRight: "none", borderLeft: "none" }}>
                {row.product_name}
              </TableCell>
              <TableCell sx={{ borderRight: "none", borderLeft: "none" }}>
                {row.unit_name ? row.unit_name : "- - -"}
              </TableCell>
              <TableCell sx={{ borderRight: "none", borderLeft: "none" }}>
                {row.totalIn}
              </TableCell>
              <TableCell sx={{ borderRight: "none", borderLeft: "none" }}>
                {row.totalOut}
              </TableCell>
              <TableCell sx={{ borderRight: "none", borderLeft: "none" }}>
                {row.balance}
              </TableCell>
              <TableCell sx={{ borderRight: "none", borderLeft: "none" }}>
                <Link to="/itemDetailPage" state={{ itemDetail: row }}>
                  <IconButton>
                    <IoChevronForwardSharp />
                  </IconButton>
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      </Box>
  );
};

export default InventoryTable;
