import React, { useEffect, useState } from "react";
import { communicationTrackerDetailListApi } from "../../../../../redux/api";
import { toast } from "react-toastify";
import DetailListTable from "./DetailListTable";
import EditCommunicationTrackerModal from "../Add-EditComponents/EditCommunicationTrackerModal";
import { useLocation } from "react-router-dom";
import BasicBreadcrumbs from "../CommonComponents/CommunicationBreadCrumbs";
import { Spinner } from "react-bootstrap";

function CommunicationTrackerDetailList() {
  const [communicationTrackerList, setCommunicationTrackerList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [editData, setEdtiData] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ref_no = queryParams.get("ref_no");
  const [loading, setLoading] = useState(true);
  const communicationTrackerListData = async () => {
    setLoading(true);
    try {
      const res = await communicationTrackerDetailListApi({ ref_no });
      setCommunicationTrackerList(res?.data?.response?.data);
    } catch (error) {
      if (!error.response) {
        toast.error("Network error: Please check your internet connection.");
      } else {
        errorsMessage(error.response.status);
      }
    } finally {
      setLoading(false);
    }
  };
  const modalOpenFunction = (data) => {
    setEdtiData(data);
    setModalShow(true);
  };

  useEffect(() => {
    communicationTrackerListData();
  }, []);
  return (
    <div className="page-wrapper">
      <div className="page-content-tab ">
        <div className="container-fluid">
          <div className="mt-2">
            <BasicBreadcrumbs />
          </div>
          <div className="row align-items-center mt-3">
            <div className="col-md-6">
              <p className="mb-0 fs-5">
                Communication against Letter Ref no. : <strong>{ref_no}</strong>
              </p>
              <p className="mb-0 fs-5">
                Total Communication :{" "}
                <strong>{communicationTrackerList?.length}</strong>
              </p>
            </div>
            <div>
              {loading ? (
                <div
                  className="d-flex align-items-center justify-content-center pb-5"
                  style={{ height: "70vh" }}
                >
                  <Spinner animation="grow" variant="danger" />
                  <Spinner animation="grow" variant="danger" />
                  <Spinner animation="grow" variant="danger" />
                </div>
              ) : (
                <DetailListTable
                  data={communicationTrackerList}
                  modalOpenFunction={modalOpenFunction}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <EditCommunicationTrackerModal
        data={editData}
        show={modalShow}
        onHide={() => setModalShow(false)}
        communicationTrackerListData={communicationTrackerListData}
      />
    </div>
  );
}

export default CommunicationTrackerDetailList;
