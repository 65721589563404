import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { FaListCheck } from "react-icons/fa6";
import { RiMessage2Fill } from "react-icons/ri";
import { FaHome } from "react-icons/fa";

function BackOfficeAsidebar() {
  const { user } = useSelector((state) => ({ ...state.auth }));
  const imageBaseUrl = process.env.REACT_APP_BASE_URL;
  const profileimage = user?.response?.userDetails?.profileimage;
  const userName = user?.response?.userDetails?.name;
  const email = user?.response?.userDetails?.email;
  const department = user?.response.userDetails.departmentName;
  const userDetail = JSON.parse(localStorage.getItem("profile"));
  const user_id = userDetail?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = userDetail?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const [asideData, setAsideData] = useState([]);
  const [mainMenu, setMainMenu] = useState([]);
  const [settingData, setSettingData] = useState([]);
  const logo = process.env.REACT_APP_LOGO_URL;
  function getAsideValues() {
    let url = baseUrl + "getmymenu";
    let data = { user_id };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setAsideData(response?.data?.response?.myMenu?.main);
        setMainMenu(response?.data?.response?.myMenu?.main);
        setSettingData(response?.data?.response?.myMenu?.setting);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    getAsideValues();
  }, []);

  return (
    <div className="left-sidebar">
      <div className="brand">
        <Link to="/dashboard" className="logo">
          <span>
            <img
              src={logo}
              alt="logo-large"
              className="logo-lg logo-light"
              style={{ width: "185px", height: "60px", marginTop: "16px" }}
            />
            <img
              src={logo}
              alt="logo-large"
              className="logo-lg logo-dark"
              style={{ width: "185px", height: "60px", marginTop: "16px" }}
            />
          </span>
        </Link>
      </div>
      <div className="sidebar-user-pro media border-end">
        <div className="position-relative mx-auto">
          <img
            src={imageBaseUrl + profileimage}
            alt="user"
            className="rounded-circle thumb-md"
          />
        </div>
        <div className="media-body ms-2 user-detail align-self-center">
          <h5 className="font-14 m-0 fw-bold">{userName} </h5>
          <p
            className="opacity-50 mb-0"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "78%",
            }}
          >
            {email}
          </p>
        </div>
      </div>
      <div className="border-end">
        <ul className="nav nav-tabs menu-tab nav-justified" role="tablist">
          <li className="nav-item">
            <Link
              className="nav-link active"
              data-bs-toggle="tab"
              to="#Main"
              role="tab"
              aria-selected="true"
            >
              Main
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              data-bs-toggle="tab"
              to="#Extra"
              role="tab"
              aria-selected="false"
            >
              Admin
            </Link>
          </li>
        </ul>
      </div>

      <div className="menu-content h-100" data-simplebar>
        <div className="menu-body navbar-vertical">
          <div
            className="collapse navbar-collapse tab-content"
            id="sidebarCollapse"
          >
            <ul
              className="navbar-nav tab-pane active"
              id="Main"
              role="tabpanel"
            >
              <li
                className="nav-item"
                style={{
                  padding: ".7rem 1rem",
                  fontSize: "1rem",
                  borderRadius: "8px",
                  margin: ".5rem 0",
                }}
              >
                <NavLink className="inactive-link" to="/dashboard">
                  <i className="fas fa-home"></i> &nbsp;&nbsp; Dashboard
                </NavLink>
              </li>
              <li
                className="nav-item"
                style={{
                  padding: ".7rem 1rem",
                  fontSize: "1rem",
                  borderRadius: "8px",
                  margin: ".5rem 0",
                }}
              >
                <NavLink className="inactive-link" to="/projects">
                  <i className="far fa-list-alt"></i> &nbsp;&nbsp; Project
                </NavLink>
              </li>
              <li
                className="nav-item"
                style={{
                  padding: ".7rem 1rem",
                  fontSize: "1rem",
                  borderRadius: "8px",
                  margin: ".5rem 0",
                }}
              >
                <NavLink className="inactive-link" to="/warrantytracker">
                  <FaListCheck /> &nbsp;&nbsp; Warranty Tracker
                </NavLink>
              </li>
              <li
                className="nav-item"
                style={{
                  padding: ".7rem 1rem",
                  fontSize: "1rem",
                  borderRadius: "8px",
                  margin: ".5rem 0",
                }}
              >
                <NavLink className="inactive-link" to="/finacialDetail">
                  <FaHome />
                  &nbsp;&nbsp; Financials
                </NavLink>
              </li>
              <li
                className="nav-item"
                style={{
                  padding: ".7rem 1rem",
                  fontSize: "1rem",
                  borderRadius: "8px",
                  margin: ".5rem 0",
                }}
              >
                <NavLink
                  className="inactive-link"
                  to="/communicationTracketList"
                >
                  <RiMessage2Fill />
                  &nbsp;&nbsp; Communications
                </NavLink>
              </li>
              <li
                className="nav-item"
                style={{
                  padding: ".7rem 1rem",
                  fontSize: "1rem",
                  borderRadius: "8px",
                  margin: ".5rem 0",
                }}
              >
                <NavLink className="inactive-link" to="/department">
                  <i className="fas fa-users"></i> &nbsp;&nbsp; Department
                </NavLink>
              </li>
              <li
                className="nav-item"
                style={{
                  padding: ".7rem 1rem",
                  fontSize: "1rem",
                  borderRadius: "8px",
                  margin: ".5rem 0",
                }}
              >
                <NavLink className="inactive-link" to="/ProjectsUsers">
                  <i className="fas fa-user-check"></i> &nbsp;&nbsp; Users
                </NavLink>
              </li>

              {/* <li
                className="nav-item"
                style={{
                  padding: ".7rem 1rem",
                  fontSize: "1rem",
                  borderRadius: "8px",
                  margin: ".5rem 0",
                }}
              >
                <NavLink className="inactive-link" to="/kanbanboard">
                  <i className="fas fa-user-check"></i> &nbsp;&nbsp; Kanban Board
                </NavLink>
              </li> */}

              {/* <li
          className="nav-item"
          style={{ padding: "1rem", fontSize: "1rem" }}
        >
          <NavLink
            className="inactive-link"
            to="roles-and-permission"
          >
            <i className="fas fa-user"></i> &nbsp;&nbsp; Roles &
            Permission
          </NavLink>
        </li> */}
            </ul>
            <ul className="navbar-nav tab-pane" id="Extra" role="tabpanel">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="#Setting"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="true"
                  aria-controls="Setting"
                >
                  <i className="ti ti-brand-asana menu-icon"></i>
                  <span>Setting</span>
                </NavLink>
                <div className="collapse show" id="Setting">
                  <ul className="nav flex-column">
                    {/* <li className="nav-item">
              <NavLink className="nav-link" to="/railway-zone">
                Railway Zone
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/unit">
                Unit
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/emd-paid">
                EMD Paid Status
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/sd-paid-status">
                SD Paid Status
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/sd-paid-through">
                SD Paid Through
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/pg-paid-status">
                PG Paid Status
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/pg-paid-through">
                PG Paid Through
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/project-type">
                Project Type
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/work-status">
                Work Status
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/client-category">
                Client Category
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/ticket-type">
                Ticket Type
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/subdivison">
                Sub Divison
              </NavLink>
            </li> */}
                    {settingData?.map((element, index) => {
                      return (
                        <li className="nav-item" key={index}>
                          <NavLink className="nav-link" to={element.url}>
                            {element.feature}
                          </NavLink>
                        </li>
                      );
                    })}
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/project-category">
                        Project Category
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/inspection-agency">
                        Inspection Agency
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="#Others"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-controls="Others"
                >
                  <i className="ti ti-folder menu-icon"></i>
                  <span>Others</span>
                </NavLink>
                <div className="collapse" id="Others">
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/UsersRoles">
                        Role
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/department-list">
                        Department List
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/permission-list">
                        Permission Group
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/features-list">
                        Features List
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/material-costing-items"
                      >
                        Material Items
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/financial-target">
                        Financial Targets
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/Project-target">
                        Project Targets
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/historic-projects">
                        Historic Projects
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/board-group">
                        Board Group
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/group-status">
                        Group Status
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/defect-types">
                        Defect Types
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/listCustomerDetail">
                        Customer Detail
                      </NavLink>
                    </li>
                    {/* <li className="nav-item">
                      <NavLink className="nav-link" to="/department-role">
                        Department Role
                      </NavLink>
                    </li> */}
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BackOfficeAsidebar;
