import React, { useEffect, useState } from "react";
import MaterialTrackerFilters from "./MaterialTrackerFilters";
import MaterialTrackerTable from "./MaterialTrackerTable";
import "../MaterialStyle/Material.css";
import { getMaterialTrackerList } from "../../purchaseApi";
import { toast } from "react-toastify";
import { errorsMessage } from "../../../constant";
import RowsPagination from "../../../common/RowsPagination";
import SimpleBackdrop from "../../../StoreDepartment/commonComponents/ShowBackDrop";

function MaterialTrackerList() {
  const [materialListData, setMaterialListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    search_by: "railway",
    startDate: "",
    endDate: "",
    search: "",
  });
  const [searchDelay, setSearchDelay] = useState(null);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [initialRender, setInitialRender] = useState(true);
  const [page, setPage] = React.useState(1); // 1-based page
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalRecords, setTotalRecords] = useState("");

  const materialTrackerListApi = async () => {
    setLoading(true);
    try {
      const res = await getMaterialTrackerList({
        ...filters,
        user_id,
        page, 
        per_page: rowsPerPage,
      });
      setMaterialListData(
        res?.data?.response?.materialTracker?.material_tracker?.data
      );
      setTotalRecords(
        res?.data?.response?.materialTracker?.material_tracker?.total
      );
    } catch (error) {
      if (!error?.response) {
        toast.error("Please Check your internet");
      }
      if (error?.response?.status) {
        errorsMessage(error?.response?.status);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (e) => {
    const { value, name } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateFilterChange = (name, value) => {
    if (name === "dateFilter") {
      setFilters({
        ...filters,
        startDate: value[0],
        endDate: value[1],
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    if (!initialRender) {
      if (searchDelay) clearTimeout(searchDelay);
      const timeout = setTimeout(() => {
        materialTrackerListApi();
      }, 500);
      setSearchDelay(timeout);
      return () => clearTimeout(timeout);
    } else {
      setInitialRender(false);
      materialTrackerListApi();
    }
  }, [filters, page, rowsPerPage]);

  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <SimpleBackdrop open={loading} />
          <MaterialTrackerFilters
            handleFilterChange={handleFilterChange}
            filters={filters}
            handleDateFilterChange={handleDateFilterChange}
          />
          <div className="col-md-12">
            <div className="material-card">
              <MaterialTrackerTable
                materialListData={materialListData}
                page={page}
                rowsPerPage={rowsPerPage}
              />
              <div className="mb-2">
                <RowsPagination
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  totalRecords={totalRecords}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MaterialTrackerList;
