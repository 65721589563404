import React from "react";
import { IoSearchOutline } from "react-icons/io5";
import DateRangeCommonFilter from "../../../../../common/DateRangeCommonFilter";

function BillingFilter({
  filters,
  handleFilterChange,
  handleDateFilterChange,
}) {
  return (
    <div className="row">
      <div className="col-md-6">
        <DateRangeCommonFilter handleChange={handleDateFilterChange} />
      </div>
      <div className="col-md-6 position-relative">
        {" "}
        <input
          className="form-control pe-5"
          placeholder="Search"
          value={filters?.search}
          onChange={handleFilterChange}
          name="search"
        />{" "}
        <IoSearchOutline
          className="position-absolute"
          style={{
            top: "50%",
            right: "10px",
            transform: "translateY(-50%)",
            fontSize: "1.4rem",
          }}
          color="#BBBBBB"
        />
      </div>
    </div>
  );
}

export default BillingFilter;
