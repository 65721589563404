import React from "react";

function AccountDetailForm({
  values,
  handleChange,
  errors,
  touched,
  serverSideValidation,
  handleBlur,
}) {
  return (
    <div>
      <div className="row g-3">
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Opening Balance
          </label>
          <div className="row">
            <div className="col-8">
              <input
                className="form-control border-secondary"
                placeholder="Enter Opening Balance"
                value={values.opening_balance}
                onChange={handleChange}
                onBlur={handleBlur}
                name="opening_balance"
              ></input>
              <p className="text-danger m-0">
                {touched?.opening_balance && errors?.opening_balance
                  ? errors?.opening_balance
                  : serverSideValidation?.opening_balance}
              </p>
            </div>
            <div className="col-4">
              <select
                className="form-control border-secondary"
                value={values.opening_balance_type}
                onChange={handleChange}
                onBlur={handleBlur}
                name="opening_balance_type"
              >
                <option value="">Select</option>
                <option value="Dr.">Dr.</option>
                <option value="Cr.">Cr.</option>
              </select>
              <p className="text-danger m-0">
                {touched?.opening_balance_type && errors?.opening_balance_type
                  ? errors?.opening_balance_type
                  : serverSideValidation?.opening_balance_type}
              </p>
            </div>
          </div>
        </div>

        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Currency <span className="text-danger">*</span>
          </label>
          <select
            className="form-control border-secondary "
            value={values.currency}
            onChange={handleChange}
            onBlur={handleBlur}
            name="currency"
          >
            <option value="">Select</option>
            <option value="INR  ">Indian Rupees(INR)</option>
          </select>
          <p className="text-danger m-0">
            {touched?.currency && errors?.currency
              ? errors?.currency
              : serverSideValidation?.currency}
          </p>
        </div>

        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Grade
          </label>
          <select
            className="form-control border-secondary "
            value={values.grade}
            onChange={handleChange}
            onBlur={handleBlur}
            name="grade"
          >
            <option value="">Select</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
          </select>
          <p className="text-danger m-0">
            {touched?.grade && errors?.grade
              ? errors?.grade
              : serverSideValidation?.grade}
          </p>
        </div>

        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Pan Number <span className="text-danger">*</span>
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Pan Number"
            value={values.pan_no}
            onChange={handleChange}
            onBlur={handleBlur}
            name="pan_no"
            minLength={10}
            maxLength={10}
          ></input>
          <p className="text-danger m-0">
            {touched?.pan_no && errors?.pan_no
              ? errors?.pan_no
              : serverSideValidation?.pan_no}
          </p>
        </div>

        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            CTS No
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter CTS No"
            value={values.cts_no}
            onChange={handleChange}
            onBlur={handleBlur}
            name="cts_no"
          ></input>
          <p className="text-danger m-0">
            {touched?.cts_no && errors?.cts_no
              ? errors?.cts_no
              : serverSideValidation?.cts_no}
          </p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            GST No <span className="text-danger">*</span>
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter GST No"
            value={values.gst_no}
            onChange={handleChange}
            onBlur={handleBlur}
            name="gst_no"
            minLength={15}
            maxLength={15}
          ></input>
          <p className="text-danger m-0">
            {touched?.gst_no && errors?.gst_no
              ? errors?.gst_no
              : serverSideValidation?.gst_no}
          </p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            IS MSME Applicable? <span className="text-danger">*</span>
          </label>
          <select
            className="form-control border-secondary"
            value={values.is_msme_check}
            onChange={handleChange}
            onBlur={handleBlur}
            name="is_msme_check"
          >
            <option value=""> select</option>
            <option value="Yes"> Yes</option>
            <option value="No"> No</option>
          </select>

          <p className="text-danger m-0">
            {touched?.is_msme_check && errors?.is_msme_check
              ? errors?.is_msme_check
              : serverSideValidation?.is_msme_check}
          </p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            MSME No.{" "}
            {values.is_msme_check === "Yes" ? (
              <span className="text-danger">*</span>
            ) : null}
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter MSME No."
            value={values.msme_no}
            onChange={handleChange}
            onBlur={handleBlur}
            name="msme_no"
            disabled={values.is_msme_check === "Yes" ? false : true}
          ></input>
          <p className="text-danger m-0">
            {touched?.msme_no && errors?.msme_no
              ? errors?.msme_no
              : serverSideValidation?.msme_no}
          </p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            MSME TPYE{" "}
            {values.is_msme_check === "Yes" ? (
              <span className="text-danger">*</span>
            ) : null}
          </label>
          <select
            className="form-control border-secondary"
            value={values.msme_type}
            onChange={handleChange}
            onBlur={handleBlur}
            name="msme_type"
            disabled={values.is_msme_check === "Yes" ? false : true}
          >
            <option value=""> select</option>
            <option value="Micro">Micro</option>
            <option value="Small">Small</option>
            <option value="Medium">Medium</option>
          </select>
          <p className="text-danger m-0">
            {touched?.msme_type && errors?.msme_type
              ? errors?.msme_type
              : serverSideValidation?.msme_type}
          </p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Credit Limit
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Credit Limit"
            value={values.credit_limit}
            onChange={handleChange}
            onBlur={handleBlur}
            name="credit_limit"
          ></input>
          <p className="text-danger m-0">
            {touched?.credit_limit && errors?.credit_limit
              ? errors?.credit_limit
              : serverSideValidation?.credit_limit}
          </p>
        </div>

        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Credit Limit (Days) <span className="text-danger">*</span>
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Credit Limit Days"
            value={values.credit_limit_days}
            onChange={handleChange}
            onBlur={handleBlur}
            name="credit_limit_days"
          ></input>
          <p className="text-danger m-0">
            {touched?.credit_limit_days && errors?.credit_limit_days
              ? errors?.credit_limit_days
              : serverSideValidation?.credit_limit_days}
          </p>
        </div>
        {/* <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Pvt Marka
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Pvt Marka"
            value={values.pvt_marka}
            onChange={handleChange}
            onBlur={handleBlur}
            name="pvt_marka"
          ></input>
          <p className="text-danger m-0">
            {touched?.pvt_marka && errors?.pvt_marka
              ? errors?.pvt_marka
              : serverSideValidation?.pvt_marka}
          </p>
        </div> */}
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Sales Target
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Sales Target"
            value={values.sales_target}
            onChange={handleChange}
            onBlur={handleBlur}
            name="sales_target"
          ></input>
          <p className="text-danger m-0">
            {touched?.sales_target && errors?.sales_target
              ? errors?.sales_target
              : serverSideValidation?.sales_target}
          </p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            ECC No
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter ECC No"
            value={values.ecc_no}
            onChange={handleChange}
            onBlur={handleBlur}
            name="ecc_no"
          ></input>
          <p className="text-danger m-0">
            {touched?.ecc_no && errors?.ecc_no
              ? errors?.ecc_no
              : serverSideValidation?.ecc_no}
          </p>
        </div>

        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Status <span className="text-danger">*</span>
          </label>
          <select
            className="form-control border-secondary "
            value={values.status}
            onChange={handleChange}
            onBlur={handleBlur}
            name="status"
          >
            <option value="Active">Active</option>
            <option value="NonActive">Non Active</option>
          </select>
          <p className="text-danger m-0">
            {touched?.status && errors?.status
              ? errors?.status
              : serverSideValidation?.status}
          </p>
        </div>
      </div>
    </div>
  );
}

export default AccountDetailForm;
