import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import axios from "axios";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./Style.css";
import ProjectCardView from "./ProjectCardView";
import ProjectGridView from "./ProjectGridView";
import {
  selectDropdowns,
  getdropdownError,
} from "../redux/features/allDropdownSlice";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import SdTracker from "../Backoffice/BackofficeComponents/Trackers/SdTracker";
import PgTracker from "../Backoffice/BackofficeComponents/Trackers/PgTracker";
import { addItem } from "../Backoffice/Common/Redux/projectDateSlice";
import CoachProductionList from "../Backoffice/BackofficeComponents/Trackers/CoachProductionList";
import {
  selectCategory,
  selectInitialTab,
  selectStatus,
  setCategory,
  setStatus,
  setTab,
} from "../Backoffice/backOfficeSlice";
import { errorsMessage } from "../constant";
import CreateProjectMenu from "../Backoffice/Common/CreateProjectMenu";

function ProjectsProject() {
  const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const baseURL = apiurl;
  const [projects, setProjects] = useState([]);
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const [loader, setLoader] = useState(true);
  const [key, setKey] = useState("card");
  // const [status, setStatus] = useState("");
  // const [category, setCategory] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [timer, setTimer] = useState(null);

  const status = useSelector(selectStatus);
  const category = useSelector(selectCategory);
  const tab = useSelector(selectInitialTab);

  const dispatch = useDispatch();
  const date = useSelector((state) => state.date.date);

  const dropdowns = useSelector(selectDropdowns);
  const dropdownErrors = useSelector(getdropdownError);

  function getProjects() {
    setLoader(true);
    const url = baseURL + `untiprojectlistupdated`;

    const body = {
      user_id: user_id,
      search: searchTerm,
      financialYear: date,
      projectCategory: category,
      workStatus: status,
    };
    axios
      .post(url, body, config)
      .then((res) => {
        setLoader(false);
        setProjects(res.data.response.projectList);
        setFilteredData(res.data.response.projectList);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
        setLoader(false);
      });
  }

  // useEffect(() => {
  //   const filteredData = projects.filter((item) => {
  //     return (
  //       item.pd_projectTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //       item.pd_fileNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //       item.pd_poloaNo.toLowerCase().includes(searchTerm.toLowerCase())
  //     );
  //   });
  //   setFilteredData(filteredData);
  // }, [searchTerm]);

  useEffect(() => {
    clearTimeout(timer);
    const delay = setTimeout(() => {
      getProjects();
    }, 500);
    setTimer(delay);
  }, [searchTerm, date, status, category]);

  useEffect(() => {
    if (dropdownErrors) {
      toast.error("Unable to load the dropdown. Please Refresh the Website.");
    }
  }, [dropdownErrors]);

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box pb-0">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"></li>
                      <li className="breadcrumb-item">Projects /</li>
                      <li className="breadcrumb-item active">Projects List</li>
                    </ol>
                  </div>
                  <h4 className="page-title font-48">PROJECTS</h4>
                </div>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-lg-6">
                <div className="d-flex align-items-center gap-2">
                  <select
                    className="form-control border-danger"
                    value={date}
                    onChange={(e) => dispatch(addItem(e.target.value))}
                  >
                    <option value="" disabled selected hidden>
                      Sort by Year
                    </option>
                    <option value="">All Projects</option>
                    <option value="2013-2014">FY 2013-14</option>
                    <option value="2014-2015">FY 2014-15</option>
                    <option value="2015-2016">FY 2015-16</option>
                    <option value="2016-2017">FY 2016-17</option>
                    <option value="2017-2018">FY 2017-18</option>
                    <option value="2018-2019">FY 2018-19</option>
                    <option value="2019-2020">FY 2019-20</option>
                    <option value="2020-2021">FY 2020-21</option>
                    <option value="2021-2022">FY 2021-22</option>
                    <option value="2022-2023">FY 2022-23</option>
                    <option value="2023-2024">FY 2023-24</option>
                    <option value="2024-2025">FY 2024-25</option>
                    <option value="2025-2026">FY 2025-26</option>
                    <option value="2026-2027">FY 2026-27</option>
                    <option value="2027-2028">FY 2027-28</option>
                  </select>
                  <select
                    className="form-control border-danger"
                    value={category}
                    onChange={(e) => dispatch(setCategory(e.target.value))}
                  >
                    <option value="" disabled selected hidden>
                      Sort by Category
                    </option>
                    <option value="">All Projects</option>
                    {dropdowns?.projectCategoryList?.map((item, i) => {
                      return (
                        <option value={item.id} key={i}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    className="form-control border-danger"
                    value={status}
                    onChange={(e) => dispatch(setStatus(e.target.value))}
                  >
                    <option value="" disabled selected hidden>
                      Sort by Status
                    </option>
                    <option value="">All Projects</option>
                    {dropdowns?.workStatus?.map((item, i) => {
                      return (
                        <option value={item.id} key={i}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="row text-end">
                  <div className="col-md-1"></div>
                  <div className="col-md-7">
                    <div className="input-group">
                      <input
                        type="text"
                        id="example-input1-group2"
                        name="example-input1-group2"
                        className="form-control border-danger font-16 py-1 w-75"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <button type="button" className="btn btn-primary btn-sm">
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <CreateProjectMenu />
                    {/* <Link to="/projects/new-project-add">
                      <button type="button" className="btn btn-primary btn-sm">
                        Create New Project
                      </button>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>

            <Tabs
              id="controlled-tab-example"
              activeKey={tab}
              onSelect={(k) => dispatch(setTab(k))}
              className="mb-3"
              style={{ fontSize: "1.2rem", fontWeight: 600 }}
            >
              <Tab eventKey="card" title="PROJECTS DOCS">
                <ProjectCardView
                  loader={loader}
                  projects={filteredData}
                ></ProjectCardView>
              </Tab>
              <Tab eventKey="grid" title="PROJECTS TRACKER">
                <ProjectGridView
                  loader={loader}
                  projects={filteredData}
                ></ProjectGridView>
              </Tab>
              <Tab eventKey="sdTracker" title="SD TRACKER">
                <SdTracker loader={loader} projects={filteredData}></SdTracker>
              </Tab>
              <Tab eventKey="pgTracker" title="PG TRACKER">
                <PgTracker loader={loader} projects={filteredData}></PgTracker>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ProjectsProject;
