import React, { useEffect, useState } from "react";
import { BankHomeIcon } from "./GurantorIcons";
import { toast } from "react-toastify";
import { bankListDropDownApi } from "../../../../../redux/api";

function GurantorForm({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  serverError,
}) {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [bankList, setBankList] = useState([]);

  const bankListDropdown = async () => {
    try {
      const res = await bankListDropDownApi({ user_id });
      setBankList(res?.data?.response?.listUntiBank?.listUntiBank);
    } catch (error) {
      

      if (!error.response) {
        toast.error("Network error: Please check your internet connection.");
      } else {
        errorsMessage(error.response.status);
      }
    } finally {
    }
  };
  useEffect(() => {
    bankListDropdown();
  }, []);
  return (
    <div className="row">
      <div className="col-md-6 mb-2">
        <label className="form-label fw-bold font-16">
          {" "}
          Select Bank <span className="text-danger">*</span>
        </label>
        <select
          className="form-control border-secondary"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.bank_id}
          name="bank_id"
        >
          <option value={""} disabled selected>
            Select
          </option>
          {bankList?.map((bank) => (
            <option value={bank?.id} key={bank?.id}>
              {bank?.name}
            </option>
          ))}
        </select>
        <p className="text-danger mb-0">
          {serverError?.bank_id
            ? serverError?.bank_id
            : errors?.bank_id
            ? errors?.bank_id
            : touched?.bank_id
            ? touched?.bank_id
            : null}{" "}
        </p>
      </div>
      <div className="col-md-6 mb-2">
        <label className="form-label fw-bold font-16">
          {" "}
          Branch <span className="text-danger">*</span>
        </label>
        <input
          className="form-control border-secondary"
          placeholder="Enter Branch"
          name="branch_name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.branch_name}
        />
        <p className="text-danger mb-0">
          {serverError?.branch_name
            ? serverError?.branch_name
            : errors?.branch_name
            ? errors?.branch_name
            : touched?.branch_name
            ? touched?.branch_name
            : null}{" "}
        </p>
      </div>
      <div className="col-md-6 mb-2">
        <label className="form-label fw-bold font-16">
          {" "}
          A/C Number <span className="text-danger">*</span>
        </label>
        <input
          className="form-control border-secondary"
          placeholder="Enter A/c Number"
          name="account_number"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.account_number}
        />
        <p className="text-danger mb-0">
          {serverError?.account_number
            ? serverError?.account_number
            : errors?.account_number
            ? errors?.account_number
            : touched?.account_number
            ? touched?.account_number
            : null}{" "}
        </p>
      </div>

      <div className="col-md-6 mb-2">
        <label className="form-label fw-bold font-16">
          {" "}
          Credit Limit <span className="text-danger">*</span>
        </label>
        <div className="d-flex align-items-center  border border-secondary rounded">
          <BankHomeIcon />
          <input
            className="form-control"
            placeholder="Credit Limit"
            name="credit_limit"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.credit_limit}
            style={{ border: "none" }}
          />
        </div>
        <p className="text-danger mb-0">
          {serverError?.credit_limit
            ? serverError?.credit_limit
            : errors?.credit_limit
            ? errors?.credit_limit
            : touched?.credit_limit
            ? touched?.credit_limit
            : null}{" "}
        </p>
      </div>
    </div>
  );
}

export default GurantorForm;
