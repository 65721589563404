import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { approvePurchaseOrder } from "../../purchaseApi";
import { toast } from "react-toastify";
import linkEXpiredBg from "..//..//..//Assets//linkEXpiredBg.png";
import CircleLinkExp from "..//..//..//Assets//CircleLinkExp.png";
import actionTakenBg from "..//..//..//Assets//actionTakenBg.png";
import CircleActionTaken from "..//..//..//Assets//CircleActionTaken.png";
import bgReqDecline from "..//..//..//Assets//bgReqDecline.png";
import crossCircleTick from "..//..//..//Assets//crossCircleTick.png";
import bgPoSuccess from "..//..//..//Assets//bgPoSuccess.png";
import Eclipse from "..//..//..//Assets//circleTick.png";

function PurchaseOrderApproval() {
  const [searchParams] = useSearchParams();
  const purchase_order_id = searchParams.get("purchase_order_id");
  const status = searchParams.get("status");
  const action_by = searchParams.get("action_by");
  const follow_up_count = searchParams.get("follow_up_count");
  const follow_up_id = searchParams.get("follow_up_id");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  function PurchaseOrderApprovalApi() {
    setLoading(true); // Start loading
    try {
      approvePurchaseOrder({
        purchase_order_id,
        status,
        action_by,
        follow_up_count,
        follow_up_id,
      })
        .then((res) => {
          console.log("approval res", res);
          setMessage(res?.data?.response?.message?.mail_status);
          setLoading(false); // Stop loading
        })
        .catch((err) => {
          setLoading(false); // Stop loading on error
          if (!navigator.onLine) {
            toast.error("No internet connection. Please check your network.");
          } else {
            toast.error("Something went wrong. Please try again.");
          }
        });
    } catch (err) {
      setLoading(false); // Stop loading on unexpected error
      toast.error("An unexpected error occurred.");
    }
  }

  useEffect(() => {
    PurchaseOrderApprovalApi();
  }, []);

  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <p>Please Wait, Status is Updating...</p>
    </div>
  ) : (
    <>
      {message === "approve" ? (
        <div className="container d-flex align-items-center justify-content-center vh-100">
          <div className="text-center po-mail">
            {/* Top Wave Design */}
            <div
              className="position-relative"
              style={{
                height: "206px",
                overflow: "hidden",
                backgroundImage: `url(${bgPoSuccess})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "10px",
              }}
            ></div>

            <div className="po-content">
              {/* Tick Icon with Circle */}
              <div
                style={{
                  height: "100px",
                  position: "relative",
                  margin: "0 auto",
                }}
              >
                <img
                  src={Eclipse}
                  alt="Circle"
                  className="img-fluid position-absolute"
                  style={{
                    width: "130px",
                    height: "130px",
                    top: "-125px",
                    left: "50%",
                    transform: "translate(-50%, 0)",
                  }}
                />
              </div>

              <h4
                style={{
                  fontSize: "25px",
                  color: "#23AD00",
                  fontWeight: "700",
                  fontFamily: "poppins",
                  marginTop: "-50px",
                  marginBottom: "15px",
                }}
              >
                PO Approved Successfully
              </h4>
              <p
                style={{
                  fontSize: "15px",
                  marginLeft: "3rem",
                  marginRight: "3rem",
                  marginBottom: "2rem",
                  color: "#6A6A6A",
                  fontWeight: "400",
                  alignItems: "center",
                }}
              >
                The Purchase Order has been approved and recorded in the system.
              </p>

              {/* Back Button */}
              <button
                className="btn"
                onClick={() => navigate("/")}
                style={{
                  backgroundColor: "#EAEAEA",
                  color: "#000000",
                  marginBottom: "3rem",
                  fontSize: "15px",
                  padding: "10px 30px 35px 30px",
                  border: "none",
                  borderRadius: "5px",
                }}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      ) : message === "decline" ? (
        <div className="container d-flex align-items-center justify-content-center vh-100">
          <div className="text-center po-decline-mail">
            {/* Top Wave Design */}
            <div
              className="position-relative"
              style={{
                height: "206px",
                overflow: "hidden",
                backgroundImage: `url(${bgReqDecline})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "10px",
              }}
            ></div>

            <div className="po-decline-content">
              {/* Tick Icon with Circle */}
              <div
                style={{
                  height: "100px",
                  position: "relative",
                  margin: "0 auto",
                }}
              >
                <img
                  src={crossCircleTick}
                  alt="Circle"
                  className="img-fluid position-absolute"
                  style={{
                    width: "130px",
                    height: "130px",
                    top: "-125px",
                    left: "50%",
                    transform: "translate(-50%, 0)",
                  }}
                />
              </div>

              <h4
                style={{
                  fontSize: "25px",
                  color: "#D90000",
                  fontWeight: "700",
                  fontFamily: "poppins",
                  marginTop: "-50px",
                  marginBottom: "15px",
                }}
              >
                PO Request Declined
              </h4>
              <p
                style={{
                  fontSize: "15px",
                  marginLeft: "3rem",
                  marginRight: "3rem",
                  marginBottom: "2rem",
                  color: "#6A6A6A",
                  fontWeight: "400",
                  alignItems: "center",
                }}
              >
                The Purchase Order has been rejected and recorded in the system.
              </p>

              {/* Back Button */}
              <button
                className="btn"
                onClick={() => navigate("/")}
                style={{
                  backgroundColor: "#EAEAEA",
                  color: "#000000",
                  marginBottom: "3rem",
                  fontSize: "15px",
                  padding: "10px 30px 35px 30px",
                  border: "none",
                  borderRadius: "5px",
                }}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      ) : message === "new_mail_sent_for_this_po" ? (
        <div className="container d-flex align-items-center justify-content-center vh-100">
          <div className="text-center po-linkExp-mail">
            {/* Top Wave Design */}
            <div
              className="position-relative"
              style={{
                height: "206px",
                overflow: "hidden",
                backgroundImage: `url(${linkEXpiredBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "10px",
              }}
            ></div>

            <div className="po-linkExp-content">
              {/* Tick Icon with Circle */}
              <div
                style={{
                  height: "100px",
                  position: "relative",
                  margin: "0 auto",
                }}
              >
                <img
                  src={CircleLinkExp}
                  alt="Circle"
                  className="img-fluid position-absolute"
                  style={{
                    width: "130px",
                    height: "130px",
                    top: "-125px",
                    left: "50%",
                    transform: "translate(-50%, 0)",
                  }}
                />
              </div>

              <h4
                style={{
                  fontSize: "25px",
                  color: "#8B896C",
                  fontWeight: "700",
                  fontFamily: "poppins",
                  marginTop: "-50px",
                  marginBottom: "15px",
                }}
              >
                Approval Link Expired
              </h4>
              <p
                style={{
                  fontSize: "15px",
                  marginLeft: "3rem",
                  marginRight: "3rem",
                  marginBottom: "2rem",
                  color: "#6A6A6A",
                  fontWeight: "400",
                  alignItems: "center",
                }}
              >
                The approval link you are trying to access has expired due to a
                new request<br></br> being raised. Please check your email for
                the latest approval link.
              </p>

              {/* Back Button */}
              <button
                className="btn"
                onClick={() => navigate("/")}
                style={{
                  backgroundColor: "#EAEAEA",
                  color: "#000000",
                  marginBottom: "3rem",
                  fontSize: "15px",
                  padding: "10px 30px 35px 30px",
                  border: "none",
                  borderRadius: "5px",
                }}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      ) : message === "action_taken_for_this_po" ? (
        <div className="container d-flex align-items-center justify-content-center vh-100">
          <div className="text-center po-actionTaken-mail">
            {/* Top Wave Design */}
            <div
              className="position-relative"
              style={{
                height: "206px",
                overflow: "hidden",
                backgroundImage: `url(${actionTakenBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "10px",
              }}
            ></div>

            <div className="po-actionTaken-content">
              {/* Tick Icon with Circle */}
              <div
                style={{
                  height: "100px",
                  position: "relative",
                  margin: "0 auto",
                }}
              >
                <img
                  src={CircleActionTaken}
                  alt="Circle"
                  className="img-fluid position-absolute"
                  style={{
                    width: "130px",
                    height: "130px",
                    top: "-125px",
                    left: "50%",
                    transform: "translate(-50%, 0)",
                  }}
                />
              </div>

              <h4
                style={{
                  fontSize: "25px",
                  color: "#E8A82D",
                  fontWeight: "700",
                  fontFamily: "poppins",
                  marginTop: "-50px",
                  marginBottom: "15px",
                }}
              >
                Action Already Taken
              </h4>
              <p
                style={{
                  fontSize: "15px",
                  marginLeft: "3rem",
                  marginRight: "3rem",
                  marginBottom: "2rem",
                  color: "#6A6A6A",
                  fontWeight: "400",
                  alignItems: "center",
                }}
              >
                This Purchase Order has already been marked as
                [Approved/Rejected].
              </p>

              {/* Back Button */}
              <button
                className="btn"
                onClick={() => navigate("/")}
                style={{
                  backgroundColor: "#EAEAEA",
                  color: "#000000",
                  marginBottom: "3rem",
                  fontSize: "15px",
                  padding: "10px 30px 35px 30px",
                  border: "none",
                  borderRadius: "5px",
                }}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default PurchaseOrderApproval;
