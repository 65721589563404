import React, { useCallback } from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import {
  nonTenderProjectDetailSchema,
  projectDetailSchema,
} from "../../../schemas/newProjectSchema";
import { useSelector } from "react-redux";
import {
  selectDropdowns,
  getdropdownError,
} from "../../../redux/features/allDropdownSlice";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { errorsMessage } from "../../../constant";
import { getCustomerApi } from "../../../StoreDepartment/commonComponents/storeApi";
import { debounce } from "lodash";

function ProjectDetailsForm({ changeKey, getProjectId }) {
  const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const [subdivisionwithzone, setsubdivisionwithzone] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [validationError, setValidationError] = useState({});
  const [pd_poloaDocs, setpd_poloaDocs] = useState("");
  const [tenderData, setTenderData] = useState([]);
  const [selectedTenderData, setSelectedTenderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [customerDropDown, setCustomerDropDown] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [pd_poloaQuantityMutipleData, setMultData] = useState([
    {
      poLoaQty: "",
      unit: "",
      startDate: "",
      completionDate: "",
      dpExtension: "",
    },
  ]);
  const navigate = useNavigate();
  const dropdowns = useSelector(selectDropdowns);
  const dropdownErrors = useSelector(getdropdownError);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectType = queryParams.get("projectType");
  const customStyles = {
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "red",
    }),

    loadingIndicator: (provided) => ({
      ...provided,
      color: "red",
    }),
  };
  const initialValues = {
    pd_fileNo: "",
    pd_railwayZone: "",
    pd_subDivision: "",
    pd_workStatus: "",
    pd_workStatusRemark: "",
    tenderId: "",
    pd_tenderDate: "",
    pd_tenderQuantity: "",
    pd_tenderDocs: "",
    pd_poloaNo: "",
    pd_poloaDate: "",
    pd_totalOrderValue: "",
    pd_poloaDocs: "",
    pd_projectTitle: "",
    pd_projectItemDescription: "",
    pd_InspectionAgency: "",
    pd_purchaseManager: "",
    pd_projectManager: "",
    pd_totalOrderQty: "",
    pd_projectCategory: "",
    pd_endDate: "",
    pd_startDate: "",
    pd_poloaQuantityMutipleData: pd_poloaQuantityMutipleData,
  };

  const NonTenderInitialValues = {
    pd_project_type: "",
    pd_startDate: "",
    pd_endDate: "",
    pd_projectManager: "",
    pd_purchaseManager: "",
    pd_totalOrderQty: "",
    pd_projectCategory: "",
    pd_workStatus: "",
    pd_poloaNo: "",
    pd_poloaDate: "",
    pd_totalOrderValue: "",
    pd_poloaDocs: "",
    pd_projectTitle: "",
    pd_projectItemDescription: "",
    pd_customer_name: "",
    pd_poloaQuantityMutipleData: pd_poloaQuantityMutipleData,
  };

  const handleMultChange = (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...pd_poloaQuantityMutipleData];
    onchangeVal[i][name] = value;
    setMultData(onchangeVal);
  };

  const POLOADocsChange = (e) => {
    const data = new FileReader();
    data.addEventListener("load", () => {
      setpd_poloaDocs(data.result);
      setFieldValue("pd_poloaDocs", data.result);
    });
    data.readAsDataURL(e.target.files[0]);
  };

  function dropDownLists() {
    let filtersubDivisionList;
    if (dropdowns) {
      filtersubDivisionList = dropdowns?.subDivisionList?.filter(
        (subDivision) => {
          return (
            Number(subDivision.railway_zone) ===
            Number(selectedTenderData?.railwayZone)
          );
        }
      );
    }
    setFieldValue(
      "pd_subDivision",
      filtersubDivisionList
        ? selectedTenderData?.subDivision
          ? selectedTenderData?.subDivision
          : ""
        : ""
    );
    setsubdivisionwithzone(filtersubDivisionList);
  }

  const handleMultDelete = (i) => {
    const deleteVal = [...values.pd_poloaQuantityMutipleData];
    deleteVal.splice(i, 1);
    setMultData(deleteVal);
    setValues({ ...values, pd_poloaQuantityMutipleData: deleteVal });
  };

  const handleMltClick = () => {
    setMultData([
      ...pd_poloaQuantityMutipleData,
      {
        poLoaQty: "",
        unit: "",
        startDate: "",
        completionDate: "",
        dpExtension: "",
      },
    ]);

    setValues({
      ...values,
      pd_poloaQuantityMutipleData: [
        ...values.pd_poloaQuantityMutipleData,
        {
          poLoaQty: "",
          unit: "",
          startDate: "",
          completionDate: "",
          dpExtension: "",
        },
      ],
    });
  };

  const submitProjectDetails = (value) => {
    setSpinner(true);
    const tenderProjectData = {
      ...value,
      user_id,
      pd_poloaDocs,
      pd_tenderDocs: "",
      pd_tenderNo: selectedTenderData.tenderNo,
      pd_project_type: "tenderProject",
    };
    const nonTenderProjectData = {
      ...value,
      user_id,
      pd_poloaDocs,
      pd_project_type: "nonTenderProject",
    };
    const data =
      projectType === "nonTenderProject"
        ? nonTenderProjectData
        : tenderProjectData;
    const tendetProjectUrl = apiurl + "storeuntiprojectfirst";
    const nonTenderProjectUrl = apiurl + "storenonrailwayproject";
    const finalUrl =
      projectType === "nonTenderProject"
        ? nonTenderProjectUrl
        : tendetProjectUrl;

    const getToken = user.response.token;
    const bearer_token = `Bearer ${getToken}`;
    axios
      .post(finalUrl, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSpinner(false);
        if (response.data.success) {
          getProjectId(response.data.response.projectId);
          toast.success("Project Details Added Successfully");
          changeKey("1");
        }
      })
      .catch((error) => {
        setSpinner(false);
        if (error.response.data.message === "Error validation") {
          setValidationError(error?.response?.data?.response);
        }
      });
  };

  async function tenderDataFetch() {
    let url = apiurl + "get-tender-drop-down-list";
    const getToken = user.response.token;
    const bearer_token = `Bearer ${getToken}`;
    await axios
      .post(
        url,
        { user_id, search: null, year: "", month: "" },
        {
          headers: {
            Authorization: bearer_token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (res) => {
        if (await res?.data?.response?.isSuccess) {
          const data = res?.data?.response?.tenderListDropDown;
          setTenderData(data);
        }
      })
      .catch((error) => {
        toast.error("something went wrong");
      });
  }
  async function getVendorMasterDropDown(term) {
    setLoading(true);
    try {
      let res = await getCustomerApi({
        user_id,
        search: term,
        page: "",
        railway_zone: "",
      });

      setCustomerDropDown(res?.data?.response?.CustomerDetailsList?.data || []);
    } catch (err) {
      errorsMessage(err?.response?.status || "Unknown error");
    } finally {
      setLoading(false);
    }
  }
  const debouncedFetchItemMasterData = useCallback(
    debounce((term) => {
      getVendorMasterDropDown(term);
    }, 800),
    [user_id]
  );

  useEffect(() => {
    if (searchTerm) {
      debouncedFetchItemMasterData(searchTerm);
    }
  }, [searchTerm, debouncedFetchItemMasterData]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setValues,
    setFieldTouched,
  } = useFormik({
    initialValues:
      projectType === "nonTenderProject"
        ? NonTenderInitialValues
        : initialValues,
    validationSchema:
      projectType === "nonTenderProject"
        ? nonTenderProjectDetailSchema
        : projectDetailSchema,
    onSubmit: (values) => {
      submitProjectDetails(values);
    },
  });

  function tenderChange(e) {
    const tenderId = e.target.value;
    setFieldValue("tenderId", tenderId);
    const selectedTender = tenderData.find((item) => item.id == tenderId);
    if (selectedTender) {
      setFieldValue("pd_railwayZone", selectedTender.railwayZone);
      setFieldValue("pd_tenderDate", selectedTender.closingDate);
      setFieldValue("pd_tenderQuantity", selectedTender.quantity);
      setSelectedTenderData(selectedTender);
    } else {
      setFieldValue("pd_railwayZone", "");
      setFieldValue("pd_tenderDate", "");
      setFieldValue("pd_tenderQuantity", "");
      setSelectedTenderData(selectedTender);
    }
  }

  useEffect(() => {
    if (dropdownErrors) {
      toast.error("Unable to load the dropdown. Please try again.");
    }
  }, [dropdownErrors]);

  useEffect(() => {
    dropDownLists();
  }, [dropdowns, values.tenderId]);

  useEffect(() => {
    tenderDataFetch();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          {projectType !== "nonTenderProject" && (
            <div className="col-md-3 col-sm-6">
              <div className="form-group mb-3">
                <label htmlFor="" className="form-label fw-bold">
                  Tender No.<span className="text-danger">*</span>
                </label>
                <select
                  className="form-control border-secondary "
                  id="tenderId"
                  name="tenderId"
                  value={values.tenderId}
                  onChange={tenderChange}
                  onBlur={handleBlur}
                >
                  <option value="">Select</option>
                  {tenderData?.map((item) => {
                    return (
                      <option value={item.id} key={item.id}>
                        {item.tenderNo}
                      </option>
                    );
                  })}
                </select>
                <p className="text-danger">
                  {errors.tenderId && touched.tenderId
                    ? errors.tenderId
                    : validationError?.tenderId}
                </p>
              </div>
            </div>
          )}

          {projectType !== "nonTenderProject" && (
            <div className="col-md-3 col-sm-6">
              <div className="form-group mb-3">
                <label htmlFor="" className="form-label fw-bold">
                  File No<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control border-secondary "
                  id="pd_fileNo"
                  name="pd_fileNo"
                  placeholder="Enter file no."
                  value={values.pd_fileNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p className="text-danger">
                  {errors.pd_fileNo && touched.pd_fileNo
                    ? errors.pd_fileNo
                    : validationError?.pd_fileNo}
                </p>
              </div>
            </div>
          )}

          {projectType !== "nonTenderProject" && (
            <div className="col-md-3 col-sm-6">
              <div className="form-group mb-3">
                <label htmlFor="" className="form-label fw-bold">
                  Railway Zone<span className="text-danger">*</span>
                </label>
                <select
                  className="form-control border-secondary "
                  id="pd_railwayZone"
                  name="pd_railwayZone"
                  value={values.pd_railwayZone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled
                >
                  <option value="">Select</option>
                  {dropdowns?.railWayZoneList?.map((item, i) => {
                    return (
                      <option value={item.id} key={i}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <p className="text-danger">
                  {errors.pd_railwayZone && touched.pd_railwayZone
                    ? errors.pd_railwayZone
                    : validationError?.pd_railwayZone}
                </p>
              </div>
            </div>
          )}

          {projectType !== "nonTenderProject" && (
            <div className="col-md-3 col-sm-6">
              <div className="form-group mb-3">
                <label htmlFor="" className="form-label fw-bold">
                  Sub Division<span className="text-danger">*</span>
                </label>
                <select
                  className="form-control border-secondary "
                  id="pd_subDivision"
                  name="pd_subDivision"
                  value={values.pd_subDivision}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Select</option>
                  {subdivisionwithzone?.map((item, i) => {
                    return (
                      <option value={item.id} key={i}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <p className="text-danger">
                  {errors.pd_subDivision && touched.pd_subDivision
                    ? errors.pd_subDivision
                    : validationError?.pd_subDivision}
                </p>
              </div>
            </div>
          )}

          {projectType !== "nonTenderProject" && (
            <div className="col-md-12 col-sm-6">
              <div className="form-group mb-3">
                <label htmlFor="" className="form-label fw-bold">
                  Work Status Remark
                </label>
                <input
                  className="form-control border-secondary "
                  id="pd_workStatusRemark"
                  name="pd_workStatusRemark"
                  placeholder="Enter work status remark"
                  value={values.pd_workStatusRemark}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p className="text-danger">
                  {errors.pd_workStatusRemark && touched.pd_workStatusRemark
                    ? errors.pd_workStatusRemark
                    : validationError?.pd_workStatusRemark}
                </p>
              </div>
            </div>
          )}

          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                Work Status<span className="text-danger">*</span>
              </label>
              <select
                className="form-control border-secondary "
                id="pd_workStatus"
                name="pd_workStatus"
                value={values.pd_workStatus}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="">Select</option>
                {dropdowns?.workStatus?.map((item, i) => {
                  return (
                    <option value={item.id} key={i}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              <p className="text-danger">
                {errors.pd_workStatus && touched.pd_workStatus
                  ? errors.pd_workStatus
                  : validationError?.pd_workStatus}
              </p>
            </div>
          </div>

          {projectType !== "nonTenderProject" && (
            <div className="col-md-3 col-sm-6">
              <div className="form-group mb-3">
                <label htmlFor="" className="form-label fw-bold">
                  Tender Date<span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  className="form-control border-secondary "
                  id="pd_tenderDate"
                  name="pd_tenderDate"
                  value={values.pd_tenderDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled
                />
                <p className="text-danger">
                  {errors.pd_tenderDate && touched.pd_tenderDate
                    ? errors.pd_tenderDate
                    : validationError?.pd_tenderDate}
                </p>
              </div>
            </div>
          )}

          {projectType !== "nonTenderProject" && (
            <div className="col-md-3 col-sm-6">
              <div className="form-group mb-3">
                <label htmlFor="" className="form-label fw-bold">
                  Tender Quantity
                </label>
                <input
                  type="text"
                  className="form-control border-secondary "
                  id="pd_tenderQuantity"
                  name="pd_tenderQuantity"
                  placeholder="Enter Tender Quantity"
                  value={values.pd_tenderQuantity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled
                />
                <p className="text-danger">
                  {errors.pd_tenderQuantity && touched.pd_tenderQuantity
                    ? errors.pd_tenderQuantity
                    : validationError?.pd_tenderQuantity}
                </p>
              </div>
            </div>
          )}

          {projectType !== "nonTenderProject" && (
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <div className="form-group w-100">
                  <label htmlFor="pd_tenderDocs" className="form-label fw-bold">
                    Tender Docs
                  </label>
                  <input
                    type="file"
                    accept="application/pdf,image/*"
                    className="form-control border-secondary "
                    id="pd_tenderDocs"
                    name="pd_tenderDocs"
                    disabled
                    hidden
                  />
                  <label
                    className="d-block border form-control border-secondary ps-0 "
                    htmlFor="pd_tenderDocs"
                    style={{ cursor: "pointer", backgroundColor: "#f1f5fa" }}
                  >
                    <span
                      className="text-dark px-3 pt-2 pb-1 me-3"
                      style={{ backgroundColor: "#f1f5fa" }}
                    >
                      <i className="las la-paperclip fs-4"></i>
                    </span>{" "}
                    {selectedTenderData?.tenderattachements?.length !== 0
                      ? ` Attachment x ${selectedTenderData?.tenderattachements?.length}`
                      : "No Attachment"}
                  </label>
                  <p className="text-danger">
                    {errors.pd_tenderDocs && touched.pd_tenderDocs
                      ? errors.pd_tenderDocs
                      : validationError?.pd_tenderDocs}
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                PO/LOA No.<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control border-secondary "
                id="pd_poloaNo"
                name="pd_poloaNo"
                placeholder="Enter PO/LOA Number"
                value={values.pd_poloaNo}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger">
                {errors.pd_poloaNo && touched.pd_poloaNo
                  ? errors.pd_poloaNo
                  : validationError?.pd_poloaNo}
              </p>
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                PO/LOA Date.<span className="text-danger">*</span>
              </label>
              <input
                type="date"
                className="form-control border-secondary "
                id="pd_poloaDate"
                name="pd_poloaDate"
                placeholder=""
                value={values.pd_poloaDate}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger">
                {errors.pd_poloaDate && touched.pd_poloaDate
                  ? errors.pd_poloaDate
                  : validationError?.pd_poloaDate}
              </p>
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                {" "}
                Total Order Value<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control border-secondary "
                id="pd_totalOrderValue"
                name="pd_totalOrderValue"
                placeholder="Enter Total Order Value"
                value={values.pd_totalOrderValue}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger">
                {errors.pd_totalOrderValue && touched.pd_totalOrderValue
                  ? errors.pd_totalOrderValue
                  : validationError?.pd_totalOrderValue}
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="d-flex align-items-center gap-3">
              <div className="form-group w-100">
                <label htmlFor="paymentTerms" className="form-label fw-bold">
                  Total Order Quantity<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control border-secondary"
                  id=""
                  name="pd_totalOrderQty"
                  placeholder="Enter Total order Quantity"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values.pd_totalOrderQty}
                />
                <p className="text-danger">
                  {errors.pd_totalOrderQty && touched.pd_totalOrderQty
                    ? errors.pd_totalOrderQty
                    : validationError?.pd_totalOrderQty}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="d-flex align-items-center gap-3">
              <div className="form-group w-100">
                <label htmlFor="paymentTerms" className="form-label fw-bold">
                  PO/LOA Docs<span className="text-danger">*</span>
                </label>
                <input
                  type="file"
                  accept="application/pdf,image/*"
                  className="form-control border-secondary "
                  id="pd_poloaDocs"
                  name="pd_poloaDocs"
                  onChange={POLOADocsChange}
                />
                <p className="text-danger">
                  {errors.pd_poloaDocs && touched.pd_poloaDocs
                    ? errors.pd_poloaDocs
                    : validationError?.pd_poloaDocs}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                Project Title<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control border-secondary "
                id="pd_projectTitle"
                name="pd_projectTitle"
                placeholder="Enter Project Title"
                value={values.pd_projectTitle}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger">
                {errors.pd_projectTitle && touched.pd_projectTitle
                  ? errors.pd_projectTitle
                  : validationError?.pd_projectTitle}
              </p>
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                Project Start Date<span className="text-danger">*</span>
              </label>
              <input
                type="date"
                className="form-control border-secondary "
                id="pd_startDate"
                name="pd_startDate"
                placeholder="Enter Start Date"
                value={values.pd_startDate}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger">
                {errors.pd_startDate && touched.pd_startDate
                  ? errors.pd_startDate
                  : validationError?.pd_startDate}
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                Project End Date<span className="text-danger">*</span>
              </label>
              <input
                type="date"
                className="form-control border-secondary "
                id="pd_endDate"
                name="pd_endDate"
                placeholder="Enter End Date"
                value={values.pd_endDate}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger">
                {errors.pd_endDate && touched.pd_endDate
                  ? errors.pd_endDate
                  : validationError?.pd_endDate}
              </p>
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                Project category<span className="text-danger">*</span>
              </label>
              <select
                className="form-control border-secondary"
                id="pd_projectCategory"
                name="pd_projectCategory"
                value={values.pd_projectCategory}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="">Select</option>
                {dropdowns?.projectCategoryList?.map((item, i) => {
                  return (
                    <option value={item.id} key={i}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {touched.pd_projectCategory && errors.pd_projectCategory ? (
                <p className="text-danger">{errors.pd_projectCategory}</p>
              ) : (
                <p className="text-danger">
                  {validationError?.pd_projectCategory}
                </p>
              )}
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                Project Manager<span className="text-danger">*</span>
              </label>
              <select
                type="text"
                className="form-control border-secondary"
                id=""
                name="pd_projectManager"
                placeholder=""
                value={values.pd_projectManager}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option>Select Project Manager</option>
                {dropdowns?.userList?.map((item, i) => {
                  return (
                    <option value={item.id} key={i}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {touched.pd_projectManager && errors.pd_projectManager ? (
                <p className="text-danger">{errors.pd_projectManager}</p>
              ) : (
                <p className="text-danger">
                  {validationError?.pd_projectManager}
                </p>
              )}
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                Purchase Manager<span className="text-danger">*</span>
              </label>
              <select
                type="text"
                className="form-control border-secondary"
                id=""
                name="pd_purchaseManager"
                value={values.pd_purchaseManager}
                placeholder=""
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option>Select Purchase Manager</option>
                {dropdowns?.userList?.map((item, i) => {
                  return (
                    <option value={item.id} key={i}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {touched.pd_purchaseManager && errors.pd_purchaseManager ? (
                <p className="text-danger">{errors.pd_purchaseManager}</p>
              ) : (
                <p className="text-danger">
                  {validationError?.pd_purchaseManager}
                </p>
              )}
            </div>
          </div>
          {projectType !== "nonTenderProject" && (
            <div className="col-md-3 col-sm-6">
              <div className="form-group mb-3">
                <label htmlFor="" className="form-label fw-bold">
                  Inspection Agency<span className="text-danger">*</span>
                </label>
                <select
                  className="form-control border-secondary "
                  id="pd_InspectionAgency"
                  name="pd_InspectionAgency"
                  value={values.pd_InspectionAgency}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Select</option>
                  {dropdowns?.inspectionAgency?.map((item, i) => {
                    return (
                      <option value={item.id} key={i}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <p className="text-danger">
                  {errors.pd_InspectionAgency && touched.pd_InspectionAgency
                    ? errors.pd_InspectionAgency
                    : validationError?.pd_InspectionAgency}
                </p>
              </div>
            </div>
          )}
          {projectType === "nonTenderProject" && (
            <div className="col-md-3 col-sm-6">
              <div className="form-group mb-3">
                <label htmlFor="" className="form-label fw-bold font-16">
                  Customer Name <span className="text-danger">*</span>
                </label>
                <Select
                  placeholder="Select Item from Name"
                  className="form-select-control border-dark"
                  options={[
                    { id: "", name: "Select an Customer" },
                    ...customerDropDown,
                  ]}
                  getOptionLabel={(customerDropDown) =>
                    customerDropDown["name"]
                  }
                  getOptionValue={(customerDropDown) => customerDropDown["id"]}
                  isLoading={loading}
                  value={
                    customerDropDown.find(
                      (option) => option.id === values.pd_customer_name
                    ) || { id: "", name: "Select an Customer" }
                  }
                  onChange={(value) => {
                    setFieldValue("pd_customer_name", value?.id);
                  }}
                  onInputChange={(value) => setSearchTerm(value)}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                      ...theme.colors,
                      primary25: "lightgrey",
                      primary: "lightgrey",
                    },
                  })}
                  styles={customStyles}
                />
                <p className="text-danger">
                  {errors.pd_customer_name && touched.pd_customer_name
                    ? errors.pd_customer_name
                    : validationError?.pd_customer_name}
                </p>
              </div>
            </div>
          )}
          <div className="col-md-12 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                Project Description<span className="text-danger">*</span>
              </label>
              <textarea
                type="text"
                className="form-control border-secondary"
                id="pd_projectItemDescription"
                name="pd_projectItemDescription"
                placeholder="Enter Project Description"
                value={values.pd_projectItemDescription}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger">
                {errors.pd_projectItemDescription &&
                touched.pd_projectItemDescription
                  ? errors.pd_projectItemDescription
                  : validationError?.pd_projectItemDescription}
              </p>
            </div>
          </div>
        </div>
        <hr></hr>
        <h5 className="m-0 badge badge-soft-danger font-16 fw-bold py-3 mb-3">
          LOT Details
        </h5>
        {values?.pd_poloaQuantityMutipleData?.map((val, i) => (
          <div className="row" key={i}>
            <div className="col-md-2 col-sm-6">
              <div className="form-group mb-3">
                <label htmlFor="" className="form-label fw-bold">
                  PO/LOA Quantity<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control border-secondary "
                  placeholder="Enter PO/LOA Quantity"
                  id="poLoaQty"
                  name={`pd_poloaQuantityMutipleData[${i}].poLoaQty`}
                  value={values.pd_poloaQuantityMutipleData[i].poLoaQty}
                  onChange={(e) => {
                    const newValues = [...values.pd_poloaQuantityMutipleData];
                    newValues[i].poLoaQty = e.target.value;
                    setFieldValue(`pd_poloaQuantityMutipleData`, newValues);
                  }}
                  onBlur={(e) => {
                    setFieldTouched(
                      `pd_poloaQuantityMutipleData[${i}].poLoaQty`,
                      true
                    );
                  }}
                />
                <p className="text-danger m-0">
                  {touched.pd_poloaQuantityMutipleData &&
                  touched.pd_poloaQuantityMutipleData[i]?.poLoaQty &&
                  errors?.pd_poloaQuantityMutipleData &&
                  errors?.pd_poloaQuantityMutipleData[i]?.poLoaQty
                    ? errors?.pd_poloaQuantityMutipleData[i]?.poLoaQty
                    : Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${i}.poLoaQty`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                </p>
              </div>
            </div>

            <div className="col-md-2 col-sm-6">
              <div className="form-group mb-3">
                <label htmlFor="" className="form-label fw-bold">
                  UOM<span className="text-danger">*</span>
                </label>
                <select
                  className="form-control border-secondary "
                  id="unit"
                  value={values.pd_poloaQuantityMutipleData[i].unit}
                  onChange={(e) => {
                    const newValues = [...values.pd_poloaQuantityMutipleData];
                    newValues[i].unit = e.target.value;
                    setFieldValue(`pd_poloaQuantityMutipleData`, newValues);
                  }}
                  onBlur={(e) => {
                    setFieldTouched(
                      `pd_poloaQuantityMutipleData[${i}].unit`,
                      true
                    );
                  }}
                >
                  <option value="">Select</option>
                  {dropdowns?.unitList?.map((item, i) => {
                    return (
                      <option value={item.id} key={i}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <p className="text-danger m-0">
                  {touched.pd_poloaQuantityMutipleData &&
                  touched.pd_poloaQuantityMutipleData[i]?.unit &&
                  errors?.pd_poloaQuantityMutipleData &&
                  errors?.pd_poloaQuantityMutipleData[i]?.unit
                    ? errors?.pd_poloaQuantityMutipleData[i]?.unit
                    : Object.entries(validationError)
                        ?.filter(([key, value]) => key.includes(`${i}.unit`))
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                </p>
              </div>
            </div>

            <div className="col-md-2 col-sm-6">
              <div className="form-group mb-3">
                <label htmlFor="" className="form-label fw-bold">
                  Start Date<span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  className="form-control border-secondary "
                  id="startDate"
                  value={values.pd_poloaQuantityMutipleData[i].startDate}
                  onChange={(e) => {
                    const newValues = [...values.pd_poloaQuantityMutipleData];
                    newValues[i].startDate = e.target.value;
                    setFieldValue(`pd_poloaQuantityMutipleData`, newValues);
                  }}
                  onBlur={(e) => {
                    setFieldTouched(
                      `pd_poloaQuantityMutipleData[${i}].startDate`,
                      true
                    );
                  }}
                />

                <p className="text-danger m-0">
                  {touched.pd_poloaQuantityMutipleData &&
                  touched.pd_poloaQuantityMutipleData[i]?.startDate &&
                  errors?.pd_poloaQuantityMutipleData &&
                  errors?.pd_poloaQuantityMutipleData[i]?.startDate
                    ? errors?.pd_poloaQuantityMutipleData[i]?.startDate
                    : Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${i}.startDate`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                </p>
              </div>
            </div>

            <div className="col-md-2 col-sm-6">
              <div className="form-group mb-3">
                <label htmlFor="" className="form-label fw-bold">
                  Completion Date<span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  className="form-control border-secondary "
                  id="completionDate"
                  // name={`pd_poloaQuantityMutipleData[${i}].completionDate`}
                  value={values.pd_poloaQuantityMutipleData[i].completionDate}
                  onChange={(e) => {
                    const newValues = [...values.pd_poloaQuantityMutipleData];
                    newValues[i].completionDate = e.target.value;
                    setFieldValue(`pd_poloaQuantityMutipleData`, newValues);
                  }}
                />
                <p className="text-danger m-0">
                  {touched.pd_poloaQuantityMutipleData &&
                  touched.pd_poloaQuantityMutipleData[i]?.completionDate &&
                  errors?.pd_poloaQuantityMutipleData &&
                  errors?.pd_poloaQuantityMutipleData[i]?.completionDate
                    ? errors?.pd_poloaQuantityMutipleData[i]?.completionDate
                    : Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${i}.completionDate`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                </p>
              </div>
            </div>

            <div className="col-md-2 col-sm-6">
              <div className="form-group mb-3">
                <label htmlFor="" className="form-label fw-bold">
                  DP Extension
                </label>
                <input
                  type="text"
                  className="form-control border-secondary "
                  id="dpExtension"
                  // name={`pd_poloaQuantityMutipleData[${i}].completionDate`}
                  placeholder="Enter DP Extention"
                  value={values.pd_poloaQuantityMutipleData[i].dpExtension}
                  onChange={(e) => handleMultChange(e, i)}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-auto col-sm-6">
              <label className="form-label ">Action</label>
              <div>
                <button
                  type="button"
                  className="btn btn-primary  align-items-center me-2 fw-bold"
                  onClick={handleMltClick}
                >
                  +
                </button>
                <button
                  type="button"
                  className="btn btn-de-danger border-danger fw-bold"
                  onClick={() => handleMultDelete(i)}
                  hidden={i === 0}
                >
                  -
                </button>
              </div>
            </div>
          </div>
        ))}

        {/* <p className="text-danger">{pd_poloaQuantityMutipleDaterror}</p> */}

        {spinner ? (
          <div className="">
            <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
            <p>Wait a moment....</p>
          </div>
        ) : (
          <>
            <button
              type="submit"
              // onClick={() => changeKey("1")}
              className="btn btn-primary btn-sm me-3"
            >
              Save
            </button>
          </>
        )}
      </form>
    </>
  );
}

export default ProjectDetailsForm;
