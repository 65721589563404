import React from "react";

function ContactDetailForm({
  handleChange,
  values,
  errors,
  touched,
  serverSideValidation,
  handleBlur,
}) {
  return (
    <div>
      <div className="row g-3">
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Contact Person <span className="text-danger">*</span>
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Contact Person"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.contact_person}
            name="contact_person"
          ></input>
          <p className="text-danger m-0">
            {touched?.contact_person && errors?.contact_person
              ? errors?.contact_person
              : serverSideValidation?.contact_person}
          </p>
        </div>

        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Job Title <span className="text-danger">*</span>
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Job Title"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.contact_job_ttitle}
            name="contact_job_ttitle"
          ></input>
          <p className="text-danger m-0">
            {touched?.contact_job_ttitle && errors?.contact_job_ttitle
              ? errors?.contact_job_ttitle
              : serverSideValidation?.contact_job_ttitle}
          </p>
        </div>

        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Designation <span className="text-danger">*</span>
          </label>
          <select
            className="form-control border-secondary "
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.contact_job_designation}
            name="contact_job_designation"
          >
            <option value="">Select</option>
            <option value="office">Office</option>
            <option value="salesManager">Sales Manager</option>
          </select>
          <p className="text-danger m-0">
            {touched?.contact_job_designation && errors?.contact_job_designation
              ? errors?.contact_job_designation
              : serverSideValidation?.contact_job_designation}
          </p>
        </div>

        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Mobile <span className="text-danger">*</span>
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Mobil Number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.contact_mobile}
            name="contact_mobile"
            minLength={10}
            maxLength={10}
          ></input>
          <p className="text-danger m-0">
            {touched?.contact_mobile && errors?.contact_mobile
              ? errors?.contact_mobile
              : serverSideValidation?.contact_mobile}
          </p>
        </div>

        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Email Address <span className="text-danger">*</span>
          </label>
          <input
            type="email"
            className="form-control border-secondary"
            placeholder="Enter Email Address"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.contact_email}
            name="contact_email"
          ></input>
          <p className="text-danger m-0">
            {touched?.contact_email && errors?.contact_email
              ? errors?.contact_email
              : serverSideValidation?.contact_email}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ContactDetailForm;
