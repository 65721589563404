import * as Yup from "yup";

const addVendorFormSchema = () => {
  return Yup.object().shape({
    // Vendor Basic Details
    party_name: Yup.string().required("This field is required"),
    under_invoice: Yup.string().required("This field is required"),
    under_account: Yup.string().required("This field is required"),

    // Address Details
    address_type: Yup.string().required("This field is required"),
    country_id: Yup.string().required("This field is required"),
    state_id: Yup.string().required("This field is required"),
    city_id: Yup.string().required("This field is required"),
    address: Yup.string().required("This field is required"),
    pin_code: Yup.string()
      .matches(/^\d+$/, "Pin code must contain only numbers")
      .length(6, "Pin code must be exactly 6 digits")
      .required("This field is required"),

    // Bank Details
    bank_name: Yup.string().required("Bank name is required"),
    ifsc_code: Yup.string()
      .min(6, "IFSC code must be at least 6 characters")
      .max(20, "IFSC code cannot exceed 20 characters")
      .matches(/^[a-zA-Z0-9]+$/, "IFSC code must be alphanumeric")
      .required("IFSC code is required"),

    account_holder: Yup.string().required("Account holder is required"),
    account_no: Yup.string()
      .matches(/^\d+$/, "Account number must contain only numbers")
      .required("Account number is required"),
    swift_code: Yup.string().when("country", {
      is: (value) => value !== "1", // Checks if the country value is NOT "1"
      then: (schema) =>
        schema
          .matches(
            /^[a-zA-Z0-9]{8,11}$/,
            "SWIFT code must be 8-11 characters long and contain only alphanumeric characters"
          )
          .required("SWIFT code is required when the country is not India"),
      otherwise: (schema) => schema.nullable(), // Allows null when country is "1"
    }),

    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    bank_address: Yup.string().required("Bank address is required"),
    bank_pin_code: Yup.string()
      .matches(/^\d+$/, "Pin code must contain only numbers")
      .length(6, "Pin code must be exactly 6 digits")
      .required("This field is required"),

    // Contact Details
    contact_person: Yup.string().required("This field is required"),
    contact_job_ttitle: Yup.string().required("This field is required"),
    contact_job_designation: Yup.string().required("This field is required"),
    contact_mobile: Yup.string()
      .matches(
        /^\d{10}$/,
        "Mobile number must be exactly 10 digits and contain only numbers"
      )
      .required("This field is required"),
    contact_email: Yup.string()
      .email("Invalid email")
      .nullable()
      .notOneOf([null], "This field cannot be null")
      .required("This field is required"),

    // Account Details
    currency: Yup.string().required("This field is required"),
    status: Yup.string().required("This field is required"),
    credit_limit_days: Yup.string().required("This field is required"),
    is_msme_check: Yup.string().required("This field is required"),
    pan_no: Yup.string()
      .matches(
        /^[a-zA-Z0-9]{10}$/,
        "PAN number must be exactly 10 characters and contain only numbers or alphabets"
      )
      .nullable()
      .notOneOf([null], "This field cannot be null")
      .required("This field is required"),
    gst_no: Yup.string()
      .matches(
        /^[a-zA-Z0-9]{15}$/,
        "GST number must be exactly 15 characters and contain only numbers or alphabets"
      )
      .nullable()
      .notOneOf([null], "This field cannot be null")
      .required("This field is required"),
    msme_no: Yup.string().when("is_msme_check", {
      is: "Yes",
      then: (schema) =>
        schema
          .min(15, "MSME number must be at least 15 characters")
          .max(20, "MSME number must be at most 20 characters")
          .matches(
            /^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>_-]*$/,
            "MSME number can contain only alphanumeric characters and special characters"
          )
          .required("This field is required"),
      otherwise: (schema) => schema.nullable(),
    }),
    msme_type: Yup.string().when("is_msme_check", {
      is: "Yes",
      then: (schema) => schema.required("This field is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  });
};

export default addVendorFormSchema;
