import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { IoIosSearch } from "react-icons/io";

function TermsAndCondition({
  termsAndCondition,
  isChecked,
  handleChangeCheck,
  loading,
  handleSearch,
  search,
  setSearch,
  termsAndConditonCheckedId,
}) {
  return (
    <>
      <table style={{ borderCollapse: "collapse", border: "1px solid black" }}>
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid black",
                padding: "10px",
                width: "10rem",
              }}
              className="text-center"
            >
              <h6 className="fw-bold">Document Type</h6>
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "10px",
                width: "10rem",
              }}
              className="text-center"
            >
              <h6 className="fw-bold">Title</h6>
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "10px",
                width: "50rem",
              }}
              className="text-center"
            >
              <div className="d-flex justify-content-between">
                <p className="invisible">dsfvd</p>
                <div className="text-center">
                  <h6 className="fw-bold text-center">DESCRIPTION</h6>
                </div>
                <div className="ms-1">
                  {/* <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 1,
                        width: "25ch",
                        height: "5ch",
                        borderColor: "red",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="standard-basic"
                      label="Search"
                      variant="standard"
                      value={search}
                      name="search"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Box> */}
                  <input
                    className="ms-auto bg-transparent"
                    style={{
                      border: "none",
                      borderBottom: "1px solid black",
                    }}
                    value={search}
                    name="search"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <IoIosSearch
                    className="text-center"
                    style={{ height: "2.3rem", width: "2.3rem" }}
                  />
                </div>
              </div>
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "10px",
                width: "5rem",
              }}
              className="text-center"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {termsAndCondition?.map((element, i) => (
            <tr key={element.id}>
              <td
                style={{ border: "1px solid black", padding: "10px" }}
                className="text-center"
              >
                {element.document_type}
              </td>
              <td
                style={{ border: "1px solid black", padding: "10px" }}
                className="text-center"
              >
                {element.title}
              </td>
              <td
                style={{ border: "1px solid black", padding: "10px" }}
                className="text-center"
              >
                {element.description}
              </td>
              <td
                style={{ border: "1px solid black", padding: "10px" }}
                className="text-center"
              >
                <input
                  type="checkbox"
                  checked={termsAndConditonCheckedId?.some(
                    (id) => element.id === id.terms_conditions_id
                  )}
                  onChange={(e) =>
                    handleChangeCheck(e, element.id, i, element?.description)
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {loading && (
        <div className="d-flex text-center justify-content-center mt-4">
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        </div>
      )}
    </>
  );
}

export default TermsAndCondition;
