import React from "react";
import AddIcon from "@mui/icons-material/Add";
import _ from "lodash";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";

function PurchaseOrderDeliveryScheduling({ data, setFieldValue }) {
  const handleChange = (rowIndex, inputIndex, field, value) => {
    const newValue = value === "" ? "" : value;
    const newData = _.cloneDeep(data);
    newData.item_details[rowIndex].schedule_data[inputIndex][field] = newValue;
    setFieldValue("item_details", newData?.item_details);

    // let calcData = calculateTotal(newData, rowIndex);
    // const currentTotalQty = parseFloat(calcData[rowIndex]?.totalQty) || 0;
    // const allowedQty = parseFloat(calcData[rowIndex]?.qty) || 0;
    // if (allowedQty - currentTotalQty < 0) {
    //   setButtonDisable(true);
    // } else {
    //   setButtonDisable(false);
    // }
  };

  const calculateTotal = (newData, inputIndex) => {
    if (!newData[inputIndex]?.hasOwnProperty("totalQty")) {
      return;
    }
    const data = _.cloneDeep(newData);

    let total = 0;
    data[inputIndex]?.schedule_data?.map((input) => {
      total += parseFloat(input.quantity) || 0;
    });
    data[inputIndex].totalQty = total;
    setData(data);
    return data;
  };

  const addInputs = (index) => {
    const newData = _.cloneDeep(data);

    if (newData?.item_details[index]?.schedule_data) {
      newData.item_details[index].schedule_data = [
        ...newData.item_details[index].schedule_data,
        {
          quantity: "",
          schedule_date: "",
          remarks: "",
        },
      ];
      setFieldValue("item_details", newData?.item_details);
    } else {
      console.error("Invalid structure in data for the given index:", index);
    }
  };

  const removeInput = (rowIndex, inputIndex) => {
    const newData = _.cloneDeep(data);
    if (newData.item_details[rowIndex].schedule_data.length > 1) {
      newData.item_details[rowIndex].schedule_data.splice(inputIndex, 1);
      setFieldValue("item_details", newData?.item_details);
    }
    calculateTotal(newData);
  };
  return (
    <div style={{ background: "#fff" }}>
      <form>
        <table
          style={{
            background: "#fff",
            borderCollapse: "collapse",
            width: "100%",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  fontSize: "small",
                  fontWeight: "700",
                  border: "1px solid #ccc",
                  padding: "8px",
                }}
              >
                Item Name
              </th>
              {/* <th
                style={{
                  fontSize: "small",
                  fontWeight: "700",
                  border: "1px solid #ccc",
                  padding: "8px",
                }}
              >
                ITEM NAME
              </th> */}
              <th
                style={{
                  fontSize: "small",
                  fontWeight: "700",
                  border: "1px solid #ccc",
                  padding: "8px",
                }}
              >
                ORDER QTY
              </th>
              {/* <th
                style={{
                  fontSize: "small",
                  fontWeight: "700",
                  border: "1px solid #ccc",
                  padding: "8px",
                }}
              >
                BAL.
              </th> */}
              <th
                style={{
                  fontSize: "small",
                  fontWeight: "700",
                  border: "1px solid #ccc",
                  padding: "8px",
                }}
              >
                SCHEDULE QTY
              </th>
              <th
                style={{
                  fontSize: "small",
                  fontWeight: "700",
                  border: "1px solid #ccc",
                  padding: "8px",
                }}
              >
                SCHEDULE DATE
              </th>
              <th
                style={{
                  fontSize: "small",
                  fontWeight: "700",
                  border: "1px solid #ccc",
                  padding: "8px",
                }}
              >
                REMAKS
              </th>
              <th
                style={{
                  fontSize: "small",
                  fontWeight: "700",
                  border: "1px solid #ccc",
                  padding: "8px",
                }}
              >
                Add/Remove
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.item_details?.map((row, rowIndex) => (
              <React.Fragment key={rowIndex}>
                <tr>
                  <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                    {row?.item_master_details?.product_name}
                  </td>
                  {/* <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                    {row?.product_name}
                  </td> */}
                  <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                    {row?.qty}
                  </td>
                  {/* <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                    {row?.balance_qty}
                  </td> */}
                  <td colSpan={4} style={{ padding: 0 }}>
                    <table style={{ width: "100%" }}>
                      {row?.schedule_data?.map((input, inputIndex) => (
                        <tr key={inputIndex}>
                          <td
                            style={{ border: "1px solid #ccc", padding: "8px" }}
                          >
                            <input
                              type="number"
                              className="w-100 form-control"
                              value={input.quantity}
                              onChange={(e) =>
                                handleChange(
                                  rowIndex,
                                  inputIndex,
                                  "quantity",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td
                            style={{ border: "1px solid #ccc", padding: "8px" }}
                          >
                            <input
                              type="date"
                              className="w-100 form-control"
                              value={input.schedule_date}
                              onChange={(e) =>
                                handleChange(
                                  rowIndex,
                                  inputIndex,
                                  "schedule_date",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td
                            style={{ border: "1px solid #ccc", padding: "8px" }}
                          >
                            <input
                              type="text"
                              className="w-100 form-control"
                              value={input.remarks}
                              onChange={(e) =>
                                handleChange(
                                  rowIndex,
                                  inputIndex,
                                  "remarks",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td
                            style={{ border: "1px solid #ccc", padding: "8px" }}
                          >
                            {inputIndex === 0 ? (
                              <button
                                type="button"
                                onClick={() => addInputs(rowIndex)}
                                className="btn btn-danger"
                              >
                                <AddIcon />
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={() =>
                                  removeInput(rowIndex, inputIndex)
                                }
                                className="btn btn-danger"
                              >
                                <RemoveOutlinedIcon />
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </form>
    </div>
  );
}

export default PurchaseOrderDeliveryScheduling;
