import React, { useState } from "react";
import { getMaterialListCsv } from "../../purchaseApi";
import { errorsMessage } from "../../../constant";
import DateRangeCommonFilter from "../../../common/DateRangeCommonFilter";

function MaterialTrackerFilters({
  filters,
  handleFilterChange,
  handleDateFilterChange,
}) {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [downloadLoading, setDownloadLoading] = useState(false);
  const railwayPOOptions = [
    { option: "Railway PO Number", value: "railway" },
    { option: "Purchase Order No.", value: "purchaseOrder" },
    { option: "Supplier", value: "supplier" },
    { option: "Item Name", value: "itemName" },
  ];

  const handleDownload = () => {
    setDownloadLoading(true);
    getMaterialListCsv({ user_id })
      .then((res) => {
        const fileUrl = res?.data?.response?.materialTrackerListCSV?.file_url;

        if (fileUrl) {
          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = "Vendor_data.csv"; // Set the default file name
          document.body.appendChild(link);
          link.click(); // Simulate a click to start the download
          document.body.removeChild(link);
        } else {
          console.error("No file URL found in the response");
        }
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setDownloadLoading(false);
      });
  };

  return (
    <div className="material-tracker-container">
      <div className="material-tracker-title">Material Tracker</div>
      <div className="material-tracker-controls">
        {/* Dropdown */}
        <select
          value={filters?.search_by}
          onChange={handleFilterChange}
          className="material-tracker-dropdown"
          name="search_by"
        >
          {railwayPOOptions.map((option, index) => (
            <option key={index} value={option?.value}>
              {option?.option}
            </option>
          ))}
        </select>

        {/* Search Bar */}
        <input
          type="text"
          value={filters?.search}
          onChange={handleFilterChange}
          placeholder="Search"
          className="material-tracker-search"
          name="search"
        />

        {/* From Date Picker */}
        <DateRangeCommonFilter handleChange={handleDateFilterChange} />

        {/* Download Button */}
        <button
          onClick={handleDownload}
          className="material-tracker-button"
          disabled={downloadLoading}
        >
          {downloadLoading ? "Downloading" : "Download"}
        </button>
      </div>
    </div>
  );
}

export default MaterialTrackerFilters;
