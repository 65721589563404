import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Link } from "react-router-dom";

const options = ["For Internal Store", "For Railway"];
// "For Railway to Store"

export default function SimpleListMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <List component="nav" aria-label="Device settings">
        <ListItemButton
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="Add Delivey Challan"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
          sx={{
            backgroundColor: "#ef4d56",
            border: "2px solid #ef4d56",
            borderRadius: "3px",
            padding: ".3rem 1rem",
            color: "white",
            "&:hover": {
              backgroundColor: "#ef4d56",
              opacity: 1,
            },
          }}
        >
          <ListItemText primary="Add&nbsp;Delivey&nbsp;Challan" />
        </ListItemButton>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={option}>
            <Link to={"/add-deliveryChallan"} state={option}>
              {option}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
