import React from "react";

function EmdDetailPreview({ emdData }) {
  return (
    <>
      <div className="">
        <label
          htmlFor=""
          className="form-label"
          style={{ textAlign: "center" }}
        >
          Type
        </label>
        <h5
          className="text-danger fw-bold m-0 form-control border-0 ps-0"
          style={{ text: "center" }}
        >
          EMD Details
        </h5>
      </div>
      <div className="col-md-12">
        <div className="form-group mb-3">
          <label htmlFor="" className="form-label">
            Paid/Exempted
          </label>
          <input
            defaultValue={emdData?.emd_paidExempted}
            readOnly="true"
            className="form-control"
            placeholder="Paid/Exempted"
          />
        </div>
      </div>

      <div className="col-md-12">
        <div className="form-group mb-3">
          <label htmlFor="" className="form-label">
            Amount
          </label>
          <input
            className="form-control"
            readOnly="true"
            defaultValue={emdData?.emd_toPay}
            placeholder="Emd to Pay"
          />
        </div>
      </div>

      <div className="col-md-12">
        <div className="form-group mb-3">
          <label htmlFor="" className="form-label">
            Transaction No.
          </label>
          <input
            className="form-control"
            readOnly="true"
            defaultValue={emdData?.emd_paidTransactionNo}
            placeholder="EMD Paid Transaction no."
          />
        </div>
      </div>
      <div className="col-md-12">
        <div className="form-group mb-3">
          <label htmlFor="" className="form-label">
            Transaction Date
          </label>
          <input
            className="form-control"
            readOnly="true"
            defaultValue={emdData?.emd_paidTransactionNoDate}
            placeholder="Transaction Date"
          />
        </div>
      </div>

      <div className="col-md-12">
        <div className="form-group mb-3">
          <label htmlFor="" className="form-label">
            Expiry Date
          </label>
          <input
            className="form-control"
            readOnly="true"
            defaultValue={emdData?.emd_expiryDate}
            placeholder="Expiry Date"
          />
        </div>
      </div>
      <div className="col-md-12">
        <div className="form-group mb-3">
          <label htmlFor="" className="form-label">
            Return Date
          </label>
          <input
            className="form-control"
            readOnly="true"
            defaultValue={emdData?.emd_returnDate}
            placeholder="Return Date"
          />
        </div>
      </div>
      <div className="col-md-12">
        <div className="form-group mb-3">
          <label htmlFor="" className="form-label">
            Return Amount
          </label>
          <input
            className="form-control"
            readOnly="true"
            defaultValue={emdData?.emd_returnAmount}
            placeholder="Return Amount"
          />
        </div>
      </div>
      <div className="col-md-12">
        <div className="form-group mb-3">
          <label htmlFor="" className="form-label">
            Due Amount
          </label>
          <input
            className="form-control"
            readOnly="true"
            defaultValue={emdData?.emd_due}
            placeholder="Due Amount"
          />
        </div>
      </div>
      <div className="col-md-12">
        <div className="form-group mb-3">
          <label htmlFor="" className="form-label">
            Return Status
          </label>
          <input
            className={`form-control ${
              Number(emdData?.emd_due) !== 0 ? "bg-danger" : "bg-success"
            }`}
            readOnly="true"
            defaultValue={emdData?.emd_returnStatus}
            placeholder="Return Status"
          />
        </div>
      </div>
      <div className="col-md-12">
        <div className="form-group mb-3">
          <label htmlFor="" className="form-label">
            Remark
          </label>
          <input
            className="form-control"
            readOnly="true"
            defaultValue={emdData?.emd_remark}
            placeholder="Remark"
          />
        </div>
      </div>
      <div></div>
      <div></div>
      <div></div>
      <div />
    </>
  );
}
export default EmdDetailPreview;
