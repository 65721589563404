import * as Yup from "yup";

export const GoodReceiptSchemaPurchaseOrder = Yup.object({
  invoice: Yup.mixed().required("This field is required."),
  invoice_no: Yup.mixed().required("This field is required."),
  invoice_date: Yup.mixed().required("This field is required."),
  received_by: Yup.mixed().required("This field is required."),
  receiving_date: Yup.mixed().required("This field is required."),
  invoice_amount: Yup.number()
    .typeError("Invoice amount must be a number.")
    .required("This field is required.")
    .positive("Invoice amount must be positive.")
    .test(
      "is-decimal",
      "Invoice amount must have at most two decimal places.",
      (value) =>
        value === undefined || /^\d+(\.\d{1,2})?$/.test(value.toString())
    ),
});
export const UpdateGoodReceiptSchemaPurchaseOrder = Yup.object({
  invoice_no: Yup.mixed().required("This field is required."),
  invoice_date: Yup.mixed().required("This field is required."),
  received_by: Yup.mixed().required("This field is required."),
  receiving_date: Yup.mixed().required("This field is required."),
  invoice_amount: Yup.number()
    .typeError("Invoice amount must be a number.")
    .required("This field is required.")
    .positive("Invoice amount must be positive.")
    .test(
      "is-decimal",
      "Invoice amount must have at most two decimal places.",
      (value) =>
        value === undefined || /^\d+(\.\d{1,2})?$/.test(value.toString())
    ),
});
