import React, { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import doneSound from "../Assets/done1.mp3";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

export default function Comments({
  handleClose,
  editTicketId,
  scrollToBottom,
}) {
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const imageBaseUrl = process.env.REACT_APP_BASE_URL;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const [comment, setComment] = useState("");
  const [commentList, setCommentList] = useState([]);
  const audioPlayer = useRef(null);
  const [deleteCommentModal, setDeleteCommentModal] = useState(false);
  const [spinner, setSpinner] = useState(false);

  ////// Function for Add comment Start

  function playAudio() {
    audioPlayer.current.play();
  }

  function addComment() {
    setSpinner(true);
    const url = `${baseUrl}storeticketfollowup`;
    const data = { user_id, comment, ticket_id: editTicketId };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
        },
      })
      .then((response) => {
        setSpinner(false);
        if (response.status !== 200) {
          return;
        }
        scrollToBottom();
        setComment("");
        getCommentList();
         
        playAudio();
        toast.success(
          "Comment added Successfully"
        );
      })
      .catch((error) => {
        toast.error("Something Wrong , Try again later.");
        setSpinner(false);
      });
  }
  ///// Function For Add Comment End

  ///// Function for Comment List Start /////
  function getCommentList() {
    const url = `${baseUrl}getticketfollowup`;
    const data = { user_id, ticket_id: editTicketId };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setCommentList(response?.data?.response?.ticketFollowupList);
        }
      })
      .catch((error) => {
      });
  }

  //// Function for Comment list End ///////

  //// Get Time for Comments ////

  function timeAgo(time) {
    let minutes;
    let hours;
    let days;
    const currentTime = new Date().getTime();
    const commentTime = new Date(time).getTime();
    const agoTime = currentTime - commentTime;
    // days = Math.floor( (agoTime % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
    hours = Math.floor((agoTime / (1000 * 60 * 60)) % 24);
    minutes = Math.floor((agoTime / 1000 / 60) << 0);
    days = Math.floor(hours / 24);


    if (minutes === "0") {
      return "Just now";
    }
    if (minutes < 60) {
      return `${minutes} minute ago`;
    }
    if (hours < 24) {
      return `${hours} hours ago`;
    }
    if (days <= 1) {
      return `${days} days ago`;
    }
  }

  ///// Delete Comment Start ///////
  function deleteComment() {
    const url = `${baseUrl}deleteticketfollowup`;
    const data = { user_id, followup_id: false };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          getCommentList();
          handleClose();
        }
      })
      .catch((error) => {
      });
  }

  ///// Delete Comment End ///////

  useState(() => {
    getCommentList();
  }, []);

  return (
    <>
      <div className="row mt-3">
        <div className="col-md-12">
          <div>
            <div className="d-flex align-self-center" />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="mx-2">
            <div className="row">
              <textarea
                className="form-control mb-4 border-secondary"
                id="comment"
                rows={1}
                placeholder="Add Comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </div>
            <div className="text-end">
              {spinner ? (
                <div className="">
                  <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                  <p>Wait a moment...</p>
                </div>
              ) : (
                <Button
                  variant="danger"
                  size="sm"
                  onClick={addComment}
                  disabled={comment.length === 0}
                >
                  Add Comment
                </Button>
              )}

              <audio ref={audioPlayer} src={doneSound} />
            </div>
          </form>
        </div>
      </div>

      <div className="row mt-3">
        <h5> All Comments</h5>

        {commentList?.map((element, index) => {
          return (
            <div className="col-12 my-3 border-bottom" key={element.id}>
              <div className="d-flex justify-content-between">
                <div className="d-flex gap-2 align-items-center">
                  <div>
                    <img src={imageBaseUrl + element?.userData?.profileimage}
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "25px",
                    }}
                    alt="DP" />
                  </div>
                  <div>
                    <h6 className=" m-0 p-0" style={{ fontWeight: "600" }}>
                      {element?.userData?.name}{" "}
                      <span
                        className="text-secondary"
                        style={{ fontSize: "10px" }}
                      >
                        &nbsp; {timeAgo(element?.created_at)}
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
              <div
                className="text-secondary me-5"
                style={{ marginLeft: "2.3rem" }}
              >
                {element?.comment}
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        size="sm"
        show={deleteCommentModal}
        onHide={() => setDeleteCommentModal(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        keyboard={false}
        className="border-danger"
        style={{ border: "1px solid red" }}
      >
        <Modal.Header className="bg-danger">
          <Modal.Title
            id="example-modal-sizes-title-sm"
            className="bg-danger m-0 p-0 text-center"
          >
            Are You sure?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-between">
          <button className="btn btn-sm btn-danger" onClick={deleteComment}>
            Confirm
          </button>
          <button
            className="btn btn-sm btn-secondary"
            onClick={() => setDeleteCommentModal(false)}
          >
            Cancel
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}
