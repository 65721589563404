import React from "react";
import { formatDate } from "../../../../../constant";
import { FaRegFilePdf } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import StatusButton from "../CommonComponents/StatusButton";

function DetailListTable({ data, modalOpenFunction }) {
  const basePdfUrl = process.env.REACT_APP_BASE_URL;
  return (
    <div className="mt-3">
      {/* Scrollable Container */}
      <div className="table-scroll-container">
        <table>
          <thead>
            <tr
              style={{ background: "#E7F2F9" }}
              className="communication-table-detail-header"
            >
              <th>To</th>
              <th>From</th>
              <th>Subject</th>
              <th>Type</th>
              <th>Current Letter Ref NO.</th>
              <th>Letter date</th>
              <th>Approved By</th>
              <th>Status</th>
              <th>Attachment</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item) => (
              <tr
                className="communication-tracker-detail-table-detail"
                key={item?.id}
              >
                <td>{item?.to ? item?.to : "- - -"}</td>
                <td>
                  {item?.written_by_name ? item?.written_by_name : "- - -"}
                </td>
                <td>{item?.subject ? item?.subject : "- - -"}</td>
                <td>{item?.type ? item?.type : "- - -"}</td>
                <td>{item?.ref_no ? item?.ref_no : "- - -"}</td>
                <td>
                  {item?.letter_date ? formatDate(item?.letter_date) : "- - -"}
                </td>
                <td>
                  {item?.approved_by_name ? item?.approved_by_name : "- - -"}
                </td>
                <td>
                  {" "}
                  <StatusButton className="communication-status-button-green">
                    {item.status ? item.status : "- - -"}
                  </StatusButton>
                </td>
                <td className="text-center">
                  {item.attachment ? (
                    <a href={basePdfUrl + item.attachment} target="_blank">
                      <FaRegFilePdf
                        color="red"
                        fontSize={18}
                        style={{ cursor: "pointer" }}
                      />
                    </a>
                  ) : (
                    "- - -"
                  )}
                </td>
                <td className="text-center">
                  <MdEdit
                    color="#18D39B"
                    fontSize={25}
                    style={{ cursor: "pointer" }}
                    onClick={() => modalOpenFunction(item)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DetailListTable;
