import Accordion from "react-bootstrap/Accordion";
import VendorMaster from "./VendorMaster";
import OtherDetail from "./OtherDetail";
import ItemDetail from "./ItemDetail";
import TaxDetail from "./TaxDetail";
import TermsAndCondition from "./TermsAndCondition";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import { errorsMessage } from "../../../constant";
import {
  listPurchaseTermsAndConditons,
  nonTenderApproverDropdownApi,
  vendorValidationApi,
} from "../../purchaseApi";
import { toast } from "react-toastify";
import purchaseOrderSchema from "./PurchaseOrderValidationSchema";
import usePagination from "../../Common/usePurchasePagination";
import VendorVerifingLoader from "./VendorVerifingLoader";
import EmailSendConfirmationModal from "./EmailSendConfirmationModal";
import PurchaseOrderDeliveryScheduling from "./PurchaseOrderDeliveryScheduling";
import PurchaseOrderPreviewModal from "./PurchaseOrderPreviewModal";

function NonTenderPurchaseOrder({ tabKey, PurchaseOrderOnProjectId, setKey }) {
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const user_name = user?.response?.userDetails?.name;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const navigate = useNavigate();
  const [serverSideValidation, setServerSideValidation] = useState("");
  const [loadings, setLoading] = useState(false);
  const [multipleItemDetail, setMultipleItemDetail] = useState([]);
  const [validationLoader, setValidationLoader] = useState(false);
  const [vendorErrorMessage, setVendorErrorMessage] = useState("");
  const tableBodyRef = useRef(null);
  const [isChecked, setIsChecked] = useState([]);
  const [purchaseOrderOnProject, setPurchaseOrderOnProject] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalButtonLoader, setModalButtonLoader] = useState(false);
  const [selectUpdatePurchaseOrder, setSelectUpdatePurchaseOrder] =
    useState("");
  const [itemIndex, setItemIndex] = useState("");
  const [search, setSearch] = useState("");
  const [timer, setTimer] = useState(null);
  const [debounceValue, setDebounceValue] = useState("");
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  const date = formattedDate;
  const [poGrandTotal, setPoGrandTotal] = useState("");
  const [approvers, setApprovers] = useState([]);
  const [show, setShow] = useState(false);
  const [poPreviewData, setPoPreviewData] = useState({
    selectedVendor: "",
    selectedBranch: "",
    selectedStore: "",
  });
  const currentDate = new Date().toISOString().split("T")[0];

  const {
    termsAndCond,
    loading,
    hasMore,
    loadMore,
    resetPagination,
    data: termsAndCondition,
  } = usePagination(
    listPurchaseTermsAndConditons,
    user_id,
    "TermMasterList",
    debounceValue
  );
  useEffect(() => {
    if (!search) {
      resetPagination();
    }
  }, [search]);
  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    const delay = setTimeout(() => {
      setDebounceValue(search);
    }, 1000);

    setTimer(delay);

    return () => clearTimeout(delay);
  }, [search]);

  useEffect(() => {
    const arrayLength = termsAndCond.length;
    const initialArray = Array.from({ arrayLength }).fill(false);
    setIsChecked((prev) => [...prev, ...initialArray]);
  }, [termsAndCondition]);

  const itemInitialValue = {
    item_id: "",
    due_date: "",
    item_description: "",
    specification: "",
    remarks: "",
    qty: "",
    rate: "",
    disc_type: "",
    disc_per: "",
    disc_amt: "",
    unit_id: "",
    gst_percent: "",
    gst_amt: "",
    amount: "",
    second_qty: "",
    second_unit_id: "",
    item_code: "",
    schedule_data: [
      {
        quantity: "",
        schedule_date: "",
        remarks: "",
      },
    ],
  };
  const [itemsValue, setItemValues] = useState({
    ...itemInitialValue,
  });

  const initialValue = {
    amt: "",
    bill_to: "",
    billing_address: "",
    credit_payment_duration: "",
    currency_type: "",
    delivery_contact_person: "",
    delivery_date: "",
    delivery_store: "",
    grand_total: "",
    gst: "",
    inspection_agency: "",
    mode_of_payment: "",
    nature: "",
    order_date: "",
    order_id: "",
    party_type: "",
    purchase_order_no: "1",
    request_type: "",
    requested_by: "",
    round_off: "",
    second_unit: "",
    state: "",
    store_contact_person: "",
    sub_total: "",
    supplier: "",
    supplier_contact_person: "",
    supplier_order: "",
    tax_amt: "",
    tax_type: "",
    total_qty: "",
    transporter_mode: "",
    unit: "",
    value_percent: "",
    terms_and_conditions: [],
    railway_po_no: "",
    item_details: [{ ...itemInitialValue }],
    project_id: "",
    as_draft: "false",
    non_tender_approver: "",
    email_remarks: "",
  };

  function handleItemChange(event) {
    const { name, value } = event.target;

    setItemValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  function handleAddDetails() {
    if (!Number.isInteger(itemIndex)) {
      const data = [...multipleItemDetail];
      data.push(itemsValue);
      setMultipleItemDetail(data);
      setItemValues({ ...itemInitialValue });
      setFieldValue("item_details", data);
      setItemIndex("");
      const total = data?.reduce((acc, item) => {
        const amount = Number(item.amount) || 0;
        const gst_amt = Number(item.gst_amt) || 0;
        const disc_amt = Number(item.disc_amt) || 0;

        return acc + amount + gst_amt - disc_amt;
      }, 0);

      setPoGrandTotal(total);
    } else {
      const data = [...multipleItemDetail];
      data[itemIndex] = itemsValue;
      setMultipleItemDetail(data);
      setItemValues({ ...itemInitialValue });
      setFieldValue("item_details", data);
      setItemIndex("");
      const total = data?.reduce((acc, item) => {
        const amount = Number(item.amount) || 0;
        const gst_amt = Number(item.gst_amt) || 0;
        const disc_amt = Number(item.disc_amt) || 0;

        return acc + amount + gst_amt - disc_amt;
      }, 0);

      setPoGrandTotal(total);
    }
  }

  function handleDeleteItem(i) {
    const updatedItems = multipleItemDetail.filter((_, index) => index !== i);

    setMultipleItemDetail(updatedItems);

    setFieldValue("item_details", updatedItems);
  }
  function handleEditItem(i) {
    const updateItem = multipleItemDetail[i];
    setItemValues(updateItem);
    setItemIndex(i);
  }

  function handleChangeCheck(e, id, i, name) {
    let value = [...values.terms_and_conditions];

    if (!value.some((element) => element.terms_conditions_id === id)) {
      value.push({ terms_conditions_id: id, terms_conditions_name: name });
    } else {
      value = value.filter((element) => element.terms_conditions_id !== id);
    }

    setFieldValue("terms_and_conditions", value);
  }

  const vendorDetailValidation = () => {
    setValidationLoader(true);
    vendorValidationApi({
      user_id,
      vendor_id: values?.supplier,
    })
      .then((res) => {
        setValidationLoader(false);
        setVendorErrorMessage("");
      })
      .catch((err) => {
        if (err?.response?.status) {
          toast.error(err?.response?.data?.response);
          setVendorErrorMessage(
            "Please ensure all mandatory fields in the vendor form are completed"
          );
        }
        setValidationLoader(false);
      });
  };

  function addPurchaseOrder(values) {
    const apiUrl = baseUrl + "addpurchaseorder";
    const data = {
      user_id,
      ...values,
      prepared_by: user_id,
      type: "non-tender",
      order_date: date,
    };
    values?.as_draft ? setLoading(true) : setModalButtonLoader(true);
    axios
      .post(apiUrl, data, config)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Form submit successfully");
          PurchaseOrderOnProjectId();
          setOpen(false);
          setKey("Update-Non-Tender");
          resetForm();
        }
      })
      .catch((error) => {
        setServerSideValidation(error?.response?.data?.response);
        errorsMessage(error?.response?.status);
      })
      .finally(() => {
        setLoading(false);
        setModalButtonLoader(false);
      });
  }
  const nonTenderApproverDropdown = async () => {
    try {
      const res = await nonTenderApproverDropdownApi({
        approver_check: poGrandTotal > 500000,
      });
      const approversData = res?.data?.response?.nonTenderPoApproverDropdown;
      setApprovers(approversData);
      setFieldValue("non_tender_approver", approversData[0]?.email);
    } catch (error) {
      if (!error?.response) {
        toast.error("Please Check your internet");
      }
      if (error?.response?.status) {
        errorsMessage(error?.response?.status);
      }
    }
  };

  const handleScroll = () => {
    const tableBody = tableBodyRef.current;

    if (
      tableBody.scrollTop + tableBody.clientHeight >=
        tableBody.scrollHeight - 1 &&
      hasMore &&
      !loading
    ) {
      loadMore();
    }
  };

  useEffect(() => {
    const tableBody = tableBodyRef.current;
    if (tableBody) {
      tableBody.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, hasMore]);
  const handleValidationErrorToast = () => {
    if (Object.keys(errors).length !== 0) {
      toast.error("Please check Your all input fields");
    }
  };
  const handleSubmitFormData = (type) => {
    if (type === "draft") {
      setFieldValue("as_draft", true);
      handleValidationErrorToast();
      handleSubmit();
    } else if (type === "approval") {
      setFieldValue("as_draft", false);
      handleValidationErrorToast();
      handleSubmit();
    }
  };
  const {
    values,
    handleSubmit,
    handleChange,
    resetForm,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: purchaseOrderSchema,
    onSubmit: (values) => {
      addPurchaseOrder(values);
    },
  });
  useEffect(() => {
    let qtyValue = itemsValue.qty;
    let rateValue = itemsValue.rate;
    let discPer = itemsValue.disc_per;
    if (itemsValue.disc_per) {
      setItemValues((prevState) => ({
        ...prevState,
        disc_amt: (qtyValue * rateValue * discPer) / 100,
      }));
    }
  }, [itemsValue.disc_per, itemsValue.qty, itemsValue.rate]);
  useEffect(() => {
    let qtyValue = itemsValue.qty;
    let rateValue = itemsValue.rate;
    let disAmt = itemsValue.disc_amt;
    let gstPer = itemsValue.gst_percent;

    if (itemsValue.gst_percent) {
      setItemValues((prevState) => ({
        ...prevState,
        gst_amt: ((qtyValue * rateValue - disAmt) * gstPer) / 100,
      }));
    }
  }, [
    itemsValue.disc_per,
    itemsValue.qty,
    itemsValue.rate,
    itemsValue.gst_percent,
    itemsValue.disc_amt,
  ]);
  useEffect(() => {
    let qtyValue = itemsValue.qty;
    let rateValue = itemsValue.rate;

    setItemValues((prevState) => ({
      ...prevState,
      amount: qtyValue * rateValue,
    }));
  }, [itemsValue.qty, itemsValue.rate]);
  useEffect(() => {
    if (values?.supplier) {
      vendorDetailValidation();
    }
  }, [values?.supplier]);
  const handlePreviewUpdate = (data, name) => {
    setPoPreviewData((prev) => ({ ...prev, [name]: data }));
  };
  return (
    <>
      <VendorVerifingLoader open={validationLoader} />
      <form>
        <VendorMaster
          values={values}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
          serverSideValidation={serverSideValidation}
          setFieldValue={setFieldValue}
          purchaseOrderOnProject={purchaseOrderOnProject}
          tabKey={tabKey}
          selectUpdatePurchaseOrder={selectUpdatePurchaseOrder}
          setSelectUpdatePurchaseOrder={setSelectUpdatePurchaseOrder}
          currentDate={currentDate}
        />
        <Accordion defaultActiveKey={["0"]} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>OTHER DETAIL</Accordion.Header>
            <Accordion.Body>
              <OtherDetail
                values={values}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                serverSideValidation={serverSideValidation}
                setFieldValue={setFieldValue}
                vendorErrorMessage={vendorErrorMessage}
                handlePreviewUpdate={handlePreviewUpdate}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>ITEM DETAIL</Accordion.Header>
            <Accordion.Body>
              <ItemDetail
                values={values}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                itemsValue={itemsValue}
                handleItemChange={handleItemChange}
                handleAddDetails={handleAddDetails}
                multipleItemDetail={multipleItemDetail}
                setItemValues={setItemValues}
                handleDeleteItem={handleDeleteItem}
                serverSideValidation={serverSideValidation}
                handleEditItem={handleEditItem}
                itemIndex={itemIndex}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>DELIVERY SCHEDULE</Accordion.Header>
            <Accordion.Body>
              <PurchaseOrderDeliveryScheduling
                data={values}
                setFieldValue={setFieldValue}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>TERM AND CONDITION</Accordion.Header>
            <Accordion.Body>
              <div
                style={{ height: "50vh", overflow: "auto" }}
                ref={tableBodyRef}
              >
                <TermsAndCondition
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  termsAndCondition={termsAndCondition}
                  isChecked={isChecked}
                  handleChangeCheck={handleChangeCheck}
                  loading={loading}
                  search={search}
                  setSearch={setSearch}
                  termsAndConditonCheckedId={values?.terms_and_conditions}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {loadings ? (
          <div className="text-end mt-3">
            <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
            <p>Adding Item...</p>
          </div>
        ) : (
          <div className="text-end mt-3">
            <button
              className="btn btn-danger mx-2"
              type="button"
              style={{ background: "white", color: "red" }}
              onClick={() => resetForm()}
            >
              Reset Form
            </button>
            <button
              className="btn btn-dark mx-2"
              type="button"
              style={{ background: "white", color: "black" }}
              onClick={() => setShow(true)}
            >
              Preview
            </button>
            <button
              className="btn btn-dark mx-2  "
              onClick={() => handleSubmitFormData("draft")}
              type="button"
              disabled={vendorErrorMessage ? true : false}
            >
              Save as Draft
            </button>
            <button
              className="btn btn-danger"
              onClick={() => {
                setFieldValue("non_tender_approver", "");
                nonTenderApproverDropdown();
                setOpen(true);
              }}
              type="button"
              disabled={vendorErrorMessage ? true : false}
            >
              Send For Approval
            </button>
          </div>
        )}
      </form>
      <EmailSendConfirmationModal
        open={open}
        setOpen={setOpen}
        handleSubmitFormData={handleSubmitFormData}
        modalButtonLoader={modalButtonLoader}
        values={values}
        handleChange={handleChange}
        type="nonTender"
        approvers={approvers}
      />
      <PurchaseOrderPreviewModal
        show={show}
        setShow={setShow}
        pdfData={values}
        selectedVendor={poPreviewData?.selectedVendor}
        selectedStore={poPreviewData?.selectedStore}
        selectedBranch={poPreviewData?.selectedBranch}
        currentDate={currentDate}
        user_name={user_name}
      />
    </>
  );
}

export default NonTenderPurchaseOrder;
