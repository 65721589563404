import React, { useCallback, useEffect, useState } from "react";
import {
  getBranchName,
  getPartyMasterList,
  getStoreMasterList,
  getVendorDropDown,
} from "../../purchaseApi";
import { errorsMessage } from "../../../constant";
import Select from "react-select";
import debounce from "lodash/debounce";

function OtherDetail({
  handleChange,
  values,
  errors,
  touched,
  serverSideValidation,
  setFieldValue,
  vendorErrorMessage,
  handlePreviewUpdate,
}) {
  const [stateDropdown, setStateDropdown] = useState([]);
  const [users, setUsers] = useState([]);
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const [vendorDropdown, setVendorDropdown] = useState([]);
  const [storeDropdown, setStoreDropdown] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [branchList, setBranchList] = useState([]);
  

  const customStyles = {
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "red", // Change loader color here
    }),

    loadingIndicator: (provided) => ({
      ...provided,
      color: "red", // Change loader color here
    }),
  };

  function getDropDown() {
    getVendorDropDown({ user_id })
      .then((res) => {
        setStateDropdown(
          res?.data?.response?.PartyMasterDropdownList?.state_masters
        );
        setUsers(res?.data?.response?.PartyMasterDropdownList?.users);
      })
      .catch((err) => {
        if (err) {
          errorsMessage(err?.response?.status);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  }

  function getBranchDropdown() {
    getBranchName({ user_id })
      .then((res) => {
        setBranchList(res?.data?.response?.BranchMasterList);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      });
  }

  function getStoreMasterDropDown() {
    getStoreMasterList({ user_id })
      .then((res) => {
        setStoreDropdown(res?.data?.response?.CityMasterList);
      })
      .catch((err) => {
        if (err) {
          errorsMessage(err?.response?.status);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  }
  function getVendorMasterDropDown(term) {
    setLoading(true);
    getPartyMasterList({ user_id, party_name: term })
      .then((res) => {
        setVendorDropdown(res?.data?.response?.PartyMasterList?.results?.data);
      })
      .catch((err) => {
        if (err) {
          errorsMessage(err?.response?.status);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const debouncedFetchItemMasterData = useCallback(
    debounce((term) => {
      getVendorMasterDropDown(term);
    }, 800),
    [user_id]
  );
  useEffect(() => {
    if (searchTerm) {
      debouncedFetchItemMasterData(searchTerm);
    }
  }, [searchTerm, debouncedFetchItemMasterData]);
  useEffect(() => {
    getDropDown();
    getStoreMasterDropDown();
    getBranchDropdown();
  }, []);
  useEffect(() => {
    if (values?.bill_to) {
      const branchDetail = branchList?.find((element) => {
        return +element?.id === +values.bill_to;
      });
      setFieldValue(
        "billing_address",
        branchDetail?.address ? branchDetail?.address : ""
      );
    }
  }, [values?.bill_to]);
  useEffect(() => {
    if (values?.supplier) {
      const contact = vendorDropdown?.find((element) => {
        return +element?.id === +values.supplier;
      });
      setFieldValue(
        "supplier_contact_person",
        contact?.contact_person ? contact?.contact_person : ""
      );
      setFieldValue(
        "credit_payment_duration",
        contact?.credit_limit_days ? contact.credit_limit_days : ""
      );
    }
  }, [values?.supplier]);
  useEffect(() => {
    if (values?.delivery_store) {
      const storeContact = storeDropdown?.find((element) => {
        return +element?.id === +values?.delivery_store;
      });
      setFieldValue(
        "store_contact_person",
        storeContact?.keeper_name ? storeContact?.keeper_name : ""
      );
      setFieldValue(
        "delivery_contact_person",
        storeContact?.contact_no ? storeContact?.contact_no : ""
      );
    }
  }, [values?.delivery_store]);

  const setVendor = () => {
    if (values.hasOwnProperty("id")) {
      debouncedFetchItemMasterData(values?.party_details?.party_name);
      setFieldValue("supplier", values?.supplier);
    }
  };

  useEffect(() => {
    setVendor();
  }, [values?.id]);
  return (
    <div>
      <div className="row">
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            State{" "}
          </label>
          <select
            className="form-control border-secondary"
            onChange={handleChange}
            value={values?.state}
            name="state"
          >
            <option value={""}>Select </option>
            {stateDropdown.map((element) => (
              <option key={element.id} value={element.id}>
                {element.state_name}
              </option>
            ))}
          </select>
          <p className="text-danger m-0">
            {touched.state && errors.state
              ? errors.state
              : serverSideValidation?.state}
          </p>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Supplier Name<span className="text-danger">*</span>
          </label>
          <Select
            placeholder="Select Item from Name"
            className="form-select-control border-dark"
            options={[
              { id: "", party_name: "Select an item" },
              ...vendorDropdown,
            ]}
            getOptionLabel={(vendorDropdown) => vendorDropdown["party_name"]}
            getOptionValue={(vendorDropdown) => vendorDropdown["id"]}
            isLoading={loading}
            value={
              vendorDropdown.find(
                (option) => option.id === values.supplier
              ) || { id: "", party_name: "Select an item" }
            }
            onChange={(value) => {
              setFieldValue("supplier", value?.id);
              handlePreviewUpdate(value, "selectedVendor");
            }}
            onInputChange={(value) => setSearchTerm(value)}
            theme={(theme) => ({
              ...theme,
              borderRadius: 4,
              colors: {
                ...theme.colors,
                primary25: "lightgrey",
                primary: "lightgrey",
              },
            })}
            styles={customStyles}
          />

          <p className="text-danger m-0">
            {touched.supplier && errors.supplier
              ? errors.supplier
              : serverSideValidation?.supplier
              ? serverSideValidation?.supplier
              : vendorErrorMessage
              ? vendorErrorMessage
              : null}
          </p>
        </div>

        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Requested By{" "}
          </label>
          <select
            className="form-control border-secondary"
            onChange={handleChange}
            value={values?.requested_by}
            name="requested_by"
          >
            <option value={""}>Select </option>
            {users.map((element) => (
              <option key={element.id} value={element.id}>
                {element.name}
              </option>
            ))}
          </select>
          <p className="text-danger m-0">
            {touched.requested_by && errors.requested_by
              ? errors.requested_by
              : serverSideValidation?.requested_by}
          </p>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Mode Of Payment{" "}
          </label>
          <select
            className="form-control border-secondary"
            onChange={handleChange}
            value={values?.mode_of_payment}
            name="mode_of_payment"
          >
            <option value={""}>Select </option>
            <option value={"Cash"}>Cash </option>
            <option value={"Neft"}>Neft </option>
            <option value={"Cheque"}>Cheque </option>
            <option value={"Advance"}>Advance </option>
          </select>
          <p className="text-danger m-0">
            {touched.mode_of_payment && errors.mode_of_payment
              ? errors.mode_of_payment
              : serverSideValidation?.mode_of_payment}
          </p>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Currency Type{" "}
          </label>
          <select
            className="form-control border-secondary"
            onChange={handleChange}
            value={values?.currency_type}
            name="currency_type"
          >
            <option value={""}>Select </option>
            <option value={"INR"}>Indian Rupees </option>
            <option value={"USD"}>US Dollar </option>
            <option value={"JPY"}>Yen (JPY)</option>
            <option value={"RMB"}>China </option>{" "}
          </select>
          <p className="text-danger m-0">
            {touched.currency_type && errors.currency_type
              ? errors.currency_type
              : serverSideValidation?.currency_type}
          </p>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Transporter Mode{" "}
          </label>
          <select
            className="form-control border-secondary"
            onChange={handleChange}
            value={values?.transporter_mode}
            name="transporter_mode"
          >
            <option value={""}>Select </option>
            <option value={"ByRoad"}>By Road </option>
            <option value={"BySurface"}>By Surface </option>
            <option value={"ByAir"}>By Air </option>
          </select>
          <p className="text-danger m-0">
            {touched.transporter_mode && errors.transporter_mode
              ? errors.transporter_mode
              : serverSideValidation?.transporter_mode}
          </p>
        </div>

        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Bill To{" "}
          </label>
          <select
            className="form-control border-secondary"
            onChange={(e) => {
              handleChange(e);
              const selectedElement = branchList.find(
                (element) => element.id === Number(e.target.value)
              );
              handlePreviewUpdate(selectedElement, "selectedBranch");
            }}
            value={values?.bill_to}
            name="bill_to"
          >
            <option value={""}>Select</option>
            {branchList?.map((element) => (
              <option key={element?.id} value={element?.id}>
                {element?.branch_name}
              </option>
            ))}
          </select>
          <p className="text-danger m-0">
            {touched.bill_to && errors.bill_to
              ? errors.bill_to
              : serverSideValidation?.bill_to}
          </p>
        </div>
        <div className="col-md-6 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Bill Address{" "}
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Bill Address"
            onChange={handleChange}
            value={values?.billing_address}
            name="billing_address"
          />
          <p className="text-danger m-0">
            {touched.billing_address && errors.billing_address
              ? errors.billing_address
              : serverSideValidation?.billing_address}
          </p>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Delivery Store <span className="text-danger">*</span>
          </label>
          <select
            className="form-control border-secondary"
            onChange={(e) => {
              handleChange(e);
              const selectedElement = storeDropdown.find(
                (element) => element.id === Number(e.target.value)
              );
              handlePreviewUpdate(selectedElement, "selectedStore");
            }}
            value={values?.delivery_store}
            name="delivery_store"
          >
            <option value={""}>Select </option>
            {storeDropdown.map((element) => (
              <option key={element.id} value={element.id}>
                {element.store_name}
              </option>
            ))}
          </select>
          <p className="text-danger m-0">
            {touched.delivery_store && errors.delivery_store
              ? errors.delivery_store
              : serverSideValidation?.delivery_store}
          </p>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Supplier's Contact Person{" "}
          </label>
          <input
            readOnly
            value={values?.supplier_contact_person}
            className="form-control border-secondary"
            placeholder="Enter Supplier's Contact Person"
            name="supplier_contact_person"
          />
        </div>

        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Store Contact Person
          </label>
          <input
            className="form-control border-secondary"
            value={values?.store_contact_person}
            name="store_contact_person"
            placeholder="Enter Store Contact Person"
            readOnly
          />
          <p className="text-danger m-0">
            {touched.store_contact_person && errors.store_contact_person
              ? errors.store_contact_person
              : serverSideValidation?.store_contact_person}
          </p>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Delivery Contact No
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Delivery  Contact No."
            value={values?.delivery_contact_person}
            name="delivery_contact_person"
            readOnly
          />
          <p className="text-danger m-0">
            {touched.delivery_contact_person && errors.delivery_contact_person
              ? errors.delivery_contact_person
              : serverSideValidation?.delivery_contact_person}
          </p>
        </div>
        {/* <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Delivery Date
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Railway No."
            type="date"
            onChange={handleChange}
            value={values?.delivery_date}
            name="delivery_date"
          />
          <p className="text-danger m-0">
            {touched.delivery_date && errors.delivery_date
              ? errors.delivery_date
              : serverSideValidation?.delivery_date}
          </p>
        </div> */}
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Credit Limit (Days)
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Credit Limit Days"
            onChange={handleChange}
            value={values?.credit_payment_duration}
            name="credit_payment_duration"
            disabled
          />
          <p className="text-danger m-0">
            {touched.credit_payment_duration && errors.credit_payment_duration
              ? errors.credit_payment_duration
              : serverSideValidation?.credit_payment_duration}
          </p>
        </div>
        <div className="col-md-8 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Remarks
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Remarks "
            type="text"
            onChange={handleChange}
            value={values?.remarks}
            name="remarks"
          />
          <p className="text-danger m-0">
            {touched.remarks && errors.remarks
              ? errors.remarks
              : serverSideValidation?.remarks}
          </p>
        </div>
      </div>
    </div>
  );
}

export default OtherDetail;
