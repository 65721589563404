import React, { useEffect, useState } from "react";
import GurantorTransactionTable from "./GurantorTransactionTable";
import GurantorCard from "./GurantorCard";
import {
  bankListDropDownApi,
  sdPgTransactionListApi,
} from "../../../../../redux/api";
import SDPGFilters from "./SDPGFIlters";
import { errorsMessage } from "../../../../../constant";
import { toast } from "react-toastify";
import BasicPagination from "../../../../Common/Pagination";
import SimpleBackdrop from "../../../../../StoreDepartment/commonComponents/ShowBackDrop";

function SDPGTransactionDetail() {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [loading, setLoading] = useState(true);
  const [sdPgData, setSdPgData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const [initialRender, setInitialRender] = useState(true);
  const [searchDelay, setSearchDelay] = useState(null);
  const [bankList, setBankList] = useState([]);
  const [filters, setFilters] = useState({
    search_by: "railway",
    startDate: "",
    endDate: "",
    search: "",
    bank_id: "",
  });

  const sdPgTransactionList = async () => {
    setLoading(true);
    try {
      const res = await sdPgTransactionListApi({ ...filters, user_id, page });
      setSdPgData(
        res?.data?.response?.untiProjectSgPgTransactionList
          ?.untiProjectSgPgTransaction?.data
      );
      setPageCount(
        res?.data?.response?.untiProjectSgPgTransactionList
          ?.untiProjectSgPgTransaction?.last_page
      );
    } catch (error) {
      if (!error.response) {
        toast.error("Network error: Please check your internet connection.");
      } else {
        errorsMessage(error.response.status);
      }
    } finally {
      setLoading(false);
    }
  };
  const bankListDropdown = async () => {
    try {
      const res = await bankListDropDownApi({ user_id });
      setBankList(res?.data?.response?.listUntiBank?.listUntiBank);
    } catch (error) {
      if (!error.response) {
        toast.error("Network error: Please check your internet connection.");
      } else {
        errorsMessage(error.response.status);
      }
    } finally {
    }
  };

  const handleFilterChange = (e) => {
    const { value, name } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleDateFilterChange = (name, value) => {
    if (name === "dateFilter") {
      setFilters({
        ...filters,
        startDate: value[0],
        endDate: value[1],
      });
    }
  };
  function handlePaginateChange(event, value) {
    setPage(value);
  }

  useEffect(() => {
    if (!initialRender) {
      if (searchDelay) clearTimeout(searchDelay);
      const timeout = setTimeout(() => {
        sdPgTransactionList();
      }, 500);
      setSearchDelay(timeout);
      return () => clearTimeout(timeout);
    } else {
      setInitialRender(false);
      sdPgTransactionList();
    }
  }, [filters, page]);
  useEffect(() => {
    bankListDropdown();
  }, []);

  return (
    <div className="page-wrapper">
      <div className="page-content-tab">
        <div className="container-fluid">
          <div className="row my-3 ">
            <SimpleBackdrop open={loading} />
            <div className="col-md-3">
              <p className="fs-4">SD/PG Transactions</p>
            </div>
            <div className="col-md-9 text-end">
              <SDPGFilters
                handleFilterChange={handleFilterChange}
                filters={filters}
                handleDateFilterChange={handleDateFilterChange}
                bankList={bankList}
              />
            </div>
          </div>
          <GurantorCard>
            <div style={{ overflow: "auto", height: "75vh" }}>
              <GurantorTransactionTable data={sdPgData} />
            </div>
            <div className="mt-2">
              <BasicPagination
                handlePaginateChange={handlePaginateChange}
                page={page}
                pageCount={pageCount}
                loading={loading}
              />
            </div>
          </GurantorCard>
        </div>
      </div>
    </div>
  );
}

export default SDPGTransactionDetail;
