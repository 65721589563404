import React, { useEffect, useState } from "react";
import Cardscarousel from "./Cardscarousel";
import GurantorCard from "./GurantorCard";
import GurantorTransactionTable from "./GurantorTransactionTable";
import AddGurantorModal from "./AddGurantorModal";
import {
  gurantorListApi,
  sdPgTransactionListApi,
} from "../../../../../redux/api";
import { errorsMessage } from "../../../../../constant";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

function Gurantor({ tabKey }) {
  const [modalShow, setModalShow] = React.useState(false);
  const [gurantorData, setGurnarorData] = useState([]);
  const [cardLoader, setCardLoader] = useState(true);
  const [loading, setLoading] = useState(true);
  const [sdPgData, setSdPgData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [initialData, setInitialData] = useState({});
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;

  const gurantorListData = async () => {
    setCardLoader(true);
    try {
      const res = await gurantorListApi({ user_id });
      setGurnarorData(res?.data?.response?.listGuarantor?.listGuarantor);
    } catch (error) {
      if (!error.response) {
        toast.error("Network error: Please check your internet connection.");
      } else {
        errorsMessage(error.response.status);
      }
    } finally {
      setCardLoader(false);
    }
  };
  const sdPgTransactionList = async () => {
    setLoading(true);
    try {
      const res = await sdPgTransactionListApi({ user_id });

      setSdPgData(
        res?.data?.response?.untiProjectSgPgTransactionList
          ?.untiProjectSgPgTransaction?.data
      );
    } catch (error) {
      if (!error.response) {
        toast.error("Network error: Please check your internet connection.");
      } else {
        errorsMessage(error.response.status);
      }
    } finally {
      setLoading(false);
    }
  };
  const modalShowFunc = (data = {}, edit = false) => {
    setIsEdit(edit);
    if (edit === true) {
      setInitialData(data);
    } else {
      setInitialData(data);
    }
    setModalShow(true);
  };
  useEffect(() => {
    if (tabKey === "GURRANTOR") {
      gurantorListData();
      sdPgTransactionList();
    }
  }, [tabKey]);

  return (
    <div>
      <GurantorCard>
        <div className="d-flex justify-content-between align-items-center mb-1">
          <div>
            <p className="fs-4">Guarantor</p>
          </div>
          <div>
            <button className="btn btn-dark" onClick={() => modalShowFunc()}>
              + Add Guarantor
            </button>
          </div>
        </div>
        <Cardscarousel
          gurantorData={gurantorData}
          modalShowFunc={modalShowFunc}
        />
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {" "}
            <p className="fs-4">Recent Transactions</p>
          </div>
          <div>
            {" "}
            <Link
              style={{ color: "#6143D7" }}
              className="pe-3"
              to={"/sdPgTransactionDetail"}
            >
              View All Transactions <MdKeyboardDoubleArrowRight />
            </Link>{" "}
          </div>
        </div>
        <div style={{ overflow: "auto", marginTop: "1rem", height: "40vh" }}>
          <GurantorTransactionTable data={sdPgData} />
        </div>
      </GurantorCard>
      <AddGurantorModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        guartorListData={gurantorListData}
        initialData={initialData}
        isEdit={isEdit}
      />
    </div>
  );
}

export default Gurantor;
