import React, { useCallback, useEffect, useState } from "react";
import {
  getBranchName,
  getPartyMasterList,
  getStoreMasterList,
  getVendorDropDown,
} from "../../Purchase/purchaseApi";
import { errorsMessage } from "../../constant";
import { fileToBase64 } from "../../Components/serviceFunctions/base64";
import Select from "react-select";
import { debounce } from "lodash";

function BasicDetails({
  values,
  handleChange,
  setFieldValue,
  serverSideValidation,
  touched,
  errors,
  branchList,
  branch_type,
  state,
  projectList,
  loadingProjectDropDown,
  setProjectSearchTerm,
  mode,
}) {
  const [vendorDropdown, setVendorDropdown] = useState([]);
  const [storeDropdown, setStoreDropdown] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const customStyles = {
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "red", // Change loader color here
    }),

    loadingIndicator: (provided) => ({
      ...provided,
      color: "red", // Change loader color here
    }),
  };
  function getStoreMasterDropDown() {
    getStoreMasterList({ user_id })
      .then((res) => {
        setStoreDropdown(res?.data?.response?.CityMasterList);
      })
      .catch((err) => {
        if (err) {
          errorsMessage(err?.response?.status);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  }
  function getDropDown() {
    getVendorDropDown({ user_id })
      .then((res) => {
        setUsers(res?.data?.response?.PartyMasterDropdownList?.users);
      })
      .catch((err) => {
        if (err) {
          errorsMessage(err?.response?.status);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  }
  async function getVendorMasterDropDown(term) {
    setLoading(true);
    try {
      let res;
      res = await getPartyMasterList({
        user_id,
        search: term,
      });
      setVendorDropdown(
        res?.data?.response?.PartyMasterList?.results?.data || []
      );
    } catch (err) {
      errorsMessage(err?.response?.status || "Unknown error");
    } finally {
      setLoading(false);
    }
  }
  const debouncedFetchMasterData = useCallback(
    debounce((term) => {
      getVendorMasterDropDown(term);
    }, 800),
    [user_id]
  );

  useEffect(() => {
    if (searchTerm) {
      debouncedFetchMasterData(searchTerm);
    }
  }, [searchTerm, debouncedFetchMasterData]);

  useEffect(() => {
    getStoreMasterDropDown();
    getDropDown();
  }, []);

  useEffect(() => {
    if (state?.detail?.id) {
      debouncedFetchMasterData(state?.detail?.branch_name);
      setFieldValue("branch_id", values?.branch_id);
    }
  }, [state]);
  return (
    <>
      <div className="row">
        {mode === "edit" ? null : (
          <div className="col-3">
            <label htmlFor="" className="form-label fw-bold font-16">
              Challan Type{" "}
            </label>
            <select
              className="form-control border-secondary"
              name="branch_type"
              value={values.branch_type}
              onChange={handleChange}
            >
              <option value={"For Branch"}>Branch </option>
              <option value={"For Vendor"}>Vendor Master </option>
            </select>
            <p className="text-danger m-0">
              {serverSideValidation
                ? serverSideValidation?.branch_type
                : touched.branch_type && errors.branch_type
                ? errors.branch_type
                : null}
            </p>
          </div>
        )}

        {branch_type === "For Branch" ? (
          <div className="col-3">
            <label htmlFor="" className="form-label fw-bold font-16">
              Branch Name{" "}
            </label>
            <select
              className="form-control border-secondary"
              name="branch_id"
              value={values.branch_id}
              onChange={handleChange}
            >
              <option value={""}>Select</option>
              {branchList?.map((element) => (
                <option key={element?.id} value={element?.id}>
                  {element?.branch_name}
                </option>
              ))}
            </select>
            <p className="text-danger m-0">
              {serverSideValidation
                ? serverSideValidation?.branch_id
                : touched.branch_id && errors.branch_id
                ? errors.branch_id
                : null}
            </p>
          </div>
        ) : (
          <div className="col-3">
            <label htmlFor="" className="form-label fw-bold font-16">
              Vendor
            </label>
            <Select
              placeholder="Select Item from Name"
              className="form-select-control border-dark"
              options={[
                { id: "", party_name: "Select an item" },
                ...vendorDropdown,
              ]}
              getOptionLabel={(vendorDropdown) => vendorDropdown["party_name"]}
              getOptionValue={(vendorDropdown) => vendorDropdown["id"]}
              isLoading={loading}
              value={
                vendorDropdown.find(
                  (option) => option.id === values.branch_id
                ) || { id: "", party_name: "Select an Vendor" }
              }
              onChange={(value) => {
                setFieldValue("branch_id", value?.id);
              }}
              onInputChange={(value) => setSearchTerm(value)}
              theme={(theme) => ({
                ...theme,
                borderRadius: 4,
                colors: {
                  ...theme.colors,
                  primary25: "lightgrey",
                  primary: "lightgrey",
                },
              })}
              styles={customStyles}
            />
            <p className="text-danger m-0">
              {serverSideValidation
                ? serverSideValidation?.branch_id
                : touched.branch_id && errors.branch_id
                ? errors.branch_id
                : null}
            </p>
          </div>
        )}

        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Transport Mode{" "}
          </label>
          <select
            className="form-control"
            name="transport_mode"
            value={values.transport_mode}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="Road">Road</option>
            <option value="Rail">Rail</option>
            <option value="Air">Air</option>
          </select>
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.transport_mode
              : touched.transport_mode && errors.transport_mode
              ? errors.transport_mode
              : null}
          </p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Vehicle Number
          </label>
          <input
            className="form-control"
            placeholder="Vehicle Number"
            name="vehicle_number"
            value={values.vehicle_number}
            onChange={handleChange}
          />
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.vehicle_number
              : touched.vehicle_number && errors.vehicle_number
              ? errors.vehicle_number
              : null}
          </p>
        </div>
        <div className="col-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Date of Supply
          </label>
          <input
            className="form-control"
            type="date"
            name="date_of_supply"
            value={values.date_of_supply}
            onChange={handleChange}
          />
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.date_of_supply
              : touched.date_of_supply && errors.date_of_supply
              ? errors.date_of_supply
              : null}
          </p>
        </div>
        <div className="col-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Remarks
          </label>
          <input
            className="form-control"
            placeholder="Enter Remarks"
            name="remarks"
            value={values.remarks}
            onChange={handleChange}
            maxLength={40}
          />
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.remarks
              : touched.remarks && errors.remarks
              ? errors.remarks
              : null}
          </p>
        </div>

        <div className="col-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Requested by{" "}
          </label>
          <select
            name="requested_by"
            value={values.requested_by}
            onChange={handleChange}
            className="form-control"
          >
            <option value={""}>Select </option>
            {users.map((element) => (
              <option key={element.id} value={element.id}>
                {element.name}
              </option>
            ))}
          </select>
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.requested_by
              : touched.requested_by && errors.requested_by
              ? errors.requested_by
              : null}
          </p>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Store Name{" "}
          </label>
          <select
            name="store_id"
            value={values.store_id}
            onChange={handleChange}
            className="form-control"
          >
            <option value={""}>Select </option>
            {storeDropdown.map((element) => (
              <option key={element.id} value={element.id}>
                {element.store_name}
              </option>
            ))}
          </select>
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.store_id
              : touched.store_id && errors.store_id
              ? errors.store_id
              : null}
          </p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Insured Policy Number
          </label>
          <input
            className="form-control"
            placeholder="Insured Policy Number"
            value={values.insured_policy_number}
            onChange={handleChange}
            name="insured_policy_number"
          />
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.insured_policy_number
              : touched.insured_policy_number && errors.insured_policy_number
              ? errors.insured_policy_number
              : null}
          </p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Purchase Order Ref No.
          </label>
          <Select
            placeholder="Select Project"
            style={{ width: "100%" }}
            className="form-select-control border-secondary"
            name="purchase_order_ref_no"
            id="purchase_order_ref_no"
            isSearchable={true}
            options={[
              { id: "", project_title: "Select an PO. No." },
              ...projectList,
            ]}
            getOptionLabel={(projectList) => projectList["pd_poloaNo"]}
            getOptionValue={(projectList) => projectList["id"]}
            isLoading={loadingProjectDropDown}
            value={
              projectList.find(
                (option) => option.id === values.purchase_order_ref_no
              ) || {
                id: "",
                pd_poloaNo: "Select an PO. No.",
              }
            }
            onChange={(value) => {
              setFieldValue("purchase_order_ref_no", value.id);
            }}
            onInputChange={(value) => setProjectSearchTerm(value)}
            theme={(theme) => ({
              ...theme,
              borderRadius: 4,
              colors: {
                ...theme.colors,
                primary25: "lightgrey",
                primary: "lightgrey",
              },
            })}
            styles={customStyles}
          />
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.purchase_order_ref_no
              : touched.purchase_order_ref_no && errors.purchase_order_ref_no
              ? errors.purchase_order_ref_no
              : null}
          </p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Eway Bill
          </label>
          <input
            type="file"
            className="form-control"
            placeholder="Purchase Order Ref No."
            name="emay_bill"
            onChange={(e) =>
              fileToBase64(e.target.files[0], "emay_bill", setFieldValue)
            }
          />
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.emay_bill
              : touched.emay_bill && errors.emay_bill
              ? errors.emay_bill
              : null}
          </p>
        </div>
        <div className="col-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            E-Way Bill No.
          </label>
          <input
            className="form-control"
            placeholder="Enter EWAY Bill No."
            name="emay_bill_no"
            value={values.emay_bill_no}
            onChange={handleChange}
            maxLength={40}
          />
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.emay_bill_no
              : touched.emay_bill_no && errors.emay_bill_no
              ? errors.emay_bill_no
              : null}
          </p>
        </div>
      </div>
    </>
  );
}

export default BasicDetails;
