import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { getStoreMasterList } from "../../Purchase/purchaseApi";
import { errorsMessage } from "../../constant";
import DateRangePickerLatest from "../commonComponents/DateRangePicker";

function GoodsReceiptNoteFilters({ handleFilterChange, filters }) {
  const [storeList, setStoreList] = useState([]);
  const [loading, setLoading] = useState(false);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const departmentName = userProfile?.response?.userDetails?.departmentName;

  const customStyles = {
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "red", // Change loader color here
    }),

    loadingIndicator: (provided) => ({
      ...provided,
      color: "red", // Change loader color here
    }),
  };
  async function getStoreMasterDropDown() {
    setLoading(true);
    try {
      let res = await getStoreMasterList({ user_id });
      // Check response structure
      setStoreList(res?.data?.response?.CityMasterList || []);
    } catch (err) {
      errorsMessage(err?.response?.status || "Unknown error");
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    getStoreMasterDropDown();
  }, []);

  return (
    <div className="row w-100">
      <div className="col-md-3">
        <Select
          placeholder="Select Item from Name"
          className="form-select-control border-dark"
          options={[{ id: "", store_name: "Select an item" }, ...storeList]}
          getOptionLabel={(storeList) => storeList["store_name"]}
          getOptionValue={(storeList) => storeList["id"]}
          isLoading={loading}
          value={
            storeList.find((option) => option.id === filters?.store_id) || {
              id: "",
              store_name: "Select an Store",
            }
          }
          onChange={(selectedOption) =>
            handleFilterChange("store_id", selectedOption?.id || "")
          }
          theme={(theme) => ({
            ...theme,
            borderRadius: 4,
            colors: {
              ...theme.colors,
              primary25: "lightgrey",
              primary: "lightgrey",
            },
          })}
          styles={customStyles}
        />
      </div>

      <div className="col-md-2">
        <div className="w-100">
          <DateRangePickerLatest
            startDate={filters?.startDate}
            endDate={filters?.endDate}
            handleChange={handleFilterChange}
          />
        </div>
      </div>

      <div className="col-md-3">
        <input
          className="form-control border-secondary w-100"
          placeholder="Search By Doc,Supplier and Rai. No."
          name="document_no"
          onChange={(e) => handleFilterChange(e.target.name, e.target.value)}
          value={filters?.document_no}
        />
      </div>

      <div className="col-md-2">
        {departmentName === "Store" ? (
          <Link to={"/GoodReceiptNote/addGoodReceiptNote/store"}>
            <button className="btn btn-danger w-100">Add for Store</button>
          </Link>
        ) : null}
      </div>

      <div className="col-md-2">
        {departmentName === "Store" ? (
          <Link to={"/GoodReceiptNote/addGoodReceiptNote/railway"}>
            <button className="btn btn-danger w-100">
              Add for Railway Site
            </button>
          </Link>
        ) : null}
      </div>
    </div>
  );
}

export default GoodsReceiptNoteFilters;
