import "../Material/Material.css";
import RequistionForm from "./RequistionForm";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { errorsMessage } from "../../constant";
import { requistionSchema } from "../../schemas/StoreSchema/RequistionFormScehma";
function EditRequisitionForm() {
  const [spinner, setSpinner] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const userDetail = JSON.parse(localStorage.getItem("profile"));
  const user_id = userDetail?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const navigate = useNavigate();
  const { state } = useLocation();
  const data = state?.data;

  const initialValue = {
    type: data?.type,
    requested_date: data?.requested_date,
    store_id: data?.store_id,
    requested_by: data?.requested_by,
    required_date: data?.required_date,
    project_id: data?.project_id,
    purpose: data?.purpose,
    items: data?.items,
  };
  const {
    values,
    handleSubmit,
    handleChange,
    resetForm,
    errors,
    touched,
    setFieldValue,
    handleBlur,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: requistionSchema,
    onSubmit: (values) => {
      editRequistion(values);
    },
  });
  const editRequistion = (values) => {
    const url = `${baseUrl}updaterequisitionform`;
    const data = { ...values, user_id, store_req_form_id: state?.data?.id };
    setSpinner(true);
    axios
      .post(url, data, config)
      .then((res) => {
        setSpinner(false);
        if (res.status == 200) {
          toast.success("Requistion Update Successfully");
          navigate(-1);
        }
      })
      .catch((err) => {
        setSpinner(false);
        setValidationError(err?.response?.data?.response);
        errorsMessage("Please try again later ,Something went wrong");
      });
  };
  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card form_goodsnote">
                <div className="card-body">
                  <div className="page-title-box d-flex justify-content-between align-items-center">
                    <h3> Requisition form</h3>
                    {/* <button className="btn btn-danger">Edit New</button> */}
                  </div>
                  <RequistionForm
                    handleChange={handleChange}
                    values={values}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    mode="edit"
                    setFieldValue={setFieldValue}
                    validationError={validationError}
                    detail={data}
                    spinner={spinner}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditRequisitionForm;
