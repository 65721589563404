import * as Yup from "yup";

export const BankDetailsSchema = Yup.object({
  bank_id: Yup.string().required("This field is required"),
  branch_name: Yup.string().required("This field is required"),
  account_number: Yup.string().required("This field is required"),
  credit_limit: Yup.number()
    .required("This field is required")
    .min(0, "Credit limit cannot be negative"),
});
