import * as yup from "yup";

export const communicationValidationSchema = yup.object().shape({
  to: yup.string().required("To is required."),
  written_by: yup.string().required("Written By is required."),
  subject: yup.string().required("Subject is required."),
  ref_po_loa_no: yup.string().required("Reference PO/LOA No is required."),
  approved_by: yup.string().required("Approved By is required."),
  letter_date: yup
    .string()
    .required("Letter Date is required.")
    .matches(
      /^\d{4}-\d{2}-\d{2}$/,
      "Letter Date must be in the format YYYY-MM-DD."
    ), // For date format validation
  type: yup.string().required("Type is required."),
  ref_no: yup.string().required("Reference Number is required."),
  status: yup.string().required("Status is required."),
  attachment: yup.string().required("Attachment is required."),
  revert_against_ref_no: yup.string().when("type", {
    is: (type) => type !== "Initial",
    then: (schema) =>
      schema.required("Revert Against Reference Number is required."),
    otherwise: (schema) => schema.nullable(),
  }),
});

export const updateCommunicationValidationSchema = yup.object().shape({
  to: yup.string().required("To is required."),
  written_by: yup.string().required("Written By is required."),
  subject: yup.string().required("Subject is required."),
  ref_po_loa_no: yup.string().required("Reference PO/LOA No is required."),
  approved_by: yup.string().required("Approved By is required."),
  letter_date: yup
    .string()
    .required("Letter Date is required.")
    .matches(
      /^\d{4}-\d{2}-\d{2}$/,
      "Letter Date must be in the format YYYY-MM-DD."
    ), // For date format validation
  type: yup.string().required("Type is required."),
  ref_no: yup.string().required("Reference Number is required."),
  status: yup.string().required("Status is required."),

  revert_against_ref_no: yup.string().when("type", {
    is: (type) => type !== "Initial",
    then: (schema) =>
      schema.required("Revert Against Reference Number is required."),
    otherwise: (schema) => schema.nullable(),
  }),
});
