import React, { useEffect, useState } from "react";
import { getVendorDropDown } from "../../../../../Purchase/purchaseApi";
import { fileToBase64 } from "../OverViewComponents/communicationFileToBase64";
import {
  againstRefNoApi,
  projectDropdownListApi,
} from "../../../../../redux/api";

function CommunicationTrackerForm({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  setFieldValue,
  formType,
  serverError,
  project,
}) {
  const [users, setUsers] = useState([]);
  const [projectDropDown, setProjectDropDown] = useState([]);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [againstRefNoData, setAgainstRefNoData] = useState([]);
  function getUserDropDown() {
    getVendorDropDown({ user_id })
      .then((res) => {
        setUsers(res?.data?.response?.PartyMasterDropdownList?.users);
      })
      .catch((err) => {
        if (err) {
          errorsMessage(err?.response?.status);
        }
      });
  }
  const poLoaDropdownData = async () => {
    try {
      const response = await projectDropdownListApi();
      setProjectDropDown(response?.data?.response?.projectPdLoaNumber);
    } catch (error) {}
  };
  const againstRefNoDropdown = async () => {
    try {
      const response = await againstRefNoApi({
        project_id: values?.ref_po_loa_no,
      });
      setAgainstRefNoData(response?.data?.response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (values?.ref_po_loa_no) {
      againstRefNoDropdown();
    }
  }, [values?.ref_po_loa_no]);

  useEffect(() => {
    getUserDropDown();
    poLoaDropdownData();
  }, []);
  return (
    <div className="row">
      <div className="col-md-6 mb-2">
        <label className="form-label fw-bold font-16">
          {" "}
          To <span className="text-danger">*</span>
        </label>
        <input
          className="form-control border-secondary"
          placeholder="To"
          name="to"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.to}
        />
        <p className="text-danger mb-0">
          {serverError?.to
            ? serverError?.to
            : errors?.to
            ? errors?.to
            : touched?.to
            ? touched?.to
            : null}{" "}
        </p>
      </div>
      <div className="col-md-6 mb-2">
        <label className="form-label fw-bold font-16">
          Written By<span className="text-danger">*</span>
        </label>
        <select
          className="form-control border-secondary"
          name="written_by"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.written_by}
        >
          <option value={""}>Select </option>
          {users.map((element) => (
            <option key={element.id} value={element.id}>
              {element.name}
            </option>
          ))}
        </select>
        <p className="text-danger mb-0">
          {serverError?.written_by
            ? serverError?.written_by
            : errors?.written_by
            ? errors?.written_by
            : touched?.written_by
            ? touched?.written_by
            : null}{" "}
        </p>
      </div>
      <div className="col-md-6 mb-2">
        <label className="form-label fw-bold font-16">
          Subject<span className="text-danger">*</span>
        </label>
        <input
          className="form-control border-secondary"
          placeholder="Enter Subject"
          name="subject"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.subject}
        />
        <p className="text-danger mb-0">
          {serverError?.subject
            ? serverError?.subject
            : errors?.subject
            ? errors?.subject
            : touched?.subject
            ? touched?.subject
            : null}{" "}
        </p>
      </div>
      <div className="col-md-6 mb-2">
        <label className="form-label fw-bold font-16">
          Ref PO/Loa No.<span className="text-danger">*</span>
        </label>
        <select
          className="form-control border-secondary"
          name="ref_po_loa_no"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.ref_po_loa_no}
          disabled={project}
        >
          <option value="">Select</option>
          {projectDropDown?.map((element) => (
            <option key={element.id} value={element.id}>
              {element.pd_poloaNo}
            </option>
          ))}
        </select>
        <p className="text-danger mb-0">
          {serverError?.ref_po_loa_no
            ? serverError?.ref_po_loa_no
            : errors?.ref_po_loa_no
            ? errors?.ref_po_loa_no
            : touched?.ref_po_loa_no
            ? touched?.ref_po_loa_no
            : null}{" "}
        </p>
      </div>
      <div className="col-md-6 mb-2">
        <label className="form-label fw-bold font-16">
          Approved By<span className="text-danger">*</span>
        </label>
        <select
          className="form-control border-secondary"
          name="approved_by"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.approved_by}
        >
          <option value={""}>Select </option>
          {users.map((element) => (
            <option key={element.id} value={element.id}>
              {element.name}
            </option>
          ))}
        </select>
        <p className="text-danger mb-0">
          {serverError?.approved_by
            ? serverError?.approved_by
            : errors?.approved_by
            ? errors?.approved_by
            : touched?.approved_by
            ? touched?.approved_by
            : null}{" "}
        </p>
      </div>
      <div className="col-md-6 mb-2">
        <label className="form-label fw-bold font-16">
          Letter Date<span className="text-danger">*</span>
        </label>
        <input
          className="form-control border-secondary"
          type="date"
          name="letter_date"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.letter_date}
          max={new Date().toISOString().split("T")[0]}
        />
        <p className="text-danger mb-0">
          {serverError?.letter_date
            ? serverError?.letter_date
            : errors?.letter_date
            ? errors?.letter_date
            : touched?.letter_date
            ? touched?.letter_date
            : null}{" "}
        </p>
      </div>
      <div className="col-md-6 mb-2">
        <label className="form-label fw-bold font-16">
          Type<span className="text-danger">*</span>
        </label>
        <select
          className="form-control border-secondary"
          name="type"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.type}
          disabled={formType === "Edit"}
        >
          <option value="">Select</option>
          <option value="Initial">Initial</option>
          <option value="Revert">Revert</option>
          <option value="Reminder">Reminder</option>
        </select>
        <p className="text-danger mb-0">
          {serverError?.type
            ? serverError?.type
            : errors?.type
            ? errors?.type
            : touched?.type
            ? touched?.type
            : null}{" "}
        </p>
      </div>
      <div className="col-md-6 mb-2">
        <label className="form-label fw-bold font-16">
          Ref No.<span className="text-danger">*</span>
        </label>
        <input
          className="form-control border-secondary"
          placeholder="Enter Ref No."
          name="ref_no"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.ref_no}
        />
        <p className="text-danger mb-0">
          {serverError?.ref_no
            ? serverError?.ref_no
            : errors?.ref_no
            ? errors?.ref_no
            : touched?.ref_no
            ? touched?.ref_no
            : null}{" "}
        </p>
      </div>
      <div className="col-md-6 mb-2">
        <label className="form-label fw-bold font-16">
          Revert Against Ref No.<span className="text-danger">*</span>
        </label>
        <select
          className="form-control border-secondary"
          name="revert_against_ref_no"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.revert_against_ref_no}
          disabled={
            (values?.type !== "Revert" && values?.type !== "Reminder") ||
            values?.ref_po_loa_no === ""
          }
        >
          <option value="">Select</option>
          {againstRefNoData?.map((element) => (
            <option key={element.id} value={element.ref_no}>
              {element.ref_no}
            </option>
          ))}
        </select>
        <p className="text-danger mb-0">
          {serverError?.revert_against_ref_no
            ? serverError?.revert_against_ref_no
            : errors?.revert_against_ref_no
            ? errors?.revert_against_ref_no
            : touched?.revert_against_ref_no
            ? touched?.revert_against_ref_no
            : null}{" "}
        </p>
      </div>
      <div className="col-md-6 mb-2">
        <label className="form-label fw-bold font-16">
          Status<span className="text-danger">*</span>
        </label>
        <select
          className="form-control border-secondary"
          name="status"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.status}
        >
          <option value="">Select</option>
          <option value="Approved">Approved</option>
          <option value="Pending">Pending</option>
          <option value="Rejected">Rejected</option>
          <option value="InProgress">InProgress</option>
        </select>
        <p className="text-danger mb-0">
          {serverError?.status
            ? serverError?.status
            : errors?.status
            ? errors?.status
            : touched?.status
            ? touched?.status
            : null}{" "}
        </p>
      </div>
      <div className="col-md-6 mb-2">
        <label className="form-label fw-bold font-16">
          Attachment<span className="text-danger">*</span>
        </label>
        <input
          type="file"
          className="form-control border-secondary"
          name="attachment"
          accept="application/pdf"
          onChange={(e) =>
            fileToBase64(e.target.files[0], "attachment", setFieldValue)
          }
        />
        <p className="text-danger mb-0">
          {serverError?.attachment
            ? serverError?.attachment
            : errors?.attachment
            ? errors?.attachment
            : touched?.attachment
            ? touched?.attachment
            : null}{" "}
        </p>
      </div>
    </div>
  );
}

export default CommunicationTrackerForm;
