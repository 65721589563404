import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import "../PurchaseStyle/PurchaseStyle.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MaterialPurchase from "../../Components/SettingSections/MaterialPurchase";
import PurchaseOrder from "../ManagmentPages/PurchaseOrder/PurchaseOrder";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import PoProjectPurchaseList from "./PoProjectPurchaseList";
import { errorsMessage, formatDate } from "../../constant";

const PurchaseProjectDetail = () => {
  const [key, setKey] = useState("material-costing");
  const [lotData, setLotData] = useState([]);
  const [lotId, setLotId] = useState("");
  const [projectDetails, setprojectDetails] = useState();
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const pdfBaseUrl = process.env.REACT_APP_BASE_URL;
  const location = useLocation();
  const state = location?.state?.data;
  const ticketData = location?.state?.boardData;
  const [boardGroupStatus, setBoardGroupStatus] = useState(
    state?.board_group_status_id
  );
  const [loading, setLoading] = useState(false);
  const [purchaseOrderOnProject, setPurchaseOrderOnProject] = useState([]);
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;

  function changeStatusList(board_group_id, e, ticket) {
    let newStatusId = e.target.value;
    const url = apiurl + "updateprojectticketboardgroup";
    const data = {
      user_id,
      board_group_id,
      board_group_status_id: newStatusId,
      ticketId: ticket,
    };
    axios
      .post(url, data, config)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Status changed successfully");
        }
      })
      .catch((error) => {
        if (error) {
          errorsMessage(error.status);
        }
      });
  }
  function PurchaseOrderOnProjectId() {
    const apiUrl = baseUrl + "getpurchaseordersproject";
    const data = {
      user_id,
      project_id: state.project_id,
    };
    setLoading(true);
    axios
      .post(apiUrl, data, config)
      .then((response) => {
        if (response.status === 200) {
          setPurchaseOrderOnProject(
            response?.data?.response?.PurchaseOrdersProject
          );
        }
      })
      .catch((error) => {
        if (error) {
          errorsMessage(error?.response?.status);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (key === "Update PO") PurchaseOrderOnProjectId();
  }, [key]);

  const fetchProjectDetails = () => {
    const url = apiurl + "untiprojectdetail";
    var body = {
      user_id: user_id,
      project_id: state.project_id,
    };
    axios
      .post(url, body, config)
      .then((res) => {
        const projectDetails = res?.data?.response?.projectDetails;
        setprojectDetails(projectDetails);
        setLotData(projectDetails?.lotDetails);
        setLotId(projectDetails?.lotDetails[0]?.id);
      })
      .catch((err) => {
        toast.error("Unable to fetch Project Details");
      });
  };

  useEffect(() => {
    fetchProjectDetails();
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab">
          <div className="container-fluid">
            <div className="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-end">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item active"> PROJECTS DETAIL</li>
                    </ol>
                  </div>
                  <h4 class="page-title">PROJECTS DETAIL</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="card">
                <div class="card-body project__img">
                  <div class="row">
                    <div class="col-lg-8 align-self-center">
                      <div class="">
                        <h5 class="font-30 m-0">
                          {projectDetails?.pd_projectTitle}{" "}
                        </h5>
                        <p class="text-muted m-0 py-4">
                          {projectDetails?.pd_projectItemDescription}{" "}
                        </p>
                        <div className="d-flex">
                          <span class="badge badge-soft-danger font-14 fw-semibold p-3">
                            Supply & Installation
                          </span>
                          <div className="col-md-4">
                            <div className="d-flex align-items-center ms-4">
                              <label className="mx-2">Status</label>

                              <select
                                className="form-control border-secondary "
                                onChange={(event) => {
                                  setBoardGroupStatus(event.target.value);
                                  changeStatusList(
                                    state?.board_group_id,
                                    event,
                                    state.id
                                  );
                                }}
                                value={boardGroupStatus}
                              >
                                {ticketData[state.board_group_id]?.map(
                                  (element) => (
                                    <option value={element.id}>
                                      {element.name}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 align-self-center ps-md-5">
                      <div class="">
                        <ul class="list-unstyled border-0 mb-0">
                          <li class="mb-2">
                            <i class="las la-check-circle text-success me-1"></i>
                            <span class="fw-bold">Reporting To :</span>{" "}
                            {state?.reporting_name}
                          </li>
                          <li class="mb-2">
                            <i class="las la-check-circle text-success me-1"></i>
                            <span class="fw-bold">Assigned Date :</span>{" "}
                            {formatDate(state?.start_date)}
                          </li>
                          <li class="mb-2">
                            <i class="las la-check-circle text-success me-1"></i>
                            <span class="fw-bold">Priority :</span>{" "}
                            {state?.priority}
                          </li>
                          <li class="mb-2">
                            <i class="las la-check-circle text-success me-1"></i>
                            <span class="fw-bold">Due Date:</span>{" "}
                            {formatDate(state?.due_date)}
                          </li>
                          <li class="mb-2">
                            <i class="las la-check-circle text-success me-1"></i>
                            <span class="fw-bold">Railway PO. No :</span>{" "}
                            {projectDetails?.pd_poloaNo}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3"
                >
                  <Tab
                    eventKey="material-costing"
                    title="SOW"
                    style={{ backgroundColor: "#e3e3e33b" }}
                  >
                    <MaterialPurchase
                      tabKey={key}
                      lotData={lotData}
                      lotId={lotId}
                      setLotId={setLotId}
                      state={state}
                    />
                  </Tab>
                  <Tab eventKey="Generate PO" title="Generate PO">
                    <PurchaseOrder
                      state={state}
                      tabKey={key}
                      PurchaseOrderOnProjectId={PurchaseOrderOnProjectId}
                      projectDetails={projectDetails}
                      setKey={setKey}
                    />
                  </Tab>
                  <Tab eventKey="Update PO" title="View/Update PO">
                    <PoProjectPurchaseList
                      state={state}
                      tabKey={key}
                      poOrderList={purchaseOrderOnProject}
                      PurchaseOrderOnProjectId={PurchaseOrderOnProjectId}
                      loading={loading}
                      projectDetails={projectDetails}
                    />
                  </Tab>
                  <Tab eventKey="Log/Updates" title="Log/Updates">
                    <section className="mt-5">
                      <div class="container-fluid">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="bs-vertical-wizard">
                              <ul>
                                <li class="complete">
                                  <a href="#">
                                    Material Received
                                    <i class="ico fa-solid fa-circle ico-green"></i>
                                    {/* <span class="desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, cumque.Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, cumque.</span> */}
                                  </a>
                                </li>

                                <li class="complete prev-step">
                                  <a href="#">
                                    Material Dispatched{" "}
                                    <i class="ico fa-solid fa-circle ico-green"></i>
                                    {/* <span class="desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, cumque.Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, cumque.</span> */}
                                  </a>
                                </li>

                                <li class="current">
                                  <a href="#">
                                    <span class="desc">
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipisicing elit. A, cumque.Lorem ipsum
                                      dolor sit amet, consectetur adipisicing
                                      elit. A, cumque.
                                    </span>
                                  </a>
                                </li>
                                <li class="locked">
                                  <a href="#">
                                    Payment Processed
                                    <span>
                                      <p className="mb-0">05:32</p>
                                      <p>Dec 20</p>
                                    </span>{" "}
                                    <i class="ico fa-solid fa-circle ico-red"></i>
                                    <span class="desc">
                                      Kindly check the requirements and terms of
                                      work and make sure everything is right.
                                    </span>
                                  </a>
                                </li>
                                <li class="current">
                                  <a href="#">
                                    <span class="desc">
                                      Kindly check the requirements and terms of
                                      work and make sure everything is right.
                                      Kindly check the requirements and terms of
                                      work and make sure everything is right.{" "}
                                    </span>
                                  </a>
                                </li>
                                <li class="locked">
                                  <a href="#">
                                    Nagotiation with Vendor{" "}
                                    <span>
                                      <p className="mb-0">05:32</p>
                                      <p>Dec 20</p>
                                    </span>{" "}
                                    <i class="ico fa-solid fa-circle ico-megenta"></i>
                                    <span class="desc">
                                      Kindly check the requirements and terms of
                                      work and make sure everything is right.
                                    </span>
                                  </a>
                                </li>
                                <li class="locked">
                                  <a href="#">
                                    PO Generated{" "}
                                    <span>
                                      <p className="mb-0">05:32</p>
                                      <p>Dec 20</p>
                                    </span>{" "}
                                    <i class="ico fa-solid fa-circle ico-blue"></i>
                                    <span class="desc">
                                      Kindly check the requirements and terms of
                                      work and make sure everything is right.
                                    </span>
                                  </a>
                                </li>
                                <li class="locked">
                                  <a href="#">
                                    ToDo{" "}
                                    <span>
                                      <p className="mb-0">05:32</p>
                                      <p>Dec 20</p>
                                    </span>{" "}
                                    <i class="ico fa-solid fa-circle ico-gre"></i>
                                    <span class="desc">
                                      Kindly check the requirements and terms of
                                      work and make sure everything is right.
                                      Kindly check the requirements and terms of
                                      workand make sure everything is right.{" "}
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </Tab>
                  <Tab eventKey="Attachments" title="Attachments">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="d-flex align-items-center justify-content-between bg-gray-document">
                          <h6>
                            {" "}
                            <span>
                              <svg
                                width="23"
                                height="22"
                                viewBox="0 0 23 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M21.5625 12.375V11H17.25V17.875H18.6875V15.125H20.8438V13.75H18.6875V12.375H21.5625Z"
                                  fill="black"
                                />
                                <path
                                  d="M13.6562 17.875H10.7812V11H13.6562C14.2279 11.0005 14.7761 11.218 15.1803 11.6047C15.5846 11.9914 15.8119 12.5157 15.8125 13.0625V15.8125C15.8119 16.3593 15.5846 16.8836 15.1803 17.2703C14.7761 17.657 14.2279 17.8745 13.6562 17.875ZM12.2188 16.5H13.6562C13.8468 16.4998 14.0295 16.4273 14.1643 16.2984C14.299 16.1695 14.3748 15.9948 14.375 15.8125V13.0625C14.3748 12.8802 14.299 12.7055 14.1643 12.5766C14.0295 12.4477 13.8468 12.3752 13.6562 12.375H12.2188V16.5Z"
                                  fill="black"
                                />
                                <path
                                  d="M7.90625 11H4.3125V17.875H5.75V15.8125H7.90625C8.28732 15.812 8.65263 15.6669 8.92209 15.4092C9.19155 15.1514 9.34318 14.802 9.34375 14.4375V12.375C9.34337 12.0104 9.1918 11.6609 8.9223 11.4031C8.65279 11.1453 8.28738 11.0004 7.90625 11ZM5.75 14.4375V12.375H7.90625L7.90697 14.4375H5.75Z"
                                  fill="black"
                                />
                                <path
                                  d="M15.8125 9.62523V6.87523C15.815 6.78488 15.7971 6.69507 15.7599 6.612C15.7226 6.52892 15.667 6.45454 15.5969 6.39398L10.5656 1.58148C10.5023 1.51435 10.4246 1.46114 10.3377 1.42553C10.2509 1.38992 10.157 1.37276 10.0625 1.37523H2.875C2.4941 1.37632 2.12913 1.52154 1.85979 1.77916C1.59045 2.03679 1.43864 2.3859 1.4375 2.75023V19.2502C1.4375 19.6149 1.58895 19.9646 1.85853 20.2225C2.12812 20.4804 2.49375 20.6252 2.875 20.6252H14.375V19.2502H2.875V2.75023H8.625V6.87523C8.62614 7.23957 8.77795 7.58868 9.04729 7.8463C9.31663 8.10393 9.6816 8.24915 10.0625 8.25023H14.375V9.62523H15.8125ZM10.0625 6.87523V3.02523L14.0875 6.87523H10.0625Z"
                                  fill="black"
                                />
                              </svg>
                            </span>{" "}
                            PO Document.pdf
                          </h6>
                          <a
                            href={pdfBaseUrl + projectDetails?.pd_poloaDocs}
                            download={pdfBaseUrl + projectDetails?.pd_poloaDocs}
                            target="_blank"
                          >
                            <button>
                              <svg
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0.53125 10.5186C0.672146 10.5186 0.807272 10.5745 0.906901 10.6742C1.00653 10.7738 1.0625 10.9089 1.0625 11.0498V13.7061C1.0625 13.9878 1.17444 14.2581 1.3737 14.4574C1.57296 14.6566 1.84321 14.7686 2.125 14.7686H14.875C15.1568 14.7686 15.427 14.6566 15.6263 14.4574C15.8256 14.2581 15.9375 13.9878 15.9375 13.7061V11.0498C15.9375 10.9089 15.9935 10.7738 16.0931 10.6742C16.1927 10.5745 16.3279 10.5186 16.4688 10.5186C16.6096 10.5186 16.7448 10.5745 16.8444 10.6742C16.944 10.7738 17 10.9089 17 11.0498V13.7061C17 14.2696 16.7761 14.8101 16.3776 15.2087C15.9791 15.6072 15.4386 15.8311 14.875 15.8311H2.125C1.56141 15.8311 1.02091 15.6072 0.622398 15.2087C0.223883 14.8101 0 14.2696 0 13.7061V11.0498C0 10.9089 0.0559708 10.7738 0.1556 10.6742C0.255228 10.5745 0.390354 10.5186 0.53125 10.5186Z"
                                  fill="black"
                                />
                                <path
                                  d="M8.12357 12.5949C8.17292 12.6443 8.23154 12.6836 8.29608 12.7104C8.36063 12.7372 8.42982 12.7509 8.49969 12.7509C8.56957 12.7509 8.63876 12.7372 8.70331 12.7104C8.76785 12.6836 8.82647 12.6443 8.87582 12.5949L12.0633 9.40738C12.1631 9.30762 12.2191 9.17232 12.2191 9.03125C12.2191 8.89018 12.1631 8.75488 12.0633 8.65512C11.9636 8.55537 11.8283 8.49933 11.6872 8.49933C11.5461 8.49933 11.4108 8.55537 11.3111 8.65512L9.03094 10.9363V1.59375C9.03094 1.45285 8.97497 1.31773 8.87535 1.2181C8.77572 1.11847 8.64059 1.0625 8.49969 1.0625C8.3588 1.0625 8.22367 1.11847 8.12404 1.2181C8.02442 1.31773 7.96844 1.45285 7.96844 1.59375V10.9363L5.68832 8.65512C5.58857 8.55537 5.45327 8.49933 5.31219 8.49933C5.17112 8.49933 5.03582 8.55537 4.93607 8.65512C4.83632 8.75488 4.78027 8.89018 4.78027 9.03125C4.78027 9.17232 4.83632 9.30762 4.93607 9.40738L8.12357 12.5949Z"
                                  fill="black"
                                />
                              </svg>
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PurchaseProjectDetail;
