import React from "react";
import MyIcon, { DpIcon } from "./MyIcons";

function BillingCards({ data, index }) {
  return (
    <div style={cardStyle}>
      <div>{index === 2 ? <DpIcon /> : <MyIcon color={data?.fontColor} />}</div>
      <div className="mx-4">
        <h4 className="mb-1" style={{ ...fontStyle, color: data?.fontColor }}>
          {data?.amount}
        </h4>
        <p className="m-0">{data?.text}</p>
      </div>
    </div>
  );
}

const cardStyle = {
  borderRadius: "8px",
  padding: "1.2rem",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  background: "#FFFFFF",
  display: "flex",
  alignItems: "center",
};

const fontStyle = {
  fontSize: "1.4rem",
  fontWeight: 500,
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
};

export default BillingCards;
