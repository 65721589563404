import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { getRoleList, getUserDropdown, departmentDropdown } from "../redux/api";
import { useFormik } from "formik";
import { userSchema } from "../schemas/Index";
import "./Style.css";

const initialValues = {
  name: "",
  designation: "",
  department_role: "",
  email: "",
  profileimage: "",
  email: "",
  phone: "",
  reporting_person: "",
  department_id: "",
  joining_date: "",
  personal_phone: "",
  prefix: "",
  boardCategory: "",
  signature: "",
};

function ProjectsUsersAdd() {
  const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const [profileimage, setProfileimage] = useState(null);
  const [validationError, setValidationError] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [departmentRoleDropdown, setDepartmentRoleDropdown] = useState([]);
  const [image, setImage] = useState(null);
  // const [error, setError] = useState("");
  const [aspectRatio, setAspectRatio] = useState({
    width: "",
    height: "",
  });

  const {
    touched,
    handleSubmit,
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: userSchema,
    onSubmit: (values) => {
      postUser(values);
    },
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (!file.type.startsWith("image/")) {
      setError("Please select a valid image file.");
      return;
    }
    const img = new Image();
    const reader = new FileReader();
    reader.onloadend = () => {
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        setAspectRatio({ width, height });
        setFieldValue("signature", img.src);
        setError("");
      };
      img.src = reader.result;
    };

    reader.readAsDataURL(file);
  };

  const handleSubmitData = (e) => {
    if (values?.signature) {
      if (aspectRatio?.height < 340 && aspectRatio?.width < 550) {
        handleSubmit(e);
      } else {
        alert(
          `Please select an image with a hight less than 340 and less than width 550 aspect ratio.`
        );
      }
    } else {
      handleSubmit(e);
    }
  };

  const handeImageChange = (e) => {
    const data = new FileReader();
    data.addEventListener("load", () => {
      setProfileimage(data.result);
      values.profileimage = data.result;
      errors.profileimage = "";
    });
    data.readAsDataURL(e.target.files[0]);
    handleChange("profileimage");
  };
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;

  function getAllDropdownList() {
    getUserDropdown({ user_id: user_id })
      .then((res) => {
        setUsers(res?.data?.response?.userList);
      })
      .catch((err) => {});
    getRoleList({ user_id: user_id })
      .then((res) => {
        setRoles(res?.data?.response?.roleList);
      })
      .catch((err) => {});
    departmentDropdown({ user_id: user_id })
      .then((res) => {
        setDepartmentList(res?.data?.response?.departmentDropDown);
      })
      .catch((err) => {});
  }
  const getDepartmentRole = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("profile"));
      const user_id = user?.response?.userDetails?.id;
      const getToken = user?.response?.token;
      const bearer_token = `Bearer ${getToken}`;

      const response = await axios.post(
        apiurl + "departmentroledropdown",
        { user_id },
        {
          headers: {
            Authorization: bearer_token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      setDepartmentRoleDropdown(response.data.response.departmentRoleDropDown);
    } catch (error) {}
  };
  const getDepartmentUserDropdown = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("profile"));
      const user_id = user?.response?.userDetails?.id;
      const getToken = user?.response?.token;
      const bearer_token = `Bearer ${getToken}`;
      const payload = {
        user_id,
        department_id: values.department_id,
        department_role: values.department_role,
      };
      const response = await axios.post(
        apiurl + "getdepartmentuserdropdown",
        payload,
        {
          headers: {
            Authorization: bearer_token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      // setDepartmentRoleDropdown(response.data.response.departmentRoleDropDown);
    } catch (error) {}
  };
  const postUser = (value) => {
    setSpinner(true);
    setValidationError("");
    const user = JSON.parse(localStorage.getItem("profile"));
    const user_id = user?.response?.userDetails?.id;
    const data = { ...value, user_id, accessType: "add" };
    const getToken = user?.response?.token;
    const bearer_token = `Bearer ${getToken}`;
    axios
      .post(apiurl + "addnewuser", data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          navigate("/ProjectsUsers");
          toast.success("Added Successfully");
        }
        if (response.data.status === "Token is Expired") {
          navigate("/");
        }

        setError(response.data.status);
        setSpinner(false);
      })
      .catch((error) => {
        setSpinner(false);
        if (error.response.data.message === "Error validation") {
          setValidationError(error.response.data.response);
        }
      });
  };
  function getGroupList() {
    const user = JSON.parse(localStorage.getItem("profile"));
    const user_id = user?.response?.userDetails?.id;
    const getToken = user?.response?.token;
    const bearer_token = `Bearer ${getToken}`;
    let url = apiurl + "listboardgroup";
    let data = { user_id };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
        },
      })
      .then((response) => {
        setGroupList(response?.data?.response.BoardGroupList);
      })
      .catch((error) => {});
  }
  useEffect(() => {
    getAllDropdownList();
    getGroupList();
    getDepartmentRole();
  }, []);
  useEffect(() => {
    if (values?.department_id && values?.department_role) {
      getDepartmentUserDropdown();
    }
  }, [values?.department_id, values?.department_role]);
  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/ProjectsUsers">Users /</Link>
                      </li>
                      <li className="breadcrumb-item active">Create User</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Create User </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form encType="multipart/form-data">
                      <div className="row">
                        <div className="col-md-1">
                          <div className="form-group mb-3">
                            <label htmlFor="prefix" className="form-label">
                              Prefix
                            </label>
                            <select
                              className="form-control border-secondary"
                              name="prefix"
                              placeholder=""
                              value={values.prefix}
                              onChange={handleChange}
                            >
                              <option value="">Select</option>
                              <option value="Mr.">Mr.</option>
                              <option value="Miss">Ms.</option>
                            </select>

                            <p className="text-danger">
                              {validationError?.prefix}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group mb-3">
                            <label htmlFor="name" className="form-label">
                              Employee Name
                            </label>
                            <input
                              type="text"
                              className="form-control border-secondary"
                              id="name"
                              name="name"
                              placeholder="Enter User Name"
                              value={values.name}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              maxLength={30}
                            />
                            {touched.name && errors.name ? (
                              <p className="text-danger">{errors.name}</p>
                            ) : (
                              <p className="text-danger">
                                {validationError?.name}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group mb-3">
                            <label htmlFor="email" className="form-label">
                              Employee Email ID
                            </label>
                            <input
                              className="form-control border-secondary"
                              id="email"
                              name="email"
                              placeholder="Ex: abc@abc.com"
                              value={values.email}
                              onChange={handleChange}
                              maxLength={60}
                              onBlur={handleBlur}
                            />

                            {touched.email && errors.email ? (
                              <p
                                className="text-capitalize"
                                style={{ color: "red" }}
                              >
                                {errors.email}
                              </p>
                            ) : (
                              <p
                                className="text-capitalize"
                                style={{ color: "red" }}
                              >
                                {validationError?.email}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-10">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group mb-3">
                                <label
                                  htmlFor="designation"
                                  className="form-label"
                                >
                                  Designation
                                </label>
                                <select
                                  className="form-control border-secondary"
                                  id="designation"
                                  name="designation"
                                  value={values.designation}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <option value="">Select</option>
                                  {roles?.map((item, i) => {
                                    return (
                                      <option value={item.id} key={i}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </select>

                                {touched.designation && errors.designation ? (
                                  <p className="text-danger">
                                    {errors.designation}
                                  </p>
                                ) : (
                                  <p className="text-danger text-capitalize">
                                    {validationError?.designation}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group mb-3">
                                <label
                                  htmlFor="department_role"
                                  className="form-label"
                                >
                                  Department Role
                                </label>
                                <select
                                  className="form-control border-secondary"
                                  id="department_role"
                                  name="department_role"
                                  value={values.department_role}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <option value="">Select</option>
                                  {departmentRoleDropdown?.map((role, i) => {
                                    return (
                                      <option value={role.id} key={i}>
                                        {role.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                {touched.department_role &&
                                errors.department_role ? (
                                  <p className="text-danger">
                                    {errors.department_role}
                                  </p>
                                ) : (
                                  <p className="text-danger text-capitalize">
                                    {validationError?.department_role}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group mb-3">
                                <label
                                  htmlFor="reporting_person"
                                  className="form-label"
                                >
                                  Reporting Manager
                                </label>
                                <select
                                  className="form-control border-secondary"
                                  id="reporting_person"
                                  name="reporting_person"
                                  value={values.reporting_person}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <option value="">Select</option>
                                  {users?.map((obj, i) => {
                                    return (
                                      <option value={obj.id} key={i}>
                                        {obj.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                {touched.reporting_person &&
                                errors.reporting_person ? (
                                  <p className="text-danger">
                                    {errors.reporting_person}
                                  </p>
                                ) : (
                                  <p className="text-danger text-capitalize">
                                    {validationError?.reporting_person}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-5">
                          <div className="form-group mb-3">
                            <label
                              htmlFor="reporting_person"
                              className="form-label"
                            >
                              Department
                            </label>
                            <select
                              className="form-control border-secondary"
                              id="department_id"
                              name="department_id"
                              value={values.department_id}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="">Select</option>
                              {departmentList?.map((obj, i) => {
                                return (
                                  <option value={obj.id} key={i}>
                                    {obj.name}
                                  </option>
                                );
                              })}
                            </select>
                            {touched.department_id && errors.department_id ? (
                              <p className="text-danger">
                                {errors.department_id}
                              </p>
                            ) : (
                              <p className="text-danger text-capitalize">
                                {validationError?.department_id}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-5">
                          <div className="form-group mb-3">
                            <label
                              htmlFor="profileimage"
                              className="form-label"
                            >
                              Image
                            </label>
                            <input
                              type="file"
                              className="form-control border-secondary"
                              id="profileimage"
                              name="profileimage"
                              onChange={handeImageChange}
                              onBlur={handleBlur}
                            />
                            {touched.profileimage && errors.profileimage ? (
                              <p className="text-danger">
                                {errors.profileimage}
                              </p>
                            ) : (
                              <p className="text-danger text-capitalize">
                                {validationError?.profileimage}
                              </p>
                            )}
                          </div>
                        </div>
                        {profileimage ? (
                          <div className="col-md-2">
                            <div className="form-group mb-3">
                              <img
                                src={profileimage}
                                style={{
                                  width: "70px",
                                  height: "70px",
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                              />
                            </div>
                          </div>
                        ) : null}

                        <div className="col-md-5">
                          <label className="form-label">
                            Contact Number{" "}
                            <span className="font-12">(Work)</span>
                          </label>
                          <div className="form-group mb-">
                            <input
                              className="form-control border-secondary "
                              id="phone"
                              name="phone"
                              placeholder="Ex: 9001900191"
                              value={values.phone}
                              onChange={handleChange}
                              maxLength={10}
                              onBlur={handleBlur}
                            />
                            {touched.phone && errors.phone ? (
                              <p className="text-danger">{errors.phone}</p>
                            ) : (
                              <p className="text-danger text-capitalize">
                                {validationError?.phone}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-5">
                          <label className="form-label">
                            Contact Number{" "}
                            <span className="font-12">(Personal)</span>
                          </label>
                          <div className="form-group mb-">
                            <input
                              className="form-control border-secondary "
                              id="personal_phone"
                              name="personal_phone"
                              placeholder="Ex: 9001900191"
                              value={values.personal_phone}
                              onChange={handleChange}
                              maxLength={10}
                              onBlur={handleBlur}
                            />

                            <p className="text-danger text-capitalize">
                              {validationError?.personal_phone}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group mb-3">
                            <label
                              htmlFor="joining_date"
                              className="form-label"
                            >
                              Joining Date
                            </label>
                            <input
                              type="date"
                              className="form-control border-secondary"
                              id="joining_date"
                              name="joining_date"
                              value={values.joining_date}
                              onChange={handleChange}
                              maxLength={12}
                              onBlur={handleBlur}
                              format="dd-MM-yyyy"
                            />
                            {touched.joining_date && errors.joining_date ? (
                              <p className="text-danger">
                                {errors.joining_date}
                              </p>
                            ) : (
                              <p className="text-danger text-capitalize">
                                {validationError?.joining_date}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group mb-3">
                            <label
                              htmlFor="reporting_person"
                              className="form-label"
                            >
                              Board category
                            </label>
                            <select
                              className="form-control border-secondary"
                              id="boardCategory"
                              name="boardCategory"
                              value={values.boardCategory}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="">Select</option>
                              {groupList?.map((obj, i) => {
                                return (
                                  <option value={obj.id} key={i}>
                                    {obj.name}
                                  </option>
                                );
                              })}
                            </select>
                            {touched.boardCategory && errors.boardCategory ? (
                              <p className="text-danger">
                                {errors.boardCategory}
                              </p>
                            ) : (
                              <p className="text-danger text-capitalize">
                                {validationError?.boardCategory}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group mb-3">
                            <label htmlFor="signature" className="form-label">
                              Signature
                            </label>
                            <input
                              type="file"
                              className="form-control border-secondary"
                              id="signature"
                              name="signature"
                              onChange={handleFileChange}
                              onBlur={handleBlur}
                            />
                            {touched.signature && errors.signature ? (
                              <p className="text-danger">{errors.signature}</p>
                            ) : (
                              <p className="text-danger text-capitalize">
                                {validationError?.signature}
                              </p>
                            )}
                          </div>
                        </div>
                        {values?.signature ? (
                          <div className="col-md-2">
                            <div className="form-group mb-3">
                              <img
                                src={values?.signature}
                                style={{
                                  width: "70px",
                                  height: "70px",
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {spinner ? (
                        <div className="">
                          <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                          <p>Creating User...</p>
                        </div>
                      ) : (
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary btn-sm me-3 mt-3"
                            onClick={handleSubmitData}
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className="btn btn-de-danger btn-sm me-3 mt-3"
                            onClick={() => navigate("/ProjectsUsers")}
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                      ``{" "}
                    </form>

                    <p style={{ color: "red" }}>{error}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ProjectsUsersAdd;
