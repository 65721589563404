import { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import DeliveryChallanList from "./DeliveryChallanList";
import MaterialTable from "./MaterialTable";
import DeliveryChallanFilter from "./DeliveryChallanFilter";
import SimpleListMenu from "../commonComponents/DropDownMenu";
import { useLocation, useNavigate } from "react-router-dom";

function DeliveryChallanTabs() {
  const [key, setKey] = useState("Challan");
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const activeTab = queryParams.get("tab") || "Challan";
    navigate(`?tab=${activeTab}`);
    setKey(activeTab);
  }, [location.search]);

  const handleSelect = (selectedKey) => {
    setKey(selectedKey);
    navigate(`?tab=${selectedKey}`);
  };

  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => handleSelect(k)}
            className="mb-3"
          >
            <Tab eventKey="Challan" title="CHALLAN">
              <DeliveryChallanList />
            </Tab>
            <Tab eventKey="MaterialOutward" title="MATERIAL OUTWARD">
              <MaterialTable />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default DeliveryChallanTabs;
