import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { IoIosArrowRoundDown } from "react-icons/io";
import { IoIosArrowRoundUp } from "react-icons/io";
import { BsCurrencyRupee } from "react-icons/bs";
import styles from "./Inventory.module.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { amountFormatJS } from "../../common/amountFormat";
import { formatDate } from "../../constant";
const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#F4F4F4",
          //   paddingTop: "0.3125rem",
          //   paddingBottom: "0.3125rem",
        },
      },
    },
  },
});
const LogsTable = ({ itemLog, name }) => {
  return (
    <div className="logs-table">
      <Box sx={{ background: "#FCFCFC" }}>
        <Box>
          {" "}
          <h5
            className="p-2 "
            style={{ fontSize: "1.375rem", fontWeight: "600" }}
          >
            <span className="fw-normal" style={{ color: "#606060" }}>
              Item:
            </span>{" "}
            {name}
          </h5>
        </Box>

        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "83vh",
            boxShadow: "none",
            background: "#FCFCFC",
            overflow: "Auto",
            maxHeight: "83vh",
          }}
        >
          <ThemeProvider theme={theme}>
            <Table
              stickyHeader
              aria-label="inventory table"
              sx={{
                borderCollapse: "collapse",
                background: "#FCFCFC",
                border: "1px solid #F1F1F1",
                padding: "1.5625rem",
                borderTop: "1px solid #CFCFCF !important",

                overflow: "auto",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "10rem", border: "none" }}>
                    Date
                  </TableCell>
                  <TableCell style={{ width: "10rem", border: "none" }}>
                    IN/OUT
                  </TableCell>
                  <TableCell style={{ width: "10rem", border: "none" }}>
                    Supplier Name
                  </TableCell>
                  <TableCell style={{ width: "10rem", border: "none" }}>
                    IN/OUT Through
                  </TableCell>
                  <TableCell style={{ width: "10rem", border: "none" }}>
                    Quantity
                  </TableCell>
                  <TableCell style={{ width: "10rem", border: "none" }}>
                    Item Price
                  </TableCell>
                  <TableCell style={{ width: "10rem", border: "none" }}>
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {itemLog.length > 0 ? (
            itemLog.map((row, index) => (
                  <TableRow
                    key={index}
                    style={{
                      border: "none",
                      marginBottom: "3rem",
                      background: "#FCFCFC",
                    }}
                  >
                    <TableCell
                      sx={{
                        // borderRight: "none",
                        // borderLeft: "none",
                        border: "none",
                        height: "2rem",
                      }}
                    >
                      {formatDate(row?.date)}
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {row?.in_out ? (
                        row?.in_out === "in" ? (
                          <>
                            <IoIosArrowRoundDown
                              className={`${styles.textGreen} fw-semibold`}
                              size={"18px"}
                            />
                            <span
                              className={`${styles.textGreen} fw-normal text-uppercase`}
                            >
                              {row?.in_out}
                            </span>
                          </>
                        ) : (
                          <>
                            <IoIosArrowRoundUp
                              className={`${styles.textRed}`}
                              size={"18px"}
                            />
                            <span
                              className={`${styles.textRed} fw-normal text-uppercase`}
                            >
                              {row?.in_out || "- - -"}
                            </span>
                          </>
                        )
                      ) : (
                        "---"
                      )}
                    </TableCell>

                    <TableCell sx={{ border: "none" }}>
                      {row?.supplier_name ? row?.supplier_name : "---"}
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {row?.in_out_through ? row?.in_out_through : "- - -"}
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {row?.quantity ? row?.quantity : "---"}
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {row?.price ? amountFormatJS(row?.price) : "---"}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        fontWeight: "600 !important",
                        color: "#D90000",
                      }}
                    >
                      {row?.total ? (
                        <span>
                          {" "}
                          <BsCurrencyRupee size="0.9375rem" />
                          {amountFormatJS(row.total)}
                        </span>
                      ) : (
                        "---"
                      )}
                    </TableCell>
                  </TableRow>
            ))
          ) : (<TableRow>
            <TableCell colSpan={7} align="center">
              <Typography variant="body1" color="textSecondary">
                No data available
              </Typography>
            </TableCell>
          </TableRow>
        )}
              </TableBody>
              
            </Table>
          </ThemeProvider>
        </TableContainer>
      </Box>
    </div>
  );
};

export default LogsTable;
