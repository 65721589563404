import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { changePasswordSchema } from "../schemas/settingSchema";

function ChangePassword() {
  const location = useLocation();
  const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;

  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    resetForm,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: (values) => {
      changePass(values)
    },
  });

  const email = location?.state?.email;
  const [newPassworderror, setNewPasswordError] = useState("");
  const [confirmPassworderror, setConfirmPasswordError] = useState("");
  const [error, setError] = useState("");
  const [spinner, setSpinner] = useState(false);
  const logo = process.env.REACT_APP_LOGO_URL;
  const navigate = useNavigate();
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const changePass = (value) => {
    setSpinner(true);
    const data = {
      ...value,
      email,
    };
  
    axios
      .post(apiurl + "updatepassword", data)
      .then((response) => {
        setSpinner(false);
        if (response?.data?.success) {
          navigate("/dashboard");
        } else {
          if (response?.data?.message === "error") {
            setError(response?.data?.response);
          } else if (response?.data?.message === "Error validation") {
            //  error.email
            setNewPasswordError(response?.data?.response?.newPassword);
            setConfirmPasswordError(response?.data?.response?.confirmPassword);
          }
        }
      })
      .catch((err) => {
        setSpinner(false);
        if (err?.response?.data?.message === "Error validation") {
          setError(err?.response?.data?.response?.name);
        } else {
          setError("Invalid Credentials");
        }
      });
  };

  const mystyle = {
    backgroundImage: "url('assets/images/p-1.png')",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <>
      <div className="auth-page" style={mystyle}>
        <div className="container-md">
          <div className="row vh-100 d-flex justify-content-center">
            <div className="col-12 align-self-center">
              <div className="row">
                <div className="col-lg-4 mx-auto">
                  <div className="card">
                    <div className="card-body p-0 auth-header-box">
                      <div className="text-center p-3">
                        <Link to="#" className="logo logo-admin">
                          <img
                            src={logo}
                            height="50"
                            alt="logo"
                            className="auth-logo"
                          />
                        </Link>
                        {/* <!-- <h4 className="mt-3 mb-1 fw-semibold text-white font-18">Reset Password For Unikit</h4>
                                            <p className="text-muted  mb-0">Enter your Email and instructions will be sent to you!</p> --> */}
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <form
                        className="mt-4 mb-3"
                        action=""
                        onSubmit={handleSubmit}
                      >
                        <div className="form-group mb-3">
                          <label className="form-label" htmlFor="">
                            New Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="newPassword"
                            name="newPassword"
                            placeholder="New Password"
                            value={values.newPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <p style={{ color: "red" }}>
                            {errors.newPassword && touched.newPassword
                              ? errors.newPassword
                              : newPassworderror}
                          </p>
                        </div>

                        <div className="form-group mb-3">
                          <label className="form-label" htmlFor="">
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="confirmPassword"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <p style={{ color: "red" }}>
                            {errors.confirmPassword && touched.confirmPassword
                              ? errors.confirmPassword
                              : confirmPassworderror}
                          </p>
                        </div>

                        <div className="form-group mb-0 row">
                          <div className="col-12">
                            {spinner ? (
                              <div className="text-center">
                                <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                                <p>Changing Password...</p>
                              </div>
                            ) : (
                              <button
                                className="btn btn-primary w-100"
                                type="submit"
                              >
                                Submit{" "}
                                <i className="fas fa-sign-in-alt ms-1"></i>
                              </button>
                            )}
                          </div>
                          {/* <!--end col--> */}
                        </div>
                        {/* <!--end form-group--> */}
                      </form>
                      <p style={{ color: "red" }}>{error}</p>
                      {/* <!--end form--> */}
                    </div>
                    {/* <!--end card-body--> */}
                    <div className="card-body bg-light-alt text-center">
                      &copy;{" "}
                      <script>document.write(new Date().getFullYear())</script>{" "}
                      Unikit
                    </div>
                  </div>
                  {/* <!--end card--> */}
                </div>
                {/* <!--end col--> */}
              </div>
              {/* <!--end row--> */}
            </div>
            {/* <!--end col--> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
