import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { errorsMessage } from "../../../../../constant";
import { addGurantorApi, editGurantorApi } from "../../../../../redux/api";
import GurantorForm from "./GurantorForm";
import { BankDetailsSchema } from "./gurantorSchema";

function AddGurantorModal({
  isEdit = false,
  initialData = {},
  setModalShow,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;

  const initialValues = {
    bank_id: initialData.bank_id || "",
    branch_name: initialData.branch_name || "",
    account_number: initialData.account_number || "",
    credit_limit: initialData.credit_limit || "",
  };

  const handleGurantor = async (values) => {
    setLoading(true);
    try {
      const apiFunction = isEdit ? editGurantorApi : addGurantorApi;
      const res = await apiFunction({
        ...values,
        user_id,
        ...(isEdit && { guarantor_id: initialData.id }),
      });
      toast.success(
        `Guarantor Bank ${isEdit ? "Updated" : "Added"} Successfully`
      );
      props?.guartorListData();
      props.onHide();
      resetForm();
    } catch (error) {
      if (!error.response) {
        toast.error("Network error: Please check your internet connection.");
      } else {
        errorsMessage(error.response.status);
      }
      if (error?.response?.status === 422) {
        setServerError(error?.response?.data?.response);
      }
    } finally {
      setLoading(false);
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: BankDetailsSchema,
    onSubmit: (values) => {
      handleGurantor(values);
    },
  });

  useEffect(() => {
    if (isEdit && initialData) {
      console.log(initialData);
      setValues(initialData);
    }
  }, [isEdit, initialData]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {isEdit ? "Edit Guarantor" : "Add New Guarantor"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <GurantorForm
            values={values}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            serverError={serverError}
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        {loading ? (
          <div className="text-center">
            <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
            <p>Loading...</p>
          </div>
        ) : (
          <div>
            <Button onClick={props.onHide} className="btn btn-secondary me-2">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              type="button"
              className="btn btn-primary"
            >
              {isEdit ? "Update" : "Add"}
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default AddGurantorModal;
