import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { FaFilePdf } from "react-icons/fa";
import {
  cancelPurchaseOrderApi,
  getlistNotreceivedItemListApi,
} from "../purchaseApi";
import { errorsMessage, formatDate } from "../../constant";
import CommonTable from "../Common/CommonTable";
import CommonTd from "../../Tender/Common/CommonTd";
import DeliveryScheduleModal from "./DeliveyScheduleModal";
import PoListMenu from "../ManagmentPages/PurchaseOrder/HelperComponnent/PoListMenu";
import ConfirmCancelModal from "../ManagmentPages/PurchaseOrder/HelperComponnent/ConfirmCancelModal";
import { toast } from "react-toastify";
import { HiDotsVertical } from "react-icons/hi";
import Button from "@mui/material/Button";
import { Tooltip } from "@mui/material";

function PoProjectPurchaseList({
  poOrderList,
  tabKey,
  loading,
  projectDetails,
  PurchaseOrderOnProjectId,
}) {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [open, setOpen] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [cancelModalLoader, setCancelModalLoader] = useState(false);
  const [cancelPoId, setCancelPoId] = useState(null);
  const [deliveries, setDeliveries] = useState([]);
  const [cancelled_reason, setCancelled_reason] = useState("");
  const tableHeading = [
    { name: "Sr No", width: "2rem" },
    { name: "Order No", width: "10rem" },
    { name: "OrderDate", width: "8rem" },
    { name: "Approval State", width: "8rem" },
    { name: "Party Name", width: "20rem" },
    { name: "Delivery Schedule", width: "8rem" },
    { name: "Pdf", width: "4rem" },
    { name: "Edit", width: "4rem" },
  ];

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleOpenModal = (element) => {
    setSelectedElement(element);
    setOpen(true);
  };

  const handleCancelPo = async () => {
    setCancelModalLoader(true);
    try {
      const res = await cancelPurchaseOrderApi({
        user_id,
        po_id: cancelPoId,
        cancelled_reason,
      });
      setCancelModalOpen(false);
      setCancelled_reason("");
      PurchaseOrderOnProjectId();
      toast.success("Purchase Order Cancelled Successfully");
    } catch (error) {
      if (!error?.response) {
        toast.error("Please Check your internet");
      }
      if (error?.response?.status) {
        errorsMessage(error?.response?.status);
      }
    } finally {
      setCancelModalLoader(false);
    }
  };
  const getlistNotreceivedItemList = async (id) => {
    setCancelPoId(id);
    try {
      const res = await getlistNotreceivedItemListApi({ user_id, po_id: id });
      setDeliveries(res?.data?.response?.listNotReceivedScheduleItemData);
    } catch (error) {
      if (!error?.response) {
        toast.error("Please Check your internet");
      }
      if (error?.response?.status) {
        errorsMessage(error?.response?.status);
      }
    }
  };

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-sm-6">
            <h4 className="page-title">Purchase Order</h4>
          </div>
          <div className="col-sm-6 text-end"></div>
        </div>

        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center pb-5"
            style={{ height: "70vh" }}
          >
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
          </div>
        ) : (
          <div className="card">
            <div
              className="card-body "
              style={{ padding: "1rem 0", margin: "0" }}
            >
              <div
                className="table-responsive table-container-scroll"
                style={{ overflowX: "auto" }}
              >
                <table
                  className="table common-table"
                  style={{ tableLayout: "unset" }}
                >
                  <CommonTable tableHeading={tableHeading} />
                  <tbody>
                    {!poOrderList?.length ? (
                      <tr colspan="6">
                        <td width="100%" className="text-center text-muted">
                          No purchase orders available for the selected project
                          and user.
                        </td>
                      </tr>
                    ) : (
                      poOrderList?.map((element, index) => (
                        <tr key={index}>
                          <CommonTd width="2rem" value={index + 1} />
                          <CommonTd
                            width="10rem"
                            value={element?.purchase_order_no}
                          />
                          <CommonTd
                            width="8rem"
                            value={formatDate(element?.order_date)}
                          />
                          <CommonTd
                            width="8rem"
                            value={
                              element?.cancelled_status
                                ? capitalizeFirstLetter(
                                    element?.cancelled_status
                                  )
                                : element?.status !== "approve"
                                ? capitalizeFirstLetter(element?.status)
                                : "Approved"
                            }
                          />
                          <CommonTd
                            width="20rem"
                            value={element?.party_details?.party_name}
                          />

                          <td>
                            <button
                              onClick={() => handleOpenModal(element)}
                              style={{
                                width: "8rem",
                                border: "1px solid white",
                                background: "rgba(239, 77, 86, .15) ",
                                color: "#ef4d56",
                                padding: "0.5rem",
                              }}
                            >
                              Add/View
                            </button>
                          </td>
                          <td>
                            <p
                              style={{
                                minWidth: "4rem",
                                maxWidth: "4rem",
                                margin: "auto",
                                padding: "auto",
                                cursor: "pointer",
                              }}
                              className="text-center"
                            >
                              {element?.status !== "approve" ? (
                                <span
                                  style={{
                                    cursor: "not-allowed",
                                    color: "lightgray",
                                  }}
                                  className="font-20 m-0 p-0"
                                >
                                  <FaFilePdf
                                    style={{ margin: "auto" }}
                                    color="gray" // Change color to indicate disabled
                                  />
                                </span>
                              ) : (
                                <Link to={`/purchase-order-pdf/${element?.id}`}>
                                  <FaFilePdf
                                    className="font-20 m-0 p-0"
                                    style={{ margin: "auto" }}
                                    color="red"
                                  />
                                </Link>
                              )}
                            </p>
                          </td>
                          <td>
                            {element?.cancelled_status ? (
                              <Tooltip
                                title="For cancelled purchase orders, editing and cancellation are not allowed"
                                arrow
                              >
                                <Button
                                  id="basic-button"
                                  style={{
                                    cursor: "not-allowed",
                                    color: "lightgray",
                                  }}
                                >
                                  <HiDotsVertical
                                    color="#001E66"
                                    fontSize={"25px"}
                                  />
                                </Button>
                              </Tooltip>
                            ) : (
                              <PoListMenu
                                tabKey={tabKey}
                                element={element}
                                projectDetails={projectDetails}
                                cancelModalOpen={cancelModalOpen}
                                setCancelModalOpen={setCancelModalOpen}
                                getlistNotreceivedItemList={
                                  getlistNotreceivedItemList
                                }
                              />
                            )}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      {selectedElement && (
        <DeliveryScheduleModal
          open={open}
          setOpen={setOpen}
          itemDetails={selectedElement?.item_details}
          id={selectedElement?.id}
          POnumber={selectedElement?.purchase_order_no}
        />
      )}
      <ConfirmCancelModal
        open={cancelModalOpen}
        setOpen={setCancelModalOpen}
        cancelModalLoader={cancelModalLoader}
        handleCancelPo={handleCancelPo}
        deliveries={deliveries}
        setCancelled_reason={setCancelled_reason}
        cancelled_reason={cancelled_reason}
      />
    </div>
  );
}

export default PoProjectPurchaseList;
