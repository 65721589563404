import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { itemMasterLatestTransaction } from "../../purchaseApi";
import { CircularProgress } from "@mui/material";
import { formatDate } from "../../../constant";

function ItemPriceHistory(props) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const itemMasterLatestTransactionApi = async () => {
    setOpen(true);
    try {
      const res = await itemMasterLatestTransaction({
        user_id: props?.user_id,
        item_id: props.itemId,
      });
      setData(
        res?.data?.response?.getItemMasterLatestTransaction?.transactions
      );
    } catch (error) {
      // Handle errors from the API call
      console.error("Error in API call:", error.message || error);
    } finally {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (props?.itemId) {
      itemMasterLatestTransactionApi();
    }
  }, [props?.itemId]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Item Name - Last 3 Transaction Details:
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="table-container">
          <table class="simple-table">
            <thead>
              <tr style={{ background: "#F4F4FB", padding: ".4rem" }}>
                <th style={{ width: "4rem" }}>
                  {" "}
                  <p style={{ color: "black" }} className="m-0">
                    S.No.
                  </p>
                </th>
                <th style={{ width: "10rem" }}>
                  <p style={{ color: "black" }} className="m-0">
                    Transaction Date
                  </p>
                </th>
                <th>
                  <p style={{ color: "black" }} className="m-0">
                    Vendor
                  </p>
                </th>
                <th style={{ width: "8rem" }}>
                  <p style={{ color: "black" }} className="m-0">
                    Rate
                  </p>
                </th>
                <th style={{ width: "8rem" }}>
                  <p style={{ color: "black" }} className="m-0">
                    Discount
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {open ? (
                <tr>
                  <td colSpan="5" style={{ textAlign: "center" }}>
                    <CircularProgress />
                  </td>
                </tr>
              ) : data?.length ? (
                data.map((item, index) => (
                  <tr
                    key={item?.id}
                    style={{
                      borderBottom: ".63px solid rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <td>{index + 1}</td>
                    <td>{formatDate(item.order_date)}</td>
                    <td>{item.party_name}</td>
                    <td className="text-danger">{item.rate}</td>
                    <td className="text-danger">{item.disc_per}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" style={{ textAlign: "center" }}>
                    No transaction for this item
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ItemPriceHistory;
