import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import axios from "axios";
import { errorsMessage } from "../../constant";
import RequistionFormInput from "./RequistionFormInput";
import { useNavigate } from "react-router-dom";
import { set } from "date-fns";
import { getVendorDropDown } from "../../Purchase/purchaseApi";
import { unnatiProjectDropDownApi } from "../commonComponents/storeApi";

const RequistionForm = ({
  handleChange,
  values,
  handleSubmit,
  errors,
  touched,
  handleBlur,
  mode,
  setFieldValue,
  validationError,
  detail,
  spinner,
}) => {
  const userDetail = JSON.parse(localStorage.getItem("profile"));
  const user_id = userDetail?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const navigate = useNavigate();
  const [projectList, setProjectList] = useState([]);
  const [items, setItems] = useState([
    {
      item_id: "",
      required_qty: "",
      issued_qty: "",
      issued_to: "",
      batch_no: "",
    },
  ]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedStoreId, setSelectedStoreId] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const [unitList, setUnitList] = useState([]);
  const [users, setUsers] = useState([]);

  function projectDropDown() {
    const data = {
      user_id,
    };
    unnatiProjectDropDownApi(data)
      .then((response) => {
        if (response.status === 200) {
          setProjectList(response?.data?.response?.projectList);
        }
      })
      .catch((error) => {
        //setServerSideValidation(error?.response?.data?.response);
        errorsMessage(error?.response?.status);
      })
      .finally(() => {});
  }

  const addItems = () => {
    setItems((prev) => {
      return [
        ...prev,
        {
          item_id: "",
          required_qty: "",
          issued_qty: "",
          issued_to: "",
          batch_no: "",
        },
      ];
    });
  };
  const removeItems = (index) => {
    const copyItems = [...items];
    const updatedItems = copyItems.splice(index, 1);
    setItems(copyItems);
    setFieldValue("items", copyItems);
  };

  const itemPropertyChangeHandler = (event, index) => {
    const { name, value } = event.target;

    setItems((prev) => {
      const updateItem = [...prev];
      updateItem[index] = { ...updateItem[index], [name]: value };

      setFieldValue("items", updateItem);
      return updateItem;
    });
  };

  useEffect(() => {
    setFieldValue("items", items);
  }, [items]);

  const handleProjectChange = (selectedOption) => {
    setFieldValue("project_id", selectedOption?.id);
    setSelectedProject(selectedOption);
    setSelectedProjectId(selectedOption?.id);
  };

  const handleStoreChange = (selectedOption) => {
    setFieldValue("store_id", selectedOption?.id);
    setSelectedStore(selectedOption);
    setSelectedStoreId(selectedOption?.id);
  };

  function getDropDown() {
    getVendorDropDown({ user_id })
      .then((res) => {
        setUsers(res?.data?.response?.PartyMasterDropdownList?.users);
      })
      .catch((err) => {
        if (err) {
          errorsMessage(err?.response?.status);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  }

  const getStoreList = () => {
    const data = { user_id };
    const url = `${baseUrl}listpurchasestoremaster`;
    axios
      .post(url, data, config)
      .then((res) => {
        if (res.status === 200) {
          setUnitList(res?.data?.response?.CityMasterList);
        }
      })
      .catch((err) => {
        errorsMessage("Please try again later ,Something went wrong");
      });
  };

  useEffect(() => {
    getStoreList();
    getDropDown();
    projectDropDown();
  }, []);
  useEffect(() => {
    if (mode == "edit") {
      setItems(values?.items);
      const selectProject = projectList.find(
        (project) => project?.id == values?.project_id
      );
      setSelectedStore(detail?.store);
      setSelectedStoreId(values?.store_id);
      setSelectedProject(selectProject);
    }
  }, [mode, unitList, projectList]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row ">
          <div className="col-3">
            <label htmlFor="" className="form-label fw-bold font-16">
              Requisition form type
            </label>
            <select
              className="form-control border-secondary"
              name="type"
              value={values?.type}
              onChange={handleChange}
            >
              <option value={"forOther"}>For Other</option>
              <option value={"inHouse"}>For In House</option>
            </select>
            <p className="text-danger m-0 ">
              {touched.requested_date && errors.requested_date
                ? errors.requested_date
                : validationError?.requested_date}
            </p>
          </div>
          <div className="col-3">
            <label htmlFor="" className="form-label fw-bold font-16">
              Date
            </label>
            <div className="input-group me-3">
              <input
                type="date"
                name="requested_date"
                value={values?.requested_date}
                className="form-control form-control-sm border-secondary"
                style={{ fontSize: ".9rem" }}
                onChange={handleChange}
              />
              <p className="text-danger m-0 ">
                {touched.requested_date && errors.requested_date
                  ? errors.requested_date
                  : validationError?.requested_date}
              </p>
            </div>
          </div>

          <div className="col-3">
            <div className="input-group me-3 d-block">
              <label htmlFor="" className="form-label fw-bold font-16">
                Project
              </label>
              <Select
                placeholder="Select Project"
                style={{ width: "100%" }}
                className="form-select-control border-secondary"
                name="project_id"
                id="project_id"
                isSearchable={true}
                options={projectList}
                getOptionLabel={(projectList) => projectList["pd_poloaNo"]}
                getOptionValue={(projectList) => projectList["id"]}
                value={selectedProject}
                onChange={handleProjectChange}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 4,
                  colors: {
                    ...theme.colors,
                    primary25: "lightgrey",
                    primary: "lightgrey",
                  },
                })}
              />
            </div>
            <p className="text-danger m-0">
              {touched.project_id && errors.project_id
                ? errors.project_id
                : validationError?.project_id}
            </p>
          </div>

          <div className="col-3">
            <div className="input-group me-3 d-block">
              <label htmlFor="" className="form-label fw-bold font-16 ">
                Site Name/Store
              </label>
              <Select
                placeholder="Select Store"
                style={{ width: "100%" }}
                className="form-select-control border-secondary"
                name="store_id"
                id="store_id"
                isSearchable={true}
                options={unitList}
                getOptionLabel={(unitList) => unitList["store_name"]}
                getOptionValue={(storeUnitList) => storeUnitList["id"]}
                value={selectedStore}
                onChange={handleStoreChange}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 4,
                  colors: {
                    ...theme.colors,
                    primary25: "lightgrey",
                    primary: "lightgrey",
                  },
                })}
              />
            </div>
            <p className="text-danger m-0 ">
              {touched.store_id && errors.store_id
                ? errors.store_id
                : validationError?.store_id}
            </p>
          </div>
          <div className="col-md-3 mt-3">
            <label htmlFor="" className="form-label fw-bold font-16">
              Requested By{" "}
            </label>
            <select
              className="form-control border-secondary"
              onChange={handleChange}
              value={values?.requested_by}
              name="requested_by"
            >
              <option value={""}>Select </option>
              {users.map((element) => (
                <option key={element.id} value={element.id}>
                  {element.name}
                </option>
              ))}
            </select>
            <p className="text-danger m-0 ">
              {touched.requested_by && errors.requested_by
                ? errors.requested_by
                : validationError?.requested_by}
            </p>
          </div>
          <div className="col-3 mt-3">
            <label htmlFor="" className="form-label fw-bold font-16">
              Required Date
            </label>
            <div className="input-group me-3">
              <input
                type="date"
                name="required_date"
                value={values?.required_date}
                className="form-control form-control-sm border-secondary"
                style={{ fontSize: ".9rem" }}
                onChange={handleChange}
              />

              <p className="text-danger m-0 ">
                {touched.required_date && errors.required_date
                  ? errors.required_date
                  : validationError?.required_date}
              </p>
            </div>
          </div>
          <div className="col-md-3 mt-3">
            <label htmlFor="" className="form-label fw-bold font-16">
              Purpose{" "}
            </label>
            <input
              placeholder="Enter Purpose"
              name="purpose"
              value={values?.purpose}
              maxLength={40}
              onChange={handleChange}
            />
            <p className="text-danger m-0 ">
              {touched.purpose && errors.purpose
                ? errors.purpose
                : validationError?.purpose}
            </p>
          </div>
        </div>
        <h5 className="mb-4 ">ITEMS</h5>
        {items?.map((item, index) => (
          <div className="row mt-4">
            <RequistionFormInput
              itemdetail={item}
              index={index}
              setItems={setItems}
              items={items}
              handleChange={handleChange}
              values={values}
              handleSubmit={handleSubmit}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              mode={mode}
              setFieldValue={setFieldValue}
              validationError={validationError}
            />

            <div className="col-2">
              <label htmlFor="" className="form-label fw-bold font-16">
                Required Qty
              </label>
              <div className="input-group me-3">
                <input
                  type="number"
                  name="required_qty"
                  value={item?.required_qty}
                  className="form-control form-control-sm border-secondary"
                  placeholder="Enter required quantity"
                  style={{ fontSize: ".9rem" }}
                  onChange={(event) => itemPropertyChangeHandler(event, index)}
                />
              </div>
              <p className="text-danger m-0 ">
                {touched.items &&
                errors.items &&
                errors.items[index] &&
                errors.items[index].required_qty
                  ? errors.items[index].required_qty
                  : validationError?.[`items.${index}.required_qty`]}
              </p>
            </div>
            {values.type !== "forOther" ? (
              <>
                <div className="col-2">
                  <label htmlFor="" className="form-label fw-bold font-16">
                    Issue Qty.
                  </label>
                  <div className="input-group me-3">
                    <input
                      type="number"
                      name="issued_qty"
                      value={item?.issued_qty}
                      className="form-control form-control-sm border-secondary"
                      placeholder="Enter Quantity"
                      style={{ fontSize: ".9rem" }}
                      onChange={(event) =>
                        itemPropertyChangeHandler(event, index)
                      }
                    />
                  </div>
                  <p className="text-danger m-0 ">
                    {touched.items &&
                    errors.items &&
                    errors.items[index] &&
                    errors.items[index].issued_qty
                      ? errors.items[index].issued_qty
                      : validationError?.[`items.${index}.issued_qty`]}
                  </p>
                </div>
                <div className="col-2">
                  <label htmlFor="" className="form-label fw-bold font-16">
                    Issued to
                  </label>
                  <div className="input-group me-3">
                    <input
                      type="text"
                      name="issued_to"
                      value={item?.issued_to}
                      className="form-control form-control-sm border-secondary"
                      placeholder="Enter Issued to"
                      style={{ fontSize: ".9rem" }}
                      onChange={(event) =>
                        itemPropertyChangeHandler(event, index)
                      }
                    />
                  </div>
                  <p className="text-danger m-0 ">
                    {touched.items &&
                    errors.items &&
                    errors.items[index] &&
                    errors.items[index].issued_to
                      ? errors.items[index].issued_to
                      : validationError?.[`items.${index}.issued_to`]}
                  </p>
                </div>
              </>
            ) : (
              <div className="col-3">
                <label htmlFor="" className="form-label fw-bold font-16">
                  Batch No.
                </label>
                <div className="input-group me-3">
                  <input
                    type="text"
                    name="batch_no"
                    value={item?.batch_no}
                    className="form-control form-control-sm border-secondary"
                    placeholder="Enter Batch No."
                    style={{ fontSize: ".9rem ", width: "100%" }}
                    onChange={(event) =>
                      itemPropertyChangeHandler(event, index)
                    }
                  />
                  <p className="text-danger m-0 ">
                    {touched.items &&
                    errors.items &&
                    errors.items[index] &&
                    errors.items[index].batch_no
                      ? errors.items[index].batch_no
                      : validationError?.[`items.${index}.batch_no`]}
                  </p>
                </div>
              </div>
            )}
            <div className="col-2 ">
              <label htmlFor="" className="form-label fw-bold font-16 d-block">
                &nbsp;
              </label>
              <button
                type="button"
                style={{ width: "40px" }}
                className="btn btn-primary d-inline-flex justify-content-center align-items-center btn-sm me-2 fs-3 p-2 mb-2"
                onClick={() => addItems()}
              >
                +
              </button>
              {index != 0 ? (
                <button
                  type="button"
                  style={{ width: "40px" }}
                  className="btn btn-primary d-inline-flex justify-content-center align-items-center btn-sm me-2 fs-3 p-2 mb-2 "
                  onClick={() => removeItems(index)}
                >
                  -
                </button>
              ) : null}
            </div>
            {values.type !== "forOther" ? (
              <div className="col-3 mt-3">
                <label htmlFor="" className="form-label fw-bold font-16">
                  Batch No.
                </label>
                <div className="input-group me-3">
                  <input
                    type="text"
                    name="batch_no"
                    value={item?.batch_no}
                    className="form-control form-control-sm border-secondary"
                    placeholder="Enter Batch No."
                    style={{ fontSize: ".9rem ", width: "100%" }}
                    onChange={(event) =>
                      itemPropertyChangeHandler(event, index)
                    }
                  />
                  <p className="text-danger m-0 ">
                    {touched.items &&
                    errors.items &&
                    errors.items[index] &&
                    errors.items[index].batch_no
                      ? errors.items[index].batch_no
                      : validationError?.[`items.${index}.batch_no`]}
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        ))}
        {spinner ? (
          <div className="">
            <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
            <p>Wait...</p>
          </div>
        ) : (
          <div class="row pt-4">
            <div>
              <button
                type="button"
                class="btn btn-de-danger btn-sm border-danger"
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-danger mx-2">
                {mode == "add" ? "Add" : "Update"}
              </button>
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default RequistionForm;
