import React, { useEffect, useState } from "react";
import DateRangePickerLatest from "./CommunicationDateRangePicker";
import CommunicationTrackerTable from "./CommunicationTrackerTable";
import AddCommunicationTrackerModal from "../Add-EditComponents/AddCommunicationTrackerModal";
import { communicationTrackerListApi } from "../../../../../redux/api";
import { toast } from "react-toastify";
import BasicPagination from "../../../../Common/Pagination";
import { Spinner } from "react-bootstrap";

function CommunicationTrackerListForProject({ project_id, tabKey }) {
  const [modalShow, setModalShow] = React.useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const [communicationTrackerList, setCommunicationTrackerList] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleChange = () => {
    console.log(":riitk");
  };
  const communicationTrackerListData = async () => {
    setLoading(true);
    try {
      const res = await communicationTrackerListApi({ project_id, page });
      setCommunicationTrackerList(res?.data?.response?.data?.data);
      setPageCount(res?.data?.response?.data?.last_page);
      setLoading(true);
    } catch (error) {
      if (!error.response) {
        toast.error("Network error: Please check your internet connection.");
      } else {
        errorsMessage(error.response.status);
      }
    } finally {
      setLoading(false);
    }
  };
  function handlePaginateChange(event, value) {
    setPage(value);
  }

  useEffect(() => {
    if (tabKey === "communicationTracker") communicationTrackerListData();
  }, [tabKey, page]);
  return (
    <div className="row align-items-center mt-3">
      <div className="col-md-6">
        <h4 className="fw-bold mb-0">Communication Tracker</h4>
      </div>
      <div className="col-md-6 d-flex gap-2 justify-content-end">
        <DateRangePickerLatest handleChange={handleChange} />
        <button className="btn btn-primary" onClick={() => setModalShow(true)}>
          + Add Communication
        </button>
      </div>
      <div>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" variant="danger" />
          </div>
        ) : (
          <CommunicationTrackerTable data={communicationTrackerList} />
        )}
        <BasicPagination
          handlePaginateChange={handlePaginateChange}
          page={page}
          pageCount={pageCount}
          loading={loading}
        />
      </div>
      <AddCommunicationTrackerModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        communicationTrackerListData={communicationTrackerListData}
        project={true}
        project_id={project_id}
      />
    </div>
  );
}

export default CommunicationTrackerListForProject;
