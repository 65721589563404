import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { amountFormatJS } from "../../common/amountFormat";

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row, departmentName } = props;
  const [open, setOpen] = React.useState(false);
  const baseURL = process.env.REACT_APP_BASE_URL;

  function formatDate(dates) {
    const dateString = dates;
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
      date
    );
    return formattedDate;
  }

  return (
    <>
      <TableRow style={{ marginTop: "1rem" }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{
            wordWrap: "break-word",
            color: "#23272E",
            fontWeight: "600",
          }}
        >
          {row.grn_no}
        </TableCell>
        <TableCell
          component="th"
          style={{
            wordWrap: "break-word",
            color: "#23272E",
            fontWeight: "600",
          }}
        >
          {row?.grn_data?.document_no}
        </TableCell>
        <TableCell
          component="th"
          style={{
            wordWrap: "break-word",
            color: "#23272E",
            fontWeight: "600",
          }}
        >
          {formatDate(row.created_at)}
        </TableCell>
        <TableCell
          component="th"
          style={{
            wordWrap: "break-word",
            color: "#23272E",
            fontWeight: "600",
          }}
        >
          {row?.grn_data?.railway_po_no}
        </TableCell>
        <TableCell
          component="th"
          style={{
            wordWrap: "break-word",
            color: "#23272E",
            fontWeight: "600",
          }}
        >
          {row?.grn_data?.prepared_by}
        </TableCell>
        <TableCell
          component="th"
          style={{
            wordWrap: "break-word",
            color: "#23272E",
            fontWeight: "600",
          }}
        >
          {row?.grn_data?.store_name}
        </TableCell>
        <TableCell
          component="th"
          style={{
            wordWrap: "break-word",
            color: "#23272E",
            fontWeight: "600",
          }}
        >
          {row?.grn_data?.supplier}
        </TableCell>
        <TableCell
          component="th"
          style={{
            wordWrap: "break-word",
            color: "#23272E",
            fontWeight: "600",
          }}
        >
          ₹{amountFormatJS(row?.invoice_amount)}
        </TableCell>

        <TableCell>
          {row?.invoice ? (
            <Link to={baseURL + row?.invoice} target="_blank">
              <BsFileEarmarkPdfFill
                color="#D03438"
                style={{ width: "22px", height: "22px" }}
              />
            </Link>
          ) : (
            "- - -"
          )}
        </TableCell>
        <TableCell>
          {row?.incoming_inspection ? (
            <Link to={baseURL + row?.incoming_inspection} target="_blank">
              <BsFileEarmarkPdfFill
                color="#D03438"
                style={{ width: "22px", height: "22px" }}
              />
            </Link>
          ) : (
            "- - -"
          )}
        </TableCell>
        <TableCell>
          {row?.supplier_test_certificate ? (
            <Link to={baseURL + row?.supplier_test_certificate} target="_blank">
              <BsFileEarmarkPdfFill
                color="#D03438"
                style={{ width: "22px", height: "22px" }}
              />
            </Link>
          ) : (
            "- - -"
          )}
        </TableCell>
        {departmentName === "Store" ? (
          <TableCell>
            {" "}
            <Link
              to={`/GoodReceiptNote/edit-Good-Receipt-Note/${
                row?.grn_receive_type === "For Railway" ? "railway" : "store"
              }`}
              state={{ data: row }}
            >
              <FiEdit style={{ width: "18.92px", height: "19px" }} />
            </Link>
          </TableCell>
        ) : null}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={"100%"}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table
                size="small"
                aria-label="purchases"
                sx={{ border: "none" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "5rem" }}>S.NO.</TableCell>
                    <TableCell>ITEM CODE</TableCell>
                    <TableCell style={{ width: "30rem" }}>ITEM NAME</TableCell>
                    <TableCell>ORDER QTY</TableCell>
                    <TableCell>Rate</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>DELIVERED QTY</TableCell>
                    <TableCell>REJECTED QTY</TableCell>
                    <TableCell>APPROVED QTY</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.item_details.map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ height: "4rem" }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell>{item.item_code}</TableCell>
                      <TableCell>{item.item_name}</TableCell>{" "}
                      <TableCell>{item.qty}</TableCell>
                      <TableCell>{item.rate}</TableCell>
                      <TableCell>{item.amount}</TableCell>
                      <TableCell>{item.delivered_qty}</TableCell>
                      <TableCell>{item.rejected_qty}</TableCell>
                      <TableCell component="th" scope="row">
                        {item.approved_qty}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function CollapsibleTable({ grnData, departmentName }) {
  return (
    <TableContainer component={Paper} sx={{ maxHeight: "78vh" }}>
      <Table
        aria-label="collapsible table"
        style={{ overflow: "auto", border: "none" }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 100 }} />
            <TableCell sx={{ width: 150, color: "#8B909A", fontWeight: "500" }}>
              GRN NO.
            </TableCell>
            <TableCell sx={{ width: 150, color: "#8B909A" }}>
              DOC.&nbsp;NUMBER
            </TableCell>
            <TableCell sx={{ width: 150, color: "#8B909A", fontWeight: "500" }}>
              DATE
            </TableCell>
            <TableCell sx={{ width: 150, color: "#8B909A", fontWeight: "500" }}>
              RAILWAY PO NUMBER
            </TableCell>
            <TableCell sx={{ width: 150, color: "#8B909A", fontWeight: "500" }}>
              PO GENERATED BY
            </TableCell>
            <TableCell sx={{ width: 200, color: "#8B909A", fontWeight: "500" }}>
              STORE
            </TableCell>
            <TableCell sx={{ width: 200, color: "#8B909A", fontWeight: "500" }}>
              SUPPLIER
            </TableCell>
            <TableCell sx={{ width: 200, color: "#8B909A", fontWeight: "500" }}>
              Invoice Amount
            </TableCell>
            <TableCell sx={{ width: 150, color: "#8B909A", fontWeight: "500" }}>
              INVOICE
            </TableCell>
            <TableCell sx={{ width: 150, color: "#8B909A", fontWeight: "500" }}>
              INCOMING INSPECTION
            </TableCell>
            <TableCell sx={{ width: 150, color: "#8B909A", fontWeight: "500" }}>
              TEST CERTIFICATE
            </TableCell>
            {departmentName === "Store" ? (
              <TableCell
                sx={{ width: 150, color: "#8B909A", fontWeight: "500" }}
              >
                ACTION
              </TableCell>
            ) : null}{" "}
          </TableRow>
        </TableHead>
        <TableBody>
          {grnData.map((row) => (
            <Row key={row.id} row={row} departmentName={departmentName} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
