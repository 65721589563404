import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import CircularSize from "../Purchase/SettingPage/ItemMaster/Loader";

function DashboardDeductionsModal({ show, setShow, data, loading }) {
  const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="p-0 fs-4 m-0">Deductions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped>
          <thead>
            <tr>
              <th colSpan={2}>Deduction</th>
              <th>Amount</th>
            </tr>
          </thead>

          {loading ? (
            <tbody>
              {" "}
              <td colSpan={2}>
                <div className="text-align-center">{<CircularSize />} </div>
              </td>
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={2}>Liquidity Damage</td>
                <td className="text-danger">{data?.liquidity_damage}</td>
              </tr>
              <tr>
                <td colSpan={2}>Penalty</td>
                <td className="text-danger">{data?.penalty}</td>
              </tr>
              <tr>
                <td colSpan={2}>Primary Cess Surcharge</td>
                <td className="text-danger">{data?.primary_cess_surcharge}</td>
              </tr>
              <tr>
                <td colSpan={2}>TDS</td>
                <td className="text-danger">{data?.tds}</td>
              </tr>
              <tr>
                <td colSpan={2}>GST</td>
                <td className="text-danger">{data?.gst}</td>
              </tr>
              <tr>
                <td colSpan={2}>Other Deductions</td>
                <td className="text-danger">{data?.other_deduction}</td>
              </tr>
            </tbody>
          )}
        </Table>{" "}
      </Modal.Body>
    </Modal>
  );
}

export default DashboardDeductionsModal;
