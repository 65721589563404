import React from "react";
import DateRangePickerLatest from "../commonComponents/DateRangePicker";

function RequisitionFilter({ handleFilterChange }) {
  return (
    <div className="col-md-auto align-items-center ms-atuo">
      <div className="row">
        <div className="col-md-6">
          <DateRangePickerLatest handleChange={handleFilterChange} />
        </div>
        <div className="col-md-6">
          <input
            className="form-control border-secondary "
            placeholder="search By Railway No. and Store"
            onChange={(e) => handleFilterChange("search", e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

export default RequisitionFilter;
