import React, { useEffect, useState } from "react";
import DeliveryScheduleFilters from "./DeliveryScheduleFilters";
import DeliveryScheduleTable from "./DeliveryScheduleTable";
import { getDeliveryScheduleDetailApi } from "../../purchaseApi";
import RowsPagination from "../../../common/RowsPagination";
import SimpleBackdrop from "../../../StoreDepartment/commonComponents/ShowBackDrop";
import { errorsMessage } from "../../../constant";
import { toast } from "react-toastify";

function DeliveryScheduleList() {
  const [deliveryScheduleListData, setDeliveryScheduleListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    search_by: "railway",
    startDate: "",
    endDate: "",
    search: "",
    search_by: "",
  });
  const [searchDelay, setSearchDelay] = useState(null);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [initialRender, setInitialRender] = useState(true);
  const [page, setPage] = React.useState(1); // 1-based page
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalRecords, setTotalRecords] = useState("");

  const deliveryScheduleListApi = async () => {
    setLoading(true);
    try {
      const res = await getDeliveryScheduleDetailApi({
        ...filters,
        user_id,
        page,
        per_page: rowsPerPage,
      });
      setDeliveryScheduleListData(
        res?.data?.response?.deliverySchedule?.deliverySchedule
      );

      setTotalRecords(
        res?.data?.response?.deliverySchedule?.deliverySchedule?.total
      );
    } catch (error) {
      if (!error?.response) {
        toast.error("Please Check your internet");
      }
      if (error?.response?.status) {
        errorsMessage(error?.response?.status);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (e) => {
    const { value, name } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateFilterChange = (name, value) => {
    if (name === "dateFilter") {
      setFilters({
        ...filters,
        startDate: value[0],
        endDate: value[1],
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    if (!initialRender) {
      if (searchDelay) clearTimeout(searchDelay);
      const timeout = setTimeout(() => {
        deliveryScheduleListApi();
      }, 500);
      setSearchDelay(timeout);
      return () => clearTimeout(timeout);
    } else {
      setInitialRender(false);
      deliveryScheduleListApi();
    }
  }, [filters, page, rowsPerPage]);
  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <SimpleBackdrop open={loading} />
          <DeliveryScheduleFilters
            filters={filters}
            handleFilterChange={handleFilterChange}
            handleDateFilterChange={handleDateFilterChange}
          />
          <div className="delivery-schedule-table-container">
            <div className="card">
              <div className="card-body">
                <DeliveryScheduleTable
                  data={deliveryScheduleListData}
                  page={page}
                  rowsPerPage={rowsPerPage}
                />
                <div className="mb-2">
                  <RowsPagination
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    totalRecords={totalRecords}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliveryScheduleList;
