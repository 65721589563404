import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function VendorVerifingLoader({ open }) {
  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column", // Stack loader and message vertically
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
      >
        <CircularProgress color="inherit" />
        <p style={{ marginTop: "10px", fontSize: "1.2rem" }}>
          Verifying vendor details, please wait...
        </p>
      </Backdrop>
    </div>
  );
}
