import React from "react";
import TdcDetailPreview from "./Preview/TdcDetailPreview";
import EmdDetailPreview from "./Preview/EmdDetailPreview";
import SdDetailPreview from "./Preview/SdDetailPreview";
import PgDetailPreview from "./Preview/PgDetailPreview";

function Preview({
  tdcData,
  emdData,
  sdData,
  pgData,
  renderState,
  setRenderState,
  billing,
}) {
  return (
    <div className="my-3">
      <h4 style={{ color: "#303e67" }}>EMD/SD/PG PAID DETAILS</h4>
      <div
        className="my-3"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(15, 12rem)",
          gridGap: "1rem",
          overflowY: "hidden",
          overflowX: "scroll",
          padding: "10px",
        }}
      >
        <TdcDetailPreview
          tdcData={tdcData}
          renderState={renderState}
          setRenderState={setRenderState}
        />
        <EmdDetailPreview
          emdData={emdData}
          renderState={renderState}
          setRenderState={setRenderState}
        />
        <PgDetailPreview
          pgData={pgData}
          renderState={renderState}
          setRenderState={setRenderState}
        />
        <SdDetailPreview
          sdData={sdData}
          renderState={renderState}
          setRenderState={setRenderState}
          billing={billing}
        />
      </div>
    </div>
  );
}

export default Preview;
