import React from "react";
function VendorMaster({
  handleChange,
  values,
  errors,
  touched,
  serverSideValidation,
  tabKey,
  date,
  railwayNo,
  currentDate,
}) {
  return (
    <div>
      <div className="row">
        {tabKey !== "Generate PO" && tabKey !== "Non-Tender" ? (
          <div className="col-md-3  mt-3">
            <label htmlFor="" className="form-label fw-bold font-16">
              Purchase Order No.{" "}
            </label>
            <input
              className="form-control border-secondary"
              placeholder="Enter Purchase Order No."
              onChange={handleChange}
              value={values?.purchase_order_no}
              name="purchase_order_no"
              disabled
            />
            <p className="text-danger m-0">
              {touched.purchase_order_no && errors.purchase_order_no
                ? errors.purchase_order_no
                : serverSideValidation?.purchase_order_no}
            </p>
          </div>
        ) : null}

        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Order Date<span className="text-danger">*</span>
          </label>
          <input
            className="form-control border-secondary"
            type="date"
            value={currentDate}
            name="order_date"
            readOnly
          />
          <p className="text-danger m-0">
            {touched.order_date && errors.order_date
              ? errors.order_date
              : serverSideValidation?.order_date}
          </p>
        </div>

        {tabKey !== "Non-Tender" && (
          <>
            <div className="col-md-3 mt-3">
              <label htmlFor="" className="form-label fw-bold font-16">
                Inspection Agency
              </label>
              <select
                className="form-control border-secondary"
                onChange={handleChange}
                value={values?.inspection_agency}
                name="inspection_agency"
              >
                <option value={""}>Select</option>
                <option value={"M/SRites"}>M/S Rites </option>
                <option value={"Consignee"}>Consignee </option>
                <option value={"M/SIntertek"}>M/S Intertek </option>
                <option value={"BureauVeritas"}>Bureau Veritas </option>
                <option value={"M/STUV"}>M/S TUV </option>
              </select>
              <p className="text-danger m-0">
                {touched.inspection_agency && errors.inspection_agency
                  ? errors.inspection_agency
                  : serverSideValidation?.inspection_agency}
              </p>
            </div>
            <div className="col-md-3 mt-3">
              <label htmlFor="" className="form-label fw-bold font-16">
                Railway PO No
              </label>
              <input
                className="form-control border-secondary"
                placeholder="Enter Railway No."
                value={railwayNo}
                name="railway_po_no"
                readOnly
              />
              <p className="text-danger m-0">
                {touched.railway_po_no && errors.railway_po_no
                  ? errors.railway_po_no
                  : serverSideValidation?.railway_po_no}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default VendorMaster;
