import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { CircularProgress, Select, MenuItem, FormControl } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

export default function EmailSendConfirmationModal({
  open,
  setOpen,
  handleSubmitFormData,
  modalButtonLoader,
  values,
  handleChange,
  type,
  approverName,
  approvers,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{
          borderRadius: "15px",
          "& .MuiPaper-root": {
            padding: ".9rem 1.2rem .9rem 1.2rem",
            borderRadius: "15px",
          },
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            fontWeight: "600",
            color: "black",
            fontSize: "2rem",
            textAlign: "left",
          }}
        >
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className="text-left"
            sx={{
              color: "black",
              fontSize: "1rem",
              textAlign: "left",
            }}
          >
            You want to send the following Purchase <br />
            Order for approval?
          </DialogContentText>
          <div className="d-flex align-items-center purchaseEmailVerificationModal mt-3 mb-3">
            <p className=" m-0" style={{ fontSize: "16px" }}>
              Approver :{" "}
            </p>
            {type === "tender" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <PersonIcon style={{ color: "red", marginRight: "5px" }} />
                <strong className="text-primary">{approverName}</strong>
              </div>
            ) : (
              <FormControl style={{ width: "auto" }}>
                <Select
                  value={
                    values?.non_tender_approver || approvers[0]?.email || ""
                  }
                  onChange={handleChange}
                  name="non_tender_approver"
                  displayEmpty
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                    color: "black",
                    border: "none",
                  }}
                  renderValue={(selected) => {
                    const selectedApprover = approvers.find(
                      (a) => a.email === selected
                    );
                    return selectedApprover ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <PersonIcon
                          style={{ color: "red", marginRight: "10px" }}
                        />
                        <strong className="text-primary">
                          {selectedApprover.name}
                        </strong>
                      </div>
                    ) : (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <PersonIcon
                          style={{ color: "grey", marginRight: "10px" }}
                        />
                        <strong className="text-muted">No one selected</strong>
                      </div>
                    );
                  }}
                >
                  {approvers.length > 0 ? (
                    approvers.map((approver, index) => (
                      <MenuItem key={index} value={approver?.email}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <PersonIcon
                            style={{ color: "red", marginRight: "10px" }}
                          />
                          {approver?.name}
                        </div>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <PersonIcon
                          style={{ color: "grey", marginRight: "10px" }}
                        />
                        Please make someone purchase manager
                      </div>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
          </div>
          <textarea
            placeholder="Enter your remarks here..."
            rows="4"
            maxLength="250"
            style={{
              width: "30rem",
              background: "#F1F1F1",
              border: "1px solid #F1F1F1",
              borderRadius: "5px",
              padding: "10px",
            }}
            name="email_remarks"
            onChange={handleChange}
            value={values?.email_remarks}
          />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "left",
            padding: "0 2rem 0 1.6rem",
          }}
        >
          <button
            onClick={handleClose}
            className="btn"
            style={{
              border: "1.1px solid black",
              borderRadius: ".25rem",
              width: "7rem",
            }}
            disabled={modalButtonLoader}
          >
            <p
              style={{
                color: "black",
                fontSize: ".95rem",
                fontWeight: "600",
              }}
              className="mb-0"
            >
              Cancel
            </p>
          </button>
          <button
            type="button"
            onClick={() => handleSubmitFormData("approval")}
            className="btn btn-primary"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              padding: "10px 20px",
              width: "7rem",
            }}
            disabled={modalButtonLoader}
          >
            {!modalButtonLoader ? (
              "Send"
            ) : (
              <CircularProgress
                size={20}
                sx={{ color: "white", position: "absolute" }}
              />
            )}
          </button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
