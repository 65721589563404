import { useFormik } from "formik";
import { useState } from "react";
import axios from "axios";
import GoodReceiptForm from "./GoodReceiptForm";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GoodReceiptSchema,
  UpdateGoodReceiptSchemaPurchaseOrder,
} from "../../schemas/StoreSchema/GoodReceiptSchema";
import { errorsMessage } from "../../constant";
import { updateGrn } from "../commonComponents/storeApi";

const EditGoodReceiptNote = () => {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [validationError, setValidationError] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  const initialValuePurchaseOrder = {
    grn_id: state?.data?.id,
    po_id: state?.data?.po_id,
    invoice_date: state?.data?.invoice_date,
    invoice_no: state?.data?.invoice_no,
    invoice: state?.data?.invoice,
    incoming_inspection: state?.data?.incoming_inspection,
    supplier_test_certificate: state?.data?.supplier_test_certificate,
    comments: state?.data?.comments,
    store_name: state?.data?.grn_data?.store_name,
    railway_po_no: state?.data?.grn_data?.railway_po_no,
    prepared_by: state?.data?.grn_data?.prepared_by,
    party_name: state?.data?.grn_data?.supplier,
    grnItems: state?.data?.item_details,
    type: state?.data?.type,
    receiving_date: state?.data?.receiving_date,
    received_by: state?.data?.received_by,
    grn_receive_type: state?.data?.grn_receive_type,
    invoice_amount: state?.data?.invoice_amount,
  };

  const initialValueDelivery = {
    grn_id: state?.data?.id,
    delivery_challan_id: state?.data?.delivery_challan_id,
    incoming_inspection: state?.data?.incoming_inspection,
    supplier_test_certificate: state?.data?.supplier_test_certificate,
    comments: state?.data?.comments,
    dispatch_store_name: state?.data?.grn_data?.store_name,
    delivered_to_store_name: state?.data?.grn_data?.supplier,
    railway_po_no: state?.data?.grn_data?.railway_po_no,
    prepared_by: state?.data?.grn_data?.prepared_by,
    deliveryChallan_Date: state?.data?.grn_data?.created_at,
    grnItems: state?.data?.item_details,
    type: state?.data?.type,
    receiving_date: state?.data?.receiving_date,
    received_by: state?.data?.received_by,
    grn_receive_type: state?.data?.grn_receive_type,
  };

  const {
    values,
    handleSubmit,
    handleChange,
    resetForm,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    setValues,
  } = useFormik({
    initialValues:
      state?.data?.type === "AgainstPurchaseOrder"
        ? initialValuePurchaseOrder
        : initialValueDelivery,
    validationSchema:
      state?.data?.type === "AgainstPurchaseOrder"
        ? UpdateGoodReceiptSchemaPurchaseOrder
        : null,
    onSubmit: (values) => {
      updateGrnDetail(values);
    },
  });

  function updateGrnDetail(values) {
    const data = {
      user_id,
      ...values,
    };
    setSpinner(true);
    updateGrn(data)
      .then((response) => {
        if (response.status === 200) {
          toast.success("GRN Updated successfully");
          navigate(-1);
        }
      })
      .catch((error) => {
        errorsMessage(error?.response?.status);
        if (error?.response?.data?.response) {
          setValidationError(error.response.data.response);
        }
      })
      .finally(() => {
        setSpinner(false);
      });
  }

  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card form_goodsnote">
                <div className="card-body">
                  <div className="page-title-box d-flex justify-content-between align-items-center">
                    <h3> Update Goods Receipt Note</h3>
                  </div>

                  <GoodReceiptForm
                    handleChange={handleChange}
                    values={values}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    mode="edit"
                    setFieldValue={setFieldValue}
                    validationError={validationError}
                    spinner={spinner}
                    state={state}
                    setValues={setValues}
                    grnType={state?.data?.type}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditGoodReceiptNote;
