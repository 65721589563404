import React from "react";

function StatusText({ children }) {
  let textColorClass = "";

  if (children === "Delayed") textColorClass = "DeliveyTableStatusRed";
  else if (children === "Early") textColorClass = "DeliveyTableStatus";
  else if (children === "Scheduled") textColorClass = "DeliveyTableStatusBlue";
  else if (children === "On Time") textColorClass = "DeliveyTableStatusGreen";

  return (
    <td className={textColorClass}>
      <p className="rounded p-2" style={{width: "1rem", height: "1rem", color: "#DC3545"}}></p>
      {children}
    </td>
  );
}

export default StatusText;
