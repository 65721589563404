import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { BsFillKanbanFill } from "react-icons/bs";
import { FaProjectDiagram } from "react-icons/fa";
import { GiReturnArrow } from "react-icons/gi";
import { FaTrashRestore } from "react-icons/fa";

function TenderAsidebar() {
  const { user } = useSelector((state) => ({ ...state.auth }));
  const imageBaseUrl = process.env.REACT_APP_BASE_URL;
  const profileimage = user?.response?.userDetails?.profileimage;
  const userName = user?.response?.userDetails?.name;
  const email = user?.response?.userDetails?.email;
  const logo = process.env.REACT_APP_LOGO_URL;

  return (
    <>
      <div className="left-sidebar">
        <div className="brand">
          <Link to="/dashboard" className="logo">
            <span>
              <img
                src={logo}
                alt="logo-large"
                className="logo-lg logo-light"
                style={{ width: "185px" , height: "60px" , marginTop : "16px" }}
              />
         
            </span>
          </Link>
        </div>
        <div className="sidebar-user-pro media border-end">
          <div className="position-relative mx-auto">
            <img
              src={imageBaseUrl + profileimage}
              alt="user"
              className="rounded-circle thumb-md"
            />
          </div>
          <div className="media-body ms-2 user-detail align-self-center">
            <h5 className="font-14 m-0 fw-bold">{userName} </h5>
            <p
              className="opacity-50 mb-0"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "78%",
              }}
            >
              {email}
            </p>
          </div>
        </div>
        <div className="border-end">
        
        </div>
        <div className="border-end">
          <ul className="nav nav-tabs menu-tab nav-justified" role="tablist">
            <li className="nav-item">
              <Link
                className="nav-link active"
                data-bs-toggle="tab"
                to="#Main"
                role="tab"
                aria-selected="true"
              >
                Main
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                data-bs-toggle="tab"
                to="#Extra"
                role="tab"
                aria-selected="false"
              >
                Admin
              </Link>
            </li>
          </ul>
        </div>

        <div className="menu-content h-100" data-simplebar>
          <div className="menu-body navbar-vertical">
            <div
              className="collapse navbar-collapse tab-content"
              id="sidebarCollapse"
            >
              <ul
                className="navbar-nav tab-pane active"
                id="Main"
                role="tabpanel"
              >
                <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                  }}
                >
                  <NavLink className="inactive-link" to="/dashboard">
                    <i className="fas fa-home"></i> &nbsp;&nbsp; Dashboard
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                  }}
                >
                  <NavLink className="inactive-link" to="/tenders">
                    <FaProjectDiagram></FaProjectDiagram> &nbsp;&nbsp; Tender
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                  }}
                >
                  <NavLink className="inactive-link" to="/emd-return-tracker">
                    <GiReturnArrow></GiReturnArrow> &nbsp;&nbsp; EMD Return
                    Tracker
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                  }}
                >
                  <NavLink className="inactive-link" to="/emd-trash-bin">
                    <FaTrashRestore></FaTrashRestore> &nbsp;&nbsp; Trash Bin
                  </NavLink>
                </li>

                <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                  }}
                >
                  <NavLink className="inactive-link" to="/tender-kanban">
                    <BsFillKanbanFill></BsFillKanbanFill> &nbsp;&nbsp; Kanban
                    Board
                  </NavLink>
                </li>
              </ul>

              <ul
                className="navbar-nav tab-pane"
                id="Extra"
                role="tabpanel"
              >
                <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                  }}
                >
                  <NavLink className="inactive-link" to="/railway-zone">
                    {/* <i className="fas fa-home"></i> */}
                     {/* &nbsp;&nbsp; */}
                      RailwayZone
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                  }}
                >
                  <NavLink className="inactive-link" to="/subdivison">
                    {/* <FaProjectDiagram></FaProjectDiagram> &nbsp;&nbsp; */}
                     Sub Divison
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                  }}
                >
                  <NavLink className="inactive-link" to="/unit">
                    {/* <GiReturnArrow></GiReturnArrow> &nbsp;&nbsp; */}
                     Unit 
                  </NavLink>
                </li>
              </ul>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TenderAsidebar;
