import React from "react";
import { amountFormatJS } from "../../../../../common/amountFormat";
import { Link } from "react-router-dom";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

function BillingListTable({ data }) {
  return (
    <div>
      <table className="finacialTableHeader">
        <thead>
          <tr
            style={{ background: "#E7F2F9", border: "1px solid #E7F2F9" }}
            className="communication-table-detail-header"
          >
            <th style={{ width: "13.5rem" }}>Project ID/Railway PO</th>
            <th>DP</th>
            <th>Project Value</th>
            <th>Billed Value</th>
            <th>Received</th>
            <th>Deduction</th>
            <th>Unbilled Value</th>
            <th>SD</th>
            <th>PG</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((item) => (
              <tr
                key={item.id}
                className="communication-table-detail"
                style={{ borderLeft: "none", borderRight: "none" }}
              >
                <td>
                  <p
                    className=" fw-6 "
                    style={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    {item.project_name ? item.project_name : "- - -"}
                  </p>
                  <p style={{ color: "black" }}>
                    {item.raiway_po_number ? item.raiway_po_number : "- - -"}
                  </p>
                </td>
                <td>{item.dp ? item.dp : "- - -"}</td>
                <td className="text-danger">
                  {item.project_value
                    ? `₹${amountFormatJS(item.project_value)}`
                    : "- - -"}
                </td>
                <td>
                  {item.billed_value
                    ? `₹${amountFormatJS(item.billed_value)}`
                    : "- - -"}
                </td>
                <td className="text-success">
                  {item.received_value
                    ? `₹${amountFormatJS(item.received_value)}`
                    : "- - -"}
                </td>
                <td>
                  {item.deducted_value
                    ? `₹${amountFormatJS(item.deducted_value)}`
                    : "- - -"}
                </td>
                <td>
                  {item.unbilled_value
                    ? `₹${amountFormatJS(item.unbilled_value)}`
                    : "- - -"}
                </td>
                <td>
                  {item.sd_value
                    ? `₹${amountFormatJS(item.sd_value)}`
                    : "- - -"}
                </td>
                <td>
                  {item.pg_value
                    ? `₹${amountFormatJS(item.pg_value)}`
                    : "- - -"}
                </td>
                <td>
                  <Link
                    style={{ color: "#6143D7" }}
                    className="pe-3"
                    to={`/projects/projectdetail/${item?.project_id}`}
                  >
                    View <MdKeyboardDoubleArrowRight />
                  </Link>{" "}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="11" className="text-center">
                No Records Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default BillingListTable;
