import React, { useState } from "react";
import { AiOutlineMore } from "react-icons/ai";
import GurantorIcons from "./GurantorIcons";
import { amountFormatJS } from "../../../../../common/amountFormat";
import { Link } from "react-router-dom";

function BankAccountCards({ data, index, modalShowFunc }) {
  const bankCard = {
    backgroundColor:
      index === 1 ? "#F4F7FF" : index === 2 ? "#FFEEF5" : "#FFF9ED",
    padding: "20px",
    borderRadius: "8px",
    color: "#000",
    fontWeight: "bold",
    textAlign: "center",
  };
  return (
    <div style={bankCard}>
      <div className="row">
        <div className="col-md-10">
          <div className="d-flex gap-2 align-items-center">
            <div>
              <GurantorIcons />
            </div>
            <div>
              <h4
                className="text-dark m-0 text-start"
                style={{ fontWeight: "600", fontSize: "1.1rem" }}
              >
                {data?.bank_name}
              </h4>
              <p className="text-dark m-0 text-start">{data?.branch_name}</p>
            </div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="text-end ">
            <Link
              to=""
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <AiOutlineMore fontSize={30} className="cursor-pointer" />
            </Link>
            <ul className="dropdown-menu " style={{ marginLeft: "-6rem" }}>
              <li>
                <p
                  className="dropdown-item m-0 cursor-pointer"
                  onClick={() => modalShowFunc(data, true)}
                >
                  Edit
                </p>
              </li>
              {/* <li>
                <p className="dropdown-item m-0 cursor-pointer">Delete</p>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-2">
        <p className="mb-2">Account Number</p>
        <p className="text-dark mb-2" style={{ fontWeight: "500" }}>
          {data?.account_number}
        </p>
      </div>
      <div className="d-flex justify-content-between">
        <p className="mb-2">Credit Limit</p>
        <p className="text-dark mb-2" style={{ fontWeight: "500" }}>
          ₹{amountFormatJS(data?.credit_limit)}
        </p>
      </div>{" "}
      <div className="d-flex justify-content-between">
        <p className="mb-2">Used Balance</p>
        <p className="text-dark mb-2" style={{ fontWeight: "500" }}>
          ₹ {data?.used_balance ? amountFormatJS(data?.used_balance) : 0}
        </p>
      </div>
      <hr style={{ border: "1px solid #CCD5EC" }} />
      <div className="d-flex justify-content-between ">
        <p className="fs-5 mt-1 mb-1">Available Balance</p>
        <p className="text-dark fs-5 mt-1 mb-1" style={{ fontWeight: "600" }}>
          ₹{amountFormatJS(data?.available_balance)}
        </p>
      </div>
    </div>
  );
}

export default BankAccountCards;
