import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { IoOpenOutline } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import StoreDetailPageCard from "./StoreDetailPageCard";
import { useState, useEffect } from "react";
import BasicPagination from "../commonComponents/Pagination";
import { getStoreDetailApi } from "../commonComponents/storeApi";
import { errorsMessage } from "../../constant";
import SimpleBackdrop from "../commonComponents/ShowBackDrop";
const StoreDetailPage = () => {
  const [loading, setLoading] = useState(false);

  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [storeData, setStoreData] = useState([]);
  const [itemDetail, setItemDetail] = useState([]);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const { id } = useParams();
  function handlePaginateChange(event, value) {
    setPage(value);
  }

  function storeDetail() {
    setLoading(true);
    getStoreDetailApi({ user_id, store_id: id,page:page })
      .then((res) => {
        setStoreData(res?.data?.response?.storeDetails);
        setItemDetail(res?.data?.response?.itemDetails?.data);
        setPageCount(res?.data?.response?.itemDetails?.last_page);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  useEffect(() => {
    storeDetail();
  }, [page]);
  function handlePaginateChange(event, value) {
    setPage(value);
  }
  return (
    <>
      <div className="page-wrapper mt-3">
        <div className="page-content-tab responsive-tab">
          <div className="container-fluid">
            <h3 className="fs-3 mb-3" style={{ fontWeight: "500" }}>
              STORE DETAIL
            </h3>
            <StoreDetailPageCard storeData={storeData} />
            <TableContainer
              component={Paper}
              sx={{
                maxHeight: "83vh",
                boxShadow: "none",
                background: "#FCFCFC",
              }}
            >
              <Table
                stickyHeader
                aria-label="inventory table"
                sx={{
                  borderCollapse: "collapse",
                  background: "#FCFCFC",
                  border: "1px solid #F1F1F1",
                  overflow: "auto",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "5rem", border: "none" }}>
                      S.No.
                    </TableCell>
                    <TableCell style={{ width: "15rem", border: "none" }}>
                      Category
                    </TableCell>
                    <TableCell style={{ width: "30rem", border: "none" }}>
                      Item Name
                    </TableCell>
                    <TableCell style={{ width: "10rem", border: "none" }}>
                      Unit
                    </TableCell>
                    <TableCell style={{ width: "10rem", border: "none" }}>
                      Total In
                    </TableCell>
                    <TableCell style={{ width: "10rem", border: "none" }}>
                      Total Out
                    </TableCell>
                    <TableCell style={{ width: "10rem", border: "none" }}>
                      Balance
                    </TableCell>
                    <TableCell style={{ border: "none", width: "10rem" }}>
                      Logs
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemDetail?.map((row, index) => (
                    <TableRow
                      key={index}
                      style={{
                        border: "none",
                        marginBottom: "3rem",
                        background: "#FCFCFC",
                      }}
                    >
                      <TableCell
                        sx={{
                          borderRight: "none",
                          borderLeft: "none",
                          height: "2rem",
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "none", borderLeft: "none" }}
                      >
                        {row?.category ? row?.category : "- - -"}
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "none", borderLeft: "none" }}
                      >
                        {row?.product_name ? row?.product_name : "---"}
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "none", borderLeft: "none" }}
                      >
                        {row?.unit ? row?.unit : "- - -"}
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "none", borderLeft: "none" }}
                      >
                        {row.totalIn}
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "none", borderLeft: "none" }}
                      >
                        {row?.total_Out ? row?.total_Out : "---"}
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "none", borderLeft: "none" }}
                      >
                        {row?.balance ? row?.balance : "--"}
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "none", borderLeft: "none" }}
                      >
                        <Link
                          to="/recent-logs"
                          state={{
                            item_id: row?.item_id,
                            store_id: id,
                            name: row?.product_name,
                          }}
                        >
                          View Logs{" "}
                          <IoOpenOutline size="0.9375rem" color="#212121" />
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <SimpleBackdrop open={loading} />
            <div className="text-end mt-2">
              <BasicPagination
                handlePaginateChange={handlePaginateChange}
                page={page}
                pageCount={pageCount}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoreDetailPage;
