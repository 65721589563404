// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDlDxor9uus38cf4Dp3XJk_k4yYHQx1TLw",
  authDomain: "unnati-dc838.firebaseapp.com",
  projectId: "unnati-dc838",
  storageBucket: "unnati-dc838.firebasestorage.app",
  messagingSenderId: "697536745547",
  appId: "1:697536745547:web:cbdd3557d0ee48f7d3fdce"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
const analytics = getAnalytics(app);
