import React, { useEffect, useState } from "react";
import DateRangePickerLatest from "./CommunicationDateRangePicker";
import CommunicationTrackerTable from "./CommunicationTrackerTable";
import AddCommunicationTrackerModal from "../Add-EditComponents/AddCommunicationTrackerModal";
import { communicationTrackerListApi } from "../../../../../redux/api";
import { toast } from "react-toastify";
import BasicPagination from "../../../../Common/Pagination";
import { Spinner } from "react-bootstrap";

function CommunicationTrackerList() {
  const [modalShow, setModalShow] = React.useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const [communicationTrackerList, setCommunicationTrackerList] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleChange = () => {
    console.log(":riitk");
  };
  const communicationTrackerListData = async () => {
    setLoading(true);
    try {
      const res = await communicationTrackerListApi({ page });
      setCommunicationTrackerList(res?.data?.response?.data?.data);
      setPageCount(res?.data?.response?.data?.last_page);
      setLoading(true);
    } catch (error) {
      if (!error.response) {
        toast.error("Network error: Please check your internet connection.");
      } else {
        errorsMessage(error.response.status);
      }
    } finally {
      setLoading(false);
    }
  };
  function handlePaginateChange(event, value) {
    setPage(value);
  }

  useEffect(() => {
    communicationTrackerListData();
  }, [page]);
  return (
    <div className="page-wrapper">
      <div className="page-content-tab ">
        <div className="container-fluid">
          <div className="row align-items-center mt-3">
            <div className="col-md-6">
              <h4 className="fw-bold mb-0">Communication Tracker</h4>
            </div>
            <div className="col-md-6 d-flex gap-2 justify-content-end">
              <DateRangePickerLatest handleChange={handleChange} />
              <button
                className="btn btn-primary"
                onClick={() => setModalShow(true)}
              >
                + Add Communication
              </button>
            </div>
            <div>
              {loading ? (
                <div
                  className="d-flex align-items-center justify-content-center pb-5"
                  style={{ height: "70vh" }}
                >
                  <Spinner animation="grow" variant="danger" />
                  <Spinner animation="grow" variant="danger" />
                  <Spinner animation="grow" variant="danger" />
                </div>
              ) : (
                <CommunicationTrackerTable data={communicationTrackerList} />
              )}
              <BasicPagination
                handlePaginateChange={handlePaginateChange}
                page={page}
                pageCount={pageCount}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
      <AddCommunicationTrackerModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        communicationTrackerListData={communicationTrackerListData}
      />
    </div>
  );
}

export default CommunicationTrackerList;
