import React from "react";

function MaterialTrackerTable({ materialListData, page, rowsPerPage }) {
  const tableHeading = [
    "S.NO.",
    "PROJECT ID/RAILWAY PO NO.",
    "PO NO.",
    "ITEM CODE",
    "SUPPLIER",
    "ITEM NAME",
    "QTY. ORDERED",
    "RATE",
    "QTY. RECEIVED",
  ];

  return (
    <table className="material-tracker-table" style={{ border: "none" }}>
      <thead className="material-heading">
        <tr>
          {tableHeading.map((heading, index) => (
            <th key={index}>{heading}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {materialListData?.map((item, index) => (
          <tr key={index}>
            <td>{(page - 1) * rowsPerPage + index + 1}</td>
            <td>
              <p
                className=" fw-6 "
                style={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                {item.project_name}
              </p>
              <p className="text-dark">{item.railway_po_no}</p>
            </td>
            <td>{item.purchase_order_no}</td>
            <td>{item.item_code}</td>
            <td>{item.supplier}</td>
            <td>{item.product_name}</td>
            <td>{item.quantity_order}</td>
            <td>{item.rate}</td>
            <td>{item.quantity_received}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default MaterialTrackerTable;
