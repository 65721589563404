import React, { useEffect, useState } from "react";
import { getpurchaseItemDropdown } from "../../purchaseApi";
import { errorsMessage } from "../../../constant";

function AddItemMaster({
  values,
  handleChange,
  errors,
  touched,
  handleFileChange,
  serverSideValidation,
  subGroupDropdown,
  handleChangeForSuggestion,
}) {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const title = process.env.REACT_APP_TITLE;
  const [itemDropdown, setItemDropdown] = useState("");
  function itemData() {
    // setLoading(true);
    getpurchaseItemDropdown({ user_id })
      .then((res) => {
        setItemDropdown(res?.data?.response?.ItemMasterDropdownList);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        // setLoading(false);
      });
  }

  useEffect(() => {
    itemData();
  }, []);
  return (
    <div>
      <div className="row">
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Category<span className="text-danger">*</span>
          </label>
          <select
            className="form-control border-secondary"
            value={values?.category}
            name="category"
            onChange={handleChangeForSuggestion}
          >
            <option value="">Select </option>
            <option value="Railway">Railway </option>
            <option value="General">General </option>
          </select>
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.category
              : touched.category && errors.category
              ? errors.category
              : null}
          </p>
        </div>
        <div className="col-md-3  mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Drawing No.{" "}
            {values?.category === "Railway" ? (
              <span className="text-danger">*</span>
            ) : null}
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Drawing No."
            name="drawing_no"
            value={values.drawing_no}
            onChange={handleChangeForSuggestion}
          />
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.drawing_no
              : touched.drawing_no && errors.drawing_no
              ? errors.drawing_no
              : null}
          </p>
        </div>
        <div className="col-md-3  mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Revision/Alteration No.
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Rev/Alt No."
            name="rev_alt_no"
            value={values.rev_alt_no}
            onChange={handleChangeForSuggestion}
          />
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.rev_alt_no
              : touched.rev_alt_no && errors.rev_alt_no
              ? errors.rev_alt_no
              : null}
          </p>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Item Type <span className="text-danger">*</span>
          </label>
          <select
            className="form-control border-secondary"
            value={values?.item_type}
            name="item_type"
            onChange={handleChangeForSuggestion}
          >
            <option value={""}>Select </option>
            <option value={"Raw Material"}>Raw Material </option>
            <option value={"Finished"}>Finished </option>
            <option value={"Brought out part"}>Brought out part </option>
            <option value={"Consumable"}>Consumable </option>
          </select>
          <p className="text-danger m-0">
            
            {serverSideValidation
              ? serverSideValidation.item_type
              : touched.item_type && errors.item_type
              ? errors.item_type
              : null}
          </p>
        </div>

        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Main Group<span className="text-danger">*</span>
          </label>
          <select
            className="form-control border-secondary"
            value={values?.main_group}
            name="main_group"
            onChange={handleChangeForSuggestion}
          >
            <option value="">Select </option>
            {itemDropdown?.main_masters?.map((element) => (
              <option value={element.id}>{element.name} </option>
            ))}
          </select>
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.main_group
              : touched.main_group && errors.main_group
              ? errors.main_group
              : null}
          </p>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Sub Group{" "}
          </label>
          <select
            className="form-control border-secondary"
            value={values?.sub_group}
            name="sub_group"
            onChange={handleChangeForSuggestion}
          >
            <option value={""}>Select </option>
            {subGroupDropdown?.map((element) => (
              <option value={element.id}>{element.name} </option>
            ))}
          </select>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Brand{" "}
          </label>
          <select
            className="form-control border-secondary"
            value={values?.brand}
            name="brand"
            onChange={handleChangeForSuggestion}
          >
            <option value="">Select </option>
            {itemDropdown?.brand_masters?.map((element) => (
              <option value={element.id}>{element.brand_name} </option>
            ))}
          </select>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Material{" "}
          </label>
          <select
            className="form-control border-secondary"
            value={values?.material}
            name="material"
            onChange={handleChangeForSuggestion}
          >
            <option value="">Select </option>
            {itemDropdown?.material_masters?.map((element) => (
              <option value={element.id}>{element.material_name} </option>
            ))}
          </select>
        </div>
        <div className="col-md-3  mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Item Name<span className="text-danger">*</span>
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Item Name"
            name="product_name"
            value={values.product_name}
            onChange={handleChangeForSuggestion}
          />
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.product_name
              : touched.product_name && errors.product_name
              ? errors.product_name
              : null}
          </p>
        </div>
        <div className="col-md-3  mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Item PL no.
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter PL No."
            name="item_pl_no"
            value={values.item_pl_no}
            onChange={handleChangeForSuggestion}
          />
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.item_pl_no
              : touched.item_pl_no && errors.item_pl_no
              ? errors.item_pl_no
              : null}
          </p>
        </div>
        <div className="col-md-3  mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Item Specification
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Item Specification"
            value={values?.product_specification}
            name="product_specification"
            onChange={handleChangeForSuggestion}
          />
        </div>
        <div className="col-md-3  mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            HSN Code{""}
          </label>
          <input
            className="form-control border-secondary"
            type="number"
            placeholder="Enter HSN code"
            value={values?.hsn_code}
            name="hsn_code"
            onChange={handleChangeForSuggestion}
          />
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.hsn_code
              : touched.hsn_code && errors.hsn_code
              ? errors.hsn_code
              : null}
          </p>
        </div>
        <div className="col-md-3  mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Item Image
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Order Id"
            type="file"
            name="product_image_url"
            accept="image/*"
            onChange={(e) => handleFileChange(e)}
          />
        </div>

        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Drive Type{" "}
          </label>
          <select
            className="form-control border-secondary"
            value={values?.drive_type}
            name="drive_type"
            onChange={handleChange}
          >
            <option value={""}>Select </option>
            <option value={"Phillips"}>Phillips </option>
            <option value={"Slotted"}>Slotted </option>
            <option value={"Combo"}>Combo </option>
            <option value={"Frearson"}>Frearson </option>
            <option value={"Hex"}>Hex </option>
          </select>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Head Type{" "}
          </label>
          <select
            className="form-control border-secondary"
            value={values?.head_type}
            name="head_type"
            onChange={handleChange}
          >
            <option value={""}>Select </option>
            <option value={"HEX"}>HEX </option>
            <option value={"PAN"}>PAN </option>
            <option value={"FLAT"}>FLAT </option>
            <option value={"CSK"}>CSK </option>
            <option value={"TRUSS"}>TRUSS </option>
          </select>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Unit<span className="text-danger">*</span>
          </label>
          <select
            className="form-control border-secondary"
            value={values?.unit}
            name="unit"
            onChange={handleChange}
          >
            <option value={""}>Select </option>
            {itemDropdown?.unit_masters?.map((element) => (
              <option key={element.id} value={element.id}>
                {element.unit_name}
              </option>
            ))}
          </select>
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.unit
              : touched.unit && errors.unit
              ? errors.unit
              : null}
          </p>
        </div>
        <div className="col-md-3  mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            GST(%){""}
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter GST(%)"
            onChange={handleChange}
            value={values.gst}
            name="gst"
            type="number"
          />
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.gst
              : touched.gst && errors.gst
              ? errors.gst
              : null}
          </p>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Unit Packaging
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Unit Packaging"
            value={values?.unit_packaging}
            name="unit_packaging"
            onChange={handleChange}
          />
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.unit_packaging
              : touched.unit_packaging && errors.unit_packaging
              ? errors.unit_packaging
              : null}
          </p>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Estimated Lead Time (In days) <span className="text-danger">*</span>
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Lead Time"
            value={values?.estimated_lead_time}
            name="estimated_lead_time"
            onChange={handleChange}
            type="number"
          />
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.estimated_lead_time
              : touched.estimated_lead_time && errors.estimated_lead_time
              ? errors.estimated_lead_time
              : null}
          </p>
        </div>
      </div>
    </div>
  );
}

export default AddItemMaster;
