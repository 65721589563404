import React from "react";
import { amountFormatJS } from "../../../../../common/amountFormat";
import { BankHomeSmallIcon } from "./GurantorIcons";

function GurantorTransactionTable({ data }) {
  return (
    <table style={{ border: "none" }}>
      <thead style={{ position: "sticky", top: -1 }}>
        <tr
          style={{ background: "#E7F2F9" }}
          className="communication-table-detail-header"
        >
          <th style={{ width: "14%" }}>Transaction Date</th>
          <th style={{ width: "16%" }}>Project ID/Railway PO</th>
          <th style={{ width: "20%" }}>Bank</th>
          <th style={{ width: "14%" }}>Transaction Type</th>
          <th style={{ width: "12%" }}>Amount</th>
          <th style={{ width: "12%" }}>Expiry Date</th>
          <th style={{ width: "12%" }}>Status</th>
        </tr>
      </thead>

      <tbody>
        {data && data.length > 0 ? (
          data.map((item) => (
            <tr
              key={item.id}
              className="communication-table-detail"
              style={{ borderLeft: "none", borderRight: "none" }}
            >
              <td>{item.transaction_date ? item.transaction_date : "- - -"}</td>
              <td>
                <p
                  className=" fw-6 "
                  style={{
                    color: "black",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {item.project_name ? item.project_name : "- - -"}
                </p>
                <p style={{ color: "black" }}>
                  {item.railway_po_number ? item.railway_po_number : "- - -"}
                </p>
              </td>
              <td>
                {" "}
                {item?.account_number ? (
                  <div className="d-flex align-items-center gap-2">
                    <BankHomeSmallIcon />
                    <div>
                      <p
                        className=" fw-6 "
                        style={{
                          color: "black",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        {item.bank_name ? item.bank_name : "- - -"}
                      </p>
                      <p style={{ color: "black" }}>
                        {" "}
                        {item?.account_number
                          ? `A/C No. ${item?.account_number}`
                          : "- - -  "}
                      </p>
                    </div>
                  </div>
                ) : (
                  "- - -"
                )}
              </td>
              <td>{item.transaction_type ? item.transaction_type : "- - -"}</td>
              <td>
                {item.amount ? `₹${amountFormatJS(item.amount)}` : "- - -"}
              </td>
              <td>{item.dp ? item.dp : "- - -"}</td>
              <td>{item.status ? item.status : "- - -"}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="11" className="text-center">
              No Records Found
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default GurantorTransactionTable;
