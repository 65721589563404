import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { HiDotsVertical } from "react-icons/hi";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

export default function PoListMenu({
  tabKey,
  element,
  projectDetails,
  setCancelModalOpen,
  getlistNotreceivedItemList,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handelModal = (element) => {
    getlistNotreceivedItemList(element?.id);
    setAnchorEl(null);
    setCancelModalOpen(true);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        type="button"
      >
        <HiDotsVertical color="#001E66" fontSize={"25px"} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          {" "}
          {element?.status === "approve" ? (
            <Tooltip
              title="Editing is not allowed for approved purchase orders."
              arrow
            >
              <p
                style={{
                  minWidth: "4rem",
                  maxWidth: "4rem",
                  margin: "auto",
                  padding: "auto",
                  cursor: "not-allowed",
                }}
                className="text-center"
              >
                Edit
              </p>
            </Tooltip>
          ) : tabKey === "Update-Non-Tender" ? (
            <Link
              to={
                tabKey === "Update-Non-Tender"
                  ? "/update-non-tender/purchaseOrder"
                  : "/update-purchase-order"
              }
              state={element}
            >
              <p
                style={{
                  minWidth: "4rem",
                  maxWidth: "4rem",
                  margin: "auto",
                  padding: "auto",
                  cursor: "pointer",
                }}
                className="text-center"
              >
                Edit
              </p>
            </Link>
          ) : (
            <Link
              to={
                tabKey === "Update-Non-Tender"
                  ? "/update-non-tender/purchaseOrder"
                  : "/update-purchase-order"
              }
              state={{
                element: element,
                projectDetails: projectDetails,
              }}
            >
              <p
                style={{
                  minWidth: "6rem",
                  maxWidth: "6rem",
                  margin: "auto",
                  padding: "auto",
                  cursor: "pointer",
                }}
                className="text-center"
              >
                Edit
              </p>
            </Link>
          )}
        </MenuItem>
        <MenuItem onClick={() => handelModal(element)} className="text-center">
          <p
            style={{
              minWidth: "6rem",
              maxWidth: "6rem",
              margin: "auto",
              padding: "auto",
              cursor: "pointer",
            }}
            className="text-center hover-red"
          >
            {" "}
            Cancel Order
          </p>
        </MenuItem>
      </Menu>
    </div>
  );
}
