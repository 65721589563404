import React from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

function stringAvatar(name) {
  if (!name || name.trim() === "") {
    return {
      sx: {
        bgcolor: "white",
        color: "black",
        fontWeight: "bold",
        textTransform: "uppercase",
        width: 56,
        height: 56,
        fontSize: "1.5rem",
      },
      children: "A",
    };
  }

  const words = name.trim().split(" ");
  const firstLetter = words[0][0];
  const secondLetter = words[1]?.[0] || "";

  return {
    sx: {
      bgcolor: "white",
      color: "black",
      fontWeight: "bold",
      textTransform: "uppercase",
      width: 56,
      height: 56,
      fontSize: "1.5rem",
    },
    children: `${firstLetter}${secondLetter}`.toUpperCase(),
  };
}

export default function BackgroundLetterAvatars({ name }) {
  return (
    <Stack direction="row" spacing={2}>
      <Avatar {...stringAvatar(name)} />
    </Stack>
  );
}
