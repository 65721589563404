import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PersonalInfo from "../Components/PersonalInfo";
import Signature from "./Signature";
import DigitalSignatureUploader from "./Signature";

function ProfileAdmin() {
  const imageBaseUrl = process.env.REACT_APP_BASE_URL;

  const [key, setKey] = useState("personalInfo");
  const [userData, setUserData] = useState([]);
  const user = JSON.parse(localStorage.getItem("profile"));
  const department = user?.response?.userDetails?.departmentName;

  function getDetails(detail) {
    setUserData(detail);
  }
  /// testing
  return (
    <>
      <div className="page-wrapper">
        <div
          className={`page-content-tab ${
            department === "Store" || department === "Purchase"
              ? "responsive-tab"
              : ""
          }`}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      {/* <li className="breadcrumb-item">
                        <Link to="#">Unikit</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Pages</Link>
                      </li> */}
                      <li className="breadcrumb-item active">Profile</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Profile</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="met-profile">
                      <div className="row">
                        <div className="col-lg-4 align-self-center mb-3 mb-lg-0">
                          <div className="met-profile-main">
                            <div className="met-profile-main-pic">
                              <img
                                src={imageBaseUrl + userData.profileimage}
                                alt=""
                                height="110"
                                className="rounded-circle"
                                style={{ width: "100px", height: "100pxyyy" }}
                              />
                              {/* <span className="met-profile_main-pic-change">
                                <i className="fas fa-camera"></i>
                              </span> */}
                            </div>
                            <div className="met-profile_user-detail">
                              <h5 className="met-user-name">{userData.name}</h5>
                              <p className="mb-0 met-user-name-post badge badge-soft-danger text-danger">
                                {userData.designationName}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 ms-auto align-self-center">
                          <ul className="list-unstyled personal-detail mb-0">
                            <li className="">
                              <i className="las la-phone mr-2 text-secondary font-22 align-middle"></i>
                              <b> phone </b> : {userData.phone}
                            </li>
                            <li className="mt-2">
                              <i className="las la-envelope text-secondary font-22 align-middle mr-2"></i>{" "}
                              <b>Email </b> : {userData.email}
                            </li>
                            <li className="mt-2">
                              <i className="las la-calendar text-secondary font-22 align-middle mr-2"></i>{" "}
                              <b>Joining Date </b> : {userData.joining_date}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                  >
                    <Tab eventKey="personalInfo" title="Personal Information">
                      <PersonalInfo getDetails={getDetails} />
                    </Tab>
                    <Tab eventKey="signature" title="Signature">
                      <DigitalSignatureUploader userData={userData}/>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ProfileAdmin;
