import React, { useEffect, useState } from "react";
import BillingCards from "./BillingCards";
import BillingListTable from "./BillingListTable";
import BillingTableCard from "./BillingTableCard";
import BillingFilter from "./BillingFilter";
import { getFinacialBillingListApi } from "../../../../../redux/api";
import { errorsMessage } from "../../../../../constant";
import { toast } from "react-toastify";
import BasicPagination from "../../../../Common/Pagination";
import SimpleBackdrop from "../../../../../StoreDepartment/commonComponents/ShowBackDrop";
import convertValue from "../../../../../Purchase/Common/AmountFormat";

function Billing() {
  const [loading, setLoading] = useState(false);
  const [billingData, setBillingData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [initialRender, setInitialRender] = useState(true);

  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    search: "",
  });

  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const cardDetail = [
    {
      fontColor: "#D93A3A",
      amount: billingData?.totalBilledValue
        ? `₹${convertValue(billingData?.totalBilledValue)}`
        : 0,
      text: "Billed Amount",
    },
    {
      fontColor: "#06B48A",
      amount: billingData?.totalPaymentReceived
        ? `₹${convertValue(billingData?.totalPaymentReceived)}`
        : 0,
      text: "Payment received",
    },
    {
      fontColor: "",
      amount: billingData?.dpApprochingThisMonth
        ? `${billingData?.dpApprochingThisMonth}`
        : 0,
      text: "Approaching DP This Month",
    },
  ];
  const BillingList = async () => {
    setLoading(true);
    try {
      const res = await getFinacialBillingListApi({
        ...filters,
        user_id,
        page,
      });

      setBillingData(res?.data?.response);
      setPageCount(res?.data?.response?.untiProjectBilingDetails?.last_page);
      setLoading(true);
    } catch (error) {
      if (!error.response) {
        toast.error("Network error: Please check your internet connection.");
      } else {
        errorsMessage(error.response.status);
      }
    } finally {
      setLoading(false);
    }
  };
  const handleFilterChange = (e) => {
    const { value, name } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleDateFilterChange = (name, value) => {
    if (name === "dateFilter") {
      setFilters({
        ...filters,
        startDate: value[0],
        endDate: value[1],
      });
    }
  };
  function handlePaginateChange(event, value) {
    setPage(value);
  }

  useEffect(() => {
    if (!initialRender) {
      const debounceTimeout = setTimeout(() => {
        BillingList();
      }, 300);

      return () => clearTimeout(debounceTimeout);
    } else {
      setInitialRender(false);
      BillingList();
    }
  }, [filters, page]);

  return (
    <div>
      <SimpleBackdrop open={loading} />
      <div className="row">
        <div className="col-md-8">
          <div className="row">
            {cardDetail?.map((data, index) => (
              <div className="col-md-4" key={index}>
                <BillingCards data={data} index={index} />
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-4"></div>
      </div>
      <div className="mt-3">
        <BillingTableCard>
          <div className="finacialTableHeader">
            <div className="row p-3">
              <div className="col-md-8">
                <p className="fs-4">Project List</p>
              </div>
              <div className="col-md-4">
                <BillingFilter
                  handleFilterChange={handleFilterChange}
                  filters={filters}
                  handleDateFilterChange={handleDateFilterChange}
                />
              </div>
            </div>
            <BillingListTable
              data={billingData?.untiProjectBilingDetails?.data}
            />
          </div>
          <BasicPagination
            handlePaginateChange={handlePaginateChange}
            page={page}
            pageCount={pageCount}
            loading={loading}
          />
        </BillingTableCard>
      </div>
    </div>
  );
}

export default Billing;
