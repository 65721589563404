import React, { useEffect, useRef, useState } from "react";
import CommonTable from "../../Common/CommonTable";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Itemmaster.module.css";
import { getItemMasterList } from "../../purchaseApi";
import CommonTd from "../../../Tender/Common/CommonTd";
import { FaRegEdit } from "react-icons/fa";
import { errorsMessage } from "../../../constant";
import { CiSearch } from "react-icons/ci";
import usePagination from "../../Common/usePurchasePagination";
import { IoIosSearch } from "react-icons/io";
import usePurchaseURLFilters from "../../Common/usePurchaseUrlFilter";
import ItemPriceHistory from "./ItemPriceHistory";

function ItemMasterList() {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [modalShow, setModalShow] = React.useState(false);

  const [search, setSearch] = useState("");
  const [timer, setTimer] = useState(null);
  const navigate = useNavigate();
  const [filters, setFilters] = usePurchaseURLFilters();
  const [itemId, setItemId] = useState("");
 
  const {
    data: itemMasterList,
    loading,
    hasMore,
    loadMore,
    resetPagination,
  } = usePagination(
    getItemMasterList,
    user_id,
    "ItemMasterList",
    filters.search
  );
  const tableBodyRef = useRef(null);
  const tableHeading = [
    {
      name: "S.NO.",
      width: "2rem",
    },
    {
      name: "DRAWING NO.",
      width: "10rem",
    },
    {
      name: "REV. ALT. NO.",
      width: "10rem",
    },
    {
      name: "ITEM NAME",
      width: "15rem",
    },
    {
      name: "ITEM SPECIFICATION",
      width: "15rem",
    },
    {
      name: "CATEGORY",
      width: "10rem",
    },
    {
      name: "ITEM TYPE ",
      width: "10rem",
    },
    {
      name: "UNIT ",
      width: "10rem",
    },
    {
      name: "EST. LEAD TIME",
      width: "10rem",
    },
    {
      name: "PRICE HISTORY",
      width: "6rem",
    },
    {
      name: "Edit",
      width: "3rem",
    },
  ];

  function editData(element) {
    navigate("/editItemMaster", { state: { editData: element } });
  }
  const handleScroll = () => {
    const tableBody = tableBodyRef.current;
    if (
      tableBody.scrollTop + tableBody.clientHeight >=
        tableBody.scrollHeight - 1 &&
      hasMore &&
      !loading
    ) {
      loadMore();
    }
  };

  useEffect(() => {
    const tableBody = tableBodyRef.current;
    if (tableBody) {
      tableBody.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, hasMore]);

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    const delay = setTimeout(() => {
      const newFilters = {
        ...filters,
        search: search,
      };
      setFilters(newFilters);
      navigate(`?search=${newFilters.search}`);
    }, 1000);

    setTimer(delay);

    return () => clearTimeout(delay);
  }, [search]);
  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="row mt-3">
            <div className="col-sm-6">
              <h4 className="page-title">ITEM MASTER</h4>
            </div>
            <div className="col-sm-4 text-end">
              <div className="postion-relative">
                <input
                  className="form-control w-75 ms-auto"
                  placeholder="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <IoIosSearch
                  size={30}
                  className="position-absolute top-0 end-0 mt-1 me-2"
                  style={{ color: "ef4d56" }}
                />
              </div>
            </div>
            <div className="col-sm-auto text-end">
              <Link to={"/addItemMaster"}>
                <button className="btn btn-danger">Add Item</button>
              </Link>
            </div>
          </div>

          <div className="card">
            <div
              className="card-body "
              style={{ padding: "1rem 0", margin: "0" }}
            >
              <div
                className="table-responsive table-container-scroll"
                style={{ overflowX: "auto" }}
              >
                <table
                  className="table common-table"
                  style={{ tableLayout: "unset" }}
                >
                  <CommonTable tableHeading={tableHeading}></CommonTable>
                  <tbody ref={tableBodyRef}>
                    {itemMasterList?.map((element, index) => (
                      <tr key={index}>
                        <CommonTd width="2rem" value={index + 1}></CommonTd>

                        <CommonTd
                          width="10rem"
                          value={element?.drawing_no}
                        ></CommonTd>
                        <CommonTd
                          width="10rem"
                          value={element?.rev_alt_no}
                        ></CommonTd>
                        <CommonTd
                          width="15rem"
                          value={element?.product_name}
                        ></CommonTd>
                        <CommonTd
                          width="15rem"
                          value={element?.product_specification}
                        ></CommonTd>
                        <CommonTd
                          width="10rem"
                          value={element?.category}
                        ></CommonTd>
                        <CommonTd
                          width="10rem"
                          value={element?.item_type}
                        ></CommonTd>
                        <CommonTd
                          width="10rem"
                          value={element?.unit_name}
                        ></CommonTd>
                        <CommonTd
                          width="10rem"
                          value={element?.estimated_lead_time}
                        ></CommonTd>
                        <td>
                          <p
                            style={{
                              color: "#3300FF",
                              maxWidth: "6rem",
                              minWidth: "6rem",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setModalShow(true);
                              setItemId(element?.id);
                            }}
                          >
                            View
                          </p>
                        </td>
                        <td>
                          <p
                            style={{
                              minWidth: "3rem",
                              maxWidth: "3rem",
                              margin: "auto",
                              padding: "auto",
                              cursor: "pointer",
                            }}
                            className="text-center"
                          >
                            <FaRegEdit
                              className="text-dark font-20"
                              style={{ margin: "auto" }}
                              onClick={() => editData(element)}
                            />
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <ItemPriceHistory
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    itemId={itemId}
                    user_id={user_id}
                  />
                  {loading && (
                    <div className="d-flex align-items-center justify-content-center pb-5">
                      <Spinner animation="grow" variant="danger" />
                      <Spinner animation="grow" variant="danger" />
                      <Spinner animation="grow" variant="danger" />
                    </div>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemMasterList;
