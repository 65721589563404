import React, { useCallback, useEffect, useState } from "react";
import InventoryTable from "./InventoryTable";
import { getInventoryListingApi } from "../commonComponents/storeApi";
import { errorsMessage } from "../../constant";
import { debounce } from "lodash";
import BasicPagination from "../commonComponents/Pagination";
import { Spinner } from "react-bootstrap";
import SimpleBackdrop from "../commonComponents/ShowBackDrop";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import StoreTable from "./StoreTable";
import { useLocation } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";

function Inventory() {
  const location = useLocation();
  const navigate = useNavigate();
 
  const tab = location.state?.tab || "Items";
  const [inventoryData, setInventoryData] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [key, setKey] = useState(tab);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
 
 
  function inventoryList(term, page = 1) {
    setLoading(true);
    getInventoryListingApi({ user_id, search: term, page: page })
      .then((res) => {
        setInventoryData(res?.data?.response?.InventoryItemsList?.data);
        setPageCount(res?.data?.response?.InventoryItemsList?.last_page);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const debouncedFetchInventoryList = useCallback(
    debounce((term, page) => {
      inventoryList(term, page);
    }, 600),
    [user_id]
  );

  useEffect(() => {
    debouncedFetchInventoryList(searchTerm, page);
  }, [searchTerm, debouncedFetchInventoryList, page]);

  function handlePaginateChange(event, value) {
    setPage(value);
  }
const handleTab =(k)=>{
setKey(k)
navigate("/inventoryList", { state: { tab: k } });
}
  return (
    <div className="page-wrapper mt-3">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          {/* <>
         
          
            <InventoryTable
              inventory={inventoryData}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              page={page}
            />
            <SimpleBackdrop open={loading} />
            <div className="text-end mt-2">
              <BasicPagination
                handlePaginateChange={handlePaginateChange}
                page={page}
                pageCount={pageCount}
                loading={loading}
              />
            </div>
          </> */}
          <div className="row">
            <div className="col-md-8">
              <h3 className="fw-500 ">INVENTORY</h3>
            </div>
          </div>

          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => handleTab(k)}
            className="mb-3"
          >
            <Tab eventKey="Items" title="Items">
              <>
                <InventoryTable
                  inventory={inventoryData}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  page={page}
                />
                <SimpleBackdrop open={loading} />
                <div className="text-end mt-2">
                  <BasicPagination
                    handlePaginateChange={handlePaginateChange}
                    page={page}
                    pageCount={pageCount}
                    loading={loading}
                  />
                </div>
              </>
            </Tab>
            <Tab eventKey="Store" title="Store">
              <StoreTable />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default Inventory;
