import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import "../Material/Material.css";
import { errorsMessage } from "../../constant";
import { Spinner } from "react-bootstrap";
import { deliveryChallanListApi } from "../commonComponents/storeApi";
import ChallanListData from "./ChallanListData";
import BasicPagination from "../commonComponents/Pagination";
import { debounce, filter } from "lodash";
import SimpleListMenu from "../commonComponents/DropDownMenu";
import DeliveryChallanFilter from "./DeliveryChallanFilter";

function DeliveryChallanList() {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [loader, setLoader] = useState(true);
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const { state } = useLocation();
  const [filters, setFilters] = useState({
    startDate: state ? state?.startDate : null,
    endDate: state ? state?.endDate : null,
    search: "",
  });

  function deliveryChallanList(term, page) {
    setLoader(true);
    deliveryChallanListApi({ ...term, user_id, page })
      .then((res) => {
        setListData(res?.data?.response?.listDeliveryChallan?.data);
        setPageCount(res?.data?.response?.listDeliveryChallan?.last_page);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  const handleFilterChange = (name, value) => {
    if (name === "dateFilter") {
      setFilters({
        ...filters,
        startDate: value[0],
        endDate: value[1],
      });
    } else {
      setFilters({ ...filters, [name]: value });
    }
  };

  const debouncedFetchDeliveryChallanList = useCallback(
    debounce((term, page) => {
      deliveryChallanList(term, page);
    }, 600),
    [user_id]
  );

  useEffect(() => {
    debouncedFetchDeliveryChallanList(filters, page);
  }, [debouncedFetchDeliveryChallanList, page, filters]);

  function handlePaginateChange(event, value) {
    setPage(value);
  }

  return (
    <div className="card mt-4">
      <div className="card-body tabsall">
        <div className="row">
          <div className="col-md-4">
            <div className="page-title-box">
              <h2 className="page-title"> DELIVERY CHALLAN </h2>
            </div>
          </div>
          <div className="col-md-8 d-flex align-items-center justify-content-end">
            <div className="d-flex">
              <DeliveryChallanFilter
                filters={filter}
                handleFilterChange={handleFilterChange}
              />
              <SimpleListMenu />
            </div>
          </div>
          {loader ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "70vh" }}
            >
              <Spinner animation="grow" variant="danger" />
              <Spinner animation="grow" variant="danger" />
              <Spinner animation="grow" variant="danger" />
            </div>
          ) : (
            <ChallanListData detail={listData} page={page} />
          )}
          <div className="text-end mt-2">
            <BasicPagination
              handlePaginateChange={handlePaginateChange}
              page={page}
              pageCount={pageCount}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliveryChallanList;
