import * as Yup from "yup";

export const projectDetailSchema = Yup.object({
  pd_fileNo: Yup.string().required("This field is required."),
  pd_railwayZone: Yup.string().required("This field is required."),
  pd_subDivision: Yup.string().required("This field is required."),
  pd_workStatus: Yup.string().required("This field is required."),
  tenderId: Yup.string().required("This field is required."),
  pd_tenderDate: Yup.string().required("This field is required."),
  pd_poloaNo: Yup.string().required("This field is required."),
  pd_poloaDate: Yup.string().required("This field is required."),
  pd_totalOrderValue: Yup.string().required("This field is required."),
  pd_poloaDocs: Yup.string().required("This field is required."),
  pd_projectTitle: Yup.string().required("This field is required."),
  pd_projectItemDescription: Yup.string().required("This field is required."),
  pd_InspectionAgency: Yup.string().required("This field is required."),
  pd_totalOrderQty: Yup.string().required("This field is required."),
  pd_projectManager: Yup.string().required("This field is required."),
  pd_purchaseManager: Yup.string().required("This field is required."),
  pd_poloaQuantityMutipleData: Yup.array().of(
    Yup.object().shape({
      poLoaQty: Yup.number().required("This field is required."),
      unit: Yup.string().required("This field is required."),
      startDate: Yup.string().required("This field is required."),
      completionDate: Yup.string()
        .required("This field is required.")
        .when("startDate", (startDate, schema) => {
          return schema.test({
            test: function (completionDate) {
              return (
                new Date(completionDate) >= new Date(startDate) ||
                this.createError({
                  message: "Completion date must be after start date",
                })
              );
            },
          });
        }),
    })
  ),
  pd_projectCategory: Yup.string().required("This field is required."),
  pd_startDate: Yup.string().required("This field is required."),
  pd_endDate: Yup.string()
    .required("This field is required.")
    .when("pd_startDate", (pd_startDate, schema) => {
      return schema.test({
        test: function (pd_endDate) {
          return (
            new Date(pd_endDate) >= new Date(pd_startDate) ||
            this.createError({
              message: "End date must be after start date",
            })
          );
        },
      });
    }),
});
export const nonTenderProjectDetailSchema = Yup.object({
  pd_workStatus: Yup.string().required("This field is required."),
  pd_poloaNo: Yup.string().required("This field is required."),
  pd_poloaDate: Yup.string().required("This field is required."),
  pd_totalOrderValue: Yup.string().required("This field is required."),
  pd_poloaDocs: Yup.string().required("This field is required."),
  pd_projectTitle: Yup.string().required("This field is required."),
  pd_projectItemDescription: Yup.string().required("This field is required."),
  pd_totalOrderQty: Yup.string().required("This field is required."),
  pd_projectManager: Yup.string().required("This field is required."),
  pd_purchaseManager: Yup.string().required("This field is required."),
  pd_customer_name: Yup.string().required("This field is required."),
  pd_poloaQuantityMutipleData: Yup.array().of(
    Yup.object().shape({
      poLoaQty: Yup.number().required("This field is required."),
      unit: Yup.string().required("This field is required."),
      startDate: Yup.string().required("This field is required."),
      completionDate: Yup.string()
        .required("This field is required.")
        .when("startDate", (startDate, schema) => {
          return schema.test({
            test: function (completionDate) {
              return (
                new Date(completionDate) >= new Date(startDate) ||
                this.createError({
                  message: "Completion date must be after start date",
                })
              );
            },
          });
        }),
    })
  ),
  pd_projectCategory: Yup.string().required("This field is required."),
  pd_startDate: Yup.string().required("This field is required."),
  pd_endDate: Yup.string()
    .required("This field is required.")
    .when("pd_startDate", (pd_startDate, schema) => {
      return schema.test({
        test: function (pd_endDate) {
          return (
            new Date(pd_endDate) >= new Date(pd_startDate) ||
            this.createError({
              message: "End date must be after start date",
            })
          );
        },
      });
    }),
});

export const loPoaSchema = Yup.object().shape({
  lcb_paymentTerms: Yup.string().required("This field is required."),
  lcb_contractSigningAuthority: Yup.string().required(
    "This field is required."
  ),
  // lcb_gstQty: Yup.string().required("This field is required."),

  lcb_basicMultipleData: Yup.array().of(
    Yup.object().shape({
      basicPerUnit: Yup.string().required("This field is required."),
      basicTotal: Yup.string().required("This field is required."),
      basicTotalQuantity: Yup.string().required("This field is required."),
    })
  ),
});

//Edit project Schema
export const EditprojectDetailSchema = Yup.object({
  pd_fileNo: Yup.string().required("This field is required."),
  pd_railwayZone: Yup.string().required("This field is required."),
  pd_subDivision: Yup.string().required("This field is required."),
  pd_workStatus: Yup.string().required("This field is required."),
  pd_tenderNo: Yup.string().required("This field is required."),
  pd_tenderDate: Yup.string().required("This field is required."),
  pd_poloaNo: Yup.string().required("This field is required."),
  pd_poloaDate: Yup.string().required("This field is required."),
  pd_totalOrderValue: Yup.string().required("This field is required."),
  pd_poloaDocs: Yup.string().required("This field is required."),
  pd_projectTitle: Yup.string().required("This field is required."),
  pd_projectItemDescription: Yup.string().required("This field is required."),
  pd_InspectionAgency: Yup.string().required("This field is required."),
  pd_totalOrderQty: Yup.string().required("This field is required."),
  pd_projectManager: Yup.string().required("This field is required."),
  pd_purchaseManager: Yup.string().required("This field is required."),
  pd_poloaQuantityMutipleData: Yup.array().of(
    Yup.object().shape({
      poLoaQty: Yup.string().required("This field is required."),
      unit: Yup.string().required("This field is required."),
      startDate: Yup.string().required("This field is required."),
      completionDate: Yup.string()
        .required("This field is required.")
        .when("startDate", (startDate, schema) => {
          return schema.test({
            test: function (completionDate) {
              return (
                new Date(completionDate) >= new Date(startDate) ||
                this.createError({
                  message: "Completion date must be after start date",
                })
              );
            },
          });
        }),
    })
  ),
  pd_projectCategory: Yup.string().required("This field is required."),
  pd_startDate: Yup.string().required("This field is required."),
  pd_endDate: Yup.string()
    .required("This field is required.")
    .when("pd_startDate", (pd_startDate, schema) => {
      return schema.test({
        test: function (pd_endDate) {
          return (
            new Date(pd_endDate) >= new Date(pd_startDate) ||
            this.createError({
              message: "End date must be after start date",
            })
          );
        },
      });
    }),
});

export const EditNonTenderprojectDetailSchema = Yup.object({
  pd_workStatus: Yup.string().required("This field is required."),
  pd_poloaNo: Yup.string().required("This field is required."),
  pd_poloaDate: Yup.string().required("This field is required."),
  pd_totalOrderValue: Yup.string().required("This field is required."),
  pd_poloaDocs: Yup.string().required("This field is required."),
  pd_projectTitle: Yup.string().required("This field is required."),
  pd_projectItemDescription: Yup.string().required("This field is required."),
  pd_totalOrderQty: Yup.string().required("This field is required."),
  pd_projectManager: Yup.string().required("This field is required."),
  pd_purchaseManager: Yup.string().required("This field is required."),
  pd_customer_name: Yup.string().required("This field is required."),
  pd_poloaQuantityMutipleData: Yup.array().of(
    Yup.object().shape({
      poLoaQty: Yup.string().required("This field is required."),
      unit: Yup.string().required("This field is required."),
      startDate: Yup.string().required("This field is required."),
      completionDate: Yup.string()
        .required("This field is required.")
        .when("startDate", (startDate, schema) => {
          return schema.test({
            test: function (completionDate) {
              return (
                new Date(completionDate) >= new Date(startDate) ||
                this.createError({
                  message: "Completion date must be after start date",
                })
              );
            },
          });
        }),
    })
  ),
  pd_projectCategory: Yup.string().required("This field is required."),
  pd_startDate: Yup.string().required("This field is required."),
  pd_endDate: Yup.string()
    .required("This field is required.")
    .when("pd_startDate", (pd_startDate, schema) => {
      return schema.test({
        test: function (pd_endDate) {
          return (
            new Date(pd_endDate) >= new Date(pd_startDate) ||
            this.createError({
              message: "End date must be after start date",
            })
          );
        },
      });
    }),
});
