export function truncateString(text, maxLength) {
  if (!text) return "";
  if (text.length > maxLength) {
    return text?.substring(0, maxLength) + "...";
  }
  return text;
}

export const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};
