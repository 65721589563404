import React from "react";

function StatusButton({ children }) {
  const statusClasses = {
    Approved: "communication-status-button-green",
    Pending: "communication-status-button-yellow",
    Rejected: "communication-status-button-red",
  };

  const statusClass =
    statusClasses[children] || "communication-status-button-blue";

  return <button className={statusClass}>{children}</button>;
}

export default StatusButton;
