import React, { useState } from "react";
import { Pagination } from "react-bootstrap";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { getDeliveryChallanPdf } from "../commonComponents/storeApi";

const ChallanListData = ({ detail, page }) => {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const baseURL = process.env.REACT_APP_BASE_URL;
  function formatNumber(value) {
    if (!value) {
      return false;
    }
    return Number(value).toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    });
  }

  function formatDate(dates) {
    const dateString = dates;
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
      date
    );
    return formattedDate;
  }

  const deliveryChallanPdf = (challanno, challanId) => {
    getDeliveryChallanPdf({ user_id, challan_id: challanId })
      .then((res) => {
        const data = res?.data?.response?.DeliveryChallanPdf?.pdf_content;
        if (data) {
          //  const base64String = data.base64String;
          const binaryString = window.atob(data);
          const binaryLen = binaryString.length;
          const bytes = new Uint8Array(binaryLen);

          for (let i = 0; i < binaryLen; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }
          const blob = new Blob([bytes], { type: "application/pdf" });

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `delivery_challan/${challanno}.pdf`;

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(link.href);
        }
      })
      .catch((err) => {
        console.error("Error fetching PDF:", err);
      })
      .finally(() => {});
  };
  return (
    <>
      <div class="col-md-12">
        <div className="table-responsive" style={{ height: "66vh" }}>
          <table className="table ">
            <thead
              className="material-outward-heading"
              style={{ position: "sticky", top: "-1px" }}
            >
              <tr style={{ border: "0" }}>
                <th scope="col">S.No.</th>
                <th scope="col">Challan no.</th>
                <th scope="col">Date</th>
                <th scope="col" className="col-2">
                  Delivered to
                </th>
                <th scope="col">Delivered from Store</th>
                <th scope="col">Prepared by</th>
                <th scope="col">Total Amount</th>
                <th scope="col">EWAY bill</th>
                <th scope="col">Delivery Challan</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {detail.map((item, index) => (
                <tr key={index} style={{ borderBottom: "1px solid #E0E0E0" }}>
                  <td>{(page - 1) * 10 + index + 1}</td>
                  <td>{item?.challan_no || "- - -"}</td>
                  <td>{formatDate(item?.created_at) || "- - -"}</td>
                  <td>{item?.delivered_to || "- - -"}</td>
                  <td>{item?.delivered_from || "- - -"}</td>
                  <td>{item?.prepared_by || "- - -"}</td>
                  <td>{formatNumber(item?.total_amount_sum) || "- - - "}</td>
                  <td>
                    {" "}
                    {item?.emay_bill ? (
                      <Link to={baseURL + item?.emay_bill} target="_blank">
                        <BsFileEarmarkPdfFill
                          color="#D03438"
                          style={{ width: "22px", height: "22px" }}
                        />
                      </Link>
                    ) : (
                      "- - -"
                    )}
                  </td>
                  <td>
                    {" "}
                    {(
                      // <Link to={baseURL + item?.emay_bill} target="_blank">
                      <BsFileEarmarkPdfFill
                        color="#D03438"
                        style={{ width: "22px", height: "22px" }}
                        onClick={() =>
                          deliveryChallanPdf(item?.challan_no, item?.id)
                        }
                      />
                    ) ||
                      // </Link>
                      " - - -"}
                  </td>
                  <td>
                    <Link
                      to="/deliveryChallan/edit-delivery-challan"
                      state={{ detail: item }}
                    >
                      <span>
                        <FaEdit style={{ width: "30px", height: "25px" }} />
                      </span>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="text-end mt-2">
          {/* <BasicPagination
            handlePaginateChange={handlePaginateChange}
            page={page}
            pageCount={pageCount}
          /> */}
        </div>
      </div>
    </>
  );
};

export default ChallanListData;
