import React from "react";

function BillingTableCard({ children }) {
  return <div style={cardStyle}>{children}</div>;
}
const cardStyle = {
  borderRadius: "8px",
  padding: "1.2rem",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  background: "#FFFFFF",
  overflow: "auto",
  height: "75vh",
  marginTtop: "0",
  overflow: "hidden",
  padding: "1rem",
  position: "relative",
};

export default BillingTableCard;
