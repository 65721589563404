import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TenderTable from "../TenderComponents/TenderTable";
import TenderCards from "../TenderComponents/TenderCards";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SlCalender } from "react-icons/sl";
import "../TenderStyle/TenderStyle.css";
import { useLocation } from "react-router-dom";
import { errorsMessage } from "../../constant";
import { Container, Row, Col } from "react-bootstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import useURLFilters from "../useTenderUrlFilter";
import { getTenderUser } from "../../redux/api";

function TenderListPage() {
  //Array destructring
  const currentDate = new Date().toISOString().split("T")[0]; 
  const [filters, setFilters] = useURLFilters({
    startDate: "",
    endDate: "",
    initialLoad: true,
    tenderReturnStatus: "",
  });
  const active = {
    backgroundColor: "transparent",
    padding: "0.2rem",
    borderBottom: "1px solid green",
  };
  const nonActive = {
    backgroundColor: "#dbdbdb",
    padding: "0.2rem",
    borderRight: "1px solid grey",
    borderLeft: "1px solid grey",
  };
  const [tenderData, setTenderData] = useState([]);
  const [key, setKey] = useState("tracker");
  const [loading, setLoading] = useState(false);
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const [searchData, setSearchData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const user_id = user?.response?.userDetails?.id;
  const department_id = user?.response?.userDetails?.department_id;
  const getToken = user?.response?.token;
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const [tenderUser, setTenderUser] = useState([]);

  const bearer_token = `Bearer ${getToken}`;
  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const navigate = useNavigate();
  const location = useLocation();
  async function downloadCsv() {
    let url = baseUrl + "tender-csv-list";
    try {
      const res = await axios.post(
        url,
        {
          user_id,
          search: filters.search,
          startDate: filters.initialLoad ? currentDate : filters.startDate,
          endDate: filters.endDate,
          contractType: filters.contractType,
          biddingStatus: filters.biddingStatus,
          month: filters.month,
          year: filters.year,
          awardedStatus: filters.awardedStatus,
          tenderReturnStatus: filters.tenderReturnStatus,
          page,
        },
        { ...config }
      );

      const fileUrl = res?.data?.response?.tenderCSVList?.file_url;

      if (fileUrl) {
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = "tender_data.csv"; // Set the default file name
        document.body.appendChild(link);
        link.click(); // Simulate a click to start the download
        document.body.removeChild(link);
      } else {
        console.error("No file URL found in the response");
      }
    } catch (error) {
      errorsMessage(error?.response?.status);
    }
  }

  async function tenderDataFetch(signal) {
    setLoading(true);
    const url = baseUrl + "gettenderlist";
    try {
      const res = await axios.post(
        url,
        {
          user_id,
          search: filters.search,
          startDate: filters.initialLoad ? currentDate : filters.startDate,
          endDate: filters.endDate,
          contractType: filters.contractType,
          biddingStatus: filters.biddingStatus,
          month: filters.month,
          year: filters.year,
          awardedStatus: filters.awardedStatus,
          tenderReturnStatus: filters.tenderReturnStatus,
          created_by: filters?.created_by,
          page,
          paginate: true,
        },
        { ...config, signal }
      );

      if (res.data.response.isSuccess) {
        const tenders = res.data.response.TendersList.data;
        setPageCount(res?.data?.response?.TendersList?.last_page);
        setTenderData(tenders);
        setSearchData(tenders);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request was canceled");
      } else {
        errorsMessage(error?.response?.status);
        if (!error) {
          errorsMessage("Please Check Your Internet");
        }
      }
    } finally {
      setLoading(false);
    }
  }
  console.log("filters", filters);
  useEffect(() => {
    const controller = new AbortController();
    tenderDataFetch(controller.signal);
    return () => {
      controller.abort();
    };
  }, [
    filters.contractType,
    filters.endDate,
    filters.month,
    filters.startDate,
    filters.year,
    filters.biddingStatus,
    filters.awardedStatus,
    filters.search,
    page,
    filters.tenderReturnStatus,
    filters.created_by,
  ]);

  useEffect(() => {
    if (filters.initialLoad) {
      setFilters({ ...filters, initialLoad: false });
    }
  }, [filters.initialLoad]);

  const year = [
    { id: "all", name: "All" },
    { id: 2025, name: 2025 },
    { id: 2024, name: 2024 },
    { id: 2023, name: 2023 },
    { id: 2022, name: 2022 },
    { id: 2021, name: 2021 },
    { id: 2020, name: 2020 },
    { id: 2019, name: 2019 },
    { id: 2018, name: 2018 },
    { id: 2017, name: 2017 },
    { id: 2016, name: 2016 },
    { id: 2015, name: 2015 },
  ];

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name == "year") {
      const newFilters = {
        ...filters,
        [name]: value,
        startDate: "",
        endDate: "",
      };
      const newSearch = setFilters(newFilters);
      navigate(`?${newSearch}`);
    } else {
      const newFilters = { ...filters, [name]: value };
      const newSearch = setFilters(newFilters);
      navigate(`?${newSearch}`);
    }
  };
  //For set Date value filter
  const handleDateFilter = (date) => {
    const { name, value } = date;
    let formattedDate = value
      ? `${value.getFullYear()}-${(value.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${value.getDate().toString().padStart(2, "0")}`
      : "";
    const newFilters = { ...filters, [name]: formattedDate };
    const newSearch = setFilters(newFilters);
    navigate(`?${newSearch}`);
  };
  const formatDate = (date) => {
    return date ? moment(date).toDate() : null;
  };
  function handlePaginateChange(event, value) {
    setPage(value);
  }
  function getTenderUserList() {
    getTenderUser({
      user_id: user_id,
      department_id,
    })
      .then((res) => {
        setTenderUser(res?.data?.response?.TenderAuthorisedList);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    getTenderUserList();
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-md-9">
                <h4 className="m-0">TENDERS</h4>
              </div>
              {/* <div className="col-md-3">
                <select
                  className="form-control border-danger w-100"
                  name="tenderReturnStatus"
                  value={filters?.tenderReturnStatus}
                  onChange={(e) => handleFilterChange(e)}
                >
                  <option value="" >
                    Tender Status
                  </option>
                  <option value="Due">Due</option>
                  <option value="Returned">Returned</option>
                  <option value="In Progress">In Progress</option>
                </select>
              </div> */}
              {!status && (
                <>
                  <div className="col-md-3 text-end">
                    <div className="d-flex gap-2">
                      <DatePicker
                        showIcon
                        selected={formatDate(filters?.startDate)}
                        onChange={(date) => {
                          handleDateFilter({ name: "startDate", value: date });
                        }}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select a date"
                        selectsStart
                        startDate={formatDate(filters?.startDate)}
                        endDate={formatDate(filters?.endDate)}
                        customInput={<StartDatePickerInput />}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        disabled={searchValue}
                        isClearable
                      />
                      <DatePicker
                        showIcon
                        selected={formatDate(filters?.endDate)}
                        onChange={(date) => {
                          handleDateFilter({ name: "endDate", value: date });
                        }}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select a date"
                        selectsEnd
                        startDate={formatDate(filters?.startDate)}
                        endDate={formatDate(filters?.endDate)}
                        minDate={formatDate(filters?.startDate)}
                        customInput={<EndDatePicker />}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        disabled={searchValue}
                        isClearable
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="d-flex row align-items-end">
              <div className="row align-items-center my-2 col-auto">
                {/* <div className="col-auto me-3">
                  <h4 className="my-0">Tender Color Status</h4>
                </div> */}
                <div className="col-auto me-4">
                  <h5>Awarded Status</h5>
                  <ul className="list-unstyled d-flex align-items-center gap-3 mb-0">
                    <li className="d-flex align-items-center gap-2">
                      {" "}
                      <span
                        style={{
                          display: "inline-block",
                          height: "1.2rem",
                          width: "2.5rem",
                          backgroundColor: "#a8e28d",
                          border: "1px solid #000",
                          borderRadius: ".2rem",
                        }}
                      ></span>
                      <span>Awarded</span>
                    </li>
                    <li className="d-flex align-items-center gap-2">
                      {" "}
                      <span
                        style={{
                          display: "inline-block",
                          height: "1.2rem",
                          width: "2.5rem",
                          backgroundColor: "#FA7070DB",
                          border: "1px solid #000",
                          borderRadius: ".2rem",
                        }}
                      ></span>
                      <span>Not Awarded</span>
                    </li>
                  </ul>
                </div>
                <div className="col-auto  me-4">
                  <h5>Bidding Status</h5>
                  <ul className="list-unstyled d-flex align-items-center gap-3 mb-0">
                    <li className="d-flex align-items-center gap-2">
                      {" "}
                      <span
                        style={{
                          display: "inline-block",
                          height: "1.2rem",
                          width: "2.5rem",
                          backgroundColor: "#fdec8b",
                          border: "1px solid #000",
                          borderRadius: ".2rem",
                        }}
                      ></span>
                      <span>Bidded</span>
                    </li>
                    <li className="d-flex align-items-center gap-2">
                      {" "}
                      <span
                        style={{
                          display: "inline-block",
                          height: "1.2rem",
                          width: "2.5rem",
                          backgroundColor: "#dcdfd8",
                          border: "1px solid #000",
                          borderRadius: ".2rem",
                        }}
                      ></span>
                      <span>Not Bidded</span>
                    </li>
                  </ul>
                </div>
                <div className="col-auto ">
                  <h5>Higher Authority Approval</h5>
                  <ul className="list-unstyled d-flex align-items-center gap-3 mb-0">
                    <li className="d-flex align-items-center gap-2">
                      {" "}
                      <span
                        style={{
                          display: "inline-block",
                          height: "1.2rem",
                          width: "2.5rem",
                          backgroundColor: "#FFC0CB",
                          border: "1px solid #000",
                          borderRadius: ".2rem",
                        }}
                      ></span>
                      <span>Pending</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-2 justify-content-end align-items-end ms-auto">
                <select
                  type="text"
                  id="created_by"
                  className="form-control border-danger"
                  name="created_by"
                  value={filters?.created_by}
                  onChange={handleFilterChange}
                >
                  <option value="" disabled hidden>
                    Created By
                  </option>
                  <option value="">All Tender</option>
                  {tenderUser?.map((user) => (
                    <option value={user?.id} key={user?.id}>
                      {user?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-auto justify-content-end align-items-end">
                <h5></h5>
                <ul className="list-unstyled d-flex align-items-center gap-3 mb-0">
                  <li className="d-flex align-items-center gap-2">
                    {" "}
                    <button
                      className="btn btn-primary btn-sm px-3"
                      onClick={downloadCsv}
                    >
                      Download CSV
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-md-7">
                <div className="row">
                  <div className="col-3">
                    <select
                      className="form-control border-danger"
                      name="contractType"
                      value={filters?.contractType}
                      onChange={(e) => handleFilterChange(e)}
                    >
                      <option value="" disabled hidden>
                        Contract Types
                      </option>
                      <option value="">All Tenders</option>
                      <option value="Works">Works</option>
                      <option value="Stores">Stores</option>
                      <option value="GEM (Government E-Marketing Portal)">
                        GEM (Government E-Marketing Portal)
                      </option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="col-3">
                    <select
                      className="form-control border-danger"
                      name="biddingStatus"
                      value={filters?.biddingStatus}
                      onChange={(e) => handleFilterChange(e)}
                    >
                      <option value="" disabled hidden>
                        Bidding Status
                      </option>
                      <option value="">All Tenders</option>
                      <option value="Bidded">Bidded</option>
                      <option value="Not Bidded">Not Bidded</option>
                    </select>
                  </div>
                  <div className="col-3">
                    <select
                      className="form-control border-danger"
                      name="awardedStatus"
                      value={filters?.awardedStatus}
                      onChange={(e) => handleFilterChange(e)}
                    >
                      <option value="" disabled hidden>
                        Awarded Status
                      </option>
                      <option value="">All Tenders</option>
                      <option value="Open">Open</option>
                      <option value="Awarded">Awarded</option>
                      <option value="Not Awarded">Not Awarded</option>
                      <option value="PostPoned">PostPoned</option>
                      <option value="Cancelled">Cancelled</option>
                    </select>
                  </div>
                  <div className="col-3">
                    <select
                      className="form-control border-danger"
                      name="year"
                      value={filters?.year}
                      onChange={(e) => {
                        handleFilterChange(e);
                      }}
                    >
                      <option value="" disabled hidden>
                        Year
                      </option>
                      {year.map((yearValue, index) => (
                        <option key={index} value={yearValue.id}>
                          {yearValue.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-md-5">
                <div className="row">
                  <div className="col-8 text-end">
                    <div className="input-group me-3">
                      <input
                        type="text"
                        name="search"
                        className="form-control form-control-sm border-danger"
                        placeholder="Search by Title or Number"
                        style={{ fontSize: ".9rem" }}
                        value={filters?.search}
                        onChange={(e) => handleFilterChange(e)}
                      />
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        style={{ zIndex: "-1" }}
                      >
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                  <div className="col-4  text-end">
                    <Link to="/tenders/add-tender">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm px-3"
                      >
                        Add&nbsp;New&nbsp;Tender
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                    style={{ fontSize: "1.2rem", fontWeight: 600 }}
                  >
                    <Tab eventKey="tracker" title="TRACKER">
                      <TenderTable
                        searchData={searchData}
                        tenderDataFetch={tenderDataFetch}
                        Loading={loading}
                        handlePaginateChange={handlePaginateChange}
                        page={page}
                        pageCount={pageCount}
                      ></TenderTable>
                    </Tab>
                    <Tab eventKey="card" title="CARDS">
                      <TenderCards
                        searchData={searchData}
                        Loading={loading}
                      ></TenderCards>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const StartDatePickerInput = ({ value, onClick }) => (
  <div className="custom-datepicker-input text-start" onClick={onClick}>
    <SlCalender />
    {value ? value : "Start date"}
  </div>
);

const EndDatePicker = ({ value, onClick }) => (
  <div className="custom-datepicker-input text-start" onClick={onClick}>
    <SlCalender />
    {value ? value : "End date"}
  </div>
);

export default TenderListPage;
