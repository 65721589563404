import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import "../PurchaseStyle/PurchaseStyle.css";
import { getPurchaseTickets } from "../purchaseApi";
import { errorsMessage, formatDate } from "../../constant";
import { toast } from "react-toastify";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import BackgroundLetterAvatars from "../Common/ImageFromName";
import SkeletonCards from "../Common/SkeltonCards";
import { SlCalender } from "react-icons/sl";
import { IoPersonOutline } from "react-icons/io5";
import { capitalizeFirstLetter, truncateString } from "../Common/TruncateText";
import { Tooltip } from "@mui/material";

const PurchaseProjectReview = () => {
  const [loading, setLoading] = useState(true);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [ticketData, setTicketData] = useState([]);
  const [boardGroupStatus, setBoardGroupStatus] = useState([]);
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = userProfile?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const [initialStatus, setInitialStatus] = useState([]);
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    railway_po_no: "",
  });
  const [timer, setTimer] = useState(null);

  function purchaseTicketData(index = null) {
    setLoading(true);
    getPurchaseTickets({ ...filters, user_id })
      .then((res) => {
        setTicketData(res.data.response.ticketsList);
        setBoardGroupStatus(res.data.response.board_group);
        const ticketList = res.data.response.ticketsList;
        const arrayList = Array.from(
          { length: ticketList.length },
          (_, index) => ({
            board_group_status_id: ticketList[index].board_group_status_id,
            board_group_status_name: ticketList[index].board_group_status,
            loading: false,
          })
        );
        setInitialStatus(arrayList);
      })
      .catch((err) => {
        errorsMessage(err?.status);
      })
      .finally(() => {
        {
          index !== null
            ? setInitialStatus((prevInitialStatus) => {
                const updatedStatus = [...prevInitialStatus];
                updatedStatus[index].loading = false;
                return updatedStatus;
              })
            : null;
        }
        setLoading(false);
      });
  }

  function changeStatusList(board_group_id, e, ticket, index) {
    setInitialStatus((prevInitialStatus) => {
      const updatedStatus = [...prevInitialStatus];
      updatedStatus[index].loading = true;
      return updatedStatus;
    });
    let newStatusId = e.target.value;
    const url = baseUrl + "updateprojectticketboardgroup";
    const data = {
      user_id,
      board_group_id,
      board_group_status_id: newStatusId,
      ticketId: ticket,
    };
    axios
      .post(url, data, config)
      .then((response) => {
        if (response.status === 200) {
          purchaseTicketData(index);
          toast.success("Status changed successfully");
        }
      })
      .catch((error) => {
        setInitialStatus((prevInitialStatus) => {
          const updatedStatus = [...prevInitialStatus];
          updatedStatus[index].loading = false;
          return updatedStatus;
        });
        errorsMessage(error.status);
      });
  }
  function handleNavigate(data, boardData) {
    navigate("/purchase-project-detail", {
      state: { data, boardData },
    });
  }
  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    const delay = setTimeout(() => {
      purchaseTicketData();
    }, 400);

    setTimer(delay);

    return () => clearTimeout(delay);
  }, [filters]);

  function priorityColor(priority) {
    switch (priority.toLowerCase()) {
      case "high":
        return "red";
      case "medium":
        return "#DAAA04";
      case "low":
        return "green";
      default:
        return "gray";
    }
  }
  function getBackgroundColor(text) {
    switch (text) {
      case "Refurbishment and Conversion":
        return "#D2EACC";
      case "Toilet upgradation":
        return "#EAE7A4";
      case "Supply":
        return "#E4E4E4";
      case "Turn Key Furnishing":
        return "#C4E5E0";
      default:
        return "#FFFFFF"; // Default color if no match
    }
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab">
          <div className="container-fluid">
            <div className="row mt-3">
              <div class="col-sm-4 col-xxl-6">
                <div class="page-title-box">
                  <h3 class="page-title">PROJECTS REVIEW </h3>
                </div>
              </div>
              <div className="col-sm-3 col-xxl-2 ms-auto">
                <div class="page-title-box">
                  <input
                    className="form-control"
                    name="railway_po_no"
                    onChange={(e) =>
                      setFilters({ ...filters, railway_po_no: e.target.value })
                    }
                    type="text"
                    placeholder="Search by Railway po no."
                  />
                </div>
              </div>
              <div className="col-sm-3 col-xxl-auto ">
                <div className="page-title-box text-end d-inline-block">
                  <Link
                    className="d-inline-block"
                    to={"/non-tender/purchaseOrder"}
                  >
                    <Button variant="contained" endIcon={<ArrowForwardIcon />}>
                      For Non Tender PO
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            {loading ? (
              <div className="row">
                <div className="col-md-6 mb-2">
                  <SkeletonCards width={"100%"} height={330} />
                </div>
                <div className="col-md-6 mb-2">
                  <SkeletonCards width={"100%"} height={330} />
                </div>
                <div className="col-md-6 mb-2">
                  <SkeletonCards width={"100%"} height={330} />
                </div>
                <div className="col-md-6 mb-2">
                  <SkeletonCards width={"100%"} height={330} />
                </div>
              </div>
            ) : (
              <div className="row">
                {ticketData?.length === 0 ? (
                  <div className="text-center">
                    <p>Sorry, currently there is no ticket assigned to you.</p>
                  </div>
                ) : (
                  ticketData?.map((element, index) => (
                    <div className="col-md-6" key={index}>
                      <div className="project_review-card p-0" key={index}>
                        <div
                          className="review-card mb-4 p-4"
                          style={{
                            cursor: "pointer",
                            background: getBackgroundColor(
                              element.project_category
                            ),
                          }}
                        >
                          <div className="row aligns-items-center">
                            <div
                              className="col-md-9 d-flex align-items-center gap-2"
                              onClick={() =>
                                handleNavigate(element, boardGroupStatus)
                              }
                            >
                              <BackgroundLetterAvatars
                                name={element?.project_category}
                              />
                              <div>
                                <h6 className="m-0">
                                  <Tooltip
                                    title={element.pd_projectTitle}
                                    arrow
                                  >
                                    {" "}
                                    {truncateString(
                                      element.pd_projectTitle,
                                      25
                                    )}{" "}
                                  </Tooltip>
                                  (LOT-
                                  {element?.lot_number}){" "}
                                </h6>
                                <p className="m-0">
                                  {element.project_category}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-3 ">
                              {initialStatus[index].loading ? (
                                <Spinner animation="border" variant="danger" />
                              ) : (
                                <select
                                  className="form-control border-secondary"
                                  onChange={(event) =>
                                    changeStatusList(
                                      element.board_group_id,
                                      event,
                                      element.id,
                                      index
                                    )
                                  }
                                  value={
                                    initialStatus[index].board_group_status_id
                                  }
                                >
                                  <option value="">Select</option>
                                  {boardGroupStatus[
                                    element.board_group_id
                                  ]?.map((status, idx) => (
                                    <option key={idx} value={status.id}>
                                      {status.name}
                                    </option>
                                  ))}
                                </select>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="purchase-ticket-detail">
                          <div className="row px-4 pe-4">
                            <div className="col-md-3">
                              <p>Assigned Date&nbsp;</p>
                            </div>
                            <div className="col-md-9">
                              <p className="purchaseTicketDetailValues">
                                <SlCalender />
                                &nbsp;
                                {formatDate(element.start_date)}
                              </p>
                            </div>
                            <div className="col-md-3">
                              <p>Due Date&nbsp;</p>
                            </div>
                            <div className="col-md-9">
                              <p className="purchaseTicketDetailValues">
                                <SlCalender />
                                &nbsp;
                                {formatDate(element.due_date)}
                              </p>
                            </div>
                            <div className="col-md-3">
                              <p>Reporting To&nbsp;&nbsp;&nbsp;</p>
                            </div>
                            <div className="col-md-9">
                              <p className="purchaseTicketDetailValues">
                                <IoPersonOutline />
                                &nbsp;
                                {element.reporting_name}
                              </p>
                            </div>
                            <div className="col-md-3">
                              <p>Railway po no&nbsp;&nbsp;&nbsp;</p>
                            </div>
                            <div className="col-md-9">
                              <p className="purchaseTicketDetailValues">
                                {element.railway_po_no}
                              </p>
                            </div>
                            <div className="col-md-3">
                              <p>Priority&nbsp;&nbsp;&nbsp;</p>
                            </div>
                            <div className="col-md-9">
                              <p
                                style={{
                                  color: priorityColor(element.priority),
                                }}
                              >
                                {capitalizeFirstLetter(element.priority)}
                              </p>
                            </div>
                            <div className="col-md-3">
                              <p>Ticket Description </p>
                            </div>
                            <div className="col-md-9">
                              <p className="text-break">
                                <Tooltip title={element.description} arrow>
                                  {truncateString(element.description, 50)}{" "}
                                </Tooltip>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PurchaseProjectReview;
