import React from "react";
import { IoChevronForwardCircleSharp } from "react-icons/io5";
import { FaRegFilePdf } from "react-icons/fa";
import { formatDate } from "../../../../../constant";
import { Link } from "react-router-dom";
import StatusButton from "../CommonComponents/StatusButton";

function CommunicationTrackerTable({ data }) {
  const basePdfUrl = process.env.REACT_APP_BASE_URL;
  return (
    <div className="mt-3">
      {/* Scrollable Container */}
      <div className="table-scroll-container">
        <table className="communication-table">
          <thead>
            <tr
              style={{ background: "#E7F2F9" }}
              className="communication-table-header"
            >
              <th></th>
              <th>To</th>
              <th>From</th>
              <th>Subject</th>
              <th>Type</th>
              <th>Ref PO/LOA No./Tendor</th>
              <th>Current Letter Ref NO.</th>
              <th>Letter date</th>
              <th>Rev. against Letter Ref no.</th>
              <th>Status</th>
              <th>Attachment</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 ? (
              data.map((item) => (
                <tr key={item.id} className="communication-table-detail">
                  <td>
                    <Link
                      to={`/communicationTrackerDetailList?ref_no=${item?.revert_against_ref_no}`}
                    >
                      {item?.total_records_count}
                      &nbsp;&nbsp;
                      <IoChevronForwardCircleSharp
                        fontSize={30}
                        style={{ cursor: "pointer" }}
                      />
                    </Link>
                  </td>
                  <td>{item.to ? item.to : "- - -"}</td>
                  <td>
                    {item.written_by_name ? item.written_by_name : "- - -"}
                  </td>
                  <td>{item.subject ? item.subject : "- - -"}</td>
                  <td>{item.type ? item.type : "- - -"}</td>
                  <td>
                    {item.ref_po_loa_no ? item.ref_po_loa_no_name : "- - -"}
                  </td>
                  <td>{item.ref_no ? item.ref_no : "- - -"}</td>
                  <td>
                    {item.letter_date ? formatDate(item.letter_date) : "- - -"}
                  </td>
                  <td>
                    {item.letter_date ? item.revert_against_ref_no : "- - -"}
                  </td>
                  <td>
                    <StatusButton>
                      {item.status ? item.status : "- - -"}
                    </StatusButton>
                  </td>
                  <td className="text-center">
                    {item.attachment ? (
                      <a href={basePdfUrl + item.attachment} target="_blank">
                        <FaRegFilePdf
                          color="red"
                          fontSize={18}
                          style={{ cursor: "pointer" }}
                        />
                      </a>
                    ) : (
                      "- - -"
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="11" className="text-center">
                  No Records Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CommunicationTrackerTable;
