import Modal from "react-bootstrap/Modal";
import React, { useEffect, useRef, useState } from "react";
import acmelogo from "../../../Assets/acme-logo.png";
// import AuthorizeSignatoryFullImage from "../../../Assets/AuthorizeSignatoryFullImage.png";

import numberToWords from "number-to-words";

import { formatDate } from "../../../constant";
import { amountFormatJS } from "../../../common/amountFormat";

function PurchaseOrderPreviewModal({
  show,
  setShow,
  pdfData,
  selectedVendor,
  selectedStore,
  selectedBranch,
  currentDate,
  railwayNo,
  user_name,
}) {
  const title = process.env.REACT_APP_TITLE;

  const convertNumberToWordsIndian = (number) => {
    const words = [];

    if (number >= 10000000) {
      const crore = Math.floor(number / 10000000);
      words.push(numberToWords.toWords(crore), "crore");
      number %= 10000000;
    }

    if (number >= 100000) {
      const lakh = Math.floor(number / 100000);
      words.push(numberToWords.toWords(lakh), "lakh");
      number %= 100000;
    }

    if (number >= 1000) {
      const thousand = Math.floor(number / 1000);
      words.push(numberToWords.toWords(thousand), "thousand");
      number %= 1000;
    }

    if (number >= 100) {
      const hundred = Math.floor(number / 100);
      words.push(numberToWords.toWords(hundred), "hundred");
      number %= 100;
    }

    if (number > 0) {
      words.push(numberToWords.toWords(number));
    }

    return words.join(" ");
  };
  const convertNumberToWordsWithCurrency = (number, currency) => {
    if (!isNaN(number) && number > 0) {
      const words = convertNumberToWordsIndian(number);
      const capitalizedWords = words.charAt(0).toUpperCase() + words.slice(1);
      return `${capitalizedWords} ${currency}`;
    } else {
      return "Invalid number";
    }
  };
  const gstGroupedMap = pdfData?.item_details?.reduce((group, item) => {
    const { gst_percent, gst_amt } = item;
    const amount = isNaN(Number(gst_amt)) ? 0 : Number(gst_amt);

    if (!group.has(gst_percent)) {
      group.set(gst_percent, 0);
    }

    group.set(gst_percent, group.get(gst_percent) + amount);

    return group;
  }, new Map());

  const gstGroupedTotals = Array.from(gstGroupedMap, ([gst, total_amount]) => ({
    gst,
    total_amount,
  }));

  return (
    <>
      <Modal
        show={show}
        fullscreen={true}
        onHide={() => setShow(false)}
        style={{ background: "#22272b" }}
      >
        <Modal.Header closeButton s>
          <Modal.Title>Purchase Order Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              margin: "0 auto",
              maxWidth: "1120px",
              // width: "100%",
              background: "white",
              padding: "0",
            }}
            id="testReportDiv"
            className="purchaseOrderPreview"
          >
            <div style={{ marginBottom: "30px" }}>
              <div className="invoic m-0 p-0" id="invoice-content">
                <div className="row g-0">
                  <div className="col-12">
                    <div
                      className="row g-0"
                      style={{ height: "116.8px", border: "1px solid black" }} // Converted from 7.3rem
                    >
                      <div className="col-md-4 img-fluid d-flex justify-content-center align-items-center">
                        <img
                          src={acmelogo}
                          style={{ height: "64px", width: "240px" }} // Converted from 4rem and 15rem
                          alt="Logo"
                        />
                      </div>
                      <div className="col-md-8 d-flex justify-content-center align-items-center">
                        <p
                          className="fw-bold"
                          style={{
                            textAlign: "center",
                            fontSize: "40px",
                            marginBottom: "16px",
                          }} // Converted from 2.5rem
                        >
                          {title || ""}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-12 d-flex justify-content-around align-items-center"
                    style={{ border: "1px solid black" }}
                  >
                    <p style={{ fontSize: "20px", marginBottom: "16px" }}>
                      {/* <strong>PO No :</strong>{" "} */}
                      {/* <span>{pdfData?.purchase_order_no}</span> &{" "} */}
                      <strong> Dated :</strong>{" "}
                      <span>{formatDate(currentDate)}</span>
                    </p>
                    <p
                      className="fw-bold"
                      style={{ fontSize: "20px", marginBottom: "16px" }}
                    >
                      PURCHASE ORDER
                    </p>
                  </div>
                  <div
                    className="col-7 "
                    style={{ border: "1px solid black", padding: "16px" }}
                  >
                    <div className="d-flex">
                      <div className="d-flex flex-column">
                        <p
                          className=" d-block"
                          style={{ fontSize: "14px", marginBottom: "16px" }}
                        >
                          <strong
                            style={{ display: "inline-block", width: "112px" }} // Converted from 7rem
                          >
                            Party Code
                          </strong>{" "}
                          <span> : </span>
                          <span>{selectedVendor.party_code}</span>
                        </p>
                        <p
                          className=" d-block"
                          style={{ fontSize: "14px", marginBottom: "4px" }}
                        >
                          <strong
                            style={{ display: "inline-block", width: "112px" }} // Converted from 7rem
                          >
                            Party Name
                          </strong>{" "}
                          <span> : </span>
                          <span>{selectedVendor.party_name}</span>
                        </p>
                        <p
                          className="d-block"
                          style={{ fontSize: "14px", marginBottom: "4px" }}
                        >
                          <strong
                            style={{ display: "inline-block", width: "112px" }} // Converted from 7rem
                          >
                            Address
                          </strong>{" "}
                          <span> : </span>
                          <span>{selectedVendor.address}</span>
                        </p>
                        <p
                          className="d-block "
                          style={{ fontSize: "14px", marginBottom: "4px" }}
                        >
                          <strong
                            style={{ display: "inline-block", width: "112px" }} // Converted from 7rem
                          >
                            GST No
                          </strong>{" "}
                          <span> : </span>
                          <span>{selectedVendor.gst_no}</span>
                        </p>
                        <p
                          className=" d-block"
                          style={{ fontSize: "14px", marginBottom: "4px" }}
                        >
                          <strong
                            style={{ display: "inline-block", width: "112px" }} // Converted from 7rem
                          >
                            Contact Person
                          </strong>{" "}
                          <span> : </span>
                          <span>{selectedVendor.contact_person}</span>
                        </p>
                        <p
                          className=" d-block"
                          style={{ fontSize: "14px", marginBottom: "4px" }}
                        >
                          <strong
                            style={{ display: "inline-block", width: "112px" }} // Converted from 7rem
                          >
                            Mobile No
                          </strong>{" "}
                          <span> : </span>
                          <span>{selectedVendor?.contact_mobile}</span>
                        </p>
                        <p
                          className=" d-block"
                          style={{ fontSize: "14px", marginBottom: "4px" }}
                        >
                          <strong
                            style={{ display: "inline-block", width: "112px" }} // Converted from 7rem
                          >
                            MSME
                          </strong>{" "}
                          <span> : </span>{" "}
                          <span>{selectedVendor?.msme_no} </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-5 "
                    style={{ border: "1px solid black", padding: "16px" }}
                  >
                    <div className="d-flex gap-3">
                      <div className="d-flex flex-column">
                        <p
                          className="d-block"
                          style={{ fontSize: "14px", marginBottom: "4px" }}
                        >
                          <strong
                            style={{ display: "inline-block", width: "96px" }} // Converted from 6rem
                          >
                            Prepared by
                          </strong>{" "}
                          <span> : </span> <span>{user_name}</span>
                        </p>
                        {pdfData?.type !== "non-tender" && (
                          <p
                            className=" d-block"
                            style={{ fontSize: "14px", marginBottom: "4px" }}
                          >
                            <strong
                              style={{ display: "inline-block", width: "96px" }} // Converted from 6rem
                            >
                              Insp. Agency
                            </strong>{" "}
                            <span> : </span>
                            <span> {pdfData?.inspection_agency}</span>
                          </p>
                        )}
                        {pdfData?.inspection_agency != "Consignee" ||
                          (pdfData?.type !== "non-tender" && (
                            <>
                              <p
                                className=" d-block"
                                style={{
                                  fontSize: "14px",
                                  marginBottom: "4px",
                                }}
                              >
                                <strong
                                  style={{
                                    display: "inline-block",
                                    width: "96px",
                                  }} // Converted from 6rem
                                >
                                  Railway PO.No
                                </strong>{" "}
                                <span> : </span>
                                <span> {railwayNo}</span>
                              </p>
                            </>
                          ))}
                        <p
                          className=" d-block"
                          style={{ fontSize: "14px", marginBottom: "4px" }}
                        >
                          <strong
                            style={{ display: "inline-block", width: "96px" }} // Converted from 6rem
                          >
                            Remarks
                          </strong>{" "}
                          <span> : </span> <span> {pdfData?.remarks} </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-7 "
                    style={{ border: "1px solid black", padding: "16px" }}
                  >
                    <strong style={{ paddingBottom: "4px", fontSize: "14px" }}>
                      Bill To :
                    </strong>
                    {selectedBranch?.branch_name && (
                      <p
                        className="mb-0"
                        style={{ fontSize: "14px", marginBottom: "4px" }}
                      >
                        {selectedBranch?.branch_name}
                      </p>
                    )}
                    {selectedBranch?.address && (
                      <p
                        className="mb-0 "
                        style={{ fontSize: "14px", marginBottom: "4px" }}
                      >
                        {selectedBranch?.address}
                        {selectedBranch?.Zip}
                      </p>
                    )}
                    <p
                      className="mb-0"
                      style={{ fontSize: "14px", marginBottom: "4px" }}
                    >
                      GSTIN NUMBER : {selectedBranch?.GSTNO}
                    </p>
                  </div>
                  <div
                    className="col-5"
                    style={{ border: "1px solid black", padding: "16px" }}
                  >
                    <strong style={{ paddingBottom: "4px", fontSize: "14px" }}>
                      Ship To :
                    </strong>
                    {selectedStore?.store_name && (
                      <p style={{ fontSize: "14px", marginBottom: "4px" }}>
                        {selectedStore?.store_name}
                      </p>
                    )}
                    {selectedStore?.store_address && (
                      <p style={{ fontSize: "14px", marginBottom: "4px" }}>
                        {selectedStore?.store_address}
                      </p>
                    )}
                    <p style={{ fontSize: "14px", marginBottom: "4px" }}>
                      Contact Person- {selectedStore?.keeper_name}, ContactNo-{" "}
                      {selectedStore?.contact_no}
                    </p>
                  </div>

                  <div
                    style={{
                      marginTop: "5px",
                      padding: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    <table
                      style={{
                        width: "100%",
                        marginBottom: "0px",
                      }}
                    >
                      <thead>
                        <tr
                          style={{
                            pageBreakInside: "avoid", // Prevent row from breaking inside the PDF
                            breakInside: "avoid",
                          }}
                        >
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "0 10px",
                              fontSize: "16px",
                              width: "41.6px",
                              pageBreakInside: "avoid", // Applies to individual cells
                              breakInside: "avoid",
                            }}
                          >
                            S.No.
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "0 10px",
                              fontSize: "16px",
                              width: "76.8px",
                              pageBreakInside: "avoid",
                              breakInside: "avoid",
                            }}
                          >
                            Item Code
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "0 10px",
                              fontSize: "16px",
                              width: "352px",
                              pageBreakInside: "avoid",
                              breakInside: "avoid",
                            }}
                          >
                            Item Name
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "0 10px",
                              fontSize: "16px",
                              width: "80px",
                              pageBreakInside: "avoid",
                              breakInside: "avoid",
                            }}
                          >
                            Qty/ Unit
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "0 10px",
                              fontSize: "16px",
                              width: "96px",
                              pageBreakInside: "avoid",
                              breakInside: "avoid",
                            }}
                          >
                            Second Qty
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "0 10px",
                              fontSize: "16px",
                              width: "80px",
                              pageBreakInside: "avoid",
                              breakInside: "avoid",
                            }}
                          >
                            Rate
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "0 10px",
                              fontSize: "16px",
                              width: "80px",
                              pageBreakInside: "avoid",
                              breakInside: "avoid",
                            }}
                          >
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {pdfData?.item_details?.map((element, i) => (
                          <tr
                            key={i}
                            style={{
                              pageBreakInside: "avoid", // Prevents row from splitting across pages
                              breakInside: "avoid",
                            }}
                          >
                            <td
                              style={{
                                border: "1px solid black",
                                pageBreakInside: "avoid",
                                breakInside: "avoid",
                                fontSize: "14px",
                              }}
                            >
                              {i + 1}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                overflowWrap: "break-word",
                                padding: "0 8px",
                                pageBreakInside: "avoid",
                                breakInside: "avoid",
                                fontSize: "14px",
                              }}
                            >
                              {element.item_code}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                overflowWrap: "break-word",
                                pageBreakInside: "avoid",
                                breakInside: "avoid",
                                fontSize: "14px",
                              }}
                            >
                              {element?.item_master_details?.product_name}{" "}
                              {element?.item_description &&
                                `,${element?.item_description}`}{" "}
                              {element?.specification &&
                                `,${element?.specification}`}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                overflowWrap: "break-word",
                                pageBreakInside: "avoid",
                                breakInside: "avoid",
                                fontSize: "14px",
                              }}
                            >
                              {element?.unit_details?.unit_name
                                ? `${element.qty} / ${element?.unit_details?.unit_name}`
                                : element.qty}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                overflowWrap: "break-word",
                                pageBreakInside: "avoid",
                                breakInside: "avoid",
                                fontSize: "14px",
                              }}
                            >
                              {element?.unit_second_details?.unit_name
                                ? `${element.second_qty} / ${element?.unit_second_details?.unit_name}`
                                : element.second_qty}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                overflowWrap: "break-word",
                                pageBreakInside: "avoid",
                                breakInside: "avoid",
                                fontSize: "14px",
                              }}
                            >
                              {element.rate}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                overflowWrap: "break-word",
                                pageBreakInside: "avoid",
                                breakInside: "avoid",
                                fontSize: "14px",
                              }}
                            >
                              {amountFormatJS(
                                Number(element.qty) * Number(element.rate)
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <table
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      align="center"
                      style={{ marginBottom: "0" }}
                    >
                      <tbody>
                        <tr style={{ pageBreakInside: "avoid" }}>
                          <td
                            style={{
                              fontSize: "16px", // Converted from 1rem
                              fontFamily: "'Open Sans', sans-serif",
                              color: "black",
                              lineHeight: "22px",
                              verticalAlign: "top",
                              textAlign: "right",
                              borderBottom: "1px solid gray", // Add bottom border
                              borderRight: "1px solid gray", // Add right border
                              fontWeight: 700,
                            }}
                          >
                            Sub Total
                          </td>
                          <td
                            style={{
                              fontSize: "16px", // Converted from 1rem
                              fontFamily: "'Open Sans', sans-serif",
                              color: "black",
                              lineHeight: "22px",
                              verticalAlign: "top",
                              textAlign: "right",
                              whiteSpace: "nowrap",
                              borderBottom: "1px solid gray", // Add bottom border
                              fontWeight: 700,
                            }}
                            width="208"
                          >
                            ₹
                            {amountFormatJS(
                              pdfData?.item_details?.reduce(
                                (accumulator, element) =>
                                  Number(accumulator) +
                                  Number(element.qty * element.rate),
                                0
                              )
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "16px", // Converted from 1rem
                              fontFamily: "'Open Sans', sans-serif",
                              color: "black",
                              lineHeight: "22px",
                              verticalAlign: "top",
                              textAlign: "right",
                              borderBottom: "1px solid gray", // Add bottom border
                              borderRight: "1px solid gray", // Add right border
                              fontWeight: 700,
                            }}
                          >
                            Discount
                          </td>
                          <td
                            style={{
                              fontSize: "16px", // Converted from 1rem
                              fontFamily: "'Open Sans', sans-serif",
                              color: "black",
                              lineHeight: "22px",
                              verticalAlign: "top",
                              textAlign: "right",
                              borderBottom: "1px solid gray", // Add bottom border
                              fontWeight: 700,
                            }}
                          >
                            -(₹
                            {amountFormatJS(
                              pdfData?.item_details.reduce(
                                (accumulator, element) =>
                                  Number(accumulator) +
                                  Number(element.disc_amt),
                                0
                              )
                            )}
                            )
                          </td>
                        </tr>
                        {gstGroupedTotals.map((gstItem, gstIndex) => (
                          <tr
                            key={gstIndex}
                            style={{ pageBreakInside: "avoid" }}
                          >
                            <td
                              style={{
                                fontSize: "16px", // Converted from 1rem
                                fontFamily: "'Open Sans', sans-serif",
                                color: "black",
                                lineHeight: "22px",
                                verticalAlign: "top",
                                textAlign: "right",
                                borderBottom: "1px solid gray", // Add bottom border
                                borderRight: "1px solid gray", // Add right border
                                fontWeight: 700,
                              }}
                            >
                              GST @{gstItem?.gst} %
                            </td>
                            <td
                              style={{
                                fontSize: "16px", // Converted from 1rem
                                fontFamily: "'Open Sans', sans-serif",
                                color: "black",
                                lineHeight: "22px",
                                verticalAlign: "top",
                                textAlign: "right",
                                borderBottom: "1px solid gray", // Add bottom border
                                fontWeight: 700,
                              }}
                            >
                              {amountFormatJS(gstItem?.total_amount)}
                            </td>
                          </tr>
                        ))}

                        <tr style={{ pageBreakInside: "avoid" }}>
                          <td
                            style={{
                              fontSize: "16px", // Converted from 1rem
                              fontFamily: "'Open Sans', sans-serif",
                              color: "black",
                              lineHeight: "22px",
                              verticalAlign: "top",
                              textAlign: "right",
                              borderBottom: "1px solid gray", // Add bottom border
                              borderRight: "1px solid gray", // Add right border
                              fontWeight: 700,
                            }}
                          >
                            <strong>Grand Total (Incl.Tax)</strong>
                          </td>
                          <td
                            style={{
                              fontSize: "16px", // Converted from 1rem
                              fontFamily: "'Open Sans', sans-serif",
                              color: "#000",
                              lineHeight: "22px",
                              verticalAlign: "top",
                              textAlign: "right",
                              borderBottom: "1px solid gray", // Add bottom border
                              fontWeight: 700,
                            }}
                          >
                            <strong>
                              ₹
                              {Math.round(
                                Number(
                                  pdfData?.item_details.reduce(
                                    (accumulator, element) =>
                                      Number(accumulator) +
                                      Number(element.qty * element.rate),
                                    0
                                  )
                                ) +
                                  Number(
                                    pdfData?.item_details.reduce(
                                      (accumulator, element) =>
                                        Number(accumulator) +
                                        Number(element.gst_amt),
                                      0
                                    )
                                  ) -
                                  Number(
                                    pdfData?.item_details.reduce(
                                      (accumulator, element) =>
                                        Number(accumulator) +
                                        Number(element.disc_amt),
                                      0
                                    )
                                  )
                              )}
                            </strong>
                          </td>
                        </tr>

                        <tr style={{ pageBreakInside: "avoid" }}>
                          <td
                            style={{
                              fontSize: "16px", // Converted from 1rem
                              fontFamily: "'Open Sans', sans-serif",
                              color: "black",
                              lineHeight: "22px",
                              verticalAlign: "top",
                              textAlign: "left",
                              borderBottom: "1px solid gray", // Add bottom border
                              fontWeight: 700,
                            }}
                          >
                            <strong>
                              Amount In Words Rupees:
                              {convertNumberToWordsWithCurrency(
                                Number(
                                  pdfData?.item_details.reduce(
                                    (accumulator, element) =>
                                      Number(accumulator) +
                                      Number(element.qty * element.rate),
                                    0
                                  )
                                ) +
                                  Number(
                                    pdfData?.item_details.reduce(
                                      (accumulator, element) =>
                                        Number(accumulator) +
                                        Number(element.gst_amt),
                                      0
                                    ) -
                                      Number(
                                        pdfData?.item_details.reduce(
                                          (accumulator, element) =>
                                            Number(accumulator) +
                                            Number(element.disc_amt),
                                          0
                                        )
                                      )
                                  ),
                                "rupees"
                              )}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table
                      style={{
                        borderCollapse: "collapse",
                        width: "100%",
                        marginBottom: "0",
                      }}
                    >
                      <thead>
                        <tr style={{ pageBreakInside: "avoid" }}>
                          <th colSpan="5" className="text-center">
                            {" "}
                            <p className="m-0" style={{ fontSize: "20px" }}>
                              Delivery&nbsp;Schedule
                            </p>{" "}
                          </th>
                        </tr>
                      </thead>
                    </table>
                    <table
                      style={{
                        borderCollapse: "collapse",
                        width: "100%",
                        marginBottom: "0",
                      }}
                    >
                      <thead>
                        <tr style={{ pageBreakInside: "avoid" }}>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "0 10px",
                              fontSize: "16px", // Converted from 1rem
                              width: "56px", // 3.5rem converted to pixels
                            }}
                          >
                            S.No.
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "0 10px",
                              fontSize: "16px", // Converted from 1rem
                              width: "128px", // 8rem converted to pixels
                            }}
                          >
                            Order Qty
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "0 10px",
                              fontSize: "16px", // Converted from 1rem
                              width: "144px", // 9rem converted to pixels
                            }}
                          >
                            SCHEDULE QTY
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "0 10px",
                              fontSize: "16px", // Converted from 1rem
                              width: "160px", // 10rem converted to pixels
                            }}
                          >
                            SCHEDULE DATE
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "0 10px",
                              fontSize: "16px", // Converted from 1rem
                            }}
                          >
                            Remarks
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {pdfData?.item_details.map((element, i) => (
                          <tr key={i} style={{ pageBreakInside: "avoid" }}>
                            <td
                              style={{
                                border: "1px solid black",
                                width: "32px",
                                fontSize: "14px",
                              }} // 2rem converted to pixels
                            >
                              {i + 1}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                overflowWrap: "break-word",
                                fontSize: "14px",
                              }}
                            >
                              {element?.unit_details?.unit_name
                                ? `${element.qty} / ${element?.unit_details?.unit_name}`
                                : element.qty}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                overflowWrap: "break-word",
                                fontSize: "14px",
                              }}
                            >
                              {element?.schedule_data?.map((element, idx) => (
                                <p
                                  key={idx}
                                  className="m-0 mb-0"
                                  style={{ color: "black", fontSize: "14px" }}
                                >
                                  Qty - {element?.quantity}
                                </p>
                              ))}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                overflowWrap: "break-word",
                                fontSize: "14px",
                              }}
                            >
                              {element?.schedule_data?.map((element, idx) => (
                                <p
                                  key={idx}
                                  className="m-0 "
                                  style={{ color: "black", fontSize: "14px" }}
                                >
                                  {formatDate(element?.schedule_date)}
                                </p>
                              ))}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                overflowWrap: "break-word",
                                fontSize: "14px",
                              }}
                            >
                              {element?.schedule_data?.map((element, idx) => (
                                <p
                                  key={idx}
                                  className="m-0 mb-0"
                                  style={{ color: "black", fontSize: "14px" }}
                                >
                                  {element?.remarks}
                                </p>
                              ))}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column", // Aligns content vertically
                      marginBottom: "100px",
                      // Prevents breaking within this container
                    }}
                  >
                    {/* Terms and Conditions Section */}
                    <div
                      style={{
                        padding: "8px",
                        borderRight: "2px solid black",
                        // Ensures the section doesn't break in the middle
                      }}
                    >
                      <h4
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginBottom: "15px", // Adjusts spacing for consistent alignment
                        }}
                      >
                        Terms And Conditions:
                      </h4>
                      {pdfData?.terms_and_conditions?.map((element, i) => (
                        <p
                          key={i}
                          style={{
                            color: "black",
                            fontSize: "14px",
                            marginBottom: "8px",
                            pageBreakInside: "avoid", // Ensures no break inside paragraphs
                          }}
                        >
                          {i + 1}. {element?.title}:{" "}
                          {element?.terms_conditions_name}
                        </p>
                      ))}
                    </div>

                    {/* Signature Section */}
                    {/* <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        pageBreakInside: "avoid", // Prevents breaking within this section
                        border: "2px solid black",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          pageBreakInside: "avoid", // Ensures no break inside the border container
                        }}
                      >
                        <div
                          style={{
                            width: "65%",
                            borderRight: "2px solid black",
                            marginLeft: "10px",
                          }}
                        >
                          <h3
                            style={{ textAlign: "start", fontWeight: "bold" }}
                          >
                            ACME INDIA INDUSTRIES LTD.
                          </h3>
                          <div style={{ display: "flex" }}>
                            <img
                              src={pdfBaseUrl + pdfData?.authorize_sign}
                              style={{
                                height: "89px",
                                width: "186px",
                                marginTop: "57px",
                                marginRight: "-143px",
                                display: "block", // Ensures the image stays centered
                              }}
                            />

                            <img
                              src={sign}
                              alt="Authorized Signatory"
                              style={{
                                height: "146px",
                                width: "263px",
                                display: "block", // Ensures the image stays centered
                              }}
                            />
                          </div>
                          <p
                            style={{
                              textAlign: "center",
                              fontWeight: 600,
                              fontSize: "20px",
                              marginBottom: "10px",
                            }}
                          >
                            Authorised Signatory
                          </p>
                        </div>
                        <div
                          style={{
                            width: "35%",
                            pageBreakInside: "avoid", // Prevents breaking inside this inner container
                          }}
                        >
                          <img
                            src={pdfBaseUrl + pdfData?.action_by_sign}
                            style={{
                              maxWidth: "176px",
                              height: "170px",
                              margin: "20px auto", // Centers the signature image
                              display: "block",
                            }}
                          />

                          <p
                            style={{
                              fontWeight: 600,
                              fontSize: "17px",
                              marginBottom: "10px", // Adds space below the text
                            }}
                          >
                            {pdfData?.action_by_name}
                          </p>
                          <p
                            style={{
                              fontWeight: 600,
                              fontSize: "20px",
                              marginBottom: "10px", // Adds space below the text
                            }}
                          >
                            Project Manager
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PurchaseOrderPreviewModal;
