import React from "react";

function GurantorIcons() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="35" cy="35" r="35" fill="white" />
      <path
        d="M33.8418 24.6855H35.1565C35.6905 24.6855 36.1233 25.1183 36.1233 25.6523C36.1233 26.1863 35.6903 26.6191 35.1565 26.6191H33.8418C33.308 26.6191 32.875 26.1863 32.875 25.6523C32.875 25.1183 33.308 24.6855 33.8418 24.6855Z"
        fill="#1A44A8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M46.7734 46.1992H50.0326C50.5666 46.1992 50.9993 46.632 50.9993 47.166V51.0329C50.9993 51.5669 50.5666 51.9997 50.0326 51.9997H18.9677C18.4337 51.9997 18.0009 51.5669 18.0009 51.0329V47.166C18.0009 46.632 18.4337 46.1992 18.9677 46.1992H22.2266V34.362H21.2598C20.7258 34.362 20.293 33.9289 20.293 33.3952V30.4948H18.9667C18.5342 30.4948 18.1545 30.2075 18.0367 29.7913C17.9188 29.3752 18.0916 28.9316 18.4599 28.7047L33.9931 19.1435C34.304 18.9522 34.696 18.9522 35.0067 19.1435L50.5399 28.7047C50.9082 28.9313 51.0812 29.3752 50.9633 29.7913C50.8455 30.2075 50.4656 30.4948 50.0331 30.4948H48.707V33.3952C48.707 33.9289 48.2739 34.362 47.7402 34.362H46.7734V46.1992ZM46.6181 28.5612L34.4999 21.102L22.3817 28.5612H46.6181ZM46.7734 30.4948H22.2266V32.4284H46.7734V30.4948ZM44.8398 46.1992V34.362H42.9062V46.1992H44.8398ZM40.9726 46.1992V34.362H37.4003V46.1992H40.9726ZM35.4667 46.1992V34.362H33.5331V46.1992H35.4667ZM31.5995 46.1992V34.362H28.0271V46.1992H31.5995ZM26.0935 46.1992V34.362H24.16V46.1992H26.0935ZM19.9345 50.0661H49.0658V48.1325H19.9345V50.0661Z"
        fill="#1A44A8"
      />
    </svg>
  );
}

export function BankHomeIcon() {
  return (
    <svg
      width="49"
      viewBox="0 0 49 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48.4697" height="38.5272" fill="#E8E8E8" />
      <path
        d="M27.9948 15.8192V16.115H30.2393V17.2633H27.9078C27.8034 18.0637 27.5714 18.6901 27.2118 19.1425C26.8639 19.5949 26.3535 19.9196 25.6807 20.1168C25.0195 20.314 24.138 20.4126 23.036 20.4126V21.7872C23.036 23.3531 23.7204 24.1361 25.0891 24.1361C26.4347 24.1361 27.1075 23.3821 27.1075 21.8742H28.569C28.5574 23.0341 28.25 23.9099 27.6468 24.5015C27.0437 25.093 26.1853 25.3888 25.0717 25.3888C23.9234 25.3888 23.0476 25.0698 22.4444 24.4319C21.8528 23.7939 21.5571 22.9007 21.5571 21.7524V19.2991C22.8794 19.2991 23.8538 19.2585 24.4801 19.1773C25.1181 19.0845 25.5821 18.8989 25.8721 18.6205C26.1621 18.3305 26.3477 17.8781 26.4289 17.2633H20.7741V16.115H26.4985V15.8018V14.2358H20.7741V13.0701H30.2393V14.2358H27.9948V15.8192Z"
        fill="#959595"
      />
    </svg>
  );
}
export function BankHomeSmallIcon() {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22.5" cy="22.5" r="22.5" fill="#EFEFEF" />
      <path
        d="M22.0811 15.6181H22.9178C23.2576 15.6181 23.533 15.8935 23.533 16.2333C23.533 16.5732 23.2575 16.8486 22.9178 16.8486H22.0811C21.7415 16.8486 21.4659 16.5732 21.4659 16.2333C21.4659 15.8935 21.7415 15.6181 22.0811 15.6181Z"
        fill="#1A44A8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.3104 29.3087H32.3844C32.7242 29.3087 32.9996 29.5841 32.9996 29.924V32.3848C32.9996 32.7246 32.7242 33 32.3844 33H12.6158C12.276 33 12.0006 32.7246 12.0006 32.3848V29.924C12.0006 29.5841 12.276 29.3087 12.6158 29.3087H14.6897V21.7759H14.0744C13.7346 21.7759 13.4592 21.5003 13.4592 21.1607V19.3149H12.6152C12.3399 19.3149 12.0983 19.1321 12.0233 18.8673C11.9484 18.6024 12.0583 18.3201 12.2927 18.1758L22.1774 12.0913C22.3753 11.9696 22.6247 11.9696 22.8224 12.0913L32.7072 18.1758C32.9416 18.32 33.0517 18.6024 32.9767 18.8673C32.9017 19.1321 32.6599 19.3149 32.3847 19.3149H31.5408V21.1607C31.5408 21.5003 31.2652 21.7759 30.9256 21.7759H30.3104V29.3087ZM30.2115 18.0845L22.4999 13.3377L14.7884 18.0845H30.2115ZM30.3104 19.3149H14.6897V20.5454H30.3104V19.3149ZM29.0799 29.3087V21.7759H27.8494V29.3087H29.0799ZM26.6189 29.3087V21.7759H24.3456V29.3087H26.6189ZM23.1152 29.3087V21.7759H21.8847V29.3087H23.1152ZM20.6542 29.3087V21.7759H18.3809V29.3087H20.6542ZM17.1504 29.3087V21.7759H15.92V29.3087H17.1504ZM13.2311 31.7695H31.7691V30.539H13.2311V31.7695Z"
        fill="#1A44A8"
      />
    </svg>
  );
}

export default GurantorIcons;
