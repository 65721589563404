import React from "react";

function AddressDetailForm({
  values,
  handleChange,
  countryDropdown,
  stateDropdown,
  cityDropdown,
  serverSideValidation,
  errors,
  touched,
  handleBlur
}) {
  return (
    <div>
      <div className="row g-3">
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Address Type<span className="text-danger">*</span>
          </label>
          <select
            className="form-control border-secondary "
            onChange={handleChange}
            onBlur={handleBlur} 
            value={values.address_type}
            name="address_type"
          >
            <option value="">Select</option>
            <option value="Work">Work</option>
            <option value="Home">Home</option>
          </select>
          <p className="text-danger m-0">
            {touched?.address_type && errors?.address_type
              ? errors?.address_type
              : serverSideValidation?.address_type}
          </p>
        </div>

        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Country<span className="text-danger">*</span>
          </label>
          <select
            className="form-control border-secondary "
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.country_id}
            name="country_id"
          >
            <option value={""}>Select</option>
            {countryDropdown?.map((element) => (
              <option value={element.id}>{element.country_name}</option>
            ))}
          </select>
          <p className="text-danger m-0">
            {touched?.country_id && errors?.country_id
              ? errors?.country_id
              : serverSideValidation?.country_id}
          </p>
        </div>

        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            State<span className="text-danger">*</span>
          </label>
          <select
            className="form-control border-secondary "
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.state_id}
            name="state_id"
          >
            <option value="">Select</option>
            {stateDropdown.map((element) => (
              <option key={element.id} value={element.id}>
                {element.state_name}
              </option>
            ))}
          </select>
          <p className="text-danger m-0">
            {touched?.state_id && errors?.state_id
              ? errors?.state_id
              : serverSideValidation?.state_id}
          </p>
        </div>

        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            City<span className="text-danger">*</span>
          </label>
          <select
            className="form-control border-secondary "
            city_id
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.city_id}
            name="city_id"
          >
            <option value="">Select</option>
            {cityDropdown?.map((element) => (
              <option value={element.id}>{element.city_name}</option>
            ))}
          </select>
          <p className="text-danger m-0">
            {touched?.city_id && errors?.city_id
              ? errors?.city_id
              : serverSideValidation?.city_id}
          </p>
        </div>

        <div className="col-6">
          <label htmlFor="" className="form-label fw-bold">
            Address<span className="text-danger">*</span>
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Address"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.address}
            name="address"
          ></input>
          <p className="text-danger m-0">
            {touched?.address && errors?.address
              ? errors?.address
              : serverSideValidation?.address}
          </p>
        </div>

        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Pin Code<span className="text-danger">*</span>
          </label>
          <input
            className="form-control border-secondary" 
            placeholder="Enter Pincode"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.pin_code}
            name="pin_code"
            minLength={6}
            maxLength={6}
          ></input>
          <p className="text-danger m-0">
            {touched?.pin_code && errors?.pin_code
              ? errors?.pin_code
              : serverSideValidation?.pin_code}
          </p>
        </div>

        {/* <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Email Address <span className="text-danger">*</span>
          </label>
          <input
            type="email"
            className="form-control border-secondary"
            placeholder="Enter Email Address"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.email}
            name="email"
          ></input>
          <p className="text-danger m-0">
            {touched?.email && errors?.email
              ? errors?.email
              : serverSideValidation?.email}
          </p>
        </div> */}
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Telephone 
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Telephone Number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.telephone}
            name="telephone"
          ></input>
          <p className="text-danger m-0">
            {touched?.telephone && errors?.telephone
              ? errors?.telephone
              : serverSideValidation?.telephone}
          </p>
        </div>
        {/* <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Mobile <span className="text-danger">*</span>
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Mobile Number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.mobile}
            name="mobile"  
            minLength={10}
            maxLength={10}
          ></input>
          <p className="text-danger m-0">
            {touched?.mobile && errors?.mobile
              ? errors?.mobile
              : serverSideValidation?.mobile}
          </p>
        </div> */}
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Fax No
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Fax Number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.fax_no}
            name="fax_no"
          ></input>
          <p className="text-danger m-0">
            {touched?.fax_no && errors?.fax_no
              ? errors?.fax_no
              : serverSideValidation?.fax_no}
          </p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold">
            Website
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Website"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.website}
            name="website"
          ></input>
          <p className="text-danger m-0">
            {touched?.website && errors?.website
              ? errors?.website
              : serverSideValidation?.website}
          </p>
        </div>
      </div>
    </div>
  );
}

export default AddressDetailForm;
