import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { GoProjectSymlink } from "react-icons/go";
import Accordion from "react-bootstrap/Accordion";
import { IoIosPeople, IoMdSettings } from "react-icons/io";
import { GoDotFill } from "react-icons/go";
import goodsReceipt from "../Assets/GoodsReceipt.png";
import requistion from "../Assets/Requistion.png";
import truck from "../Assets/truck.png";
import { GoHome } from "react-icons/go";
import { GoPackage } from "react-icons/go";
import { GoPackageDependencies } from "react-icons/go";
import { IoDocumentTextOutline } from "react-icons/io5";
import { BsFileText } from "react-icons/bs";
import { PiTruckLight } from "react-icons/pi";
import { IoDocumentsOutline } from "react-icons/io5";
import { MdOutlineInventory2 } from "react-icons/md";
import { FaFileInvoice } from "react-icons/fa";

function StoreAsidebar() {
  const { user } = useSelector((state) => ({ ...state.auth }));
  const imageBaseUrl = process.env.REACT_APP_BASE_URL;
  const profileimage = user?.response?.userDetails?.profileimage;
  const userName = user?.response?.userDetails?.name;
  const email = user?.response?.userDetails?.email;
  const logo = process.env.REACT_APP_LOGO_URL;
  return (
    <>
      <div className="left-sidebar purchase-asidebar">
        <div className="brand">
          <Link to="/dashboard" className="logo">
            <span>
              <img
                src={logo}
                alt="logo-large"
                className="logo-lg logo-light"
                style={{ width: "130px", height: "70px" }}
              />
              <img
                src={logo}
                alt="logo-large"
                className="logo-lg logo-dark"
                style={{ width: "130px", height: "70px" }}
              />
            </span>
          </Link>
        </div>
        <div className="sidebar-user-pro media border-end">
          <div className="position-relative mx-auto">
            <img
              src={imageBaseUrl + profileimage}
              alt="user"
              className="rounded-circle thumb-md"
            />
          </div>
          <div className="media-body ms-2 user-detail align-self-center">
            <h5 className="font-14 m-0 fw-bold">{userName} </h5>
            <p
              className="opacity-50 mb-0"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "78%",
              }}
            >
              {email}
            </p>
          </div>
        </div>
        <div className="border-end">
          <ul className="nav nav-tabs menu-tab nav-justified" role="tablist">
            <li className="nav-item text-light fw-bold">STORE DEPARTMENT</li>
          </ul>
        </div>
        <div className="menu-content h-100" data-simplebar>
          <div className="menu-body navbar-vertical">
            <div
              className="collapse navbar-collapse tab-content"
              id="sidebarCollapse"
            >
              <ul
                className="navbar-nav tab-pane active"
                id="Main"
                role="tabpanel"
              >
                <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                  }}
                >
                  <NavLink className="inactive-link" to="/dashboard">
                    <GoHome></GoHome> &nbsp;&nbsp; Dashboard
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                  }}
                >
                  <NavLink className="inactive-link" to="/GoodReceiptNote">
                    <GoPackage /> &nbsp;&nbsp; Goods Receipt Note
                  </NavLink>
                </li>
                {/* <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                  }}
                >
                  <NavLink className="inactive-link" to="/matarial-in">
                    <GoPackageDependencies /> &nbsp;&nbsp; Material In
                  </NavLink>
                </li> */}

                <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                  }}
                >
                  <NavLink className="inactive-link" to="/requisition-list">
                    <IoDocumentTextOutline /> &nbsp;&nbsp; Requisition Form
                  </NavLink>
                </li>

                <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                  }}
                >
                  <NavLink className="inactive-link" to="/deliveryChallan">
                    <BsFileText /> &nbsp;&nbsp; Delivery Challan
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                  }}
                >
                  <NavLink className="inactive-link" to="/listTaxInvoice">
                    <FaFileInvoice /> &nbsp;&nbsp; Tax Invoice
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                  }}
                >
                  <NavLink className="inactive-link" to="/item-master">
                    <FaFileInvoice /> &nbsp;&nbsp; Item Master
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                  }}
                >
                  <NavLink className="inactive-link" to="/listCustomerDetail">
                    <IoIosPeople /> &nbsp;&nbsp;Customer Detail
                  </NavLink>
                </li>

                <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                  }}
                >
                  <NavLink className="inactive-link" to="/vehicle-request-list">
                    <PiTruckLight />
                    &nbsp;&nbsp; Vehicle Request
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                  }}
                >
                  <NavLink className="inactive-link" to="/inventoryList">
                    <MdOutlineInventory2 /> &nbsp;&nbsp; Inventory
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                  }}
                >
                  <NavLink className="inactive-link" to="/QMS-List">
                    <IoDocumentsOutline /> &nbsp;&nbsp; QMS
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        ;
      </div>
    </>
  );
}

export default StoreAsidebar;
