import React from "react";
import { formatDate } from "../../../constant";

function DeliveryScheduleTable({ data, page, rowsPerPage }) {
  const getStatusColor = (status) => {
    switch (status) {
      case "Delayed":
        return "#DC3545"; // Red
      case "Scheduled":
        return "#007BFF"; // Blue
      case "On Time":
        return "#28A745"; // Green
      case "Early":
        return "#6F42C1"; // Purple
      default:
        return "black";
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive" style={{ height: "70vh" }}>
          <table className="table">
            <thead
              className="delivery-schedule-heading"
              style={{ position: "sticky", top: "-1px" }}
            >
              <tr>
                <th>S.NO.</th>
                <th>PROJECT ID/RAILWAY PO.No.</th>
                <th>PO NO.</th>
                <th>SCHEDULE DATE</th>
                <th>SUPPLIER</th>
                <th>STORE</th>
                <th>GRN NO.</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody className="delivery-schedule-body">
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={item.id}>
                    <td>{(page - 1) * rowsPerPage + index + 1}</td>
                    <td>
                      {item.pd_projectTitle || "---"}
                      <p className="">{item.pd_poloaNo}</p>
                    </td>
                    <td>{item.purchase_order_no || "---"}</td>
                    <td>{formatDate(item.schedule_date) || "---"}</td>
                    <td>{item.supplier || "---"}</td>
                    <td>{item.store_name || "---"}</td>
                    <td>{item.grn_no || "---"}</td>
                    <td>
                      <span
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                          fontWeight: "bold",
                          color: getStatusColor(item.schedule_status || "---"),
                        }}
                      >
                        <span
                          style={{
                            width: "6px",
                            height: "6px",
                            borderRadius: "50%",
                            backgroundColor: getStatusColor(
                              item.schedule_status || "---"
                            ),
                            display: "inline-block",
                            marginRight: "6px",
                          }}
                        ></span>
                        {item.schedule_status || "---"}
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="8"
                    style={{ textAlign: "center", fontStyle: "italic" }}
                  >
                    No Delivery to show Currently
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default DeliveryScheduleTable;
